import React from "react";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import FormModal from "../../../widgets/FormModal";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {
  AddendumMutation,
  AddendumMutationVariables,
  AddendumReasonEnum,
  CrossMutation, CrossMutationVariables, DictKeyEnum
} from "../../../../libs/api/graphqlTypes";
import {ADDENDUM, CROSS} from "../../../../libs/api/commands";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";
import useGetDicti from "../../common/hooks/useGetDicti";
import {FieldsBuilder} from "../../../../libs/uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "./sendPayLinkModal/styles";
import Button from "../../../../libs/uiKit/button";
import {Modal} from "../../../../libs/uiKit/modal";
import {IForm} from "../../../../libs/uiKit/fieldsBuilder/types";
import {useForm} from "react-hook-form";
import {StyledCrossResult} from "./styles";


const CrossModal = (props: IModalProps) => {

  const { id } = useParams<{ id?: string }>();

  const { data } = props;
  const { dateBegin } = data;

  const {setNotification} = useNotification();

  const { data: propertySums, loading: loadingPropertySums } = useGetDicti(DictKeyEnum.CrosssaleProperty);
  const { data: propertyInteriors, loading: loadingPropertyInteriors } = useGetDicti(DictKeyEnum.CrossaleInterior);
  const { data: propertyResponsibilities, loading: loadingPropertyResponsibilities } = useGetDicti(DictKeyEnum.CrossaleResponsibility);

  const [statePremiumSum, setStatePremiumSum] = React.useState<number|undefined>(undefined);
  const [stateRegister, setStateRegister] = React.useState<boolean>(false);

  const [cross, {loading: loadingCross}] = useMutation<CrossMutation, CrossMutationVariables>(CROSS, {
    refetchQueries: ['loadContract'], awaitRefetchQueries: true
  });

  const onSubmit = (formData: any) => {
    if(!id) return;

    cross({
      variables: {
        id: parseInt(id, 10),
        date_sign: formatDateToStringRu(formData.date),
        responsibility: parseInt(formData.responsibility?.value, 10),
        interior: parseInt(formData.interior?.value, 10),
        property: parseInt(formData.property?.value, 10),
        register: stateRegister,
      }
    }).then((data) => {
      if(stateRegister){
        props.onClose();
      } else {
        setStatePremiumSum(data.data?.cross || 0);
      }
    })
      .catch(error => setNotification({type: "error", text: error}))
  }

  const formHook = useForm({mode: "onBlur"});
  const onFormSubmit = formHook.handleSubmit(onSubmit)

  const [interior, property, responsibility] = formHook.watch(["interior", "property", "responsibility"]);

  const isCalculated = (statePremiumSum !== undefined);

  const form: IForm = {
    formHook,
    hotReload: true,
    fields: [
      {
        field: {
          fieldName: "date",
          fieldType: "date",
          validationType: "minMaxDate",
          defaultValue: dayjs(dateBegin, 'DD.MM.YYYY').add(5, 'day').toDate(),
          minDate: dayjs(dateBegin, 'DD.MM.YYYY').add(5, 'day').toDate(),
          maxDate: dayjs(dateBegin, 'DD.MM.YYYY').add(90, 'day').toDate(),
          errorMessage: "Выберите дату",
          placeholder: "Дата начала",
          isRequired: true,
        }
      },
      {
        field: {
          fieldName: "interior",
          fieldType: "select",
          isLoading: loadingPropertyInteriors,
          errorMessage: "Выберите сумму",
          placeholder: "Внутренняя отделка",
          items: propertyInteriors,
          isClearable: true,
        }
      },
      {
        field: {
          fieldName: "responsibility",
          fieldType: "select",
          isLoading: loadingPropertyResponsibilities,
          errorMessage: "Укажите сумму",
          placeholder: "Гражданская ответственность",
          items: propertyResponsibilities,
          isClearable: true,
        }
      },
      {
        field: {
          fieldName: "property",
          fieldType: "select",
          isLoading: loadingPropertySums,
          errorMessage: "Укажите сумму",
          placeholder: "Движимое имущество",
          items: propertySums,
          isClearable: true,

        }
      },
    ]
  };

  return (
    <Modal {...props} header="Защита ипотеки" isLoading={false}>
      <form onSubmit={onFormSubmit}>
        <FieldsBuilder {...form}/>
        {isCalculated && <StyledCrossResult>Стоимость полиса: {statePremiumSum} RUB</StyledCrossResult>}
        <StyledButtonsGroup style={{gridTemplateColumns: isCalculated ? "1fr 1fr 1fr" : "1fr 1fr"}}>
          <Button
            isLoading={loadingCross}
            appearance="filled"
            type="submit"
            isDisabled={!interior && !property && !responsibility}
            onClick={() => setStateRegister(false)}>
            {isCalculated ? "Пересчитать" : "Рассчитать"}
          </Button>
          {isCalculated && <Button isLoading={loadingCross} appearance="filled" type="submit" onClick={() => setStateRegister(true)}>
              Оформить
          </Button>}
          <Button appearance="transparent" onClick={props.onClose}>Отмена</Button>
        </StyledButtonsGroup>
      </form>
    </Modal>
  );
}

export default CrossModal;