import React from 'react';
import { useTitleContext } from './context';
import { AddIcon } from '../../../../../../icons/ui/Add';
import Button from '../../../../../../libs/uiKit/button';
import { StyledSubjectHeader } from './styles';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import TitleIgniter from '../../../widgets/title';

const TitleUi: React.FC = () => {
	const { titles, addTitleHandler, deleteTitleHandler } = useTitleContext();

	return (
		<>
			{titles.map(title => (
				<React.Fragment key={`title-${title.number}`}>
					{title.number > 0 && (
						<StyledSubjectHeader>
							<Heading3>Объект страхования {title.number + 1}</Heading3>
							<Button appearance='icon' onClick={(): void => deleteTitleHandler(title.number)} icon={<TrashIcon />} />
						</StyledSubjectHeader>
					)}
					<TitleIgniter key={`Title-${title.number}`} {...title} />
				</React.Fragment>
			))}
			<Button appearance='link' iconBefore={<AddIcon />} onClick={addTitleHandler}>
				Добавить объект страхования
			</Button>
		</>
	);
};

export default React.memo(TitleUi);
