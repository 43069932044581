import React from "react";
import MainLayoutIgniter from "../common/mainLayout";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";

const Error500Page: React.FC = () => {

    const {
        lastError
    } = useGlobalContext();

    return (
        <MainLayoutIgniter>
            <h1>Ошибка</h1>
            <h4 style={{color: "red"}}>{lastError?.message}</h4>
        </MainLayoutIgniter>
    );
}

export default React.memo(Error500Page);