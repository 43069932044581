import React from 'react';
import { useDispatch } from 'react-redux';
import { onboardingSlice } from '../ds/redux/onboarding';
import { useTypedSelector } from '../../../../apps/main/ds/redux';

const useOnboarding = (): { isOpen: boolean; switchOnboarding: (isOpen: boolean) => void } => {
	const { isOpen } = useTypedSelector(store => store.onboarding);
	const dispatch = useDispatch();

	const switchOnboarding = React.useCallback(
		(currentIsOpen: boolean): void => {
			dispatch(onboardingSlice.actions.switchOnboarding(currentIsOpen));
		},
		[dispatch]
	);

	return {
		isOpen,
		switchOnboarding,
	};
};

export default useOnboarding;
