import styled from 'styled-components';
import { blue, blue3, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledFeatures = styled.div`
	max-width: 46rem;
	h1 {
		color: ${blue};
		margin-bottom: 3rem;
	}
`;

export const StyledFeaturesWrapper = styled.div`
	padding: 4rem 1.5rem;
	min-height: calc(100vh - 8rem);
	background: ${blue3};
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
		min-height: calc(100vh - 4rem);
	}
`;

export const StyledLoginFormWrapper = styled.div`
	padding: 4rem 1.5rem;
	min-height: calc(100vh - 8rem);
	background: ${white};
	display: flex;
	justify-content: center;

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
		min-height: calc(100vh - 4rem);
	}
`;
export const StyledLoginFormLayout = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 36rem;
	width: 100%;
`;
export const StyledLoginForm = styled.div`
	h1 {
		margin-bottom: 3rem;
	}
	.linkButton {
		margin-top: 3rem;
	}

	@media screen and (max-width: 767px) {
		.linkButton {
			margin-top: 2rem;
		}
	}
`;

export const StyledLoginLogo = styled.div`
	cursor: pointer;
	width: fit-content;
	margin-bottom: 3rem;
`;
