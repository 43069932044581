import React from 'react';
import { ANIMATION_SEC } from '../globalStyles';

export const SelectArrowIcon: React.FC<{ direction: 'top' | 'down' | 'left' | 'right' }> = ({ direction }) => {
	let deg: number = 0;

	switch (direction) {
		case 'left':
			deg = -90;
			break;
		case 'right':
			deg = 90;
			break;
		case 'top':
			deg = 180;
			break;
		case 'down':
			deg = 0;
			break;
		default:
			break;
	}
	return (
		<div
			style={{
				transition: `all ${ANIMATION_SEC}s`,
				transform: `rotate(${deg}deg)`,
				transformOrigin: '0.5rem 0.6rem',
			}}
		>
			<svg width='0.9rem' height='0.4rem' viewBox='0 0 9 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0L4.5 4L9 0L0 0Z' fill='currentColor' />
			</svg>
		</div>
	);
};
