import { createSlice } from '@reduxjs/toolkit';

const isLeftMenuOpen: boolean = localStorage.getItem('leftMenu') === 'true';
const initialState = {
	isOpen: isLeftMenuOpen,
};

export const leftMenuSlice = createSlice({
	name: 'leftMenu',
	initialState,
	reducers: {
		switchLeftMenu: state => {
			localStorage.setItem('leftMenu', !state.isOpen ? 'true' : 'false');
			state.isOpen = !state.isOpen;
		},
	},
});

export const leftMenuReducer: typeof leftMenuSlice.reducer = leftMenuSlice.reducer;
