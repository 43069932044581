import React from 'react';
import SettingsUi from './ui';
import SettingsContextProvider from './ui/context';

const SettingsPage: React.FC = () => (
	<SettingsContextProvider>
		<SettingsUi />
	</SettingsContextProvider>
);

export default React.memo(SettingsPage);
