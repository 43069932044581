import React from 'react';
import RolesTabUi from './ui';
import { RolesTabContextProvider } from './ui/context';

const RolesTabIgniter: React.FC = () => (
	<RolesTabContextProvider>
		<RolesTabUi />
	</RolesTabContextProvider>
);

export default React.memo(RolesTabIgniter);
