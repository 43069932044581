import React from 'react';
import { GroupItem } from './context';
import { StyledGroupToggle, StyledGroupToggleButtons, StyledGroupToggleHeader } from './styles';
import { GroupToggleArrowIcon } from '../../../../../../icons/ui/GroupToggleArrow';
import { Heading4 } from '../../../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../../../libs/uiKit/button';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import { PencilIcon } from '../../../../../../icons/ui/Pencil';
import { AddIcon } from '../../../../../../icons/ui/Add';
import GroupToggleBody from './GroupToggleBody';
import { useRolesContext } from '../../../ui/context';

const GroupToggle: React.FC<{ group: GroupItem }> = ({ group }) => {
	const { openDeleteGroupHandler, openCreateUserHandler, openCreateGroupHandler } = useRolesContext();
	const [stateToggleOpen, setToggleOpen] = React.useState<boolean>(false);

	return (
		<>
			<StyledGroupToggle>
				<StyledGroupToggleHeader onClick={(): void => setToggleOpen(state => !state)}>
					<GroupToggleArrowIcon direction={stateToggleOpen ? 'down' : 'right'} />
					<Heading4>{group.name}</Heading4>
				</StyledGroupToggleHeader>

				{!group.specialType && (
					<StyledGroupToggleButtons>
						<Button tooltip='Удалить группу' onClick={(): void => openDeleteGroupHandler(group.id)} appearance='icon' icon={<TrashIcon />} />
						<Button tooltip='Редактировать' onClick={(): void => openCreateGroupHandler({ ...group, isEdit: true })} appearance='icon' icon={<PencilIcon />} />
						<Button tooltip='Добавить пользователя' onClick={(): void => openCreateUserHandler({ groupId: group.id || undefined })} appearance='icon' icon={<AddIcon />} />
					</StyledGroupToggleButtons>
				)}
			</StyledGroupToggle>
			{stateToggleOpen && <GroupToggleBody currentGroup={group} />}
		</>
	);
};

export default React.memo(GroupToggle);
