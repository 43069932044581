import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ME } from '../../../../libs/api/queries';
import { MeQuery, MeQueryVariables } from '../../../../libs/api/graphqlTypes';
import { AgreementSteps } from '../../../../modules/pages/createAgreement/ds/repositories/commands';
import { GqlApi, GQLApi } from '../../../../libs/api/gqlApi';
import { ErrorType, getGraphqlError } from '../../../../libs/utils/getGraphqlError';
import { BASE_URL, NEED_AUTH_ERROR } from '../../../../libs/utils/staticData';
import { Restrictions } from '../../../../modules/pages/setting/ui/context';
import useNotification from "../../../../modules/widgets/notifier/ui/hooks/useNitification";

type UserTypes = 'client' | 'admin' | 'user' | 'kias';

type AuthRedirectCmd = {
	error: ErrorType;
	needAuthCallback?: (error: string) => void;
	justErrorCallback?: (error: string) => void;
};

type GlobalContext = {
	api: GQLApi;
	user: {
		id: number | undefined;
		isSuper: boolean;
		type: UserTypes | undefined;
		restrictions: Restrictions;
		username: string | undefined;
		phone: string | undefined;
		email: string | undefined;
		roleId: number | undefined;
		groupId: number | undefined;
		curator: {
			email: string | undefined;
			phone: string | undefined;
			username: string | undefined;
		};
		banksOk: boolean;
	};
	routes: {
		// main lk
		authorizationDefault: () => string;
		authorizationClient: () => string;
		dashboard: () => string;
		permissionsManage: () => string;
		agreementByIdSeller: (id: string) => string;
		agreementByIdUnderwriter: (id: string) => string;
		contractById: (id: string) => string;
		contractAnketaById: (id: string) => string;
		invoiceById: (id: string) => string;
		createAgreementStep: (id: string, step: AgreementSteps) => string;
		agreementsLog: () => string;
		underwriterLog: () => string;
		contractsLog: () => string;
		prolongationLog: () => string;
		profile: () => string;
		roles: () => string;
		roleById: (id: string) => string;
		settings: () => string;
		reports: () => string;
		precalculation: () => string;
		error500: (error: ErrorType) => void;
		checkError500Handler: (cmd: AuthRedirectCmd) => void;
		redirectToAuthorization: () => void;

		// client
		policies: () => string;
	};
	mockData: boolean;
	lastError?: ErrorType;
};

const GlobalContext = createContext<GlobalContext>({} as GlobalContext);
export const useGlobalContext = (): GlobalContext => useContext(GlobalContext);

const checkError500Handler = ({ error, justErrorCallback, needAuthCallback }: AuthRedirectCmd): void => {
	const errorText = getGraphqlError(error);
	if (errorText === NEED_AUTH_ERROR) {
		const beforeAuthPage = window.location.pathname.replace(BASE_URL || '', '');
		if(beforeAuthPage && beforeAuthPage !== '/authorization'){
			localStorage.setItem('beforeAuthPage', beforeAuthPage);
			console.log('Save beforeAuthPage: ', beforeAuthPage);
		}
		if (needAuthCallback) needAuthCallback(errorText);
		return;
	}
	if (justErrorCallback) justErrorCallback(errorText);
};

export const GlobalContextProvider: React.FC = ({ children }) => {
	const navigate = useNavigate();
	const api = GqlApi();

	const [lastErrorState, setLastErrorState] = React.useState<ErrorType | null>(null);
	const { data: dataUser, loading: userLoading } = useQuery<MeQuery, MeQueryVariables>(ME, {
		onError: error => checkError500Handler({ error, justErrorCallback: () => {
			setLastErrorState(error);
			navigate('/error500');
			}, needAuthCallback: () => navigate('/authorization') }),
	});

	const redirectToAuthorization = React.useCallback(() => {
		if (dataUser?.me?.type === 'client') return navigate('/authorization/client');
		return navigate('/authorization');
	}, [dataUser?.me?.type, navigate]);

	const mockData: boolean = !!process.env.REACT_APP_MOCK_DATA;

	const value: GlobalContext = React.useMemo(
		() => ({
			api,
			user: {
				id: Number(dataUser?.me?.id) || undefined,
				isSuper: !!dataUser?.me?.isSuper,
				restrictions: (dataUser?.me?.restrictions || []).reduce<Restrictions>((prev, restriction) => ({ ...prev, [restriction?.tag || 'unknown']: restriction?.value }), {}),
				username: dataUser?.me?.username || undefined,
				phone: dataUser?.me?.phone || undefined,
				email: dataUser?.me?.email || undefined,
				roleId: dataUser?.me?.role?.id || undefined,
				groupId: dataUser?.me?.group?.id || undefined,
				type: dataUser?.me?.type as UserTypes,
				curator: {
					username: dataUser?.me?.group?.curator?.username,
					email: dataUser?.me?.group?.curator?.email || undefined,
					phone: dataUser?.me?.group?.curator?.phone || undefined,
				},
				banksOk: userLoading ? true : dataUser?.me?.banksOk || false,
			},
			routes: {
				redirectToAuthorization,
				authorizationDefault: () => '/authorization',
				authorizationClient: () => '/authorization/client',
				dashboard: () => '/',
				policies: () => '/policies',
				permissionsManage: () => '/permissions',
				agreementsLog: () => '/agreementsLog',
				underwriterLog: () => '/underwriterLog',
				agreementByIdSeller: id => `/agreement/${id}`,
				agreementByIdUnderwriter: id => `/underwriting/${id}`,
				contractById: id => `/contract/${id}`,
				contractAnketaById: id => `/contract/${id}/anketa`,
				invoiceById: id => `/createInvoice/${id}`,
				createAgreementStep: (id, step) => `/createAgreement/${id}/${step}`,
				contractsLog: () => '/contractsLog',
				prolongationLog: () => '/prolongationLog',
				profile: () => '/profile',
				roles: () => '/roles',
				precalculation: () => '/precalculation',
				roleById: id => `/roles/${id}`,
				error500: error => checkError500Handler({
					error,
					justErrorCallback: (error) => {
						console.log('error', error);
					},
					needAuthCallback: () => redirectToAuthorization()
				}),
				settings: () => '/settings',
				reports: () => '/reports',
				checkError500Handler,
			},
			mockData,
			lastError: lastErrorState,
		}),
		[api, dataUser, redirectToAuthorization, navigate, mockData, lastErrorState]
	);

	return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
