import React from 'react';
import { Heading2, Heading3, Heading4, Text4 } from '../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../libs/uiKit/button';
import Checkbox from '../../../../libs/uiKit/checkbox';
import { RiskResultItem, usePrecalculationContext } from './context';
import { DownloadIcon } from '../../../../icons/ui/Download';
import {
	StyledBankCard,
	StyledBankCardHeader,
	StyledBankCardsWrapper,
	StyledBankLogo,
	StyledBankPremium,
	StyledBankTable,
	StyledBankTableColumns,
	StyledBorrowerNeedOsm,
	StyledPrecalculationFormCard,
	StyledResultButtonsWrapper, StyledText4Oms, StyledText4OmsWrapper,
} from './styles';
import { QuestionIcon } from '../../../../icons/ui/Question';
import { stringToMoney } from '../../../../libs/uiKit/utils';
import risks from "./Risks";

const RiskTable: React.FC<RiskResultItem> = React.memo(props => {
	const { name, borrowers, isTableForLife } = props;
	const heads: string[] = [`Риск. ${name}`, 'Тариф, %', 'Страх. премия'];

	return (
		<StyledBankTable>
			<StyledBankTableColumns>
				{heads.map((head, index) => (
					<Text4 key={`lifeHead-${index}`}>{head}</Text4>
				))}

				{isTableForLife && (
					<>
						{(borrowers || []).map((borrower, index) => (
							<React.Fragment key={`borrowerRisk-${index}`}>
								<StyledBorrowerNeedOsm>
									<Text4>{borrower.name}</Text4>
									{borrower.needOms && <Button appearance='icon' icon={<QuestionIcon />} tooltip='Необходимо прохождение медицинского обследования.' />}
								</StyledBorrowerNeedOsm>
								<Text4 className='precalculationRow'>{stringToMoney(borrower.tariff, 4)}</Text4>
								<Text4 className='precalculationRow'>{stringToMoney(borrower.premium)}</Text4>
							</React.Fragment>
						))}
					</>
				)}
				{!isTableForLife && (
					<>
						{(borrowers || []).map((borrower, index) => (
							<React.Fragment key={`borrowerRisk-${index}`}>
								<Text4 className='precalculationRow'>{borrower.name}</Text4>
								<Text4 className='precalculationRow'>{stringToMoney(borrower.tariff, 4)}</Text4>
								<Text4 className='precalculationRow'>{stringToMoney(borrower.premium)}</Text4>
							</React.Fragment>
						))}
					</>
				)}
			</StyledBankTableColumns>
		</StyledBankTable>
	);
});

const Result: React.FC = () => {
	const { downloadAllHandler, downloadSelectedHandler, selectedLength, isLoading, changeSelectedPrecalcHandler, selectBankHandler, resultItems, isDisabledSelectBank } =
		usePrecalculationContext();

	if (resultItems.length === 0) return null;

	return (
		<StyledPrecalculationFormCard>
			<Heading2>Результаты расчета</Heading2>
			<StyledResultButtonsWrapper>
				<Button appearance='text' onClick={downloadAllHandler} isLoading={isLoading} iconBefore={<DownloadIcon />}>
					Выгрузить все
				</Button>
				{!!selectedLength && (
					<Button appearance='text' onClick={downloadSelectedHandler} isLoading={isLoading} iconBefore={<DownloadIcon />}>
						Выгрузить выбранные ({selectedLength})
					</Button>
				)}
			</StyledResultButtonsWrapper>

			<StyledBankCardsWrapper>
				{resultItems.map(({ isn, isnBank, name, sum, premium, logo, risks, isSelected, isExpress, noExpressReason }) => (
					<StyledBankCard key={`card-${isn}`}>
						<StyledBankCardHeader>
							<Checkbox onChange={(): void => changeSelectedPrecalcHandler(isn)} label={name} checked={isSelected} />
							<StyledBankLogo>
								<img alt={name} src={logo} />
							</StyledBankLogo>
						</StyledBankCardHeader>

						<StyledBankPremium>
							<Text4>Страховая премия, итог</Text4>
							<Heading3>{premium}</Heading3>
							<Text4>Страховая сумма</Text4>
							<Heading4>{sum}</Heading4>
						</StyledBankPremium>

						{risks.map((risk, index) => (
							<RiskTable key={`risk-${isn}-${index}`} {...risk} />
						))}

						{
							risks.filter(risk => risk.borrowers.filter(borrower => borrower.needOms).length > 0).length > 0 &&
							<StyledText4OmsWrapper>
								<StyledText4Oms>МЕДО</StyledText4Oms>
							</StyledText4OmsWrapper>
						}

						<Button isDisabled={isDisabledSelectBank} isLoading={isLoading} onClick={(): void => selectBankHandler(isnBank, false)} appearance='transparent'>
							Выбрать
						</Button>
						<Button style={{marginTop: "10px"}}
								isDisabled={isDisabledSelectBank || !isExpress}
								isLoading={isLoading}
								onClick={(): void => selectBankHandler(isnBank, true)}
								appearance='transparent'
								title={isExpress ? undefined : noExpressReason}
						>
							Оформить по декларации
						</Button>

					</StyledBankCard>
				))}
			</StyledBankCardsWrapper>
		</StyledPrecalculationFormCard>
	);
};

export default React.memo(Result);
