import styled, { css } from 'styled-components';
import { IStylesInputProps } from './types';
import { ANIMATION_SEC } from '../globalStyles';

export const StyledInputWrapper = styled.div<IStylesInputProps>`
	position: relative;
	> input:focus + label,
	> input:not(:placeholder-shown) + label {
		top: 0.6rem;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.input.placeholder.withValue.color};
		font-weight: 300;
	}

	.iconBefore,
	.iconAfter {
		position: absolute;
		top: 0;
		display: flex;
		height: 100%;
		align-items: center;
		color: ${({ theme }) => theme.input.icon.color};
		transition: all ${ANIMATION_SEC}s ease;
	}

	.iconBefore {
		left: 1.5rem;
	}

	.iconAfter {
		right: 1.5rem;
	}

	:hover {
		.iconBefore,
		.iconAfter {
			color: ${({ theme }) => theme.input.icon.hover.color};
		}
	}

	:focus-within {
		.iconBefore,
		.iconAfter {
			color: ${({ theme }) => theme.input.icon.focus.color};
		}
	}
`;

export const StyledInput = styled.input<IStylesInputProps>`
	box-sizing: border-box;
	font-size: 1.4rem;
	outline: none;
	padding: 1rem ${({ iconAfter }) => (iconAfter ? '4rem' : '1rem')} 0 ${({ iconBefore }) => (iconBefore ? '4rem' : '1rem')};
	height: 6rem;
	width: 100%;
	color: ${({ theme }) => theme.input.color};
	border: 1px solid ${({ theme }) => theme.input.border};
	background: ${({ theme }) => theme.input.bg};
	transition: all ${ANIMATION_SEC}s ease;

	:hover {
		border-color: ${({ theme }) => theme.input.hover.border};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.input.placeholder.hover.color};
		}
	}

	:focus {
		border-color: ${({ theme }) => theme.input.focus.border};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.input.placeholder.focus.color} !important;
		}
	}

	:disabled {
		color: ${({ theme }) => theme.input.disabled.color};
		cursor: no-drop;
		border-color: ${({ theme }) => theme.input.disabled.border};
		background: ${({ theme }) => theme.input.disabled.bg};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.input.placeholder.disabled.color};
		}
	}

	${({ isInvalid }) =>
		isInvalid &&
		css`
			border-color: ${({ theme }) => theme.input.invalid.border};
			background: ${({ theme }) => theme.input.invalid.bg};

			// placeholder
			& + * {
				color: ${({ theme }) => theme.input.placeholder.invalid.color} !important;
			}
		`}
`;

export const StyledInputLabel = styled.label<IStylesInputProps>`
	width: calc(100% - ${({ iconBefore }) => (iconBefore ? '4rem' : '1rem')});
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	color: ${({ theme }) => theme.input.placeholder.color};
	left: ${({ iconBefore }) => (iconBefore ? '4rem' : '1rem')};
	top: calc(50% - 1rem);
	font-size: 1.4rem;
	line-height: 2rem;
	pointer-events: none;
	transition: all ${ANIMATION_SEC}s ease;

	${({ isRequired }) =>
		isRequired &&
		css`
			::before {
				content: '* ';
				color: ${({ theme }) => theme.input.requiredColor};
			}
		`}
`;
