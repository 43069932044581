import React from 'react';
import { useDisapproveContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';
import { StyledButtonsGroup, StyledDisapproveWrapper } from './styles';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyles';

const DisapproveUi: React.FC = () => {
	const { isLoading, isOpen, onClose, onFormSubmit, form, isHaveRisks } = useDisapproveContext();

	return (
		<Modal onClose={onClose} isOpen={isOpen} header='Повторное согласование'>
			<StyledDisapproveWrapper>
				<form onSubmit={onFormSubmit}>
					{!isHaveRisks && <Heading3>Нет подходящих рисков</Heading3>}
					{isHaveRisks && (
						<>
							<Heading3>Выберите риски для согласования</Heading3>
							<FieldsBuilder {...form} />
						</>
					)}
					<StyledButtonsGroup>
						<Button type='submit' appearance='filled' isDisabled={!isHaveRisks} isLoading={isLoading}>
							Отправить
						</Button>
						<Button onClick={() => onClose()} appearance='transparent' isLoading={isLoading}>
							Отмена
						</Button>
					</StyledButtonsGroup>
				</form>
			</StyledDisapproveWrapper>
		</Modal>
	);
};
export default React.memo(DisapproveUi);
