import React from 'react';
import { useRoleTabContext } from './context';
import Button from '../../../../../../libs/uiKit/button';
import { StyledRoleHeader, StyledRoleItem, StyledSearch } from './styles';
import Input from '../../../../../../libs/uiKit/input';
import { SearchIcon } from '../../../../../../icons/ui/Search';
import { TrashIcon } from '../../../../../../libs/uiKit/icons/Trash';
import { Text2 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { useRolesContext } from '../../../ui/context';

const RolesTabUi: React.FC = () => {
	const { openDeleteRoleHandler } = useRolesContext();
	const { searchHandler, roles, roleClickHandler, isLoading } = useRoleTabContext();

	return (
		<>
			<StyledSearch>
				<Input placeholder='Поиск по названию роли' isLoading={isLoading} debounceMs={500} onChange={searchHandler} iconAfter={<SearchIcon />} />
			</StyledSearch>
			{roles.map(({ id, name }) => (
				<StyledRoleItem key={id}>
					<StyledRoleHeader onClick={(): void => roleClickHandler(id)}>
						<Text2>{name}</Text2>
					</StyledRoleHeader>
					<Button tooltip='Удалить роль' onClick={(): void => openDeleteRoleHandler(id)} appearance='icon' icon={<TrashIcon />} />
				</StyledRoleItem>
			))}
		</>
	);
};

export default React.memo(RolesTabUi);
