export const light = {
  blue5_20: "rgba(3, 102, 214, 0.2);",

  grey0: "#fafbfc",
  grey1: "#f6f8fa",
  grey2: "#e1e4e8",
  grey3: "#d1d5da",
  grey4: "#959da5",
  grey5: "#6a737d",
  grey6: "#586069",
  grey7: "#444d56",
  grey8: "#2f363d",
  grey9: "#24292e",

  blue0: "#f1f8ff",
  blue1: "#dbedff",
  blue2: "#c8e1ff",
  blue3: "#79b8ff",
  blue4: "#2188ff",
  blue5: "#0366d6",
  blue6: "#005cc5",
  blue7: "#044289",
  blue8: "#032f62",
  blue9: "#05264c",

  green0: "#f0fff4",
  green1: "#dcffe4",
  green2: "#bef5cb",
  green3: "#85e89d",
  green4: "#34d058",
  green5: "#28a745",
  green6: "#22863a",
  green7: "#176f2c",
  green8: "#165c26",
  green9: "#144620",

  purple0: "#f5f0ff",
  purple1: "#e6dcfd",
  purple2: "#d1bcf9",
  purple3: "#b392f0",
  purple4: "#8a63d2",
  purple5: "#6f42c1",
  purple6: "#5a32a3",
  purple7: "#4c2889",
  purple8: "#3a1d6e",
  purple9: "#29134e",

  yellow0: "#fffdef",
  yellow1: "#fffbdd",
  yellow2: "#fff5b1",
  yellow3: "#ffea7f",
  yellow4: "#ffdf5d",
  yellow5: "#ffd33d",
  yellow6: "#f9c513",
  yellow7: "#dbab09",
  yellow8: "#b08800",
  yellow9: "#735c0f",

  orange0: "#fff8f2",
  orange1: "#ffebda",
  orange2: "#ffd1ac",
  orange3: "#ffab70",
  orange4: "#fb8532",
  orange5: "#f66a0a",
  orange6: "#e36209",
  orange7: "#d15704",
  orange8: "#c24e00",
  orange9: "#a04100",

  red0: "#ffeef0",
  red1: "#ffdce0",
  red2: "#fdaeb7",
  red3: "#f97583",
  red4: "#ea4a5a",
  red5: "#d73a49",
  red6: "#cb2431",
  red7: "#b31d28",
  red8: "#9e1c23",
  red9: "#86181d",

  pink0: "#ffeef8",
  pink1: "#fedbf0",
  pink2: "#f9b3dd",
  pink3: "#f692ce",
  pink4: "#ec6cb9",
  pink5: "#ea4aaa",
  pink6: "#d03592",
  pink7: "#b93a86",
  pink8: "#99306f",
  pink9: "#6d224f",
};

export const dark = {
  blue5_20: "rgba(49, 109, 202, 0.2);",

  grey0: "#cdd9e5",
  grey1: "#adbac7",
  grey2: "#909dab",
  grey3: "#768390",
  grey4: "#636e7b",
  grey5: "#545d68",
  grey6: "#444c56",
  grey7: "#373e47",
  grey8: "#2d333b",
  grey9: "#22272e",

  blue0: "#c6e6ff",
  blue1: "#96d0ff",
  blue2: "#6cb6ff",
  blue3: "#539bf5",
  blue4: "#4184e4",
  blue5: "#316dca",
  blue6: "#255ab2",
  blue7: "#1b4b91",
  blue8: "#143d79",
  blue9: "#0f2d5c",

  green0: "#b4f1b4",
  green1: "#8ddb8c",
  green2: "#6bc46d",
  green3: "#57ab5a",
  green4: "#46954a",
  green5: "#347d39",
  green6: "#2b6a30",
  green7: "#245829",
  green8: "#1b4721",
  green9: "#113417",

  purple0: "#eedcff",
  purple1: "#dcbdfb",
  purple2: "#dcbdfb",
  purple3: "#b083f0",
  purple4: "#986ee2",
  purple5: "#8256d0",
  purple6: "#6b44bc",
  purple7: "#5936a2",
  purple8: "#472c82",
  purple9: "#352160",

  yellow0: "#fbe090",
  yellow1: "#eac55f",
  yellow2: "#daaa3f",
  yellow3: "#c69026",
  yellow4: "#ae7c14",
  yellow5: "#966600",
  yellow6: "#805400",
  yellow7: "#6c4400",
  yellow8: "#593600",
  yellow9: "#452700",

  orange0: "#ffddb0",
  orange1: "#ffbc6f",
  orange2: "#f69d50",
  orange3: "#e0823d",
  orange4: "#cc6b2c",
  orange5: "#ae5622",
  orange6: "#94471b",
  orange7: "#7f3913",
  orange8: "#682d0f",
  orange9: "#4d210c",

  red0: "#ffd8d3",
  red1: "#ffb8b0",
  red2: "#ff938a",
  red3: "#f47067",
  red4: "#e5534b",
  red5: "#c93c37",
  red6: "#ad2e2c",
  red7: "#922323",
  red8: "#78191b",
  red9: "#78191b",

  pink0: "#ffd7eb",
  pink1: "#ffb3d8",
  pink2: "#fc8dc7",
  pink3: "#e275ad",
  pink4: "#c96198",
  pink5: "#ae4c82",
  pink6: "#983b6e",
  pink7: "#7e325a",
  pink8: "#69264a",
  pink9: "#551639",
};
