import React from "react";
import {ITextAreaProps, TypeTextAreaRef} from "./types";
import {StyledTextArea, StyledTextAreaLabel, StyledTextAreaWrapper} from "./styles";
import FormMessage from "../formMessage";
import Loader from "../loader";

const TextArea: React.FC<ITextAreaProps> = React.forwardRef((props, ref: TypeTextAreaRef) => {
  const {
    isInvalid,
    isRequired,
    placeholder,
    isLoading,
    helpMessage,
    isDisabled,
    errorMessage,
    ...rest
  } = props;

  return (
    <StyledTextAreaWrapper>
      <StyledTextArea
        {...rest}
        ref={ref}
        disabled={!!isDisabled}
        placeholder=" "
        isInvalid={!!isInvalid}
      />
      {isLoading && <span className="iconAfter"><Loader loaderWidth={2}/></span>}
      {!!placeholder && <StyledTextAreaLabel isRequired={!!isRequired}>{placeholder}</StyledTextAreaLabel>}
      {!!errorMessage && <FormMessage type="error" message={errorMessage}/>}
      {helpMessage && !errorMessage && <FormMessage message={helpMessage} type="helper" />}
    </StyledTextAreaWrapper>
  );
});

export default React.memo(TextArea);
