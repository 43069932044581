import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { StyledNotifierWrapper, StyledStatusNotification } from './styles';
import { ModalDialogDone } from '../../../../icons/ui/ModalDialogDone';
import { AttentionIcon } from '../../../../icons/ui/Attention';
import { Text2 } from '../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../libs/uiKit/button';
import { CloseIcon } from '../../../../icons/ui/Close';
import { useNotifierContext } from './context';
import Loader from '../../../../libs/uiKit/loader';

const NotifierUi: React.FC = () => {
	const { notificationItems, deleteNotification } = useNotifierContext();

	return (
		<StyledNotifierWrapper animationTimeout={0.5}>
			<TransitionGroup component={null}>
				{notificationItems.map(notification => (
					<CSSTransition key={notification.id} timeout={500} classNames='notify'>
						<StyledStatusNotification>
							{notification.type === 'success' && <ModalDialogDone />}
							{notification.type === 'error' && <AttentionIcon />}
							{notification.type === 'warn' && <AttentionIcon />}
							{notification.type === 'loading' && <Loader loaderWidth={2} />}
							<Text2>{notification.description}</Text2>
							<Button appearance='icon' icon={<CloseIcon />} onClick={(): void => deleteNotification(notification.id)} />
						</StyledStatusNotification>
					</CSSTransition>
				))}
			</TransitionGroup>
		</StyledNotifierWrapper>
	);
};

export default React.memo(NotifierUi);
