import React, { createContext, useContext } from 'react';

export type AuthorizationType = 'client' | 'default';

type AuthorizationContextProps = {
	type: AuthorizationType;
	children: React.ReactNode;
};

type AuthorizationContext = {
	isShowLoginClient: boolean;
	isShowLoginDefault: boolean;
	isShowRecoverPassword: boolean;
	isShowRecoverPasswordButton: boolean;
	isShowBackToLoginButton: boolean;
	changeIsShowRecoverPassword: (isShow: boolean) => void;
};

const AuthorizationContext = createContext<AuthorizationContext>({} as AuthorizationContext);

export const useAuthorizationContext = (): AuthorizationContext => useContext(AuthorizationContext);

const AuthorizationContextProvider: React.FC<AuthorizationContextProps> = ({ type, children }) => {
	const [stateShowRecoverPassword, setShowRecoverPassword] = React.useState(false);

	const value: AuthorizationContext = React.useMemo(
		() => ({
			isShowLoginClient: type === 'client' && !stateShowRecoverPassword,
			isShowLoginDefault: type === 'default',
			isShowRecoverPassword: stateShowRecoverPassword,
			isShowRecoverPasswordButton: type === 'client' && !stateShowRecoverPassword,
			isShowBackToLoginButton: type === 'client' && stateShowRecoverPassword,
			changeIsShowRecoverPassword: isShow => setShowRecoverPassword(isShow),
		}),
		[type, stateShowRecoverPassword]
	);

	return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
};

export default React.memo(AuthorizationContextProvider);
