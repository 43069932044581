import React, { useRef } from 'react';
import { StyledFormMessage } from './styles';
import { IFormMessageProps } from './types';
import { TextCaption } from '../globalStyles';

const FormMessage: React.FC<IFormMessageProps> = props => {
	const { message, type } = props;
	const refMessage = useRef<HTMLDivElement | null>(null);
	React.useEffect(() => {
		if (!refMessage.current || type !== 'error') return;
		const rect = refMessage.current.getBoundingClientRect();
		if (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
			return;

		window.scrollTo({
			left: 0,
			top: rect.top,
			behavior: 'smooth',
		});
	}, [message, type]);

	return (
		<>
			{!!message && (
				<StyledFormMessage ref={refMessage} type={type}>
					<TextCaption>{message}</TextCaption>
				</StyledFormMessage>
			)}
		</>
	);
};

export default React.memo(FormMessage);
