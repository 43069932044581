import React, { createContext, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { HeadType, RowType } from '../../../../../libs/uiKit/table/types';
import { LoadChangeLogQuery, LoadChangeLogQueryVariables } from '../../../../../libs/api/graphqlTypes';
import { LOAD_CHANGE_LOG } from '../../../../../libs/api/queries';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { IModalProps } from '../../../../../libs/uiKit/modal/types';

export type AgreementChangeLogContextProps = {
	id: number | null;
	children?: React.ReactNode;
} & IModalProps;

type AgreementChangeLogContext = {
	head: HeadType;
	rows: RowType[];
	isLoading: boolean;
} & IModalProps;

const AgreementChangeLogContext = createContext<AgreementChangeLogContext>({} as AgreementChangeLogContext);

const TABLE_HEAD: HeadType = {
	cells: [
		{
			key: 'date',
			content: 'Дата',
		},
		{
			key: 'user',
			content: 'Инициатор',
		},
		{
			key: 'description',
			content: 'Событие',
		},
	],
};

export const useAgreementChangeLogContext = (): AgreementChangeLogContext => useContext(AgreementChangeLogContext);

export const AgreementChangeLogContextProvider: React.FC<AgreementChangeLogContextProps> = props => {
	const { children, id, isOpen, onClose } = props;
	const {
		routes: { error500 },
	} = useGlobalContext();

	const [getChangeLog, { data: dataChangeLog, loading: loadingChangeLog }] = useLazyQuery<LoadChangeLogQuery, LoadChangeLogQueryVariables>(LOAD_CHANGE_LOG, {
		onError: error => error500(error),
	});

	React.useEffect(() => {
		if (!id || !isOpen) return;
		getChangeLog({
			variables: {
				id,
			},
		});
	}, [getChangeLog, id, isOpen]);

	const { activity_log } = dataChangeLog?.loadAgreement || {};

	const rows: RowType[] = (activity_log || []).reduce<RowType[]>((prev, activity, index) => {
		if (!activity) return prev;
		const { added, eventName, user, description } = activity;
		return [
			...prev,
			{
				key: `row-changelog-${index}`,
				cells: [
					{ key: '1', content: added },
					{ key: '3', content: user?.username || '-' },
					{ key: '4', content: description },
				],
			},
		];
	}, []);

	const value: AgreementChangeLogContext = React.useMemo(
		() => ({
			isLoading: loadingChangeLog,
			head: TABLE_HEAD,
			rows,
			isOpen,
			onClose,
		}),
		[isOpen, loadingChangeLog, onClose, rows]
	);

	return <AgreementChangeLogContext.Provider value={value}>{children}</AgreementChangeLogContext.Provider>;
};
