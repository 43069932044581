import styled from 'styled-components';
import { grey20 } from '../../../../../../libs/uiKit/globalStyledComponents';

export const StyledQuestion = styled.div`
	> p:first-child {
		opacity: 0.5;
		margin-bottom: 0.5rem;
	}
	padding-bottom: 1rem;
	border-bottom: 1px solid ${grey20};
	margin-bottom: 1.5rem;
`;
