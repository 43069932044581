import styled from 'styled-components';
import { ANIMATION_SEC } from '../globalStyles';

export const StyledAccordion = styled.div<{ isOpen: boolean }>`
	width: 100%;
`;

export const StyledHeader = styled.div`
	display: flex;
	padding: 2rem;
	cursor: pointer;
`;

export const StyledToggle = styled.button<{ isOpen: boolean }>`
	padding: 0;
	margin-left: auto;
	background: none;
	width: 2.5rem;
	height: 2.5rem;
	border: none;
	position: relative;
	transition-property: transform, background;
	transition-duration: ${ANIMATION_SEC}s, 0.2s;
	transition-timing-function: ease-in-out, ease-out;

	&:hover {
		&::before,
		&::after {
			background: ${({ theme }) => theme.accordion.icon.hover.color};
		}
	}

	&::before,
	&::after {
		content: '';
		background: ${({ theme }) => theme.accordion.icon.color};
		position: absolute;
		height: 2px;
		left: 3px;
		right: 3px;
		top: 50%;
		transform: translateY(-50%);
		transition: inherit;
	}

	&::after {
		transform: ${props => (props.isOpen ? 'translateY(-50%)' : 'translateY(-50%) rotate(90deg)')};
	}
`;

export const BodyWrapper = styled.div<{ isOpen: boolean; resultHeight: number }>`
	overflow: hidden;
	transition: all ${ANIMATION_SEC}s ease-in-out;
	height: ${props => {
		return props.isOpen ? `${props.resultHeight}px` : '1px';
	}};
`;

export const StyledBody = styled.div`
	padding: 2rem;
	padding-top: 0;

	.acc-enter {
		display: block;
	}

	.acc-exit-done {
		display: none;
	}
`;

export const StyledEmptyToggle = styled.div`
	h5 {
		margin: 1.5rem 0;
	}
`;
