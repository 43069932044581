import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { IRangePickerFieldProps } from '../types';
import RangePicker from '../../rangePicker';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import { fieldsValidate } from '../utils/fieldValidation';
import getFieldError from '../utils/getFieldError';

const RangeField: React.FC<IRangePickerFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			value,
			hotReload,
			onChange,
			onBlur,
			// for validation
			minValue,
			maxValue,
			validationType,
			isRequired,
			errorMessage,
			regexpPattern,
			// other
			min,
			max,
			...rest
		},
		formHook: { control, setValue },
	} = props;
	const validationHandler = useCallback(
		(validateValue: string) =>
			fieldsValidate({
				value: validateValue,
				validationType: validationType || 'minMax',
				minValue: minValue || min,
				maxValue: maxValue || max,
				regexpPattern,
			}),
		[validationType, minValue, maxValue, regexpPattern, min, max]
	);
	const {
		field,
		formState: { errors },
	} = useController({
		name: fieldName,
		defaultValue,
		control,
		rules: {
			validate: (validateValue: string) => validationHandler(validateValue),
			required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
		},
	});
	const message = getFieldError(fieldName, errors);
	const registrationWithUserProps = {
		...field,
		onChange: (ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (onChange) onChange(ev);
			field.onChange(ev);
		},
		onBlur: (ev: React.FocusEvent<HTMLInputElement>): void => {
			if (onBlur) onBlur(ev);
			field.onBlur();
		},
	};

	React.useEffect(() => {
		if (value === undefined) return;
		setValue(fieldName, value);
	}, [fieldName, value, setValue]);

	React.useEffect(() => {
		if (defaultValue === undefined || !hotReload) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, hotReload, setValue]);

	return <RangePicker {...rest} min={min} max={max} {...registrationWithUserProps} isInvalid={!!message} isRequired={isRequired} errorMessage={message} />;
};

export default React.memo(RangeField);
