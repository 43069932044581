import React, { useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { WrapperDaData } from './styles';
import Input from '../input';
import { IDaDataProps, IDaDataValue, TypesDaData } from './types';
import { TypeInputRef } from '../input/types';
import Button from '../button';
import { CloseIcon } from '../icons/Close';
import DropDownDaDataContent from './dropDownDaDataContent';
import ClickAway from '../clickAway';

const dadataToken = `${process.env.REACT_APP_DADATA_TOKEN}`;
let timeout: ReturnType<typeof setTimeout>;

const getDefaultDaData = async (address: string, type: TypesDaData): Promise<IDaDataValue[] | null> => {
	const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${type}`;
	const options: RequestInit = {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Token ${dadataToken}`,
		},
		body: JSON.stringify({ query: address }),
	};
	const response = await fetch(url, options);
	if (response.status === 200) {
		const { suggestions } = (await response.json()) || {};
		if (suggestions) return suggestions;
		return null;
	}
	return null;
};

const DaData: React.FC<IDaDataProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { dropDownHelpMessage, value, defaultValue, isLoading, onChange, daDataType, emptyMessage, debounceMs = 500, ...rest } = props;

	const [stateInputValue, setInputValue] = React.useState<string>(defaultValue?.value || '');
	const [itemsFromDaData, setItemsFromDaData] = React.useState<IDaDataValue[] | null>(null);
	const [isSearching, setIsSearching] = React.useState<boolean>(false);
	const [dropDownContentIsOpen, setDropDownContentIsOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (value === undefined || value === null) return;
		setInputValue(value.value);
	}, [value]);

	const inputChangeHandler = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (!dropDownContentIsOpen) setDropDownContentIsOpen(true);
			setInputValue(ev.target.value);
			if (onChange) onChange(ev.target.value ? { value: ev.target.value } : null);
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (!ev.target.value) return;
				setIsSearching(true);
				getDefaultDaData(ev.target.value, daDataType)
					.then((data: IDaDataValue[] | null) => {
						if (!data) return;
						setItemsFromDaData(data);
					})
					.finally(() => setIsSearching(false));
			}, debounceMs);
		},
		[daDataType, debounceMs, dropDownContentIsOpen, onChange]
	);

	const clearHandler = useCallback((): void => setInputValue(''), []);

	const toggleDaDataHandler = useCallback((): void => setDropDownContentIsOpen(state => !state), []);

	const daDataChangeHandler = useCallback(
		(currentValue: IDaDataValue): void => {
			setInputValue(currentValue.value);
			if (dropDownContentIsOpen) setDropDownContentIsOpen(false);
			if (onChange) onChange(currentValue);
		},
		[onChange, dropDownContentIsOpen]
	);

	return (
		<WrapperDaData>
			<ClickAway isOpen={dropDownContentIsOpen} onClickAway={(): void => setDropDownContentIsOpen(false)}>
				<Input
					{...rest}
					isLoading={isLoading || isSearching}
					ref={ref}
					value={stateInputValue}
					iconAfter={!!stateInputValue && <Button onClick={(): void => clearHandler()} appearance='icon' icon={<CloseIcon />} />}
					onClick={(): void => toggleDaDataHandler()}
					onChange={inputChangeHandler}
				/>
				<CSSTransition
					in={dropDownContentIsOpen}
					timeout={300}
					mountOnEnter
					unmountOnExit
					classNames={{
						enterActive: 'listShow',
						enterDone: 'listShow',
						exitActive: 'listHide',
						exitDone: 'listHide',
					}}
				>
					<DropDownDaDataContent
						dropDownHelpMessage={dropDownHelpMessage}
						inputValue={stateInputValue}
						emptyMessage={emptyMessage}
						items={itemsFromDaData}
						daDataChangeHandler={daDataChangeHandler}
					/>
				</CSSTransition>
			</ClickAway>
		</WrapperDaData>
	);
});
export default React.memo(DaData);
