import React, { createContext, useContext } from 'react';
import dayjs from 'dayjs';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';

type CopyrightContext = {
	isAuthorization: boolean;
	isShowCuratorContacts: boolean;
	closeCuratorModal: () => void;
	openCuratorModal: () => void;
	isCuratorModalOpen: boolean;
	curatorName: string;
	curatorPhone: string;
	curatorEmail: string;
	phone: string;
	copyrightText: React.ReactNode;
};

const CopyrightContext = createContext<CopyrightContext>({} as CopyrightContext);

export const useCopyrightContext = (): CopyrightContext => useContext(CopyrightContext);

export const CopyrightContextProvider: React.FC<{ isAuthorization: boolean }> = ({ children, isAuthorization }) => {
	const {
		user: {
			curator: { username, email, phone },
		},
	} = useGlobalContext();
	const [stateCuratorModalIsOpen, setCuratorModalIsOpen] = React.useState<boolean>(false);

	const isHaveCurator: boolean = !!username && !!email && !!phone;

	const copyrightText = React.useMemo(
		() =>
			isAuthorization ? (
				<>© 2009–{dayjs().format('YYYY')} ООО «Абсолют Страхование»</>
			) : (
				<>
					© 2009–{dayjs().format('YYYY')} ООО <br /> «Абсолют Страхование»
				</>
			),
		[isAuthorization]
	);

	const value: CopyrightContext = React.useMemo(
		() => ({
			isAuthorization,
			isShowCuratorContacts: !isAuthorization && isHaveCurator,
			closeCuratorModal: () => setCuratorModalIsOpen(false),
			openCuratorModal: () => setCuratorModalIsOpen(true),
			isCuratorModalOpen: stateCuratorModalIsOpen,
			curatorName: username || 'unknown',
			curatorPhone: phone || 'unknown',
			curatorEmail: email || 'unknown',
			phone: '+7 (495) 025-77-77',
			copyrightText,
		}),
		[isAuthorization, username, phone, email, copyrightText, isHaveCurator, stateCuratorModalIsOpen]
	);

	return <CopyrightContext.Provider value={value}>{children}</CopyrightContext.Provider>;
};
