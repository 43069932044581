import React, { ChangeEvent, useCallback } from 'react';
import { StyledInputRange } from './styles';
import { IRangeProps } from './types';
import { TypeInputRef } from '../input/types';
import { getValueByStep, stringToNumber } from '../utils';

const NativeRange: React.FC<IRangeProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { min, max, step, onChange, defaultValue = min, value, isDisabled, ...rest } = props;

	const [stateRangeValue, setRangeValue] = React.useState<number>(Number(stringToNumber(getValueByStep(Number(defaultValue), step))));

	React.useEffect(() => {
		if (typeof value === 'undefined') return;
		setRangeValue(Number(stringToNumber(getValueByStep(Number(value), step))));
	}, [value, step]);

	const inputChangeHandler = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value: currentInputValue },
			} = ev;
			if (onChange) onChange(ev);
			if (typeof value !== 'undefined') return;
			setRangeValue(Number(currentInputValue));
		},
		[onChange, value]
	);

	return (
		<StyledInputRange
			{...rest}
			type='range'
			min={min}
			ref={ref}
			disabled={isDisabled}
			max={max}
			step={step}
			value={stateRangeValue}
			onChange={inputChangeHandler}
			percent={((stateRangeValue - min) / (max - min)) * 100}
		/>
	);
});

export default React.memo(NativeRange);
