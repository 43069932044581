import { gql } from '@apollo/client';

export const AUTH = gql`
	mutation signIn($username: String!, $password: String!, $isClient: Boolean) {
		signIn(username: $username, password: $password, isClient: $isClient) {
			id
			isSuper
			type
			permissions
		}
	}
`;

export const CREATE_UPDATE_ROLE = gql`
	mutation createUpdateRole($id: Int, $input: RoleInput) {
		role(id: $id, input: $input) {
			id
		}
	}
`;

export const CREATE_UPDATE_USER = gql`
	mutation createUpdateUser($id: Int, $input: UserInput) {
		user(id: $id, input: $input)
	}
`;

export const UPDATE_RESTRICTION = gql`
	mutation restrictions($userId: String, $groupId: String, $restrictions: [RestrictionInput], $comment: String) {
		restrictions(user_id: $userId, group_id: $groupId, restrictions: $restrictions, comment: $comment)
	}
`;

export const CREATE_UPDATE_GROUP = gql`
	mutation createUpdateGroup($id: Int, $input: GroupInput) {
		group(id: $id, input: $input) {
			id
		}
	}
`;

export const DELETE_GROUP = gql`
	mutation deleteGroup($id: Int!) {
		deleteGroup(id: $id)
	}
`;
export const DELETE_USER = gql`
	mutation deleteUser($id: Int!) {
		deleteUser(id: $id)
	}
`;
export const DELETE_ROLE = gql`
	mutation deleteRole($id: Int!) {
		deleteRole(id: $id)
	}
`;

export const DELETE_AGREEMENT = gql`
  mutation deleteAgreement($id: Int!) {
    deleteAgreement(id: $id)
  }
`;

export const LOGOUT = gql`
	mutation logout {
		logout
	}
`;

export const SAVE_AGREEMENT_DRAFT = gql`
	mutation saveAgreement($id: Int, $calcForm: CalcFormInput) {
		saveAgreement(id: $id, calcForm: $calcForm)
	}
`;

export const REGISTER_CONTRACT = gql`
	mutation registerAgreement($id: Int!) {
		registerAgreement(id: $id)
	}
`;

export const AGREEMENT_TO_DRAFT = gql`
    mutation agreementToDraft($id: Int!) {
        agreementToDraft(id: $id)
    }
`;

export const SIGN_CONTRACT = gql`
	mutation signAgreement($id: Int!) {
		signAgreement(id: $id)
	}
`;

export const UNSIGN_CONTRACT = gql`
    mutation unsignAgreement($id: Int!) {
        unsignAgreement(id: $id)
    }
`;

export const SAVE_ANKETA = gql`
	mutation saveAnketa($id: Int!, $contragentIsn: Int!, $questions: object) {
		saveAnketa(id: $id, contragentIsn: $contragentIsn, questions: $questions) {
			anketaIsn
		}
	}
`;

export const DOWNLOAD_PRECALC = gql`
	mutation printExpressCalculation($isn: [String!]) {
		printExpressCalculation(isn: $isn) {
			id
			filename
			url
		}
	}
`;

export const SEND_SMS = gql`
	mutation sendAnketaSms($id: Int!, $contragentIsn: Int!, $isDeclaration: Boolean) {
		sendAnketaSms(id: $id, contragentIsn: $contragentIsn, isDeclaration: $isDeclaration)
	}
`;

export const SIGN_ANKETA = gql`
	mutation signAnketa($id: Int!, $contragentIsn: Int!, $code: String!, $isDeclaration: Boolean) {
		signAnketa(id: $id, contragentIsn: $contragentIsn, code: $code, isDeclaration: $isDeclaration)
	}
`;

export const PRINT_MUTATION = gql`
	mutation print($isn: Int!, $type: PrintTypeEnum!, $template: PrintTemplateEnum, $templateIsn: Int, $email: String, $subject: String, $contragentIsn: Int) {
		print(isn: $isn, type: $type, template: $template, templateIsn: $templateIsn, email: $email, subject: $subject, contragentIsn: $contragentIsn) {
			id
			filename
			url
		}
	}
`;

export const DOWNLOAD_ALL_MUTATION = gql`
	mutation downloadAll($id: Int) {
		downloadAll(id: $id) {
			id
			filename
			url
		}
	}
`;

export const ADD_AGREEMENT_FILE = gql`
	mutation addAgreementFiles($agreementId: Int!, $files: [Upload], $context: AgreementFileContextEnum!) {
		addAgreementFiles(agreement_id: $agreementId, files: $files, context: $context) {
			id
		}
	}
`;

export const CALC_AGREEMENT = gql`
	mutation calculateAgreement($id: Int!) {
		calculateAgreement(id: $id)
	}
`;

export const CHANGE_UNDERWRITING = gql`
	mutation changeCalculation($id: Int, $input: ChangeCalculationInput) {
		changeCalculation(id: $id, input: $input)
	}
`;

export const CHANGE_AGREEMENT = gql`
	mutation changeAgreement($id: Int, $input: ChangeAgreementInput) {
		changeAgreement(id: $id, input: $input)
	}
`;

export const SAVE_MESSAGE = gql`
	mutation comment($id: Int!, $text: String!) {
		comment(id: $id, text: $text) {
			id
		}
	}
`;

export const VIEW_MESSAGE = gql`
	mutation viewComment($id: [Int!]) {
		viewComment(id: $id)
	}
`;

export const PREPARE_INVOICE = gql`
	mutation prepareInvoice($id: Int!, $isn: Int) {
		prepareInvoice(id: $id, isn: $isn)
	}
`;

export const SEND_PAYMENT_LINK = gql`
	mutation sendPaymentLink($agreementIsn: Int!, $phone: String, $email: String, $isSendFinish: Boolean, $agreementNo: String, $premiumSum: Float) {
		sendPaymentLink(agreementIsn: $agreementIsn, phone: $phone, email: $email, isSendFinish: $isSendFinish, agreementNo: $agreementNo, premiumSum: $premiumSum)
	}
`;

export const APPLY_KV = gql`
  mutation applyKv($id: Int!, $kv: Float!) {
    applyKv(id: $id, kv: $kv)
  }
`;

export const DISAPPROVE = gql`
  mutation disapprove($id: Int!, $risk_isns: [Int!]) {
    disapprove(id: $id, risk_isns: $risk_isns)
  }
`;

export const PROLONG = gql`
	mutation prolong($isn: String!, $date_sign: String, $sum: Float, $agent: LabelValueInput) {
		prolong(isn: $isn, date_sign: $date_sign, sum: $sum, agent: $agent)
	}
`;

export const UPDATE_CONTRAGENT_FILLING = gql`
  mutation updateContragentFilling($id: Int!, $contragentIsn: Int!, $fillingType: ContragentFillingTypeEnum) {
		updateContragentFilling(id: $id, contragentIsn: $contragentIsn, fillingType: $fillingType)
	}
`;

export const SEARCH_AGREEMENT = gql`
  mutation searchAgreement($number: String!) {
		searchAgreement(number: $number)
	}
`;

export const ADDENDUM = gql`
  mutation addendum($id: Int!, $reason: AddendumReasonEnum!, $exclude: AddendumExcludeInput, $recalc: AddendumRecalcInput) {
		addendum(id: $id, reason: $reason, exclude: $exclude, recalc: $recalc)
	}
`;

export const SEND_TO_SB = gql`
  mutation sendToSb($id: Int!, $input: SendToSbInput) {
    sendToSb(id: $id, input: $input)
  }
`;

export const CROSS = gql`
  mutation cross($id: Int!, $date_sign: String, $responsibility: Float, $interior: Float, $property: Float, $register: Boolean) {
		cross(id: $id, date_sign: $date_sign, responsibility: $responsibility, interior: $interior, property: $property, register: $register)
	}
`;

export const ASSIGN_AGREEMENT = gql`
  mutation assignAgreement($id: Int!) {
    assignAgreement(id: $id)
  }
`;

export const CONTINUE_WITH_ANKETA = gql`
  mutation continueWithAnketa($id: Int!, $anketaIsn: Int!) {
    continueWithAnketa(id: $id, anketaIsn: $anketaIsn)
  }
`;

export const MOCK = gql`
  mutation mock($key: String!) {
    mock(key: $key)
  }
`;

export const ENTER_ADDENDUM_MODE = gql`
  mutation enterAddendumMode($id: Int!, $life: Boolean, $property: Boolean, $titul: Boolean) {
    enterAddendumMode(id: $id, life: $life, property: $property, titul: $titul)
  }
`;

export const COPY_ANKETA_FOR_AGREEMENT = gql`
  mutation copyAnketaForAgreement($id: Int!, $anketaIsn: Int!, $forEdit: Boolean) {
    copyAnketaForAgreement(id: $id, anketaIsn: $anketaIsn, forEdit: $forEdit)
  }
`;

export const UPLOAD_FILES = gql`
  mutation uploadFiles($id: Int!) {
		uploadFiles(id: $id)
	}
`;

export const CALCULATE_AGREEMENT_FINISH = gql`
  mutation calculateAgreementFinish($id: Int!) {
     calculateAgreementFinish(id: $id)
  }
`;

export const CREATE_REPORT = gql`
	mutation createReport($dateBeg: String!, $dateEnd: String!,$frontNodeIsn: Int) {
	  createReport(dateBeg: $dateBeg, dateEnd: $dateEnd, frontNodeIsn: $frontNodeIsn) {
	    id
      filename
      url
    }
  }
`;