import React                          from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useMutation}                   from "@apollo/react-hooks";
import useGetDicti from "../../common/hooks/useGetDicti";
import {DictKeyEnum} from "../../../../libs/api/graphqlTypes";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";

import {IForm, IFormField} from "../../../../libs/uiKit/fieldsBuilder/types";
import {Modal} from "../../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../../libs/uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../invoice/widgets/sendPayLinkModal/styles";
import Button from "../../../../libs/uiKit/button";
import {PROLONG} from "../../../../libs/api/commands";
import {stringToNumber} from "../../../../libs/uiKit/utils";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";

const ProlongationModal = (props: IModalProps) => {
  const {setNotification} = useNotification();
  
  const {data} = props;
  
  const {
    routes: { contractById },
  } = useGlobalContext();
  
  const navigate = useNavigate();
  
  const {contractIsn} = data;
  
  const { data: agentsItems, loading: loadingAgents } = useGetDicti(DictKeyEnum.Agentlist);

  const [agreementProlongation, {loading: loadingAgreementProlongation}] = useMutation(PROLONG);

  // const date = moment().format("DD.MM.YYYY");
  
  const formHook = useForm({mode: "onBlur"});
  const {handleSubmit, watch} = formHook;

  const form: IForm = {
    formHook,
    hotReload: true,
    fields: [
      {
        field: {
          fieldName: "limitSum",
          fieldType: "number",
          digitsAfterDot: 2,
          isRequired: true,
          errorMessage: "Введите страховую сумму",
          placeholder: "Страховая сумма"
        }
      },
      {
        field: {
          fieldName: "signDate",
          fieldType: "date",
          isRequired: true,
          // validationType: "minMaxDate",
          // minDate: date,
          // defaultValue: date,
          errorMessage: "Выберите дату подписания",
          placeholder: "Дата подписания"
        }
      },
      {
        isHidden: !agentsItems.length,
        field: {
          fieldName: "agentIsn",
          fieldType: "select",
          items: agentsItems,
          isClearable: true,
          errorMessage: "Выберите агента",
          placeholder: "Агент"
        }
      },
    ],
  };

  const onFormSubmit = handleSubmit((formData) => {
    
    const {limitSum, signDate, agentIsn} = formData;
    
    console.log('prolong ', contractIsn);

    agreementProlongation({
      variables: {
        isn: contractIsn,
        date_sign: formatDateToStringRu(signDate),
        agent: agentIsn,
        sum: Number(stringToNumber(limitSum))
      }
    })
      .then(({data: {prolong}}) => {
        if (!prolong) return setNotification({type: "error", text: "Отсутствует id договора"});
        props.onClose();
        navigate(contractById(String(prolong)))
      })
      .catch(error => setNotification({type: "error", text: error}))
  });

  return (
    <Modal {...props} isLoading={loadingAgents} header="Пролонгация договора">
      <form onSubmit={onFormSubmit}>
        <FieldsBuilder {...form}/>
        <StyledButtonsGroup>
          <Button
            type="submit"
            isLoading={loadingAgreementProlongation}
            appearance="filled">
            Продлить
          </Button>
          <Button
            onClick={() => props.onClose()}
            isLoading={loadingAgreementProlongation}
            appearance="transparent">
            Отмена
          </Button>
        </StyledButtonsGroup>
      </form>
    </Modal>
  )
};

export default ProlongationModal;