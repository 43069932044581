import React from "react";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import FormModal from "../../../widgets/FormModal";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {AddendumMutation, AddendumMutationVariables, AddendumReasonEnum} from "../../../../libs/api/graphqlTypes";
import {ADDENDUM} from "../../../../libs/api/commands";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";


const ChangeRecalcModal = (props: IModalProps) => {

  const { id } = useParams<{ id?: string }>();

  const {setNotification} = useNotification();

  const [addendum, {loading: loadingAddendum}] = useMutation<AddendumMutation, AddendumMutationVariables>(ADDENDUM, {
    refetchQueries: ['loadContract'], awaitRefetchQueries: true
  });

  const onSubmit = (formData: any) => {
    if(!id) return;

    addendum({
      variables: {
        id: parseInt(id, 10),
        reason: AddendumReasonEnum.Recalc,
        recalc: {
          sum: formData?.sum,
          date: formatDateToStringRu(formData?.date),
          months: formData?.months,
          rate: formData?.rate,
          annuitet_sum: formData?.annuitet_sum,
          date_end: formData?.date_end ? formatDateToStringRu(formData?.date_end) : null,
        }
      }
    }).then((data) => {
      props.onClose();
    })
      .catch(error => setNotification({type: "error", text: error}))
  }

  return (
    <FormModal {...props}
      header="Перерасчет"
      fields={[
        {
          field: {
            fieldName: "sum",
            fieldType: "number",
            isRequired: true,
            digitsAfterDot: 2,
            errorMessage: "Укажите сумму",
            placeholder: "Сумма (остаток задолженности)",
            grid: 6,
          }
        },
        {
          field: {
            fieldName: "date",
            fieldType: "date",
            isRequired: true,
            validationType: "minMaxDate",
            minDate: dayjs().toDate(),
            defaultValue: dayjs().toDate(),
            errorMessage: "Выберите дату",
            placeholder: "Дата",
            grid: 6,
          }
        },
        {
          field: {
            fieldName: "months",
            fieldType: "number",
            isRequired: true,
            errorMessage: "Укажите срок кредита",
            placeholder: "Срок кредита",
            grid: 6,
          }
        },
        {
          field: {
            fieldName: "rate",
            fieldType: "number",
            isRequired: true,
            validationType: "minMax",
            minValue: 1,
            maxValue: 99,
            errorMessage: "Укажите процентную ставку",
            placeholder: "Процентная ставка",
            grid: 6,
          }
        },
        {
          field: {
            fieldName: "annuitet_sum",
            fieldType: "number",
            digitsAfterDot: 2,
            errorMessage: "Укажите сумму",
            placeholder: "Аннуитетный платеж",
            grid: 6,
          }
        },
        {
          field: {
            fieldName: "date_end",
            fieldType: "date",
            validationType: "minMaxDate",
            minDate: dayjs().toDate(),
            errorMessage: "Выберите дату окончания кредита",
            placeholder: "Дата окончания кредита",
            grid: 6,
          }
        },
      ]}
      onSubmit={onSubmit}
      submitButton="Отправить"
      isLoading={loadingAddendum}
    />

  );
}

export default ChangeRecalcModal;