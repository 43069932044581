import styled from 'styled-components';
import { blue, DEFAULT_SHADOW, grey, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledHeaderWrapper = styled.div`
	padding: 4rem 4rem 0 4rem;
	width: calc(100% - 8rem);
	@media screen and (max-width: 767px) {
		padding: 1.5rem 1.5rem 0 1.5rem;
		width: calc(100% - 3rem);
	}

	border-bottom: 1px solid ${grey};
`;

export const StyledHeaderEvents = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const StyledHeaderInformation = styled.div`
	margin-top: 3rem;
	display: grid;
	grid-template-columns: 0.9fr 0.9fr 0.9fr 0.9fr 1.4fr;
	grid-row-gap: 2rem;
	grid-column-gap: 1.5rem;
	@media screen and (max-width: 767px) {
		margin-top: 1.5rem;
		grid-template-columns: 1fr;
	}
`;
export const StyledInformationItem = styled.div`
	display: flex;
	flex-direction: column;
	> p:first-child {
		opacity: 0.5;
		margin-bottom: 1rem;
	}
`;

export const StyledEventsButtons = styled.div`
	display: flex;
	align-items: center;
	.iconButton {
		margin-right: 2.5rem;
	}
	.linkButton {
		margin-right: 2.5rem;
	}
`;

export const StyledSignetAgreementButtons = styled.div`
	display: flex;

	> button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 767px) {
		> button {
			width: calc(50% - 0.5rem);
			margin-right: 1rem;
		}
		.nivanaviDropDown {
			width: calc(50% - 0.5rem);
		}
	}
`;

export const StyledSummsWrapper = styled.div``;
export const StyledSumm = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 1rem;
	> p:first-child {
		opacity: 0.5;
	}
	p {
		text-align: right;
	}
	margin-bottom: 1rem;

	@media screen and (max-width: 767px) {
		p {
			text-align: unset;
		}
	}
`;

export const StyledImportantNumber = styled.div`
	color: ${blue};
	font-weight: bold;
`;
export const StyledUnderwritingRisksForm = styled.div`
	padding: 3rem;

	@media screen and (max-width: 767px) {
		padding: 0;
	}
`;

export const StyledUnderwritingHeaderFields = styled.div`
	margin-top: 3rem;
`;

export const StyledUnderwritingHeader = styled.div`
	display: flex;
	flex-direction: column;
	.textButton {
		margin-bottom: 4.5rem;
	}
`;

export const StyledUnderwritingWrapper = styled.div`
	background: ${white};
	margin-top: 4rem;
	box-shadow: ${DEFAULT_SHADOW};
	padding-bottom: 2rem;

	@media screen and (max-width: 767px) {
		margin-top: 2rem;
	}
`;

export const StyledRiskInformation = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 2rem;
	border-top: 1px solid ${grey};
	border-bottom: 1px solid ${grey};
	margin: 2rem 0 3rem 0;
	padding: 2rem 0;
	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;

export const StyledCalculationDataWrapper = styled.div`
	padding: 1.5rem 0 4rem 0;
`;
export const StyledRiskFormWrapper = styled.div`
	h4,
	h5 {
		margin-bottom: 1rem;
	}
`;

export const StyledRemark = styled.div`
	display: flex;
	align-items: flex-start;
	opacity: 0.5;
	> p {
		margin-left: 1rem;
	}
	> svg {
		max-width: 1.5rem;
		max-height: 1.5rem;
		min-width: 1.5rem;
		min-height: 1.5rem;
	}
	margin-bottom: 2rem;
`;

export const StyledTableAreaWrapper = styled.div`
	margin-bottom: 4rem;

	h3 {
		margin-bottom: 3rem;
	}
	padding: 4rem 4rem 0 4rem;

	@media screen and (max-width: 767px) {
		h3 {
			margin-bottom: 2rem;
		}
		margin-bottom: 3rem;
		padding: 3rem 1.5rem 0 1.5rem;
	}
`;

export const StyledToggleAreaWrapper = styled.div`
	border-top: 1px solid ${grey};
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;

export const StyledToggleAreaLeft = styled.div`
	border-right: 1px solid ${grey};
`;

export const StyledFillingTypeBadge = styled.span`
  color: white;
  background-color: #41B6E6;
  font-size: 1.5rem;
  padding: 0.4rem 0.7rem;
  border-radius: 10px;
  margin-bottom: 3rem;
`;

export const StyledFillingTypeBadgeContainer = styled.div`
  margin-left: 2rem;
  margin-top: 1rem;
`;
