import styled, { css } from 'styled-components';
import { ANIMATION_SEC, Z_INDEX_LITTLE_BIT, white } from '../globalStyles';

export const StyledSelectWrapper = styled.div`
	position: relative;

	.listShow {
		pointer-events: unset;
		opacity: 1;
	}

	.listHide {
		pointer-events: none;
		opacity: 0;
	}
`;
export const StyledSelectItemsWrapper = styled.div<{ height: number; dropDownHelpMessage?: string }>`
	position: absolute;
	width: 100%;
	background: ${({ theme }) => theme.select.bg};
	box-sizing: border-box;
	border: 1px solid ${({ theme }) => theme.select.border};
	margin-top: -1px;
	padding-top: 0.5rem;
	overflow-y: auto;
	z-index: ${Z_INDEX_LITTLE_BIT + 1};
	transition: opacity ${ANIMATION_SEC}s;
	opacity: 0;
	max-height: ${({ height }) => height}rem;
	// &:after {
	// 	content: '${({ dropDownHelpMessage }) => dropDownHelpMessage || 'Для навигации можно использовать \u2191, \u2193 и Enter'}';
	// 	position: sticky;
	// 	bottom: 0;
	// 	padding: 5px 0 5px 2rem;
	// 	font-weight: bold;
	// 	font-size: 1.3rem;
	// 	background-color: ${white};
	// 	display: block;
	// 	width: 100%;
	// 	box-sizing: border-box;
	// }
`;
export const StyledSelectItem = styled.div<{ isActive: boolean; hover: boolean }>`
	padding: 1.5rem 3rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	color: ${({ isActive, theme }) => (isActive ? theme.select.item.selected.color : theme.select.item.color)};

	${({ hover, theme }) =>
		hover &&
		css`
			background: ${theme.select.item.hover.bg};
			color: ${theme.select.item.hover.color};
		`}
`;

export const StyledSelectEmpty = styled.div`
	padding: 1rem;
	text-align: center;
`;

export const StyledSelectArrowIcon = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

export const StyledSelectEvents = styled.div`
	height: 100%;
	display: flex;
	align-items: center;

	> .iconButton {
		margin: 0.5rem 1rem 0 0;
	}
`;
