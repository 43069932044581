import styled from 'styled-components';
import { blue, grey } from '../../../../../../libs/uiKit/globalStyledComponents';

const toggleHeight = 6.5;

export const StylesGroupTabWrapper = styled.div`
	h3 {
		margin: 2rem 0 2rem 0;
	}
`;

export const StyledSearch = styled.div`
	margin: 4rem 0 3rem 0;
`;

export const StyledGroupToggle = styled.div`
	height: ${toggleHeight}rem;
	display: flex;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid ${grey};
`;

export const StyledGroupToggleHeader = styled.div<{ isEnabled?: boolean }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;

	:hover {
		color: ${blue};
	}
	h4 {
		margin-left: 2rem;
	}

	> p {
		color: ${({ isEnabled }) => (isEnabled ? 'unset' : grey)};
	}
`;

export const StyledGroupToggleBody = styled.div`
	padding-left: 2.5rem;
`;

export const StyledEmptyGroup = styled.div`
	padding: 1rem 0;
	display: flex;
	justify-content: center;
`;

export const StyledGroupToggleButtons = styled.div`
	display: flex;
	align-items: center;

	button {
		margin-right: 2rem;
	}
`;
