import styled from 'styled-components';
import { red } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledAgreementsLogHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4rem;
	@media screen and (max-width: 767px) {
		.filledButton {
			width: 100%;
		}
	}
`;

export const StyledApplicationSearch = styled.div`
	margin-top: 4rem;
	position: relative;

	@media screen and (max-width: 767px) {
		margin: 2rem 0 3rem 0;
	}
`;

export const StyledFilterIcon = styled.div<{ isFilter: boolean }>`
	position: relative;

	${({ isFilter }) =>
		isFilter
			? `
      ::after {
        content: "";
        width: 5px;
        position: absolute;
        height: 5px;
        background: ${red};
        border-radius: 50%;
        top: -5px;
        right: -5px;
      }
      `
			: ''}
`;

export const StyledFilterButtons = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 2rem;
	.filledButton {
		margin-bottom: 2rem;
	}
`;

export const StyledFilter = styled.div`
	display: flex;
`;

export const StyledGroupPath = styled.div`
	> p {
		white-space: nowrap;
	}
`;
