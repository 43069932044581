import React, { useCallback } from 'react';
import { ITextAreaFieldProps } from '../types';
import { fieldsValidate } from '../utils/fieldValidation';
import TextArea from '../../textArea';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import getFieldError from '../utils/getFieldError';

const TextAreaField: React.FC<ITextAreaFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			value,
			hotReload,
			onChange,
			onBlur,
			// for validation
			minLength,
			maxLength,
			validationType,
			isRequired,
			errorMessage,
			regexpPattern,
			// other
			...rest
		},
		formHook: {
			register,
			setValue,
			formState: { errors },
		},
	} = props;
	const message: string | undefined = getFieldError(fieldName, errors);
	const validationHandler = useCallback(
		(validateValue: string) => fieldsValidate({ value: validateValue, validationType, minLength, maxLength, regexpPattern }),
		[validationType, minLength, maxLength, regexpPattern]
	);
	const fieldRegistration = React.useMemo(
		() =>
			register(fieldName, {
				required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
				validate: (validateValue: string) => validationHandler(validateValue),
			}),
		[errorMessage, isRequired, fieldName, register, validationHandler]
	);

	const registrationWithUserProps = React.useMemo(
		() => ({
			...fieldRegistration,
			onChange: async (ev: React.ChangeEvent<HTMLTextAreaElement>): Promise<void> => {
				if (onChange) onChange(ev);
				await fieldRegistration.onChange(ev);
			},
			onBlur: async (ev: React.FocusEvent<HTMLTextAreaElement>): Promise<void> => {
				if (onBlur) onBlur(ev);
				await fieldRegistration.onBlur(ev);
			},
		}),
		[fieldRegistration, onChange, onBlur]
	);

	React.useEffect(() => {
		if (!hotReload || defaultValue === undefined) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, setValue, hotReload]);

	return <TextArea {...rest} defaultValue={defaultValue} value={value} isInvalid={!!message} isRequired={isRequired} errorMessage={message} {...registrationWithUserProps} />;
};

export default React.memo(TextAreaField);
