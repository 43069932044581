import React from 'react';
import LoginClientUi from './ui';
import { LoginClientContextProvider } from './ui/context';

const LoginClientIgniter: React.FC = () => (
	<LoginClientContextProvider>
		<LoginClientUi />
	</LoginClientContextProvider>
);

export default React.memo(LoginClientIgniter);
