import React from 'react';
import { useAgreementTableContext } from './context';
import Table from '../../../../libs/uiKit/table';

const AgreementTableUi: React.FC = () => {
	const { head, rows, isLoading } = useAgreementTableContext();

	return <Table head={head} rows={rows} isLoading={isLoading} />;
};

export default React.memo(AgreementTableUi);
