import React from 'react';
import DiscussionUi from './ui';
import { DiscussionContextProvider, DiscussionProps } from './ui/context';

const DiscussionIgniter: React.FC<DiscussionProps> = props => (
	<DiscussionContextProvider {...props}>
		<DiscussionUi />
	</DiscussionContextProvider>
);

export default React.memo(DiscussionIgniter);
