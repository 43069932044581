import React, { createContext, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { PERMISSIONS, ROLES } from '../../../../../../../libs/api/queries';
import {
	CreateUpdateRoleMutation,
	CreateUpdateRoleMutationVariables,
	PermissionsQuery,
	PermissionsQueryVariables,
	RolesQuery,
	RolesQueryVariables,
} from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';
import { IEnumItem } from '../../../../../../../libs/uiKit/enums/types';
import { CREATE_UPDATE_ROLE } from '../../../../../../../libs/api/commands';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';

type FormRole = {
	name: string;
	permissions: IEnumItem[];
};

type RoleByIdContext = {
	roleName: string;
	isLoading: boolean;
	goBackHandler: () => void;
	onFormSubmit: () => void;
	formRoleName: IForm;
	formPermissions: IForm;
	deletePermissionHandler: (value: string | number) => void;
	showMoreHandler: () => void;
	isShowShowMore: boolean;
	permissionsToShow: IEnumItem[];
	showMoreText: string;
	deleteRoleHandler: () => void;
	deleteRoleId: number | undefined;
	closeDeleteRoleHandler: () => void;
	deleteRoleCallback: () => void;
	roleUsersCount: number;
};

const MAX_PERMISSION_SYMBOL = 30;
const PERMISSION_PER_PAGE = 10;

const RoleByIdContext = createContext<RoleByIdContext>({} as RoleByIdContext);

export const useRoleByIdContext = (): RoleByIdContext => useContext(RoleByIdContext);

const RoleByIdContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		routes: { error500, roles },
	} = useGlobalContext();
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { setNotification } = useNotification();
	const formHook = useForm<FormRole>({ mode: 'onBlur' });

	const [stateSelectedPermissions, setSelectedPermissions] = React.useState<IEnumItem[]>([]);
	const [statePermissionsPerPage, setPermissionsPerPage] = React.useState<number>(PERMISSION_PER_PAGE);
	const [stateDeleteRoleModal, setDeleteRoleModal] = React.useState<number | undefined>(undefined);

	const { data: dataRole, loading: loadingRole } = useQuery<RolesQuery, RolesQueryVariables>(ROLES, {
		variables: {
			ids: [Number(id)],
		},
		onError: error => error500(error),
	});
	const { data: dataPermissions, loading: loadingPermissions } = useQuery<PermissionsQuery, PermissionsQueryVariables>(PERMISSIONS, { onError: error => error500(error) });
	const [createUpdateRole, { loading: loadingUpdateRole }] = useMutation<CreateUpdateRoleMutation, CreateUpdateRoleMutationVariables>(CREATE_UPDATE_ROLE, {
		refetchQueries: ['roles'],
	});

	const { name, permissions: rolePermissions, users_count } = (dataRole?.roles || [])[0] || {};
	const defaultPermissions: IEnumItem[] = (rolePermissions || []).map(permission => ({ label: permission?.name || 'unknown', value: String(permission?.id || 'unknown') }));
	const defaultPermissionsForUse: string = JSON.stringify(defaultPermissions.length === 0 ? undefined : defaultPermissions);
	const permissions: IEnumItem[] = (dataPermissions?.permissions || []).map(permission => ({ label: permission?.name || 'unknown', value: String(permission?.id) || 'unknown' }));

	const permissionsToShow: IEnumItem[] = stateSelectedPermissions
		.filter((_, index) => index < statePermissionsPerPage)
		.map(permission => ({
			...permission,
			label: permission.label.length > MAX_PERMISSION_SYMBOL ? `${permission.label.substring(0, MAX_PERMISSION_SYMBOL)}...` : permission.label,
		}));

	React.useEffect(() => {
		if (!defaultPermissionsForUse) return;
		setSelectedPermissions(JSON.parse(defaultPermissionsForUse));
	}, [defaultPermissionsForUse]);

	const onFormSubmit = formHook.handleSubmit(data => {
		createUpdateRole({
			variables: {
				id: Number(id),
				input: {
					name: data.name,
					permissions: (data.permissions || []).map(({ value }: IEnumItem) => Number(value)),
				},
			},
		})
			.then(() => setNotification({ type: 'success', text: 'Роль успешно обновлена' }))
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const deletePermissionHandler = React.useCallback((deleteValue: string | number) => {
		setSelectedPermissions(state => state.filter(({ value }) => value !== deleteValue));
	}, []);

	const deleteRoleHandler = React.useCallback(() => setDeleteRoleModal(Number(id)), [id]);

	const showMoreHandler = React.useCallback(() => {
		setPermissionsPerPage(state => state + PERMISSION_PER_PAGE);
	}, []);

	const formRoleName: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'name',
						defaultValue: name,
						isRequired: true,
						placeholder: 'Название роли',
						errorMessage: 'Введите название',
					},
				},
			],
		}),
		[name, formHook]
	);

	const formPermissions: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'enums',
						fieldName: 'permissions',
						isRequired: true,
						isLoading: loadingPermissions,
						defaultValue: stateSelectedPermissions,
						placeholder: 'Поиск по функциям',
						onChange: value => setSelectedPermissions(value),
						items: permissions,
						errorMessage: 'Выберите функции',
					},
				},
			],
		}),
		[loadingPermissions, formHook, permissions, stateSelectedPermissions]
	);

	const value: RoleByIdContext = React.useMemo(
		() => ({
			roleName: name || '',
			isLoading: loadingRole || loadingUpdateRole,
			goBackHandler: () => navigate(-1),
			onFormSubmit,
			formRoleName,
			formPermissions,
			deletePermissionHandler,
			showMoreHandler,
			isShowShowMore: permissionsToShow.length < stateSelectedPermissions.length,
			permissionsToShow,
			showMoreText: `Еще ${stateSelectedPermissions.length - statePermissionsPerPage} функций`,
			deleteRoleHandler,
			deleteRoleId: stateDeleteRoleModal,
			closeDeleteRoleHandler: () => setDeleteRoleModal(undefined),
			deleteRoleCallback: () => navigate(roles()),
			roleUsersCount: users_count || 0,
		}),
		[
			name,
			loadingRole,
			loadingUpdateRole,
			onFormSubmit,
			formRoleName,
			formPermissions,
			deletePermissionHandler,
			showMoreHandler,
			permissionsToShow,
			stateSelectedPermissions.length,
			statePermissionsPerPage,
			deleteRoleHandler,
			stateDeleteRoleModal,
			navigate,
			roles,
		]
	);

	return <RoleByIdContext.Provider value={value}>{children}</RoleByIdContext.Provider>;
};

export default React.memo(RoleByIdContextProvider);
