import styled from 'styled-components';
import { grey, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledProfileWrapper = styled.div`
	h2 {
		margin-bottom: 4rem;
	}

	h3 {
		margin: 4rem 0 2rem 0;
	}

	h3:first-child {
		margin: 0 0 2rem 0;
	}

	@media screen and (max-width: 767px) {
		h2 {
			margin-bottom: 2rem;
		}

		h3 {
			margin: 3rem 0 1.5rem 0;
		}

		h3:first-child {
			margin: 0 0 1.5rem 0;
		}
	}
`;

export const StyledProfileCard = styled.div`
	padding: 0 15rem;
	@media screen and (max-width: 767px) {
		padding: 0;
	}
`;

export const StyledProfileForm = styled.div`
	background: ${white};
	padding: 3rem;
	margin-bottom: 3.5rem;
	@media screen and (max-width: 767px) {
		padding: 1.5rem;
	}
`;

export const StyledProfileFieldItem = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 0.5rem;
	> p:first-child {
		opacity: 0.5;
	}

	padding: 2rem 0;
	border-bottom: 1px solid ${grey};

	@media screen and (max-width: 767px) {
		> p:first-child {
			font-size: 1.2rem;
		}
		grid-template-columns: 1fr;
	}
`;

export const StyledProfilePasswordWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;
