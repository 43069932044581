import styled, { css } from 'styled-components';
import { ANIMATION_SEC, visuallyHidden } from '../globalStyles';

export const StyledCheckboxWrapper = styled.label<{ isInvalid: boolean }>`
	cursor: pointer;
	white-space: nowrap;
	color: ${({ theme }) => theme.checkbox.color};
	transition: all ${ANIMATION_SEC}s;

	${({ isInvalid }) =>
		isInvalid &&
		css`
			color: ${({ theme }) => theme.checkbox.invalid.color};

			input + * {
				border-color: ${({ theme }) => theme.checkbox.box.invalid.border};
			}
		`};

	:hover {
		color: ${({ theme }) => theme.checkbox.hover.color};
	}

	:active {
		color: ${({ theme }) => theme.checkbox.active.color};
	}

	> *:last-child {
		margin-left: 1rem;
	}
`;

export const StyledPseudoCheckbox = styled.span`
	width: 2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all ${ANIMATION_SEC}s;
	border: 1px solid ${({ theme }) => theme.checkbox.box.border};
	background: ${({ theme }) => theme.checkbox.box.bg};
	box-sizing: border-box;

	> svg {
		opacity: 0;
	}
`;

export const StyledCheckbox = styled.input`
  ${visuallyHidden};

  :checked {
    // custom checkbox
    & + * {
      border-color: ${({ theme }) => theme.checkbox.box.checked.border};
      background: ${({ theme }) => theme.checkbox.box.checked.bg};
      color: ${({ theme }) => theme.checkbox.box.checked.color};

      svg {
        opacity: 1;
      }
    }
  }

  :disabled {
    // custom checkbox
    & + * {
      cursor: no-drop;
      border-color: ${({ theme }) => theme.checkbox.box.disabled.border};
      background: ${({ theme }) => theme.checkbox.box.disabled.bg};
      color: ${({ theme }) => theme.checkbox.box.disabled.color};
    }

    // label after custom checkbox
    & + * + * {
      color: ${({ theme }) => theme.checkbox.disabled.color};
      cursor: no-drop;
    }
  }

  :required {
    & + * + *::after {
      content: " *";
      color: ${({ theme }) => theme.checkbox.requiredColor};
    }
  }
}
`;
