import styled from 'styled-components';
import { ANIMATION_SEC } from '../globalStyles';

export const StyledModalWrapper = styled.div`
	&.modal-enter {
		opacity: 0;
	}

	&.modal-enter-active {
		opacity: 1;
		transition: opacity 300ms;
	}

	&.modal-exit {
		opacity: 1;
	}

	&.modal-exit-active {
		opacity: 0;
		transition: opacity 300ms;
	}

	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
export const StyledModalBackground = styled.div`
	background: ${({ theme }) => theme.modal.backdrop};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const StyledModal = styled.div<{ width: number }>`
	background: ${({ theme }) => theme.modal.bg};
	color: ${({ theme }) => theme.modal.color};
	padding: 3rem;
	position: relative;
	width: ${({ width }) => width}rem;

	> h2 {
		margin-bottom: 4rem;
	}

    overflow-y: auto;

	@media screen and (max-width: 767px) {
		padding: 6rem 1.5rem 2rem 1.5rem;
		width: calc(100% - 3rem);
		min-height: calc(100vh - 8rem);
		overflow-y: auto;
		overflow-x: hidden;

		> h2 {
			margin-bottom: 2rem;
		}
	}
`;

export const StyledCloseButton = styled.div`
	z-index: 15;
	cursor: pointer;
	position: absolute;
	top: 2rem;
	right: 2rem;
	color: ${({ theme }) => theme.modal.icon.color};
	transition: all ${ANIMATION_SEC}s;

	:hover {
		transform: scale(1.1);
		color: ${({ theme }) => theme.modal.icon.hover.color};
	}

	:active {
		color: ${({ theme }) => theme.modal.icon.active.color};
	}

	svg {
		width: 1.7rem;
		height: 1.7rem;
	}

	@media screen and (max-width: 767px) {
		top: 6rem;
	}
`;

export const StyledModalTwoButtonsGroup = styled.div`
	width: 100%;
	display: flex;
	button {
		width: calc(50% - 0.5rem);
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
`;

export const StyledTextModalWrapper = styled.div`
	h2 {
		margin-bottom: 2rem;
	}
  p {
    margin-bottom: 3rem;
  }
`;