import React from 'react';
import { useJobContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const JobUi: React.FC = () => {
	const { form } = useJobContext();

	return (
		<>
			<FieldsBuilder {...form} />
		</>
	);
};

export default React.memo(JobUi);
