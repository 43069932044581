import styled from 'styled-components';

export const StyledRolesTabWrapper = styled.div`
	width: 50%;

	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;

export const StyledRolesWrapper = styled.div`
	@media screen and (max-width: 767px) {
		padding-bottom: 4rem;
	}
`;
export const StylesGroupTabWrapper = styled.div`
	h3 {
		margin: 2rem 0 2rem 0;
	}
`;

export const StyledRolesHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3rem;
`;

export const StyledButtonsGroupAutoSize = styled.div`
	display: flex;
	button {
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
	@media screen and (max-width: 767px) {
		button {
			width: calc(50% - 0.5rem);
			margin-right: 1rem;
		}

		button:last-child {
			margin: 0;
		}
	}
`;

export const StyledButtonsGroup = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
	}
`;
export const StyledFullSizeOnMobileButton = styled.div`
	@media screen and (max-width: 767px) {
		> button {
			width: 100%;
		}
	}
`;
