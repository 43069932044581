import React from 'react';
import LoginDefaultUi from './ui';
import { LoginDefaultContextProvider } from './ui/context';

const LoginDefaultIgniter: React.FC = () => (
	<LoginDefaultContextProvider>
		<LoginDefaultUi />
	</LoginDefaultContextProvider>
);

export default React.memo(LoginDefaultIgniter);
