import React from 'react';
import {useMutation} from "@apollo/client";
import { useAnketaFillingContext } from './context';
import Select from '../../../../../../libs/uiKit/select';
import SendFillingIgniter from '../widgets/sendFilling';
import UploadFillingIgniter from '../widgets/uploadFilling';
import OnlineFillingIgniter from '../widgets/onlineFilling';
import Button from '../../../../../../libs/uiKit/button';
import { StyledAnketaFillingWrapper } from './styles';
import DeclarationFilling from "../widgets/declarationFilling";
import {
	ContragentFillingTypeEnum,
	UpdateContragentFillingMutation,
	UpdateContragentFillingMutationVariables
} from "../../../../../../libs/api/graphqlTypes";
import {UPDATE_CONTRAGENT_FILLING} from "../../../../../../libs/api/commands";
import {AGREEMENT_DRAFT} from "../../../../../../libs/api/queries";
import {ContragentId} from "../../contragent/ui/context";

const AnketaFillingUi: React.FC = () => {
	const {
		fillingTypeItems,
		person,
		agreementId,
		isFull,
		selectChangeHandler,
		cancelFillingHandler,
		fillingTypeValue,
		isShowFillingDeclaration,
		isShowFillingOnline,
		isShowFillingSend,
		isShowFillingUpload,
		successFillingCallback,
	} = useAnketaFillingContext();

	const [updateContragentFilling, { loading: updateContragentLoading }] = useMutation<UpdateContragentFillingMutation, UpdateContragentFillingMutationVariables>(UPDATE_CONTRAGENT_FILLING, {
		refetchQueries: [
			{
				query: AGREEMENT_DRAFT,
				variables: {
					id: agreementId
				},
			},
		],
		awaitRefetchQueries: true,
	});

		const saveDeclFilling = (personId: ContragentId|undefined) => {
		if (!agreementId || !personId) return;
		updateContragentFilling({
			variables: {
				id: agreementId,
				contragentIsn: personId,
				fillingType: ContragentFillingTypeEnum.Declaration
			}
		}).then(() => {
			successFillingCallback();
		})
	}

	return (
		<StyledAnketaFillingWrapper>
			<Select placeholder='Выберите тип заполнения' value={fillingTypeValue} items={fillingTypeItems} onChange={selectChangeHandler} />
			{isShowFillingOnline && (
				<OnlineFillingIgniter
					isFull={isFull}
					agreementId={agreementId}
					defaultValue={{ sendSmsPhone: person.phoneMobile || '' }}
					personId={person.isn || null}
					isAlreadySigned={person.anketaSigned}
					successFillingCallback={successFillingCallback}
				/>
			)}
			{isShowFillingUpload &&
				<UploadFillingIgniter
					agreementId={agreementId}
					personId={person.isn || null}
					successFillingCallback={successFillingCallback}
					fillingType="upload"
					agreementMode={false}
					person={person}
				/>}
			{isShowFillingSend && (
				<SendFillingIgniter
					agreementId={agreementId}
					personId={person.isn || null}
					successFillingCallback={successFillingCallback}
					defaultValue={{ phone: person.phoneMobile || '' }}
				/>
			)}
			{isShowFillingDeclaration && (
				<Button isLoading={updateContragentLoading} appearance='filled' style={{marginTop: '15px'}} onClick={() => saveDeclFilling(person.isn) }>
					Сохранить
				</Button>
			)}
			<Button appearance='transparent' onClick={cancelFillingHandler} style={{marginTop: '15px'}}>
				Назад
			</Button>
		</StyledAnketaFillingWrapper>
	);
};

export default React.memo(AnketaFillingUi);
