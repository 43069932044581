import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RolesUi from './ui';
import RolesContextProvider from './ui/context';
import RoleByIdPage from './pages/roleById';

const RolesPage: React.FC = () => (
	<RolesContextProvider>
		<Routes>
			<Route path=':id' element={<RoleByIdPage />} />
			<Route path='' element={<RolesUi />} />
		</Routes>
	</RolesContextProvider>
);

export default React.memo(RolesPage);
