import React from 'react';
import DiscountUi from './ui';
import { DiscountContextProps, DiscountContextProvider } from './ui/context';

const DiscountIgniter: React.FC<DiscountContextProps> = props => (
	<DiscountContextProvider {...props}>
		<DiscountUi />
	</DiscountContextProvider>
);

export default React.memo(DiscountIgniter);
