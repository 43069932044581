import React from 'react';
import { useCreateRoleContext } from './context';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import { StyledButtonsGroup } from './styles';
import Button from '../../../../../../libs/uiKit/button';

const CreateRoleUi: React.FC = () => {
	const { isOpen, onClose, header, onFormSubmit, form, isLoadingCreateRole } = useCreateRoleContext();

	return (
		<Modal isOpen={isOpen} onClose={onClose} header={header}>
			<form onSubmit={onFormSubmit}>
				<FieldsBuilder {...form} />
				<StyledButtonsGroup>
					<Button type='submit' isLoading={isLoadingCreateRole} appearance='filled'>
						Создать роль
					</Button>
					<Button onClick={onClose} isLoading={isLoadingCreateRole} appearance='transparent'>
						Отмена
					</Button>
				</StyledButtonsGroup>
			</form>
		</Modal>
	);
};

export default React.memo(CreateRoleUi);
