import React, { useMemo } from 'react';
import { StyledSkeletonTdEmptyItem, StyledSkeletonTdItem, StyledSkeletonTrItem } from './styles';

export const SkeletonTable: React.FC<{ length: number }> = ({ length }) => {
	const memoSkeletonTdList = useMemo(
		() =>
			new Array(length).fill(0).map((value, index) => (
				<StyledSkeletonTdItem key={index}>
					<StyledSkeletonTdEmptyItem />
				</StyledSkeletonTdItem>
			)),
		[length]
	);

	return (
		<>
			<StyledSkeletonTrItem>{memoSkeletonTdList}</StyledSkeletonTrItem>
			<StyledSkeletonTrItem>{memoSkeletonTdList}</StyledSkeletonTrItem>
			<StyledSkeletonTrItem>{memoSkeletonTdList}</StyledSkeletonTrItem>
			<StyledSkeletonTrItem>{memoSkeletonTdList}</StyledSkeletonTrItem>
		</>
	);
};
