import React from 'react';
import SkipPrecalculationUi from './ui';
import { SkipPrecalculationContextProvider, SkipPrecalculationProps } from './ui/context';

const SkipPrecalculationIgniter: React.FC<SkipPrecalculationProps> = props => (
	<SkipPrecalculationContextProvider {...props}>
		<SkipPrecalculationUi />
	</SkipPrecalculationContextProvider>
);

export default React.memo(SkipPrecalculationIgniter);
