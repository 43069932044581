import React from 'react';

export const SearchIcon: React.FC = () => (
	<svg width='1.6rem' height='1.6rem' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.0702 10.1272C11.089 9.16254 11.7234 7.80434 11.7234 6.29968C11.7234 3.37275 9.32289 1 6.3617 1C3.40052 1 1 3.37275 1 6.29968C1 9.22661 3.40052 11.5994 6.3617 11.5994C7.80062 11.5994 9.10715 11.0391 10.0702 10.1272ZM10.0702 10.1272L15 15'
			stroke='currentColor'
			strokeWidth='1.8'
		/>
	</svg>
);
