import React from 'react';
import {StyledStatisticsCardWarning} from "./styles";
import {Text3} from "../../../libs/uiKit/globalStyledComponents";

const UserWarning = () =>
    (
        <StyledStatisticsCardWarning>
            <Text3 style={{color: "red"}}>Тарификатор для вашей точки продаж не настроен. Обратитесь к вашему
                куратору</Text3>
        </StyledStatisticsCardWarning>
    )

export default UserWarning;