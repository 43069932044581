import React, {createContext, useContext, useEffect} from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { useCreateAgreementContext } from '../../../../ui/context';

type UploadFilesContext = {
	form: IForm;
	riskTitle: boolean;
	riskProperty: boolean;
};

export type UploadFilesForm = {
	additionalFiles?: any[];
};

const UploadFilesContext = createContext<UploadFilesContext>({} as UploadFilesContext);

export const useUploadFilesContext = (): UploadFilesContext => useContext(UploadFilesContext);

const UploadFilesContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		risks: { riskTitle, riskProperty },
		sharedFiles,
		setSharedFiles,
	} = useCreateAgreementContext();
	const formHook = useFormContext<UploadFilesForm>();

	const additionalFiles = formHook.watch('additionalFiles');

	useEffect(() => {
		if(sharedFiles?.length > 0 && !additionalFiles){
			formHook.setValue('additionalFiles', sharedFiles);
		}
	}, [sharedFiles, additionalFiles]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'upload',
						fieldName: 'additionalFiles',
						placeholder: 'Перетащите файлы сюда',
						onChange: (files: any[]) => {
							setSharedFiles(files);
						}
					},
				},
			],
		}),
		[formHook]
	);

	const value: UploadFilesContext = React.useMemo(
		() => ({
			form,
			riskTitle,
			riskProperty,
		}),
		[form, riskProperty, riskTitle]
	);

	return <UploadFilesContext.Provider value={value}>{children}</UploadFilesContext.Provider>;
};

export default React.memo(UploadFilesContextProvider);
