import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {useMutation} from "@apollo/client";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";
import {AgreementId} from "../createAgreement/ui/context";
import {
  LoadContractQuery,
  LoadContractQueryVariables, PrepareInvoiceMutation, PrepareInvoiceMutationVariables,
  PrintMutation,
  PrintMutationVariables, PrintTypeEnum
} from "../../../libs/api/graphqlTypes";
import {LOAD_CONTRACT} from "../../../libs/api/queries";
import {PREPARE_INVOICE, PRINT_MUTATION} from "../../../libs/api/commands";
import {
  StyledAgreementNumber,
  StyledContractHeader,
  StyledContractWrapper, StyledHeaderInformation,
  StyledHeaderWrapper, StyledInformationItem
} from "../contract/ui/styles";
import {Heading2, Heading3, Text4} from "../../../libs/uiKit/globalStyledComponents";
import LoadingString from "../../../libs/uiKit/loadingString";
import Status from "../../../libs/uiKit/status";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {fileDownloader} from "../../../libs/utils/fileDownloader";
import MainLayoutIgniter from "../common/mainLayout";
import {
  StyledBigPremiumHeader, StyledButtonWrapper,
  StyledGrayLabel,
  StyledPremiumBlock,
  StyledToggleAreaLeft,
  StyledToggleAreaWrapper
} from "./ui/styles";
import {stringToMoney} from "../../../libs/uiKit/utils";
import Button from "../../../libs/uiKit/button";
import {SendIcon} from "../../../icons/ui/Send";
import {FileIcon} from "../../../icons/ui/File";
import Accordion from "../../../libs/uiKit/accordion";
import {
  StyledButtonsWrapper,
  StyledDocuments,
  StyledDownloadFileItem,
  StyledFileName
} from "../../widgets/documents/ui/styles";
import {DownloadIcon} from "../../../icons/ui/Download";
import GetInvoiceModal from "./widgets/getInvoiceModal";
import SendPayLinkModal from "./widgets/sendPayLinkModal";
import SendPrintModal from "./widgets/SendPrintModal";
import {ArrowIcon} from "../../../icons/ui/Arrow";

const InvoicePage: React.FC = () => {
  const {
    routes: { error500, contractById },
  } = useGlobalContext();

  const navigate = useNavigate();

  const { id } = useParams<{ id?: string }>();
  const contractId: AgreementId = Number.isNaN(Number(id)) ? null : Number(id);

  const { data: dataContract, loading: loadingContract } = useQuery<LoadContractQuery, LoadContractQueryVariables>(LOAD_CONTRACT, {
    variables: { id: contractId },
    onError: error => error500(error),
  });

  const [stateInvoiceModalIsOpen, setInvoiceModalIsOpen] = React.useState<boolean>(false);
  const [stateSendModalIsOpen, setSendModalIsOpen] = React.useState<boolean>(false);
  const [stateSendModalData, setSendModalData] = React.useState<any>(null);
  const [stateSendPrintModalData, setSendPrintModalData] = React.useState<any>(null);

  const [selectedInvoice, setSelectedInvoice] = React.useState<any>(null);

  const [stateResultDocumentsOpen, setResultDocumentsOpen] = React.useState<boolean>(true);

  const closeInvoiceModalHandler = React.useCallback(() => setInvoiceModalIsOpen(false), []);
  const closeSendModalHandler = React.useCallback(() => setSendModalIsOpen(false), []);

  const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const document = dataContract?.loadAgreement;

  const agreement = document?.agreement;

  const isPayed = agreement?.is_payed || false;

  const invoices = document?.invoices || [];

  const resultDocuments = document?.resultDocuments || [];

  type InformationItem = { title: string; description: React.ReactNode };

  const informationItems: InformationItem[] = React.useMemo(
    () => [
      {
        title: 'Номер договора',
        description: (
          <StyledAgreementNumber>
            <Text4>{document?.number}</Text4>
          </StyledAgreementNumber>
        ),
      },
      {
        title: 'Статус',
        description: (
          <LoadingString isLoading={loadingContract}>
            <Status status={document?.status?.label} color={document?.status?.color} />
          </LoadingString>
        ),
      },
      {
        title: 'Статус оплаты',
        description: (
          <LoadingString isLoading={loadingContract}>
            {isPayed ? <Status status='Оплачен' color='#397e2d' /> : <Status status='Не оплачен' color='#d23630' />}
          </LoadingString>
        ),
      },
    ],
    [
      document
    ]
  );

  const [prepareInvoice] = useMutation<PrepareInvoiceMutation, PrepareInvoiceMutationVariables>(PREPARE_INVOICE, {
    refetchQueries: ['loadContract']
  })

  const { setNotification } = useNotification();

  const sendPaymentLinkHandler = (agreementId: number, invoice: any) => {
    setSendModalIsOpen(true);
    setSendModalData({
      agreementId,
      phone: agreement?.policyholder?.phone,
      email : agreement?.policyholder?.email,
      invoice,
    });
  }

  const makeInvoiceHanlder = (contractId: number, invoice: any) => {
    prepareInvoice({
      variables: {
        id: contractId,
        isn: invoice.agreementIsn
      }
    }).catch(error => setNotification({ type: 'error', text: error }))
  }

  const openInvoiceHandler = (contractId: number, invoice: any) => {
    setSelectedInvoice(invoice);
    setInvoiceModalIsOpen(true);
  }

  const deleteInvoiceHandler = (contractId: number, invoice: any) => {
    console.log('delete invoice');
  }

  const downloadResultDocumentHandler = (isn: number, type: PrintTypeEnum, templateIsn: number, warning: string | null | undefined) => {
    if(warning) return setNotification({ type: 'error', text: warning });
    downloadDoc({
      variables: {
        isn,
        type,
        templateIsn
      },
    }).then(({ data }) => {
      if (!data?.print) return setNotification({ type: 'error', text: 'no doc' });
      fileDownloader(data.print.url, data.print.filename);
    }).catch(error => setNotification({ type: 'error', text: error }))
  }

  const sendResultDocumentHandler = (resultDocument: any) => {
    if(resultDocument?.warning) return setNotification({ type: 'error', text: resultDocument?.warning });
    setSendPrintModalData(resultDocument);
  }

  return (
    <MainLayoutIgniter>
      <Heading2>Договор страхования</Heading2>
      <StyledContractWrapper>

        <StyledHeaderWrapper>
          <Button onClick={() => { navigate(contractById(id || "")); }} appearance='text' iconBefore={<ArrowIcon direction='left' />}>
            Общие сведения
          </Button>
          <StyledContractHeader>
            <Heading3>Получить документы</Heading3>
          </StyledContractHeader>
          <StyledHeaderInformation>
            {informationItems.map(({ title, description }, index) => (
              <StyledInformationItem key={`info-item-${index}`}>
                <Text4>{title}</Text4>
                {description}
              </StyledInformationItem>
            ))}
          </StyledHeaderInformation>
        </StyledHeaderWrapper>
        <StyledToggleAreaWrapper>
          <StyledToggleAreaLeft>
            <Heading3>Общие сведения</Heading3>

            {invoices.map((invoice) => (
              <div>
                <StyledPremiumBlock>
                  <StyledGrayLabel>{invoice.summName}</StyledGrayLabel>
                  <StyledBigPremiumHeader>{stringToMoney(invoice.invoiceSum)} RUB</StyledBigPremiumHeader>
                </StyledPremiumBlock>
                {!invoice?.isAlreadyPayed &&
                  (invoice?.invoiceIsn ? (
                    <div>
                      <StyledButtonWrapper>
                        <Button iconBefore={<SendIcon/>} appearance='link' onClick={() => sendPaymentLinkHandler(invoice?.agreementIsn || 0, invoice) } >
                          Отправить ссылку на оплату
                        </Button>
                      </StyledButtonWrapper>

                      <StyledButtonWrapper>
                        <Button iconBefore={<FileIcon/>} appearance='link' onClick={() => openInvoiceHandler(contractId || 0, invoice) } >
                          Перейти к счету
                        </Button>
                      </StyledButtonWrapper>

                      <StyledButtonWrapper>
                        <Button iconBefore={<FileIcon/>} appearance='link' onClick={() => deleteInvoiceHandler(contractId || 0, invoice) } >
                          Аннулировать счет
                        </Button>
                      </StyledButtonWrapper>
                    </div>
                  ) : (
                    <div>
                      <StyledButtonWrapper>
                        <Button iconBefore={<SendIcon/>} appearance='link' onClick={() => sendPaymentLinkHandler(invoice?.agreementIsn || 0, invoice) } >
                          Отправить ссылку на оплату
                        </Button>
                      </StyledButtonWrapper>

                      <StyledButtonWrapper>
                        <Button iconBefore={<FileIcon/>} appearance='link' onClick={() => makeInvoiceHanlder(contractId || 0, invoice) } >
                          Сформировать счет на оплату
                        </Button>
                      </StyledButtonWrapper>
                    </div>
                  ))
                }
              </div>
            ))}




          </StyledToggleAreaLeft>
          <div>
            <Accordion header='Документы по договору' isOpen={stateResultDocumentsOpen} onChange={setResultDocumentsOpen}>
              <StyledDocuments>
                {resultDocuments.length !== 0 &&
                  resultDocuments.map(resultDocument => {
                    if(!resultDocument) return;
                    const { name, isn, type, templateIsn, warning } = resultDocument;
                    return (
                      <StyledDownloadFileItem id={name}>
                        <StyledFileName>
                          <FileIcon />
                          <Text4>{name?.length > 35 ? `${name.substr(0, 35)}...` : name}</Text4>
                        </StyledFileName>
                        <StyledButtonsWrapper>
                          <Button appearance='link' isLoading={loadingDownloadDoc} onClick={() => downloadResultDocumentHandler(isn, type, templateIsn, warning)} iconBefore={<DownloadIcon />}>
                            Скачать
                          </Button>
                          <Button appearance='link' isLoading={loadingDownloadDoc} onClick={() => sendResultDocumentHandler(resultDocument)} iconBefore={<SendIcon />}>
                            Отправить
                          </Button>
                        </StyledButtonsWrapper>
                      </StyledDownloadFileItem>
                    );
                  })}
              </StyledDocuments>
            </Accordion>
          </div>
        </StyledToggleAreaWrapper>

      </StyledContractWrapper>
      <GetInvoiceModal isOpen={stateInvoiceModalIsOpen} isLoading={false} onClose={closeInvoiceModalHandler} data={{
        invoice: selectedInvoice,
        email: agreement?.policyholder?.email,
        subject: `Счет ${selectedInvoice?.invoiceNo} по договору ${document?.number}`
      }}/>
      <SendPayLinkModal isOpen={stateSendModalIsOpen} isLoading={false} onClose={closeSendModalHandler} data={stateSendModalData}/>
      <SendPrintModal onClose={() => setSendPrintModalData(false)} isOpen={!!stateSendPrintModalData}
          data={{
            document: stateSendPrintModalData,
            email: agreement?.policyholder?.email
          }}
      />
    </MainLayoutIgniter>
  );

}

export default InvoicePage;