import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { ROLES } from '../../../../../../../libs/api/queries';
import { RolesQuery, RolesQueryVariables } from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

type Role = {
	name: string;
	id: number;
};

type RoleTabContext = {
	searchHandler: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	roles: Role[];
	roleClickHandler: (id: number) => void;
	isLoading: boolean;
};

const RoleTabContext = createContext<RoleTabContext>({} as RoleTabContext);

export const useRoleTabContext = (): RoleTabContext => useContext(RoleTabContext);

export const RolesTabContextProvider: React.FC = ({ children }) => {
	const {
		routes: { error500, roleById },
	} = useGlobalContext();
	const navigate = useNavigate();
	const [stateSearch, setSearch] = React.useState<string>('');
	const { data: dataRoles, loading: loadingRoles } = useQuery<RolesQuery, RolesQueryVariables>(ROLES, { onError: error => error500(error) });
	const roles: Role[] = (dataRoles?.roles || []).map(role => ({ name: role?.name || 'unknown', id: Number(role?.id) }));
	const filterRoles: Role[] = roles.filter(({ name }) => name.toLowerCase().includes(stateSearch.toLowerCase()));

	const searchHandler = React.useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSearch(value), []);

	const roleClickHandler = React.useCallback((id: number) => navigate(roleById(String(id))), [navigate, roleById]);

	const value: RoleTabContext = React.useMemo(
		() => ({
			searchHandler,
			roleClickHandler,
			roles: filterRoles,
			isLoading: loadingRoles,
		}),
		[searchHandler, roleClickHandler, filterRoles, loadingRoles]
	);

	return <RoleTabContext.Provider value={value}>{children}</RoleTabContext.Provider>;
};
