import React, { createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '../../../../../../../../apps/main/ui/GlobalContext';
import { useUserRights } from '../../../../../hooks/useUserRights';
import { useMainLayoutContext } from '../../../../ui/context';
import { LOGOUT } from '../../../../../../../../libs/api/commands';
import useNotification from '../../../../../../../widgets/notifier/ui/hooks/useNitification';
import useOnboarding from '../../../../../../../widgets/onboarding/hooks/useNitification';

type LinkItem = { label: string; link: string; isHidden: boolean; clickHandler: (link: string) => void };

type LeftMenuContext = {
	logoClickHandler: () => void;
	activeLinkIndex: number;
	links: LinkItem[];
	isLeftMenuOpen: boolean;
};

const LeftMenuContext = createContext<LeftMenuContext>({} as LeftMenuContext);

export const useLeftMenuContext = (): LeftMenuContext => useContext(LeftMenuContext);

export const LeftMenuContextProvider: React.FC = ({ children }) => {
	const { rightsHandler } = useUserRights();
	const { switchOnboarding } = useOnboarding();
	const {
		user: { type },
		routes: { policies, dashboard, contractsLog, agreementsLog, prolongationLog, redirectToAuthorization, roles, settings, reports, profile, underwriterLog },
	} = useGlobalContext();
	const { isLeftMenuOpen } = useMainLayoutContext();
	const navigate = useNavigate();
	const location = useLocation();
	const { setNotification } = useNotification();
	const windowWidth = useWindowWidth({ wait: 300 });

	const [logout] = useMutation(LOGOUT);

	const setActiveLinkHandler = (link: string): void => {
		if (link === location.pathname) return;
		navigate(link);
	};

	const logoutHandler = React.useCallback(() => {
		logout()
			.then(() => redirectToAuthorization())
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [logout, redirectToAuthorization, setNotification]);

	const logoClickHandler = React.useCallback(() => {
		if (type === 'client') return navigate(policies());
		navigate(dashboard());
	}, [type, dashboard, navigate, policies]);

	const desktopLinks: LinkItem[] = [
		{ label: 'Статистика', link: dashboard(), isHidden: !rightsHandler('statisticsPage'), clickHandler: setActiveLinkHandler },
		{
			label: 'Журнал заявок',
			link: agreementsLog(),
			isHidden: !rightsHandler('applicationsLogPage'),
			clickHandler: setActiveLinkHandler,
		},
		{
			label: 'Журнал договоров',
			link: contractsLog(),
			isHidden: !rightsHandler('agreementsLogPage'),
			clickHandler: setActiveLinkHandler,
		},
		{
			label: 'Журнал пролонгаций',
			link: prolongationLog(),
			isHidden: !rightsHandler('prolongationsLogPage'),
			clickHandler: setActiveLinkHandler,
		},
		{
			label: 'Журнал согласований',
			link: underwriterLog(),
			isHidden: !rightsHandler('underwriterLogPage'),
			clickHandler: setActiveLinkHandler,
		},
		{ label: 'Роли и группы', link: roles(), isHidden: !rightsHandler('manageUsers'), clickHandler: setActiveLinkHandler },
		{ label: 'Настройки', link: settings(), isHidden: !rightsHandler('settingsPage'), clickHandler: setActiveLinkHandler },
		{ label: 'Отчеты', link: reports(), isHidden: !rightsHandler('reportsPage'), clickHandler: setActiveLinkHandler },
	];

	const mobileLinks: LinkItem[] = [
		{ label: 'Профиль', link: profile(), isHidden: false, clickHandler: setActiveLinkHandler },
		{
			label: 'Помощь',
			link: 'help',
			isHidden: false,
			clickHandler: (): void => switchOnboarding(true),
		},
		{
			label: 'Выйти',
			link: 'redirectToAuth',
			isHidden: false,
			clickHandler: logoutHandler,
		},
	];

	const clientLinks: LinkItem[] = [
		{ label: 'Главная', link: policies(), isHidden: false, clickHandler: setActiveLinkHandler },
		{ label: 'Мои договора', link: '/myAgreements', isHidden: false, clickHandler: setActiveLinkHandler },
		{ label: 'Заполнить анкету', link: '/myAnketa', isHidden: false, clickHandler: setActiveLinkHandler },
	];

	const linksBuilder = (): LinkItem[] => {
		switch (type) {
			case 'admin':
			case 'user':
			case 'kias':
				if (windowWidth < 768) return [...desktopLinks, ...mobileLinks];
				return desktopLinks;
			case 'client':
				if (windowWidth < 768) return [...clientLinks, ...mobileLinks];
				return clientLinks;
			default:
				return [];
		}
	};
	const links: LinkItem[] = linksBuilder();
	const activeLinkIndex: number = links.filter(({ isHidden }) => !isHidden).findIndex(({ link }) => link === location.pathname);

	const value: LeftMenuContext = React.useMemo(
		() => ({
			links,
			isLeftMenuOpen,
			activeLinkIndex,
			logoClickHandler,
		}),
		[links, isLeftMenuOpen, activeLinkIndex, logoClickHandler]
	);

	return <LeftMenuContext.Provider value={value}>{children}</LeftMenuContext.Provider>;
};
