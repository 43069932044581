import { combineReducers } from 'redux';
import { notificationReducer } from '../../../../../modules/widgets/notifier/ds/redux/notification';
import { onboardingReducer } from '../../../../../modules/widgets/onboarding/ds/redux/onboarding';
import { leftMenuReducer } from '../../../../../modules/pages/common/mainLayout/widgets/leftMenu/ds/redux/leftMenu';
export const rootReducer = combineReducers({
	notify: notificationReducer,
	leftMenu: leftMenuReducer,
	onboarding: onboardingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
