import styled from 'styled-components';

export const StylesDocsHintWrapper = styled.div`
	> h4 {
		margin-bottom: 2rem;
	}

	p {
		margin-bottom: 1rem;
	}
`;
