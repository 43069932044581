import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
};

export const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		switchOnboarding: (state, payload: { payload: boolean }) => {
			state.isOpen = payload.payload;
		},
	},
});

export const onboardingReducer: typeof onboardingSlice.reducer = onboardingSlice.reducer;
