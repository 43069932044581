import styled from 'styled-components';
import { DEFAULT_SHADOW, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledSettingsWrapper = styled.div`
	h2 {
		margin-bottom: 4rem;
	}
	@media screen and (max-width: 767px) {
		h2 {
			margin-bottom: 2rem;
		}
	}
`;

export const StyledStepWrapper = styled.div`
	padding: 0 15rem;
	@media screen and (max-width: 767px) {
		padding: 0;
	}
`;

export const StyledStepFormWrapper = styled.div`
	padding: 4rem;
	background: ${white};
	box-shadow: ${DEFAULT_SHADOW};
	margin-bottom: 5rem;

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
		margin-bottom: 2rem;
	}
`;
