import React from "react";
import {useFormContext} from "react-hook-form";
import {useLazyQuery, useMutation} from "@apollo/client";
import {AgreementId} from "../createAgreement/ui/context";
import {
  AnketaQuery,
  AnketaQueryVariables, ContragentFillingTypeEnum,
  PrintTemplateEnum,
  PrintTypeEnum,
  SignAnketaMutation,
  SignAnketaMutationVariables,
  UpdateContragentFillingMutation,
  UpdateContragentFillingMutationVariables,
  useSaveAnketaMutation,
  useSendAnketaSmsMutation
} from "../../../libs/api/graphqlTypes";
import {AGREEMENT_DRAFT, ANKETA, LOAD_AGREEMENT} from "../../../libs/api/queries";
import {IForm, IFormField} from "../../../libs/uiKit/fieldsBuilder/types";
import {ISelectItem} from "../../../libs/uiKit/select/types";
import {
  HIDDEN_ALCOHOL,
  HIDDEN_EXTREME,
  HIDDEN_SMOKE,
  HIDDEN_SPORT,
  HIDDEN_WEIGHT,
  ISN_ALCOHOL,
  ISN_DISEASES,
  ISN_EXTREME,
  ISN_HOSPITAL,
  ISN_POLICE,
  ISN_SMOKE,
  ISN_SPORT,
  ISN_SUGAR,
  ISN_WEIGHT,
  RADIO_BOOLEAN,
  REQUIRED_ANKETA_FIELDS
} from "../../../libs/utils/staticData";
import {getSelectDefaultValue} from "../../../libs/utils/getSelectdefaultValue";
import {StyledAnketaTitle} from "../createAgreement/widgets/anketaFilling/widgets/onlineFilling/ui/styles";
import {FieldsBuilder} from "../../../libs/uiKit/fieldsBuilder";
import Button from "../../../libs/uiKit/button";
import {DownloadIcon} from "../../../icons/ui/Download";
import SendSms from "../createAgreement/widgets/anketaFilling/widgets/sendSms";
import {usePrintMutation} from "../../../libs/api/hooks/usePrintMutation";
import {SIGN_ANKETA, UPDATE_CONTRAGENT_FILLING} from "../../../libs/api/commands";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";
import {useNavigate} from "react-router-dom";

export type OnlineFillingContextProps = {
  agreementId: AgreementId;
  phone: string;
}

type InsuranceFormItem = {
  title: string;
  form: IForm;
};

type AnketaFormType = {
  [key: string]: string | number | ISelectItem;
};



const AnketaEdit = (props: OnlineFillingContextProps) => {

  const formHook = useFormContext();

  const {agreementId, phone} = props;

  const [getAnketaQuestions, { data: dataAnketaQuestions }] = useLazyQuery<AnketaQuery, AnketaQueryVariables>(ANKETA, { onError: error => console.log('23') });
  const [saveAnketaMutation, { loading: loadingSaveAnketa }] = useSaveAnketaMutation();
  const [sendSmsMutation, { loading: loadingSendSms }] = useSendAnketaSmsMutation();
  const {doPrint, isPrintLoading} = usePrintMutation();
  const [signAnketa, { data: dataSignAnketa, loading: loadingSignAnketa }] = useMutation<SignAnketaMutation, SignAnketaMutationVariables>(SIGN_ANKETA, {
    refetchQueries: ['loadContract'], awaitRefetchQueries: true
  });

  const isSigned: boolean = dataSignAnketa?.signAnketa === 'ok';

  const [stateRemarks, setRemarks] = React.useState<string[]>([]);

  const { setNotification } = useNotification();
  const navigate = useNavigate();

  const {
    routes: { contractById },
  } = useGlobalContext();

  const [isnWeightValue, isnSmokeValue, isnAlcoholValue, isnSportValue, isnExtremeValue] = formHook.watch([ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME]);
  const isWeight: boolean = isnWeightValue === 'true';
  const isSmoke: boolean = isnSmokeValue === 'true';
  const isAlcohol: boolean = isnAlcoholValue === 'true';
  const isSport: boolean = isnSportValue === 'true';
  const isExtreme: boolean = isnExtremeValue === 'true';

  const checkIsHidden = (name: string, isRadio: boolean): boolean => {
    if (HIDDEN_WEIGHT.includes(name)) return !isWeight;
    if (HIDDEN_SMOKE.includes(name)) return !isSmoke;
    if (HIDDEN_ALCOHOL.includes(name)) return !isAlcohol;
    if (HIDDEN_SPORT.includes(name)) return !isSport;
    if (HIDDEN_EXTREME.includes(name)) return !isExtreme;
    if (![ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(name) && isRadio) return !stateRemarks.includes(name);
    return false;
  };

  const getRemarkPlaceholder = (name: string) => {
    switch (name) {
      case ISN_HOSPITAL:
        return 'Укажите сколько раз, по поводу какого заболевания';
      case ISN_SUGAR:
        return 'Укажите свой уровень глюкозы в крови';
      default:
        if (ISN_DISEASES.includes(name)) return 'Укажите даты, диагнозы, симптомы, жалобы, опишите подробности';
        return 'Укажите подробности';
    }
  };

  const formatAnketaValueToBack = (value: string | number | ISelectItem): string | number | boolean | undefined | null => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (typeof value === 'object') return Number(value?.value) || null;
    return value;
  };

  const saveAnketa = async () => {
    debugger;
    const result = await formHook.trigger(undefined, { shouldFocus: true });
    if (!result) return false;
    const formData: AnketaFormType = formHook.getValues() as AnketaFormType;
    const anketaAnswers = Object.entries(formData).reduce<{ isn: string; value: any; remark?: string | number | ISelectItem }[]>((prev, [key]) => {
      if (!key.startsWith('question-') || key.endsWith('-remark')) return prev;
      const isn: string | undefined = key.split('-')[1];
      const remark = formData[`question-${isn}-remark`];
      const fieldValue = formData[`question-${isn}`];
      const formattedValue = formatAnketaValueToBack(fieldValue);
      if (!isn) return prev;
      return [...prev, {isn, remark, value: formattedValue}];
    }, []);
    await saveAnketaMutation({ variables: { id: agreementId || 0, contragentIsn: 1, questions: anketaAnswers } });
    return true;
  }

  const downloadAnketa = async () => {
    if(await saveAnketa()){
      doPrint(agreementId || 0, PrintTypeEnum.B2B, PrintTemplateEnum.Anketa);
    }
  }

  const codeEnteredHandler = (code: string) => {
    signAnketa({ variables: { id: agreementId || 0, contragentIsn: 1, code } })
      .then(() => {
        setNotification({ type: 'success', text: 'Анкета успешно подписана' });
        navigate(contractById(String(agreementId)));
      })
      .catch(error => setNotification({ type: 'error', text: error }));
  }

  const getCodeHandler = async () => {
    if (await saveAnketa()) {
      sendSmsMutation({
        variables: {
          id: agreementId || 0,
          contragentIsn: 1,
          isDeclaration: false
        }
      }).then(() => {
        setNotification({type: 'success', text: 'Код успешно отправлен'});
      }).catch(error => setNotification({ type: 'error', text: error }));
    }
  }


  React.useEffect(() => {
    if (!agreementId) return;
    getAnketaQuestions({ variables: { id: agreementId, contragentIsn: 1 } });
  }, [agreementId, getAnketaQuestions]);

  console.log('dataAnketaQuestions', dataAnketaQuestions);

  const forms: InsuranceFormItem[] = (dataAnketaQuestions?.anketa || [])
    .filter(part => ['step_2', 'step_8'].includes(part?.key || 'unknown'))
    .map(part => ({
    title: part?.text || 'unknown',
    form: {
      formHook,
      fields: (part?.questions || []).reduce<IFormField[]>((prev, question) => {
        if (!question) return prev;
        const { isn, type, text, dicti, value, remark } = question;
        const fieldName = `question-${isn}`;
        const placeholder = text || undefined;
        const items: ISelectItem[] = (dicti || []).map(dict => ({ label: dict?.name || 'unknown', value: dict?.isn || 'unknown' }));
        const isValidate: boolean = REQUIRED_ANKETA_FIELDS.includes(fieldName);

        if (type === 'INTEGER')
          return [
            ...prev,
            {
              isHidden: checkIsHidden(fieldName, false),
              field: {
                fieldType: 'number',
                fieldName,
                placeholder,
                defaultValue: value || undefined,
                ...(isValidate
                  ? {
                    validationType: 'minMax',
                    minValue: 1,
                    maxValue: 999,
                    isRequired: true,
                  }
                  : {}),
              },
            },
          ];

        if (type === 'CHECKBOX' && [ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(fieldName))
          return [
            ...prev,
            {
              isHidden: checkIsHidden(fieldName, true),
              label: placeholder,
              field: {
                fieldType: 'radio',
                items: RADIO_BOOLEAN,
                defaultValue: value === '1' ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
                fieldName,
              },
            },
          ];

        if (type === 'CHECKBOX' && ![ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(fieldName))
          return [
            ...prev,
            {
              label: placeholder,
              field: {
                fieldType: 'radio',
                items: RADIO_BOOLEAN,
                defaultValue: value === '1' ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
                onChange: ev => setRemarks(state => (ev.target.value === 'true' ? [...state, fieldName] : state.filter(name => name !== fieldName))),
                fieldName,
              },
            },
            {
              isHidden: checkIsHidden(fieldName, true),
              field: {
                fieldType: 'input',
                fieldName: `${fieldName}-remark`,
                defaultValue: remark || undefined,
                placeholder: getRemarkPlaceholder(fieldName),
              },
            },
          ];

        if (type === 'TEXT')
          return [
            ...prev,
            {
              isHidden: checkIsHidden(fieldName, false),
              field: {
                fieldType: 'input',
                fieldName,
                defaultValue: value || undefined,
                placeholder,
              },
            },
          ];
        if (type === 'SQL')
          return [
            ...prev,
            {
              isHidden: checkIsHidden(fieldName, false),
              field: {
                fieldType: 'select',
                fieldName,
                defaultValue: getSelectDefaultValue(value, items),
                placeholder,
                items,
              },
            },
          ];

        return prev;
      }, []),
    },
  }));

  return (
      <>
        {forms.map(({ title, form }, index) => (
          <React.Fragment key={`insurance-form-${index}`}>
            <StyledAnketaTitle>{title}</StyledAnketaTitle>
            <FieldsBuilder {...form} />
          </React.Fragment>
        ))}
        <Button isLoading={loadingSaveAnketa || isPrintLoading} appearance='link' iconBefore={<DownloadIcon />} onClick={downloadAnketa}>
          Скачать анкету
        </Button>
        <SendSms isLoading={loadingSaveAnketa || loadingSendSms || loadingSignAnketa} isDisabled={false} codeEnteredHandler={codeEnteredHandler} getCodeHandler={getCodeHandler} defaultValue={{
          sendSmsPhone: phone,
        }} agreementMode={false} />
      </>
  )
}

export default AnketaEdit;