import styled from 'styled-components';

export const StyledUploadFileInToggle = styled.div`
	margin-bottom: 2rem;
`;

export const StyledDownloadAllButton = styled.div`
	margin-bottom: 2rem;
`;

export const StyledDocuments = styled.div`
	max-height: 50rem;
	overflow: auto;
	> .linkButton {
		margin: 0 0 1.5rem 4rem;
	}

	@media screen and (max-width: 767px) {
		> .linkButton {
			margin: 0 0 1.5rem 0;
		}
		margin: 0 0 1.5rem 1.5rem;
	}
`;

export const StyledFileName = styled.div`
	display: flex;
	align-items: center;
	p {
		margin-left: 1.5rem;
	}
`;

export const StyledDownloadFileItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2.5rem;
	:first-child {
		margin-top: 1rem;
	}
`;

export const StyledButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	button:last-child {
		margin-left: 1.5rem;
	}
`;
