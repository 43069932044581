import React from "react";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import FormModal from "../../../widgets/FormModal";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {fileDownloader} from "../../../../libs/utils/fileDownloader";
import {PrintMutation, PrintMutationVariables} from "../../../../libs/api/graphqlTypes";
import {PRINT_MUTATION} from "../../../../libs/api/commands";


const SendPrintModal = (props: IModalProps) => {

  const { id } = useParams<{ id?: string }>();

  const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
    fetchPolicy: 'network-only',
  });

  const {data, onClose} = props;
  const {document, email} = data;

  const { name, isn, type, templateIsn, subject } = document||{};


  const {setNotification} = useNotification();

  const onSubmit = (formData: any) => {
    if(!id) return;
    const email = formData?.email;
    downloadDoc({
      variables: {
        isn,
        type,
        templateIsn,
        email,
        subject: subject || name
      },
    }).then(({ data }) => {
      setNotification({type:"success", text: `Документ отправлен на адрес ${email}`});
      onClose();
    }).catch(error => setNotification({ type: 'error', text: error }))

  }

  return (
    <FormModal {...props}
      header="Отправить документ"
      fields={[
        {
          field: {
            fieldName: "email",
            fieldType: "input",
            validationType: "email",
            errorMessage: "Введите email",
            placeholder: "Email",
            defaultValue: email
          }
        },
      ]}
      onSubmit={onSubmit}
      submitButton="Отправить"
      isLoading={loadingDownloadDoc}
    />

  );
}

export default SendPrintModal;