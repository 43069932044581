import React from 'react';
import MainLayoutContextProvider from './ui/context';
import MainLayoutUi from './ui';

const MainLayoutIgniter: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<MainLayoutContextProvider>
		<MainLayoutUi>{children}</MainLayoutUi>
	</MainLayoutContextProvider>
);

export default MainLayoutIgniter;
