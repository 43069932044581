import styled from 'styled-components';
import { black, grey, lightBlue } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledContacts = styled.div<{ isAuthorization?: boolean }>`
	${({ isAuthorization }) =>
		isAuthorization
			? `
      p {
        opacity: 0.5;
      }
      margin-top: 4rem;
      `
			: `
      margin-top: 3rem;
      padding: 0 4rem;
      p {
        white-space: nowrap;
        margin-top: 1.5rem;
        opacity: 0.5;
      }
      `}
	display: flex;
	flex-direction: column;

	h5 {
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	h5 > span {
		display: block;
		margin-left: 1rem;
	}

	h5 > svg {
		min-width: 1.5rem;
	}
`;

export const StyledCuratorContacts = styled.div`
	cursor: pointer;
	:hover {
		color: ${lightBlue};
	}
	margin-bottom: 1.5rem;
`;

export const StyledContactPhone = styled.a`
	:hover {
		color: ${lightBlue};
	}
	:focus {
		outline: none;
	}
	color: ${black};
	text-decoration: none;
	margin-bottom: 1.5rem;
`;

export const StyledCuratorContactsWrapper = styled.div`
	margin-top: 2rem;
	> p {
		opacity: 0.5;
	}
`;
export const StyledCuratorContactItem = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 1rem;

	> p:first-child {
		opacity: 0.5;
	}

	border-bottom: 1px solid ${grey};
	margin-bottom: 1.5rem;
	padding-bottom: 2rem;

	@media screen and (max-width: 767px) {
		> p:last-child {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}
`;
