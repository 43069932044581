import React from 'react';
import { useCopyrightContext } from './context';
import { StyledContactPhone, StyledContacts, StyledCuratorContactItem, StyledCuratorContacts, StyledCuratorContactsWrapper } from './styles';
import { Heading3, Heading5, Text1, Text3, TextButton } from '../../../../libs/uiKit/globalStyledComponents';
import { ContactPersonIcon } from '../../../../icons/ui/ContactPerson';
import { PhoneIcon } from '../../../../icons/ui/Phone';
import { Modal } from '../../../../libs/uiKit/modal';

const CopyrightUi: React.FC = () => {
	const { isAuthorization, isShowCuratorContacts, closeCuratorModal, curatorName, curatorPhone, curatorEmail, openCuratorModal, isCuratorModalOpen, phone, copyrightText } =
		useCopyrightContext();

	return (
		<>
			<StyledContacts isAuthorization={isAuthorization}>
				{isShowCuratorContacts && (
					<StyledCuratorContacts onClick={openCuratorModal}>
						<Heading5>
							<ContactPersonIcon />
							<span>Контакты куратора</span>
						</Heading5>
					</StyledCuratorContacts>
				)}
				<StyledContactPhone href={`tel:${phone}`}>
					<Heading5>
						<PhoneIcon />
						<span>{phone}</span>
					</Heading5>
				</StyledContactPhone>
				<Text3>{copyrightText}</Text3>
			</StyledContacts>

			<Modal onClose={closeCuratorModal} isOpen={isCuratorModalOpen}>
				<StyledCuratorContactsWrapper>
					<StyledCuratorContactItem>
						<TextButton>Ваш куратор</TextButton>
						<Heading3>{curatorName}</Heading3>
					</StyledCuratorContactItem>
					<StyledCuratorContactItem>
						<TextButton>Телефон</TextButton>
						<Text1>{curatorPhone}</Text1>
					</StyledCuratorContactItem>
					<StyledCuratorContactItem>
						<TextButton>E-mail</TextButton>
						<Text1>{curatorEmail}</Text1>
					</StyledCuratorContactItem>
					<TextButton>с 09:00 до 18:00 по московскому времени, пн-пт</TextButton>
				</StyledCuratorContactsWrapper>
			</Modal>
		</>
	);
};

export default React.memo(CopyrightUi);
