import styled from 'styled-components';
import {white} from "../../../libs/uiKit/globalStyledComponents";

export const StyledStatisticsCardWarning = styled.div`
  padding: 3rem;
  background: ${white};
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  margin-bottom: 4rem;
  
  @media screen and (max-width: 767px) {
    padding: 1.5rem;
  }
`;