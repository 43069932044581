import React, { createContext, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';
import {
	CreateUpdateGroupMutation,
	CreateUpdateGroupMutationVariables,
	GroupsQuery,
	GroupsQueryVariables,
	UsersQuery,
	UsersQueryVariables,
} from '../../../../../../../libs/api/graphqlTypes';
import { GROUPS, USERS } from '../../../../../../../libs/api/queries';
import { CREATE_UPDATE_GROUP } from '../../../../../../../libs/api/commands';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { getSelectDefaultValue } from '../../../../../../../libs/utils/getSelectdefaultValue';

export type GroupProps = {
	id?: number | undefined | null;
	name?: string | undefined;
	leaderId?: number | undefined;
	curatorId?: number | undefined;
	parentId?: number | undefined;
	email?: string | undefined;
	isEdit?: boolean;
};

export type CreateGroupProps = GroupProps & IModalProps;

type CreateGroupContext = IModalProps & {
	isLoadingUpdateGroup: boolean;
	onFormSubmit: () => void;
	form: IForm;
	isEdit: boolean;
	id: number;
	name: string;
};

type CreateGroupForm = {
	name: string;
	email?: string;
	parent?: ISelectItem;
	leader?: ISelectItem;
	curator?: ISelectItem;
};

const CreateGroupContext = createContext<CreateGroupContext>({} as CreateGroupContext);

export const useCreateGroupContext = (): CreateGroupContext => useContext(CreateGroupContext);

export const CreateGroupContextProvider: React.FC<CreateGroupProps> = props => {
	const { id, name, leaderId, curatorId, parentId, email, isEdit, children, onClose, isOpen, header } = props;
	const {
		routes: { error500 },
	} = useGlobalContext();
	const { setNotification } = useNotification();
	const formHook = useForm<CreateGroupForm>({ mode: 'onBlur' });

	const { data: dataUsers, loading: loadingUsers } = useQuery<UsersQuery, UsersQueryVariables>(USERS, { onError: error => error500(error) });
	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });
	const [createGroup, { loading: loadingCreateGroup }] = useMutation<CreateUpdateGroupMutation, CreateUpdateGroupMutationVariables>(CREATE_UPDATE_GROUP, {
		refetchQueries: ['groups', 'users'],
	});

	const selectUsers: ISelectItem[] = (dataUsers?.users || []).map(user => ({ label: user?.email || 'unknown', value: String(user?.id) }));
	const selectGroups: ISelectItem[] = (dataGroups?.groups || []).map(group => ({ label: group?.name || 'unknown', value: String(group?.id) }));

	const onFormSubmit = formHook.handleSubmit(data => {
		createGroup({
			variables: {
				id: id || null,
				input: {
					name: data.name,
					email: data.email,
					parent_id: Number(data.parent?.value) || null,
					leader_id: Number(data.leader?.value) || null,
					curator_id: Number(data.curator?.value) || null,
				},
			},
		})
			.then(() => {
				setNotification({ type: 'success', text: `Группа успешно ${isEdit ? 'обновлена' : 'создана'}` });
				onClose();
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'name',
						isRequired: true,
						grid: 6,
						placeholder: 'Название группы',
						errorMessage: 'Введите название',
						defaultValue: name,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'email',
						grid: 6,
						validationType: 'email',
						placeholder: 'Email группы',
						defaultValue: email,
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'parent',
						isLoading: loadingGroups,
						items: selectGroups,
						placeholder: 'Родительская группа',
						isSearchAvailable: true,
						isClearable: true,
						defaultValue: getSelectDefaultValue(parentId, selectGroups),
						height: 12,
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'leader',
						placeholder: 'Лидер группы',
						isLoading: loadingUsers,
						items: selectUsers,
						isSearchAvailable: true,
						isClearable: true,
						defaultValue: getSelectDefaultValue(leaderId, selectUsers),
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'curator',
						placeholder: 'Куратор группы',
						isLoading: loadingUsers,
						items: selectUsers,
						isSearchAvailable: true,
						isClearable: true,
						defaultValue: getSelectDefaultValue(curatorId, selectUsers),
					},
				},
			],
		}),
		[curatorId, email, formHook, leaderId, loadingGroups, loadingUsers, name, parentId, selectGroups, selectUsers]
	);

	const value: CreateGroupContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isEdit: !!isEdit,
			isLoadingUpdateGroup: loadingCreateGroup,
			onClose,
			isOpen,
			header,
			id: id || 0,
			name: name || '',
		}),
		[form, header, isEdit, isOpen, loadingCreateGroup, onClose, onFormSubmit]
	);

	return <CreateGroupContext.Provider value={value}>{children}</CreateGroupContext.Provider>;
};
