import React, { createContext, useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PropertyContextProps, PropertyField } from '../../../../widgets/property/ui/context';
import { DEFAULT_PROPERTY_DATA } from '../../../../ds/repositories/commands';

type PropertyContext = {
	properties: PropertyContextProps[];
	deletePropertyHandler: (number: number) => void;
	addPropertyHandler: () => void;
};

export type PropertyForm = {
	properties: PropertyField[];
};

const PropertyContext = createContext<PropertyContext>({} as PropertyContext);

export const usePropertyContext = (): PropertyContext => useContext(PropertyContext);

const PropertyContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const formHook = useFormContext<PropertyForm>();
	const { fields, append, remove } = useFieldArray({
		control: formHook.control,
		name: 'properties',
	});

	const deletePropertyHandler = React.useCallback((number: number) => remove(number), [remove]);
	const addPropertyHandler = React.useCallback(() => {
		append(DEFAULT_PROPERTY_DATA)
		/* fields.forEach((field) => {
			field.pledgeShare = Math.round( 100 / fields.length )
		}) */
	}, [append]);

	const value: PropertyContext = React.useMemo(
		() => ({
			properties: fields.map((field, index) => ({
				isFull: index === 0,
				arrayName: 'properties',
				defaultValues: field,
				number: index,
			})),
			deletePropertyHandler,
			addPropertyHandler,
		}),
		[addPropertyHandler, deletePropertyHandler, fields]
	);

	return <PropertyContext.Provider value={value}>{children}</PropertyContext.Provider>;
};

export default React.memo(PropertyContextProvider);
