import styled, { css } from 'styled-components';
import { ANIMATION_SEC, visuallyHidden } from '../globalStyles';

export const StyledRadioBox = styled.label<{ isDisabled: boolean; isInvalid: boolean }>`
	display: flex;
	align-items: center;
	cursor: ${({ isDisabled }) => (isDisabled ? 'no-drop' : 'pointer')};
	margin-right: 1.5rem;

	${({ isInvalid }) =>
		isInvalid
			? css`
					color: ${({ theme }) => theme.radio.invalid.color};

					// circle
					& input + * {
						border: 1px solid ${({ theme }) => theme.radio.circle.invalid.border} !important;
					}
			  `
			: css`
					color: ${({ theme }) => theme.radio.color};

					:hover {
						color: ${({ theme }) => theme.radio.hover.color};
					}

					:active {
						color: ${({ theme }) => theme.radio.active.color};
					}
			  `};
`;

export const StyledRadioInput = styled.input`
	${visuallyHidden}
	&:checked + *::before {
		width: 1rem;
		height: 1rem;
		background: ${({ theme }) => theme.radio.circle.checked.bg};
	}

	// circle
	&:disabled + * {
		opacity: 0.5;
	}

	// label
	&:disabled + * + * {
		color: ${({ theme }) => theme.radio.disabled.color};
	}
`;

export const StyledRadioItemsWrapper = styled.div<{ template?: string; rowGap?: number; columnGap?: number }>`
	${({ template, rowGap, columnGap }) =>
		template
			? css`
					display: grid;
					grid-template-columns: ${template};
					row-gap: ${rowGap || 1.5}rem;
					column-gap: ${columnGap || 1.5}rem;
			  `
			: css`
					display: flex;
			  `}
`;

export const StyledRadioWrapper = styled.div`
	position: relative;
`;

export const StyledCircle = styled.span`
	display: inline-block;
	box-sizing: border-box;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.radio.circle.border};
	vertical-align: middle;
	position: relative;
	margin-right: 1rem;

	&::before {
		content: '';
		transition: all ${ANIMATION_SEC}s ease-in-out;
		background: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}
`;
