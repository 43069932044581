import React from 'react';
import { usePrecalculationContext } from './context';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import { StyledRisksFormWrapper } from './styles';
import Risks from './Risks';

const HouseReady: React.FC = () => {
	const { risksForm, isShowRiskLife, isShowRiskProperty, isShowRiskTitle } = usePrecalculationContext();

	return (
		<>
			<FieldsBuilder {...risksForm} />
			<StyledRisksFormWrapper>
				{isShowRiskLife && <Risks riskType='life' />}
				{isShowRiskProperty && <Risks riskType='property' />}
				{isShowRiskTitle && <Risks riskType='title' />}
			</StyledRisksFormWrapper>
		</>
	);
};

export default React.memo(HouseReady);
