import React, { createContext, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { LOAD_AGREEMENT } from '../../../../../libs/api/queries';
import {
	CalculationRisk,
	ChangeCalculationMutation,
	ChangeCalculationMutationVariables,
	DictKeyEnum,
	LoadAgreementUnderwritingQuery,
	LoadAgreementUnderwritingQueryVariables, ResultDocument, useAssignAgreementMutation,
} from '../../../../../libs/api/graphqlTypes';
import { AgreementId } from '../../../createAgreement/ui/context';
import { TimeReloadIcon } from '../../../../../icons/ui/TimeReload';
import { useUserRights } from '../../../common/hooks/useUserRights';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';
import { formatStringRuToDate } from '../../../../../libs/utils/gormatDateToUsa';
import useGetDicti from '../../../common/hooks/useGetDicti';
import { getClearSelectValue } from '../../../../../libs/utils/getSelectdefaultValue';
import { stringToMoney } from '../../../../../libs/uiKit/utils';
import { formatUnderwritingStatusToBack, formRiskLifeBuilder, formRiskPropertyBuilder, formRiskTitleBuilder, getRisksField } from '../../ds/commands';
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import { CHANGE_UNDERWRITING } from '../../../../../libs/api/commands';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import riskProperty from "../../../contract/widgets/riskProperty";
import {formatContractDataToFormData, setDataToContractForm} from "../../../contract/ds/repositories/commands";

type HeaderButton = { label: string; event: () => void; icon: React.ReactNode; isLoading?: boolean; isDisabled?: boolean };

type InformationItem = { title: string; description: string; color?: string | null; isStatus?: boolean; description2?: string };

export type RiskForm = { form: IForm; informationItems: { label: string; value: string }[]; remark: string | undefined | null };

export type RiskFormItem = {
	title: string;
	forms: RiskForm[];
};

type RiskField = {
	heightWeight?: number;
	pressure?: number;
	base_tariff?: number;
	tariff?: number;
	share?: number;
	discount?: number;
	allowance?: number;
	status?: string;
};

type UnderwritingForm = {
	tariffGroup: ISelectItem;
	program: ISelectItem;
	risksLife: RiskField[];
	risksProperty: RiskField[];
	risksTitle: RiskField[];
};

type UnderwritingContext = {
	risksDropdownIsOpen: boolean;
	changeLogIsOpen: boolean;
	accordionIsOpen: boolean;
	switchRisksDropDown: (isOpen: boolean) => void;
	switchAccordion: (isOpen: boolean) => void;
	headerButtons: HeaderButton[];
	informationItems: InformationItem[];
	isShowGoToAgreement: boolean;
	isDisabledGoToAgreement: boolean;
	isDisabledRecalc: boolean;
	isDisabledChangeRisks: boolean;
	isLoadingGoToAgreement: boolean;
	isLoadingEvents: boolean;
	isLoadingAgreement: boolean;
	goToAgreementHandler: () => void;
	goBackHandler: () => void;
	closeChangeLogHandler: () => void;
	riskForm: IForm;
	headerForm: IForm;
	totalSumm: string;
	totalSummWithDiscount: string;
	discount: string;
	discountDueKv: string;
	riskFormItems: RiskFormItem[];
	onFormSubmit: () => void;
	agreementId: AgreementId;
	resultDocuments: ResultDocument[]
	email: string;
	isAssignedToMe: boolean;
	assignToMeHandler: () => void;
	fillingTypeText: string | null | undefined;
	isAtUnderwriting: boolean;
	calculation: any;
	status: string | null | undefined;
};

const UnderwritingContext = createContext<UnderwritingContext>({} as UnderwritingContext);

export const useUnderwritingContext = (): UnderwritingContext => useContext(UnderwritingContext);

const UnderwritingContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		routes: { error500, contractById, agreementsLog },
		user: {
			id: currentUserId
		}
	} = useGlobalContext();
	const { rightsHandler } = useUserRights();
	const { id } = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const { setNotification, showError } = useNotification();
	const formHook = useForm<UnderwritingForm>({ mode: 'onBlur' });

	useFieldArray({
		control: formHook.control,
		name: 'risksLife',
	});
	useFieldArray({
		control: formHook.control,
		name: 'risksProperty',
	});
	useFieldArray({
		control: formHook.control,
		name: 'risksTitle',
	});

	const agreementId: AgreementId = Number.isNaN(Number(id)) ? null : Number(id);

	const { data: selectPrograms, loading: loadingPrograms } = useGetDicti(DictKeyEnum.Program);
	const { data: selectTariffGroup, loading: loadingTariffGroup } = useGetDicti(DictKeyEnum.Group);

	const [stateRisksDropdownIsOpen, setRisksDropdownIsOpen] = React.useState<boolean>(false);
	const [stateChangeLogModalIsOpen, setChangeLogModalIsOpen] = React.useState<boolean>(false);
	const [stateAccordionIsOpen, setAccordionIsOpen] = React.useState<boolean>(true);

	const { data: dataAgreement, loading: loadingAgreement } = useQuery<LoadAgreementUnderwritingQuery, LoadAgreementUnderwritingQueryVariables>(LOAD_AGREEMENT, {
		variables: { id: agreementId },
		onError: error => showError(error.message),
	});
	const [changeUnderwriting, { loading: loadingChangeUnderwriting }] = useMutation<ChangeCalculationMutation, ChangeCalculationMutationVariables>(CHANGE_UNDERWRITING, {
		refetchQueries: ['loadAgreementUnderwriting'],
		awaitRefetchQueries: true,
	});

	const [ assignAgreement, {loading: loadingAssignAgreement} ] = useAssignAgreementMutation({
		refetchQueries: ['loadAgreementUnderwriting'],
		awaitRefetchQueries: true,
	});

	const { number, added, user, status, calculation, calcForm, resultDocuments, underwriters, fillingTypeText, relatedAgreementId: contractId } = dataAgreement?.loadAgreement || {};
	const {
		life_risks,
		property_risks,
		titul_risks,
		program,
		beneficiary,
		tariff_group,
		credit_terms,
		policyholder_birthday,
		policyholder_fio,
		policyholder_email,
		premium_sum,
		premium_sum_with_kv,
		seller_discount_due_kv,
		discount_kv,
		discount_kv_sum,
		agent,
		bank_kv
	} = calculation || {};
	const { riskLife } = calcForm || {};

	const isCanceled: boolean = ['annul', 'clientRefusal'].includes(status?.value || '');
	const isAtUnderwriting: boolean = ['waitingForUnderwriting', 'partiallyAgreed'].includes(status?.value || '');
	const risksLife: CalculationRisk[] = (life_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksProperty: CalculationRisk[] = (property_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksTitle: CalculationRisk[] = (titul_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);

	const isFormBlocked = isCanceled || loadingChangeUnderwriting || !!contractId;

	const switchRisksDropDown = React.useCallback((isOpen: boolean) => setRisksDropdownIsOpen(isOpen), []);
	const switchAccordion = React.useCallback((isOpen: boolean) => setAccordionIsOpen(isOpen), []);
	const goToAgreementHandler = React.useCallback(() => navigate(contractById(String(contractId))), [contractById, navigate]);
	const goBackHandler = React.useCallback(() => navigate(agreementsLog()), [navigate, agreementsLog]);
	const closeChangeLogHandler = React.useCallback(() => setChangeLogModalIsOpen(false), []);
	const onFormSubmit = formHook.handleSubmit(
		data => {
			changeUnderwriting({
				variables: {
					id: agreementId,
					input: {
						tariff_group: data.tariffGroup,
						program: data.program,
						risks: [
							...data.risksLife.map((risk, index) => ({
								isn: risksLife[index].isn,
								status: formatUnderwritingStatusToBack(risk.status),
								high_tariff_height: risk.heightWeight,
								high_tariff_press: risk.pressure,
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
								base_tariff: risk.base_tariff,
								tariff: risk.tariff,
							})),
							...data.risksProperty.map((risk, index) => ({
								isn: risksProperty[index].isn,
								status: formatUnderwritingStatusToBack(risk.status),
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
								base_tariff: risk.tariff,
							})),
							...data.risksTitle.map((risk, index) => ({
								isn: risksTitle[index].isn,
								status: formatUnderwritingStatusToBack(risk.status),
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
								base_tariff: risk.tariff,
							})),
						],
					},
				},
			})
				.then(() => setNotification({ type: 'success', text: 'Данные успешно обновлены' }))
				.catch(error => setNotification({ type: 'error', text: error }))
				.finally(() => switchRisksDropDown(false));
		},
		() => (stateAccordionIsOpen ? undefined : switchAccordion(true))
	);

	const assignToMeHandler = () => {
		if(!agreementId) return;
		assignAgreement({
			variables: {
				id: agreementId
			}
		})
	}

	const headerButtons: HeaderButton[] = React.useMemo(
		() => [
			{
				label: 'История изменений',
				icon: <TimeReloadIcon />,
				event: () => setChangeLogModalIsOpen(true),
			},
		],
		[]
	);

	const underwritersLine = (underwriters||[]).map((user) => user?.username).join(", ");
	const isAssignedToMe = ((underwriters||[]).filter((user) => user?.id === currentUserId ).length > 0);

	const informationItems: InformationItem[] = React.useMemo(
		() => [
			{ title: 'Номер заявки', description: number || 'Неизвестно' },
			{
				title: 'Статус',
				description: status?.label || 'Неизвестно',
				color: status?.color,
				isStatus: true,
				description2: underwriters ? `Назначено на ${underwritersLine}` : "Ожидание"
			},
			{
				title: 'Создана',
				description: `${user?.username || 'Не известный'} ${added}`,
			},
			{
				title: 'Точка продаж (группа)',
				description: `${user?.isSuper ? 'Администратор' : `${user?.group?.name || 'неизвестно'}`}`,
			},
		],
		[added, number, status?.color, status?.label, user?.group?.name, user?.isSuper, user?.username]
	);

	const {
		user: {
			restrictions: {
				maxDiscount
			}
		},
	} = useGlobalContext();

	const maxDiscountValue = maxDiscount || 0;

	const riskFormItems: RiskFormItem[] = React.useMemo(
		() => [
			{ ...formRiskLifeBuilder({
					risks: risksLife,
					formHook,
					isDisabled: isFormBlocked,
					arrayName: 'risksLife',
					maxDiscount: maxDiscountValue,
					totalTariff: rightsHandler('lifeTotalTariff'),
			}) },
			{ ...formRiskPropertyBuilder({ risks: risksProperty, formHook, isDisabled: isFormBlocked, arrayName: 'risksProperty', maxDiscount: maxDiscountValue, totalTariff: false }) },
			{ ...formRiskTitleBuilder({ risks: risksTitle, formHook, isDisabled: isFormBlocked, arrayName: 'risksTitle', maxDiscount: maxDiscountValue, totalTariff: false }) },
		],
		[formHook, isFormBlocked, risksLife, risksProperty, risksTitle]
	);

	const riskForm: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				...getRisksField(risksLife, 'risksLife', 'жизнь'),
				...getRisksField(risksProperty, 'risksProperty', 'имущество'),
				...getRisksField(risksTitle, 'risksTitle', 'титул'),
			],
		}),
		[formHook, risksLife, risksProperty, risksTitle]
	);

	const headerForm: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'daData',
						daDataType: 'fio',
						fieldName: 'name',
						placeholder: 'ФИО Заемщика',
						validationType: 'fullName',
						defaultValue: policyholder_fio ? { value: policyholder_fio } : undefined,
						isDisabled: true,
						isRequired: true,
						errorMessage: 'Введите ФИО',
						grid: 3,
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: 'age',
						defaultValue: policyholder_birthday ? formatStringRuToDate(policyholder_birthday) : undefined,
						isDisabled: true,
						placeholder: 'Дата рождения',
						isRequired: true,
						errorMessage: 'Выберите дату рождения',
						grid: 3,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'currentBeneficiary',
						placeholder: 'Выгодоприобретатель',
						isRequired: true,
						isDisabled: true,
						defaultValue: beneficiary || undefined,
						errorMessage: 'Введите выгодоприобретателя',
						grid: 3,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: 'creditTerms',
						isDisabled: true,
						defaultValue: credit_terms || undefined,
						placeholder: 'Срок кредитования, месяцы',
						isRequired: true,
						validationType: 'minMax',
						maxValue: 600,
						errorMessage: 'Введите срок',
						grid: 3,
					},
				},

				{
					isHidden: !agent,
					field: {
						fieldType: 'input',
						fieldName: 'partnerName',
						placeholder: 'Партнер',
						defaultValue: agent || undefined,
						isDisabled: true,
						grid: 3,
					},
				},
				{
					isHidden: !agent,
					field: {
						fieldType: 'input',
						fieldName: 'kvProc',
						defaultValue: discount_kv || undefined,
						isDisabled: true,
						placeholder: 'КВ %',
						digitsAfterDot: 2,
						grid: 3,
					},
				},
				{
					isHidden: !agent,
					field: {
						fieldType: 'input',
						fieldName: 'kvSum',
						isDisabled: true,
						placeholder: 'КВ руб',
						defaultValue: discount_kv_sum || undefined,
						digitsAfterDot: 2,
						grid: 3,
					},
				},
				{
					isHidden: !agent,
					field: {
						fieldType: 'input',
						fieldName: 'kvBank',
						isDisabled: true,
						defaultValue: bank_kv || undefined,
						placeholder: 'КВ банка',
						digitsAfterDot: 2,
						grid: 3,
					},
				},

				{
					isHidden: !riskLife,
					field: {
						fieldType: 'select',
						fieldName: 'tariffGroup',
						isDisabled: isFormBlocked,
						placeholder: 'Тарифная группа',
						errorMessage: 'Выберите тарифную группу',
						isLoading: loadingTariffGroup,
						items: selectTariffGroup,
						// defaultValue: getClearSelectValue(tariff_group),
						isRequired: true,
						marginBottom: 0,
						grid: 6,
					},
				},
				{
					isHidden: !riskLife,
					field: {
						fieldType: 'select',
						fieldName: 'program',
						isDisabled: isFormBlocked,
						placeholder: 'Программа',
						errorMessage: 'Выберите программу',
						isLoading: loadingPrograms,
						items: selectPrograms,
						// defaultValue: getClearSelectValue(program),
						isRequired: true,
						isSearchAvailable: true,
						isClearable: true,
						grid: 6,
					},
				},
			],
		}),
		[
			beneficiary,
			credit_terms,
			formHook,
			isFormBlocked,
			loadingPrograms,
			loadingTariffGroup,
			policyholder_birthday,
			policyholder_fio,
			riskLife,
			selectPrograms,
			selectTariffGroup,
		]
	);

	React.useEffect(() => {
		const tariffGroupSelect = getClearSelectValue(tariff_group);
		const programSelect = getClearSelectValue(program);
		if (!tariffGroupSelect || !programSelect) return;
		formHook.setValue('tariffGroup', tariffGroupSelect);
		formHook.setValue('program', programSelect);

	}, [tariff_group, program, formHook]);


	const value: UnderwritingContext = React.useMemo(
		() => ({
			agreementId,
			risksDropdownIsOpen: stateRisksDropdownIsOpen,
			switchRisksDropDown,
			headerButtons,
			informationItems,
			changeLogIsOpen: stateChangeLogModalIsOpen,
			isShowGoToAgreement: !!contractId,
			isLoadingGoToAgreement: loadingAgreement,
			isDisabledGoToAgreement: !rightsHandler('agreementsLogPage'),
			goToAgreementHandler,
			closeChangeLogHandler,
			isDisabledRecalc: isFormBlocked,
			riskForm,
			headerForm,
			isLoadingEvents: loadingChangeUnderwriting || loadingAssignAgreement,
			isLoadingAgreement: loadingAgreement,
			isDisabledChangeRisks: !rightsHandler('approveRisks') || riskForm.fields.filter(({ isHidden }) => !isHidden).length === 0 || isCanceled,
			totalSumm: `${stringToMoney(premium_sum)} RUB`,
			totalSummWithDiscount: `${stringToMoney(premium_sum_with_kv)} RUB`,
			discount: String(discount_kv || 0),
			discountDueKv: String(seller_discount_due_kv || 0),
			goBackHandler,
			riskFormItems,
			onFormSubmit,
			switchAccordion,
			accordionIsOpen: stateAccordionIsOpen,
			resultDocuments: resultDocuments || [],
			email: policyholder_email||"",
			isAssignedToMe,
			assignToMeHandler,
			fillingTypeText,
			status: status?.value,
			isAtUnderwriting,
			calculation,
		}),
		[
			agreementId,
			closeChangeLogHandler,
			goBackHandler,
			goToAgreementHandler,
			headerButtons,
			headerForm,
			informationItems,
			isCanceled,
			loadingAgreement,
			loadingChangeUnderwriting,
			loadingAssignAgreement,
			onFormSubmit,
			premium_sum,
			premium_sum_with_kv,
			discount_kv,
			seller_discount_due_kv,
			rightsHandler,
			riskForm,
			riskFormItems,
			stateAccordionIsOpen,
			stateChangeLogModalIsOpen,
			stateRisksDropdownIsOpen,
			switchAccordion,
			switchRisksDropDown,
			resultDocuments,
			policyholder_email,
			assignToMeHandler,
			fillingTypeText,
			isAtUnderwriting,
		]
	);

	return <UnderwritingContext.Provider value={value}>{children}</UnderwritingContext.Provider>;
};

export default React.memo(UnderwritingContextProvider);
