import styled, { css } from 'styled-components';
import { ANIMATION_SEC } from '../globalStyles';

export const StyledTextAreaWrapper = styled.div`
	position: relative;

	> textarea:focus + label,
	> textarea:not(:placeholder-shown) + label {
		top: 0;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.input.placeholder.withValue.color};
		font-weight: 300;
	}

	.iconAfter {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
	}
`;

export const StyledTextArea = styled.textarea<{ isInvalid: boolean }>`
	padding: 1.5rem;
	box-sizing: border-box;
	outline: none;
	min-height: 8rem;
	max-height: 20rem;
	resize: none;
	width: 100%;
	color: ${({ theme }) => theme.textArea.color};
	border: 1px solid ${({ theme }) => theme.textArea.border};
	background: ${({ theme }) => theme.textArea.bg};
	transition: all ${ANIMATION_SEC}s ease;
	font: inherit;
	font-size: 1.4rem;
	line-height: 2rem;

	:hover {
		border-color: ${({ theme }) => theme.textArea.hover.border};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.textArea.placeholder.hover.color};
		}
	}

	:focus {
		border-color: ${({ theme }) => theme.textArea.focus.border};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.textArea.placeholder.focus.color} !important;
		}
	}

	:disabled {
		color: ${({ theme }) => theme.textArea.disabled.color};
		cursor: no-drop;
		border-color: ${({ theme }) => theme.textArea.disabled.border};
		background: ${({ theme }) => theme.input.disabled.bg};

		// placeholder
		& + * {
			color: ${({ theme }) => theme.textArea.placeholder.disabled.color};
		}
	}

	${({ isInvalid }) =>
		isInvalid &&
		css`
			border-color: ${({ theme }) => theme.textArea.invalid.border};
			background: ${({ theme }) => theme.input.invalid.bg};

			// placeholder
			& + * {
				color: ${({ theme }) => theme.textArea.placeholder.invalid.color} !important;
			}
		`}
`;

export const StyledTextAreaLabel = styled.label<{ isRequired: boolean }>`
	position: absolute;
	white-space: nowrap;
	color: ${({ theme }) => theme.textArea.placeholder.color};
	font-size: 1.4rem;
	line-height: 2rem;
	pointer-events: none;
	transition: all ${ANIMATION_SEC}s ease;
	top: 1.5rem;
	left: 1.5rem;

	${({ isRequired }) =>
		isRequired &&
		css`
			::before {
				content: '* ';
				color: ${({ theme }) => theme.textArea.requiredColor};
			}
		`}
`;
