import React from 'react';
import UploadFilesUi from './ui';
import UploadFilesContextProvider from './ui/context';

const UploadFilesPage: React.FC = () => (
	<UploadFilesContextProvider>
		<UploadFilesUi />
	</UploadFilesContextProvider>
);

export default React.memo(UploadFilesPage);
