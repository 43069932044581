import React from 'react';
import { StyledEnumFieldWrapper, StyledEnumItem, StyledEnumItemsWrapper, StyledEnumsLoader, StyledSelectEmpty } from './styles';
import Checkbox from '../checkbox';
import FormMessage from '../formMessage';
import { IEnumItem, IEnumProps } from './types';
import Input from '../input';
import Loader from '../loader';
import { Heading4 } from '../globalStyles';
import { SearchIcon } from '../../../icons/ui/Search';

const Enums: React.FC<IEnumProps> = React.forwardRef((props, ref) => {
	const { items, value, defaultValue, emptyMessage, isRequired, errorMessage, height, isDisabled, isLoading, isInvalid, onChange, placeholder } = props;
	const [stateSearch, setSearch] = React.useState<string>('');
	const [stateEnums, setEnums] = React.useState<IEnumItem[]>(Array.isArray(defaultValue) ? defaultValue : []);

	React.useEffect(() => {
		if (value === undefined || !Array.isArray(value)) return;
		setEnums(value);
	}, [value]);

	const selectEnumHandler = React.useCallback(
		(currentItem: IEnumItem) => {
			if (currentItem.isDisabled || isDisabled) return;
			const newEnumsState: IEnumItem[] = stateEnums.find(item => currentItem.value === item.value)
				? stateEnums.filter(item => item.value !== currentItem.value)
				: [...stateEnums, currentItem];
			setEnums(newEnumsState);
			if (onChange) onChange(newEnumsState);
		},
		[stateEnums, onChange, isDisabled]
	);

	const searchHandler = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => setSearch(ev.target.value), []);

	const filterItems = (items || []).filter(({ label }) => (label || '').toLocaleLowerCase().trim().includes(stateSearch.toLocaleLowerCase().trim()));

	return (
		<>
			<StyledEnumFieldWrapper>
				<Input
					debounceMs={300}
					placeholder={placeholder}
					isRequired={!!isRequired}
					isDisabled={!!isDisabled}
					isInvalid={isInvalid}
					onChange={searchHandler}
					iconAfter={<SearchIcon />}
				/>
				<StyledEnumItemsWrapper isInvalid={!!isInvalid} height={height}>
					{isLoading && (
						<StyledEnumsLoader>
							<Loader />
						</StyledEnumsLoader>
					)}
					{!isLoading && (
						<>
							{!filterItems.length && (
								<StyledSelectEmpty>
									<Heading4>{emptyMessage || 'Нет элементов'}</Heading4>
								</StyledSelectEmpty>
							)}
							{!!filterItems.length &&
								filterItems.map(item => (
									<StyledEnumItem key={`enum-item-${item.value}`} onClick={(): void => selectEnumHandler(item)}>
										<Checkbox
											label={item.label}
											isDisabled={!!item.isDisabled}
											onChange={(): void => selectEnumHandler(item)}
											checked={!!stateEnums.find(stateEnum => item.value === stateEnum?.value)}
										/>
									</StyledEnumItem>
								))}
						</>
					)}
				</StyledEnumItemsWrapper>
			</StyledEnumFieldWrapper>
			{errorMessage && <FormMessage message={errorMessage} type='error' />}
		</>
	);
});

export default React.memo(Enums);
