import styled from 'styled-components';

export const StyledFormField = styled.div<{ marginBottom?: number; grid?: number }>`
	margin-left: 2rem;
	margin-right: 2rem;

	> p {
		opacity: 0.5;
		margin-bottom: 1.5rem;
	}
	width: calc(${({ grid }) => ((grid || 12) * 100) / 12}% - 4rem);
	margin-bottom: ${({ marginBottom }) => (marginBottom === undefined ? 3 : marginBottom)}rem;

	@media screen and (max-width: 767px) {
		width: 100%;
		margin-bottom: 2rem;
	}
`;

export const FieldColumnWrapper = styled.div<{ grid?: number }>`
	display: inline-block;
	width: ${({ grid }) => ((grid || 12) * 100) / 12}%;

	@media screen and (max-width: 767px) {
		display: unset;
		width: 100%;
	}
`;

export const StyledFormWrapper = styled.div`
	width: calc(100% + 4rem);
	margin: 0 -2rem 0 -2rem;
`;
