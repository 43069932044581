import React from 'react';
import { useController } from 'react-hook-form';
import { ISelectFieldProps } from '../types';
import Select from '../../select';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import { ISelectItem } from '../../select/types';
import getFieldError from '../utils/getFieldError';

const SelectField: React.FC<ISelectFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			hotReload,
			onChange,
			onBlur,
			// for validation
			isRequired,
			errorMessage,
			// other
			...rest
		},
		formHook: { control, setValue },
	} = props;

	const {
		field,
		formState: { errors },
	} = useController({
		name: fieldName,
		defaultValue,
		control,
		rules: {
			required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
		},
	});
	const message: string | undefined = getFieldError(fieldName, errors);

	const registrationWithUserProps = React.useMemo(
		() => ({
			...field,
			onChange: (fieldValue: ISelectItem | null): void => {
				if (onChange) onChange(fieldValue);
				field.onChange(fieldValue);
			},
			onBlur: (ev: React.FocusEvent<HTMLInputElement>): void => {
				if (onBlur) onBlur(ev);
				// field.onBlur();
			},
		}),
		[onBlur, onChange, field]
	);

	React.useEffect(() => {
		if (!hotReload || defaultValue === undefined) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, setValue, hotReload]);

	return <Select {...rest} {...registrationWithUserProps} isInvalid={!!message} isRequired={isRequired} errorMessage={message} />;
};

export default React.memo(SelectField);
