import React from 'react';
import GroupTabUi from './ui';
import { GroupTabContextProvider } from './ui/context';

const GroupTabIgniter: React.FC = () => (
	<GroupTabContextProvider>
		<GroupTabUi />
	</GroupTabContextProvider>
);

export default React.memo(GroupTabIgniter);
