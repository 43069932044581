import styled from 'styled-components';

export const StyledButtonsGroup = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
	}
`;
