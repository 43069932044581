import React from 'react';
import {useForm} from "react-hook-form";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
	AddAgreementFilesMutation, AddAgreementFilesMutationVariables, AgreementFileContextEnum,
	LoadFilesQuery,
	LoadFilesQueryVariables, PrintMutation, PrintMutationVariables,
	ResultDocument, useDownloadAllMutation
} from "../../../libs/api/graphqlTypes";
import useNotification from "../notifier/ui/hooks/useNitification";


import {LOAD_FILES} from "../../../libs/api/queries";
import {ADD_AGREEMENT_FILE, PRINT_MUTATION} from "../../../libs/api/commands";
import {fileDownloader} from "../../../libs/utils/fileDownloader";
import {IForm} from "../../../libs/uiKit/fieldsBuilder/types";
import Accordion from "../../../libs/uiKit/accordion";
import {
	StyledButtonsWrapper,
	StyledDocuments, StyledDownloadAllButton,
	StyledDownloadFileItem,
	StyledFileName,
	StyledUploadFileInToggle
} from "./ui/styles";
import {DocumentsHint} from "../../pages/createAgreement/pages/uploadFiles/ui";
import {FieldsBuilder} from "../../../libs/uiKit/fieldsBuilder";
import Button from "../../../libs/uiKit/button";
import {SaveFileIcon} from "../../../icons/ui/SaveFile";
import {FileIcon} from "../../../icons/ui/File";
import {Text4} from "../../../libs/uiKit/globalStyles";
import {DownloadIcon} from "../../../icons/ui/Download";
import {SendIcon} from "../../../icons/ui/Send";
import SendPrintModal from "../../pages/invoice/widgets/SendPrintModal";

export type DocumentsProps = {
	id: number | null;
	isShowHint?: boolean;
	isOpenDefault?: boolean;
	resultDocuments: ResultDocument[];
	email?: string;
};

type Document = {
	id: string;
	name: string;
	url: string;
	added?: string;
};

type DocumentsForm = {
	files: any;
};

const DocumentsWidget: React.FC<DocumentsProps> = props => {
	const { children, id, isShowHint, isOpenDefault, resultDocuments, email } = props;
	const { setNotification } = useNotification();
	const formHook = useForm<DocumentsForm>({
		mode: 'onBlur',
	});

	const [stateIsOpen, setIsOpen] = React.useState<boolean>(isOpenDefault || false);

	const [getDocuments, { data: dataDocuments, loading: loadingDocuments }] = useLazyQuery<LoadFilesQuery, LoadFilesQueryVariables>(LOAD_FILES, {
		onError: error => setNotification({ type: 'error', text: error }),
	});

	const [saveFiles, { loading: loadingSaveFiles }] = useMutation<AddAgreementFilesMutation, AddAgreementFilesMutationVariables>(ADD_AGREEMENT_FILE, {
		refetchQueries: ['loadFiles'],
	});

	React.useEffect(() => {
		if (!stateIsOpen || !id) return;
		getDocuments({
			variables: {
				id,
			},
		});
	}, [getDocuments, id, stateIsOpen]);

	const { riskProperty, riskTitle } = dataDocuments?.loadAgreement?.calcForm || {};

	const documents: Document[] = (dataDocuments?.loadAgreement?.files || []).map(document => ({
		id: document?.id || '',
		name: (document?.filename || '').length > 35 ? `${(document?.filename || '').substring(0, 35)}...` : document?.filename || '',
		url: document?.url || '',
		added: '',
	}));

	const [downloadAll, {loading: loadingDownloadAll }] = useDownloadAllMutation({
		fetchPolicy: 'network-only'
	})

	const downloadAllHandler = () => {
			downloadAll({
				variables: {
					id,
				},
			}).then(({ data }) => {
				if (!data?.downloadAll) return setNotification({ type: 'error', text: 'no doc' });
				fileDownloader(data.downloadAll.url, data.downloadAll.filename);
			}).catch(error => setNotification({ type: 'error', text: error }))
	}

	const downloadFileHandler = React.useCallback((file: Document) => fileDownloader(file.url, file.name), []);
	const switchIsOpenAccordion = React.useCallback((isOpen: boolean) => setIsOpen(isOpen), []);

	const onFormSubmit = formHook.handleSubmit(data => {
		if (!id) return setNotification({ type: 'error', text: 'no agreement id' });
		saveFiles({
			variables: {
				agreementId: id,
				files: data.files,
				context: AgreementFileContextEnum.Agreement,
			},
		})
			.then(() => {
				formHook.setValue('files', []);
				setNotification({ type: 'success', text: 'Файлы успешно добавлены' });
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'upload',
						fieldName: 'files',
						isRequired: true,
						errorMessage: 'Прикрепите файлы',
						placeholder: 'Прикрепите сюда файлы',
					},
				},
			],
		}),
		[formHook]
	);

	const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
		fetchPolicy: 'network-only',
	});

	const [stateSendPrintModalData, setSendPrintModalData] = React.useState<any>(null);

	const downloadResultDocumentHandler = (resultDocument: ResultDocument) => {
		downloadDoc({
			variables: {
				isn: resultDocument.isn,
				type: resultDocument.type,
				templateIsn: resultDocument.templateIsn
			},
		}).then(({ data }) => {
			if (!data?.print) return setNotification({ type: 'error', text: 'no doc' });
			fileDownloader(data.print.url, data.print.filename);
		}).catch(error => setNotification({ type: 'error', text: error }))
	}

	return (
		<>
			<Accordion header='Прикрепленные документы' isOpen={stateIsOpen} onChange={switchIsOpenAccordion}>
				<StyledUploadFileInToggle>
					{isShowHint && <DocumentsHint riskProperty={!!riskProperty} riskTitle={!!riskTitle} />}
					<form onSubmit={onFormSubmit}>
						<FieldsBuilder {...form} />
						<Button iconBefore={<SaveFileIcon />} appearance='link' type='submit' isLoading={loadingDocuments || loadingSaveFiles}>
							Сохранить прикрепленные
						</Button>
					</form>
				</StyledUploadFileInToggle>
				<StyledDocuments>
					{documents.length !== 0 &&
						documents.map(file => {
							const { name, added, id } = file;
							return (
								<StyledDownloadFileItem key={id}>
									<StyledFileName>
										<FileIcon />
										<Text4>{name?.length > 35 ? `${name.substr(0, 35)}...` : name}</Text4>
										{!!added && <Text4>{added}</Text4>}
									</StyledFileName>
									<StyledButtonsWrapper>
										<Button appearance='link' isLoading={loadingDocuments} onClick={() => downloadFileHandler(file)} iconBefore={<DownloadIcon />}>
											Скачать
										</Button>
									</StyledButtonsWrapper>
								</StyledDownloadFileItem>
							);
						})}

					{documents.length !== 0 && (
						<StyledDownloadAllButton>
							<Button appearance='link' isLoading={loadingDownloadAll} onClick={downloadAllHandler} iconBefore={<DownloadIcon />}>
								Скачать все
							</Button>
						</StyledDownloadAllButton>
					)}

					<div style={{borderTop: "solid 1px #cccccc", height: "2rem"}}/>

					{resultDocuments.length !== 0 &&
						resultDocuments.map(resultDocument => {
							const { name, templateIsn } = resultDocument;
							return (
								<StyledDownloadFileItem key={name}>
									<StyledFileName>
										<FileIcon />
										<Text4>{name?.length > 35 ? `${name.substr(0, 35)}...` : name}</Text4>
									</StyledFileName>
									<StyledButtonsWrapper>
										<Button appearance='link' isLoading={loadingDownloadDoc} onClick={() => downloadResultDocumentHandler(resultDocument)} iconBefore={<DownloadIcon />}>
											Скачать
										</Button>
										{templateIsn === 13902 &&
											<Button appearance='link' isLoading={loadingDownloadDoc} onClick={() => setSendPrintModalData(resultDocument)} iconBefore={<SendIcon />}>
												Отправить
											</Button>
										}

									</StyledButtonsWrapper>
								</StyledDownloadFileItem>
							);
						})}
				</StyledDocuments>

			</Accordion>
			<SendPrintModal onClose={() => setSendPrintModalData(false)} isOpen={!!stateSendPrintModalData}
					data={{
						document: stateSendPrintModalData,
						email,
					}}
			/>
		</>
	);
};

export default DocumentsWidget;
