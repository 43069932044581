import styled from 'styled-components';
import { black40 } from '../globalStyles';

export const StyledRangePicker = styled.div`
	position: relative;

	.nivaInput {
		border-bottom: unset;
	}

	> input {
		position: absolute;
	}
`;

export const RangePickerFooter = styled.div`
	position: absolute;
	width: 100%;
	top: calc(100% + 0.5rem);
	display: flex;
	justify-content: space-between;
	color: ${black40};
`;
