import {IModalProps} from "../../../../../../libs/uiKit/modal/types";
import FormModal from "../../../../../widgets/FormModal";
import {Modal} from "../../../../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../../../../libs/uiKit/fieldsBuilder";
import {useForm} from "react-hook-form";
import {useLazyQuery} from "@apollo/client";
import {USERS} from "../../../../../../libs/api/queries";
import {useParams} from "react-router-dom";
import React from "react";
import {Heading3, TextButton} from "../../../../../../libs/uiKit/globalStyledComponents";

const RoleUsersPopup = (props: IModalProps) => {

    const {id} = useParams();

    const [getUsers, {data: dataUsers, loading: loadingUsers, error: errorUsers}] = useLazyQuery(USERS, {variables: {roleId: parseInt(id || "", 10)}});

    const formHook = useForm({mode: "onBlur"});

    const [search] = formHook.watch(["search"]);

    React.useEffect(() => {
        if (!props.isOpen) return;
        getUsers();
    }, [props.isOpen])

    const upcaseSearch = search?.toUpperCase();

    // @ts-ignore
    const filteredUsers = search ? (dataUsers?.users||[]).filter(({full_name, username}) => full_name.toUpperCase().includes(upcaseSearch) || username.includes(search)) : dataUsers?.users||[];

    // @ts-ignore
    const userLines = filteredUsers.length ? filteredUsers.map(({id, full_name, username}) => <TextButton key={id}>{`${full_name} (${username})`}</TextButton>) : [];

    return (
        <Modal {...props} header="Пользователи c данной ролью" isLoading={loadingUsers}>
            <FieldsBuilder fields={[
                {
                    field: {
                        fieldName: "search",
                        fieldType: "input",
                        placeholder: "Фильтр",
                    }
                }
            ]} formHook={formHook} />
            {userLines.length === 0 && <Heading3>Нет пользователей</Heading3>}
            {userLines.length > 0 && userLines}
        </Modal>
    )
}

export default RoleUsersPopup;