import React from 'react';
import { ICheckboxFieldProps } from '../types';
import Checkbox from '../../checkbox';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import getFieldError from '../utils/getFieldError';

const CheckboxField: React.FC<ICheckboxFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultChecked,
			hotReload,
			checked,
			onChange,
			onBlur,
			// for validation
			isRequired,
			errorMessage,
			// other
			...rest
		},
		formHook: {
			register,
			setValue,
			formState: { errors },
		},
	} = props;

	const message: string | undefined = getFieldError(fieldName, errors);

	const fieldRegistration = React.useMemo(
		() =>
			register(fieldName, {
				required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
			}),
		[errorMessage, isRequired, fieldName, register]
	);

	const registrationWithUserProps = React.useMemo(
		() => ({
			...fieldRegistration,
			onChange: async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
				if (onChange) onChange(ev);
				await fieldRegistration.onChange(ev);
			},
			onBlur: async (ev: React.FocusEvent<HTMLInputElement>): Promise<void> => {
				if (onBlur) onBlur(ev);
				await fieldRegistration.onBlur(ev);
			},
		}),
		[fieldRegistration, onChange, onBlur]
	);

	React.useEffect(() => {
		if (!hotReload || defaultChecked === undefined) return;
		setValue(fieldName, defaultChecked);
	}, [fieldName, defaultChecked, setValue, hotReload]);

	return (
		<Checkbox
			{...rest}
			{...registrationWithUserProps}
			checked={checked}
			defaultChecked={defaultChecked}
			isInvalid={!!message}
			isRequired={isRequired}
			errorMessage={message}
		/>
	);
};

export default React.memo(CheckboxField);
