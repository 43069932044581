import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { JSEncrypt } from 'jsencrypt';
import { useNavigate } from 'react-router-dom';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { AUTH } from '../../../../../../../libs/api/commands';
import { SignInMutation, SignInMutationVariables } from '../../../../../../../libs/api/graphqlTypes';
import { rightsHandler } from '../../../../../common/hooks/useUserRights';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY || 'unknown';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

type FormType = {
	username: string;
	password: string;
};

type LoginDefaultContext = {
	form: IForm;
	loginHandler: () => void;
	isLoadingLogin: boolean;
};

const LoginDefaultContext = createContext<LoginDefaultContext>({} as LoginDefaultContext);

export const useLoginDefaultContext = (): LoginDefaultContext => useContext(LoginDefaultContext);

export const LoginDefaultContextProvider: React.FC = ({ children }) => {
	const {
		routes: { dashboard, permissionsManage, agreementsLog, contractsLog },
	} = useGlobalContext();
	const navigate = useNavigate();
	const { setNotification } = useNotification();
	const formHook = useForm<FormType>({ mode: 'onBlur' });

	const [login, { loading: loadingLogin }] = useMutation<SignInMutation, SignInMutationVariables>(AUTH, { refetchQueries: ['me'] });

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{ field: { fieldType: 'input', fieldName: 'username', placeholder: 'Логин', isRequired: true, errorMessage: 'Введите логин' } },
				{
					field: {
						fieldType: 'input',
						fieldName: 'password',
						type: 'password',
						placeholder: 'Пароль',
						isRequired: true,
						errorMessage: 'Введите пароль',
						validationType: 'password',
					},
				},
			],
		}),
		[formHook]
	);

	const getAllowedPathname = (permissions: string[]): string | undefined => {
		if (rightsHandler('statisticsPage', permissions)) return dashboard();
		if (rightsHandler('applicationsLogPage', permissions)) return agreementsLog();
		if (rightsHandler('agreementsLogPage', permissions)) return contractsLog();
		if (rightsHandler('manageUsers', permissions)) return permissionsManage();
		return undefined;
	};

	const loginHandler = formHook.handleSubmit(data => {
		if (!data.username || !data.password) return setNotification({ type: 'error', text: 'no login or password' });
		const encryptedPassword: string | boolean = encrypt.encrypt(data.password);
		if (typeof encryptedPassword === 'boolean') return setNotification({ type: 'error', text: 'cant encrypt password' });

		login({
			variables: { username: data.username, password: encryptedPassword, isClient: false },
		})
			.then(dataSignIn => {
				if (!dataSignIn.data?.signIn?.id) return setNotification({ type: 'error', text: 'no user id' });
				if ((dataSignIn.data?.signIn?.permissions || []).length === 0) return setNotification({ type: 'loading', text: 'Ожидайте подтверждения' });
				const savedPath = localStorage.getItem('beforeAuthPage');
				if(savedPath){
					console.log('Navigate to saved path', savedPath);
					localStorage.setItem('beforeAuthPage', '');
					return navigate(savedPath);
				}
				const path = getAllowedPathname(dataSignIn.data?.signIn?.permissions || []);
				if (path) {
					return navigate(path);
				}
				return setNotification({ type: 'error', text: 'Нет доступных разделов для вашей роли' });
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const value: LoginDefaultContext = React.useMemo(
		() => ({
			form,
			loginHandler,
			isLoadingLogin: loadingLogin,
		}),
		[form, loginHandler, loadingLogin]
	);

	return <LoginDefaultContext.Provider value={value}>{children}</LoginDefaultContext.Provider>;
};
