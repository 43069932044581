import React from 'react';
import LeftMenuUi from './ui';
import { LeftMenuContextProvider } from './ui/context';

const LeftMenuIgniter: React.FC = () => (
	<LeftMenuContextProvider>
		<LeftMenuUi />
	</LeftMenuContextProvider>
);

export default React.memo(LeftMenuIgniter);
