import React, { ChangeEvent, useCallback } from 'react';
import { StyledFileItem, StyledFilesWrapper, StyledPlaceholder, StyledUploadFileArea, StyledUploadFileInput, StyledUploadFileWrapper } from './styles';
import { IUploadProps } from './types';
import { Text4 } from '../globalStyles';
import Button from '../button';
import { TrashIcon } from '../icons/Trash';
import FormMessage from '../formMessage';
import Loader from '../loader';
import { uuidv4 } from '../utils';

const UploadFile: React.FC<IUploadProps> = React.forwardRef((props, ref) => {
	const { value, defaultValue, onChange, isLoading, isDisabled, errorMessage, placeholder, helpMessage, isInvalid, isRequired } = props;
	const [stateFiles, setFiles] = React.useState<any[]>(defaultValue || []);

	const inputRef: { current: HTMLInputElement | null } = React.useRef(null);

	const isShowPlaceholder: boolean = !!placeholder && !isLoading;

	React.useEffect(() => {
		if (!Array.isArray(value)) return;
		setFiles(value);
	}, [value]);

	const onChangeHandler = useCallback(
		(currentValue: any[]) => {
			if (onChange) onChange(currentValue);
			if (Array.isArray(value)) return;
			setFiles(currentValue);
		},
		[onChange, value]
	);

	const addFileHandler = useCallback(
		({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
			const stateFileNames: string[] = stateFiles.map(({ name }) => name);
			const filterFiles = Object.entries(files as FileList).reduce<any[]>((prev, [, file]) => {
				const { name } = file;
				if (stateFileNames.includes(name)) return prev;
				return [...prev, file];
			}, []);
			onChangeHandler([...stateFiles, ...filterFiles]);
			if (!inputRef.current) return;
			inputRef.current.value = '';
		},
		[onChangeHandler, stateFiles]
	);

	const deleteFileHandler = useCallback((deleteName: string): void => onChangeHandler(stateFiles.filter(({ name }) => name !== deleteName)), [onChangeHandler, stateFiles]);

	return (
		<StyledUploadFileWrapper>
			<StyledUploadFileArea isInvalid={!!isInvalid} isDisabled={!!isDisabled || !!isLoading}>
				{isShowPlaceholder && <StyledPlaceholder isRequired={isRequired}>{placeholder}</StyledPlaceholder>}
				{isLoading && <Loader />}
				{!isLoading && (
					<StyledFilesWrapper>
						{stateFiles.map(({ name }) => (
							<StyledFileItem key={`file-${name}-${uuidv4()}`}>
								<Text4>{name.length > 40 ? `${name.substring(0, 40)}...` : name}</Text4>
								<Button appearance='icon' icon={<TrashIcon />} onClick={(): void => deleteFileHandler(name)} />
							</StyledFileItem>
						))}
					</StyledFilesWrapper>
				)}
				<StyledUploadFileInput ref={inputRef} disabled={!!isDisabled || !!isLoading} type='file' multiple onChange={addFileHandler} />
			</StyledUploadFileArea>
			{errorMessage && <FormMessage message={errorMessage} type='error' />}
			{helpMessage && !errorMessage && <FormMessage message={helpMessage} type='helper' />}
		</StyledUploadFileWrapper>
	);
});

export default React.memo(UploadFile);
