import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import MainLayoutIgniter from "../common/mainLayout";
import {Heading2, Heading3, Text4} from "../../../libs/uiKit/globalStyledComponents";
import {
  StyledButtonsGroupAutoSize,
  StyledContractHeader, StyledContractRegistrationForm,
  StyledContractWrapper,
  StyledEventsButtons, StyledHeaderInformation, StyledHeaderWrapper, StyledInformationItem,
  StyledSignetAgreementButtons, StyledTotalInformation, StyledTotalInformationItem
} from "../contract/ui/styles";
import Button from "../../../libs/uiKit/button";
import {DownloadIcon} from "../../../icons/ui/Download";
import FlyButtons from "../../../libs/uiKit/flyButtons";
import DropdownMenu from "../../../libs/uiKit/dropdownMenu";
import {SelectArrowIcon} from "../../../libs/uiKit/icons/SelectArrow";
import OnlineFillingIgniter from "../createAgreement/widgets/anketaFilling/widgets/onlineFilling";
import {ContractForm} from "../contract";
import AnketaEdit from "./AnketaEdit";
import {LoadContractQuery, LoadContractQueryVariables} from "../../../libs/api/graphqlTypes";
import {LOAD_CONTRACT} from "../../../libs/api/queries";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {ArrowIcon} from "../../../icons/ui/Arrow";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";

const ContractAnketaPage = () => {

  const formHook = useForm({ mode: 'onBlur' });

  const { id } = useParams<{ id?: string }>();

  const {
    routes: { contractById },
  } = useGlobalContext();

  const { setNotification } = useNotification();

  const navigate = useNavigate();

  const { data: dataContract, loading: loadingContract } = useQuery<LoadContractQuery, LoadContractQueryVariables>(LOAD_CONTRACT, {
    variables: { id: parseInt(id || "", 10) },
    onError: error => setNotification({ text: error, type: 'error' })
  });

  const { agreement } = dataContract?.loadAgreement || {};

  return (
      <MainLayoutIgniter>
        <Button onClick={() => { navigate(contractById(id || "")); }} appearance='text' iconBefore={<ArrowIcon direction='left' />}>
          Назад к договору
        </Button>
        <Heading2>Анкета: {agreement?.policyholder?.fio}</Heading2>
          <StyledContractWrapper style={{paddingTop: "1rem"}}>
            <StyledContractRegistrationForm>
              <FormProvider {...formHook}>
                <AnketaEdit
                  agreementId={parseInt(id || "", 10)}
                  phone={agreement?.policyholder?.phone || ""}
                />
              </FormProvider>
            </StyledContractRegistrationForm>
        </StyledContractWrapper>
      </MainLayoutIgniter>
  );
}

export default ContractAnketaPage;