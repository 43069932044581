import React from 'react';
import { useHeaderContext } from './context';
import { StyledHeader, StyledHeaderActions, StyledHeaderNavigation, StyledMobileLogo, StyledNavigationItems, StyledServiceName, StyledUserName } from './styles';
import { BurgerIcon } from '../../../../../../../icons/ui/Burger';
import Button from '../../../../../../../libs/uiKit/button';
import { LogoMobile } from '../../../../../../../icons/logo/LogoMobile';
import { TextButton } from '../../../../../../../libs/uiKit/globalStyledComponents';

const HeaderUi: React.FC = () => {
	const { switchLeftMenuHandler, username, links } = useHeaderContext();

	return (
		<>
			<StyledHeader>
				<StyledHeaderActions>
					<Button appearance='flag' onClick={switchLeftMenuHandler} icon={<BurgerIcon />} />
					<StyledMobileLogo>
						<LogoMobile />
					</StyledMobileLogo>

					<StyledServiceName>
						<TextButton>Ипотека</TextButton>
					</StyledServiceName>
				</StyledHeaderActions>

				<StyledHeaderNavigation>
					<StyledUserName>
						<TextButton>{username}</TextButton>
					</StyledUserName>
					<StyledNavigationItems>
						{links.map(({ icon, clickHandler, isLoading, isHidden }, index) => {
							if (isHidden) return null;
							return <Button appearance='flag' isLoading={isLoading} key={`header-link-${index}`} onClick={clickHandler} icon={icon} />;
						})}
					</StyledNavigationItems>
				</StyledHeaderNavigation>
			</StyledHeader>
		</>
	);
};

export default React.memo(HeaderUi);
