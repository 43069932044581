import React from 'react';
import SendFillingUi from './ui';
import SendFillingContextProvider, { SendFillingContextProps } from './ui/context';

const SendFillingIgniter: React.FC<SendFillingContextProps> = props => (
	<SendFillingContextProvider {...props}>
		<SendFillingUi />
	</SendFillingContextProvider>
);

export default React.memo(SendFillingIgniter);
