import styled, { css } from 'styled-components';
import { grey20 } from '../globalStyledComponents';

export const PaginationStyled = styled.div`
	display: flex;
	padding: 1.5rem;
	justify-content: center;
	.flagButton {
		width: 3.5rem;
		height: 3.5rem;
	}
`;

export const Ellipsis = styled.span`
	width: 3.5rem;
	height: 3.5rem;
	padding: 1px 0.6rem;
	box-sizing: border-box;
	justify-content: center;
	display: flex;
	align-items: center;
	cursor: default;
	:hover {
		background: none;
	}
`;

export const StyledPaginationButton = styled.button<{ isCurrent?: boolean }>`
	width: 3.5rem;
	height: 3.5rem;
	padding: 1px 0.6rem;
	border: none;
	background: none;
	cursor: pointer;

	color: ${({ theme }) => theme.pagination.btn.color};

	:hover {
		background: ${grey20};
	}

	${({ isCurrent }) =>
		isCurrent &&
		css`
			background: ${({ theme }) => theme.pagination.btn.current.bg};
			color: ${({ theme }) => theme.pagination.btn.current.color};

			:hover {
				background: ${({ theme }) => theme.pagination.btn.current.hover.bg};
			}
		`}
`;

export const StyledListWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2.2rem;
`;
