import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { HeadType, RowType } from '../../../../../libs/uiKit/table/types';
import { CalculationRisk, LoadAgreementUnderwritingQuery, LoadAgreementUnderwritingQueryVariables } from '../../../../../libs/api/graphqlTypes';
import Status from '../../../../../libs/uiKit/status';
import { stringToMoney, stringToNumberDigits } from '../../../../../libs/uiKit/utils';
import { LOAD_AGREEMENT } from '../../../../../libs/api/queries';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { Heading5 } from '../../../../../libs/uiKit/globalStyles';

export type AgreementTableContextProps = {
	id: number | null;
	children?: React.ReactNode;
};

type AgreementTableContext = {
	head: HeadType;
	rows: RowType[];
	isLoading: boolean;
};

const AgreementTableContext = createContext<AgreementTableContext>({} as AgreementTableContext);

const TABLE_HEAD: HeadType = {
	cells: [
		{
			key: 'risk',
			content: 'Риск',
		},
		{
			key: 'status',
			content: 'Статус',
		},
		{
			key: 'summ',
			content: 'Страховая сумма RUB',
		},
		{
			key: 'tariffBase',
			content: 'Базовый тариф',
		},
		{
			key: 'premiumSummBase',
			content: 'Базовая премия RUB',
		},
		{
			key: 'allowance',
			content: 'Надбавка',
		},
		{
			key: 'discount',
			content: 'Скидка %',
		},
		{
			key: 'totalTariff',
			content: 'Общий тариф',
		},
		{
			key: 'totalPremium',
			content: 'Итоговая премия RUB',
		},
	],
};

const rowBuilder = (risk: CalculationRisk | undefined | null, isLife?: boolean): RowType => {
	const { isn, type, status, base_tariff, base_premium_sum, premium_sum, limit_sum, tariff, discount, high_tariff_press, high_tariff_height, raising_factor } = risk || {};

	return {
		key: `row-${isn}`,
		cells: [
			{ key: '1', content: type },
			{ key: '2', content: <Status code={status} /> },
			{ key: '3', content: stringToMoney(limit_sum) },
			{ key: '4', content: stringToNumberDigits(base_tariff, 4) },
			{ key: '5', content: stringToMoney(base_premium_sum) },
			{
				key: '6',
				content: isLife ? stringToNumberDigits((high_tariff_press || 0) + (high_tariff_height || 0) + (raising_factor || 0), 2) : stringToNumberDigits(raising_factor, 2),
			}, // надбавка
			{ key: '7', content: stringToNumberDigits(discount, 2) }, // скидка
			{ key: '8', content: stringToNumberDigits(tariff, 4) }, // общий тариф
			{ key: '9', content: stringToMoney(premium_sum) }, // итоговая премия
		],
	};
};

export const useAgreementTableContext = (): AgreementTableContext => useContext(AgreementTableContext);

export const AgreementTableContextProvider: React.FC<AgreementTableContextProps> = props => {
	const { children, id } = props;
	const {
		routes: { error500 },
	} = useGlobalContext();

	const { data: dataAgreement, loading: loadingAgreement } = useQuery<LoadAgreementUnderwritingQuery, LoadAgreementUnderwritingQueryVariables>(LOAD_AGREEMENT, {
		variables: { id },
		onError: error => error500(error),
	});

	const { calculation } = dataAgreement?.loadAgreement || {};
	const { life_risks, property_risks, titul_risks } = calculation || {};

	const risksLife: CalculationRisk[] = (life_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksProperty: CalculationRisk[] = (property_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksTitle: CalculationRisk[] = (titul_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);

	const rowsCategories: { id: string; title: string; risks: CalculationRisk[] }[] = [
		{ id: 'underwritingRiskLife', risks: risksLife, title: 'Жизнь' },
		{ id: 'underwritingRiskProperty', risks: risksProperty, title: 'Имущество' },
		{ id: 'underwritingRiskTitul', risks: risksTitle, title: 'Титул' },
	];

	const rows: RowType[] = rowsCategories.reduce<RowType[]>((prev, { id: rowId, title, risks }) => {
		if (!risks || risks.length === 0) return prev;
		return [
			...prev,
			{
				key: rowId,
				width: 10,
				cells: [
					{
						key: `heading-${rowId}`,
						content: <Heading5>{title}</Heading5>,
					},
					...Array.from({ length: TABLE_HEAD.cells.length - 1 }).map((value, index) => ({
						key: `empty-${id}-${index}`,
						content: '',
					})),
				],
			},
			...risks.map(risk => rowBuilder(risk)),
		];
	}, []);

	const value: AgreementTableContext = React.useMemo(
		() => ({
			isLoading: loadingAgreement,
			head: TABLE_HEAD,
			rows,
		}),
		[loadingAgreement, rows]
	);

	return <AgreementTableContext.Provider value={value}>{children}</AgreementTableContext.Provider>;
};
