import { useState, useMemo } from 'react';
import { RowType, SortTypes } from '../types';

interface ConfigProps {
	sortType: SortTypes;
	key: number;
	direction: 'ascending' | 'descending';
}

interface UseSortableDataProps {
	items?: RowType[];
	config?: ConfigProps | null;
}

export const useSortableData = ({
	items,
	config = null,
}: UseSortableDataProps):
	| { requestSort: undefined; sortedRows: undefined; sortConfig: undefined }
	| { requestSort: (sortType: SortTypes, key: number) => void; sortedRows: RowType[]; sortConfig: ConfigProps | null } => {
	const [sortConfig, setSortConfig] = useState(config);

	const sortedRows = useMemo(() => {
		if (!items) return items;

		const sortableItems = [...items];

		if (sortConfig !== null) {
			switch (sortConfig?.sortType) {
				case 'number':
					sortableItems.sort((prev, next) => {
						if (!prev || !next) return 0;
						if (Number(prev.cells[sortConfig.key].pureValue) < Number(next.cells[sortConfig.key].pureValue)) {
							return sortConfig.direction === 'ascending' ? -1 : 1;
						}
						if (Number(prev.cells[sortConfig.key].pureValue) > Number(next.cells[sortConfig.key].pureValue)) {
							return sortConfig.direction === 'ascending' ? 1 : -1;
						}
						return 0;
					});
					break;

				case 'alphabet':
					sortableItems.sort((prev, next) => {
						if (!prev || !next) return 0;
						if (String(prev.cells[sortConfig.key].pureValue) < String(next.cells[sortConfig.key].pureValue)) {
							return sortConfig.direction === 'ascending' ? -1 : 1;
						}
						if (String(prev.cells[sortConfig.key].pureValue) > String(next.cells[sortConfig.key].pureValue)) {
							return sortConfig.direction === 'ascending' ? 1 : -1;
						}
						return 0;
					});
					break;

				case 'date':
					sortableItems.sort((prev, next) => {
						if (!prev || !next) return 0;
						if (new Date(String(prev.cells[sortConfig.key].pureValue)) < new Date(String(next.cells[sortConfig.key].pureValue))) {
							return sortConfig.direction === 'ascending' ? -1 : 1;
						}
						if (new Date(String(prev.cells[sortConfig.key].pureValue)) > new Date(String(next.cells[sortConfig.key].pureValue))) {
							return sortConfig.direction === 'ascending' ? 1 : -1;
						}
						return 0;
					});
					break;

				default:
					return;
			}
		}
		return sortableItems;
	}, [items, sortConfig]);

	if (!sortedRows) return { sortedRows, requestSort: undefined, sortConfig: undefined };

	const requestSort = (sortType: SortTypes, key: number): void => {
		let direction: 'ascending' | 'descending' = 'ascending';
		if (sortConfig && sortConfig.sortType === sortType && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ sortType, direction, key });
	};

	return { sortedRows, requestSort, sortConfig };
};
