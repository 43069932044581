import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { ContragentField } from '../../../contragent/ui/context';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { OnlineFillingForm } from '../../widgets/onlineFilling/ui/context';
import { AgreementId } from '../../../../ui/context';
import { SendFillingForm } from '../../widgets/sendFilling/ui/context';
import { getSelectDefaultValue } from '../../../../../../../libs/utils/getSelectdefaultValue';
import {UploadFillingForm} from "../../widgets/uploadFilling";

export type FillingType = 'send' | 'upload' | 'online' | 'declaration' | null;

type AnketaFillingContext = {
	agreementId: AgreementId;
	isShowFillingOnline: boolean;
	isShowFillingUpload: boolean;
	isShowFillingSend: boolean;
	isShowFillingDeclaration: boolean;
	isFull: boolean;
	fillingTypeItems: ISelectItem[];
	fillingTypeValue: ISelectItem | undefined;
	selectChangeHandler: (value: ISelectItem | null) => void;
	cancelFillingHandler: () => void;
	person: ContragentField;
	successFillingCallback: () => void;
};

export type AnketaFillingContextProps = {
	agreementId: AgreementId;
	person: ContragentField;
	isFull: boolean;
	children?: React.ReactNode;
	successFillingCallback: () => void;
	cancelFillingHandler: () => void;
	isDeclarationAvailable: boolean;
};

const AnketaFillingContext = createContext<AnketaFillingContext>({} as AnketaFillingContext);

export const useAnketaFillingContext = (): AnketaFillingContext => useContext(AnketaFillingContext);

const AnketaFillingContextProvider: React.FC<AnketaFillingContextProps> = props => {
	const { children, person, isFull, agreementId, cancelFillingHandler, successFillingCallback, isDeclarationAvailable } = props;
	const formHook = useFormContext<OnlineFillingForm | UploadFillingForm | SendFillingForm>();

	const [stateFillType, setFillType] = React.useState<FillingType | null>(null);

	const fillingTypeItems: ISelectItem[] = React.useMemo(
		() => [
			{ label: 'Заполнить онлайн', value: 'online' },
			{ label: 'Загрузить анкету', value: 'upload' },
			...(isDeclarationAvailable ? [{label: "Декларация", value: "declaration"}] : [])
		],
		[]
	);

	React.useEffect(() => {
		if (!person.fillingType) return;
		setFillType(person.fillingType);
	}, [person.fillingType]);

	const selectChangeHandler = React.useCallback(
		(value: ISelectItem | null) => {
			setFillType((value?.value as FillingType) || null);
			if (value?.value !== 'online' || !isFull) return;
			formHook.setValue('jobPlace', person.jobPlace);
			formHook.setValue('organizationName', person.organizationName);
			formHook.setValue('organizationAddress', person.organizationAddress);
			formHook.setValue('isOfficeJob', person.isOfficeJob);
			formHook.setValue('position', person.position);
			formHook.setValue('field', person.field);
		},
		[formHook, isFull, person.field, person.isOfficeJob, person.jobPlace, person.organizationAddress, person.organizationName, person.position]
	);

	const value: AnketaFillingContext = React.useMemo(
		() => ({
			cancelFillingHandler,
			isShowFillingOnline: stateFillType === 'online',
			isShowFillingUpload: stateFillType === 'upload',
			isShowFillingSend: stateFillType === 'send',
			isShowFillingDeclaration: stateFillType === 'declaration',
			fillingTypeItems,
			selectChangeHandler,
			fillingTypeValue: getSelectDefaultValue(stateFillType, fillingTypeItems),
			person,
			agreementId,
			isFull,
			successFillingCallback,
		}),
		[cancelFillingHandler, stateFillType, fillingTypeItems, selectChangeHandler, person, agreementId, isFull, successFillingCallback]
	);

	return <AnketaFillingContext.Provider value={value}>{children}</AnketaFillingContext.Provider>;
};

export default React.memo(AnketaFillingContextProvider);
