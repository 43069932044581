import React, { useCallback } from 'react';
import { IFloatInputFieldProps } from '../types';
import { fieldsValidate } from '../utils/fieldValidation';
import { IFloatInputProps } from '../../floatInput/types';
import FloatInput from '../../floatInput/FloatInput';
import NumberInput from '../../floatInput/NumberInput';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import getFieldError from '../utils/getFieldError';

const NumberField: React.FC<IFloatInputFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			value,
			hotReload,
			onChange,
			onBlur,
			digitsAfterDot,
			// for validation
			minValue,
			maxValue,
			validationType,
			isRequired,
			errorMessage,
			regexpPattern,
			// other
			...rest
		},
		formHook: {
			register,
			setValue,
			formState: { errors },
		},
	} = props;
	const message: string | undefined = getFieldError(fieldName, errors);
	const validationHandler = useCallback(
		(validateValue: string) => fieldsValidate({ value: validateValue, validationType, minValue, maxValue, regexpPattern }),
		[validationType, minValue, maxValue, regexpPattern]
	);

	const fieldRegistration = React.useMemo(
		() =>
			register(fieldName, {
				valueAsNumber: true,
				required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
				validate: (validateValue: string) => validationHandler(validateValue),
			}),
		[errorMessage, isRequired, fieldName, register, validationHandler]
	);

	const registrationWithUserProps = React.useMemo(
		() => ({
			...fieldRegistration,
			onChange: async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
				if (onChange) onChange(ev);
				await fieldRegistration.onChange(ev);
			},
			onBlur: async (ev: React.FocusEvent<HTMLInputElement>): Promise<void> => {
				if (onBlur) onBlur(ev);
				await fieldRegistration.onBlur(ev);
			},
		}),
		[fieldRegistration, onChange, onBlur]
	);

	React.useEffect(() => {
		if (typeof defaultValue === 'undefined' || !hotReload) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, setValue, hotReload]);

	React.useEffect(() => {
		if (typeof value === 'undefined') return;
		setValue(fieldName, value);
	}, [fieldName, value, setValue]);

	const propsForInputNumber: IFloatInputProps = {
		...rest,
		value,
		defaultValue,
		digitsAfterDot,
		isInvalid: !!message,
		isRequired,
		errorMessage: message,
		...registrationWithUserProps,
	};

	if (typeof digitsAfterDot === 'number') return <FloatInput {...propsForInputNumber} />;
	return <NumberInput {...propsForInputNumber} />;
};

export default React.memo(NumberField);
