import React from 'react';

export const DataPickerIcon: React.FC = () => (
	<svg width='1.8rem' height='1.9rem' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M1 18.0004H17V3.68457H1V18.0004Z' stroke='currentColor' strokeWidth='1.5' />
		<path d='M4.5 3.5V0.815789' stroke='currentColor' strokeWidth='1.5' />
		<path d='M13.5 3.68421V1' stroke='currentColor' strokeWidth='1.5' />
		<path d='M1 8.15792H17' stroke='currentColor' strokeWidth='1.5' />
	</svg>
);
