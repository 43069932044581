import React from "react";
import MainLayoutIgniter from "../common/mainLayout";

const NotFoundPage: React.FC = () =>
  (
    <MainLayoutIgniter>
      <h1>404</h1>
      <h3>Страница не найдена</h3>
    </MainLayoutIgniter>
  )

export default React.memo(NotFoundPage);