import React from 'react';
import {useForm, useFormContext} from "react-hook-form";
import {AgreementId, useCreateAgreementContext} from "../../../../ui/context";
import {ContragentField, ContragentId} from "../../../contragent/ui/context";
import {FieldsBuilder} from "../../../../../../../libs/uiKit/fieldsBuilder";
import Button from "../../../../../../../libs/uiKit/button";
import {useGlobalContext} from "../../../../../../../apps/main/ui/GlobalContext";
import useNotification from "../../../../../../widgets/notifier/ui/hooks/useNitification";
import {QueriesCreateAgreement} from "../../../../ds/repositories/queries";
import {CommandsCreateAgreement} from "../../../../ds/repositories/commands";
import {IForm} from "../../../../../../../libs/uiKit/fieldsBuilder/types";
import {StyledAnketaTitle} from "./styles";
import {PeopleType} from "../../../../pages/insurance";
import {
	StyledButtonsWrapper,
	StyledDownloadFileItem,
	StyledFileName
} from "../../../../../../widgets/documents/ui/styles";
import {FileIcon} from "../../../../../../../icons/ui/File";
import {Text4} from "../../../../../../../libs/uiKit/globalStyles";
import {DownloadIcon} from "../../../../../../../icons/ui/Download";
import {fileDownloader} from "../../../../../../../libs/utils/fileDownloader";

export type UploadFillingForm = {
	anketaFiles: string;
};

export type UploadFillingProps = {
	agreementId: AgreementId;
	personId: ContragentId;
	children?: React.ReactNode;
	successFillingCallback: () => void;
	fillingType: "upload" | "declaration";
	agreementMode: boolean;
	person?: ContragentField;
};

type Document = {
	id: string;
	name: string;
	url: string;
	added?: string;
};

const UploadFilling: React.FC<UploadFillingProps> = props => {
	const { children, personId, agreementId, successFillingCallback, fillingType, agreementMode, person } = props;
	const { api } = useGlobalContext();
	const formHook = useForm<UploadFillingForm>();

	const { documents } = useCreateAgreementContext();

	const selectedDocuments = (documents || []).filter(document => !!person?.anketaFileId && person?.anketaFileId.includes(document.id));

	const subjectName = (fillingType === 'declaration') ? 'декларации' : 'анкеты';

	const { setNotification } = useNotification();
	const queriesCreateAgreements = React.useMemo(() => QueriesCreateAgreement(api), [api]);
	const commandsCreateAgreements = React.useMemo(() => CommandsCreateAgreement(api, queriesCreateAgreements), [api, queriesCreateAgreements]);
	const [stateLoadingAnketa, setLoadingAnketa] = React.useState<boolean>(false);

	const downloadFileHandler = React.useCallback((file: Document) => fileDownloader(file.url, file.name), []);

	const saveUploadHandler = React.useCallback(async () => {
		const result = await formHook.trigger();
		if (!result) return;
		const currentFormData: UploadFillingForm = formHook.getValues() as UploadFillingForm;
		if (!personId) return setNotification({ type: 'error', text: 'no person id' });
		setLoadingAnketa(true);
		commandsCreateAgreements.uploadFilling({
			id: agreementId,
			personId,
			fillingType,
			currentFormData,
			successCallback: () => {
				setLoadingAnketa(false);
				successFillingCallback();
			},
			errorCallback: error => {
				setNotification({ type: 'error', text: error });
				setLoadingAnketa(false);
			},
			agreementMode,
		});
	}, [agreementId, commandsCreateAgreements, formHook, personId, setNotification, successFillingCallback]);

	const form: IForm = {
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'upload',
						isRequired: true,
						errorMessage: 'Прикрепите файлы',
						fieldName: 'anketaFiles',
						placeholder: 'Прикрепить файл',
					},
				},
			],
	};

	return (
		<>
			<StyledAnketaTitle>Загрузка {subjectName}</StyledAnketaTitle>
			{selectedDocuments.length !== 0 &&
				selectedDocuments.map(file => {
					const { name, added, id } = file;
					return (
						<StyledDownloadFileItem key={id}>
							<StyledFileName>
								<FileIcon />
								<Text4>{name?.length > 35 ? `${name.substr(0, 35)}...` : name}</Text4>
								{!!added && <Text4>{added}</Text4>}
							</StyledFileName>
							<StyledButtonsWrapper>
								<Button appearance='link' onClick={() => downloadFileHandler(file)} iconBefore={<DownloadIcon />}>
									Скачать
								</Button>
							</StyledButtonsWrapper>
						</StyledDownloadFileItem>
					);
				})}
			<FieldsBuilder {...form} />
			<Button appearance='filled' isLoading={stateLoadingAnketa} onClick={saveUploadHandler} style={{marginTop: '15px'}}>
				Сохранить
			</Button>
		</>
	);
};

export default UploadFilling;
