import React from 'react';
import {
	ICheckboxFieldProps,
	IDaDataFieldProps,
	IDateFieldProps,
	IElementFieldProps,
	IEnumsFieldProps,
	IFieldSwitcherProps,
	IFloatInputFieldProps,
	IForm,
	IInputFieldProps,
	IInputMaskFieldProps,
	IRadioFieldProps,
	IRangePickerFieldProps,
	ISelectFieldProps,
	ITextAreaFieldProps,
	IUploadFileFieldProps,
} from './types';
import { FieldColumnWrapper, StyledFormField, StyledFormWrapper } from './styles';
import { Text4 } from '../globalStyles';
import TextAreaField from './fields/TextAreaField';
import InputField from './fields/InputField';
import CheckboxField from './fields/CheckboxField';
import NumberField from './fields/NumberField';
import RangeField from './fields/RangeField';
import InputMaskField from './fields/InputMaskField';
import DateField from './fields/DateField';
import RadioField from './fields/RadioField';
import SelectField from './fields/SelectField';
import DaDataField from './fields/DaDataField';
import UploadFileField from './fields/UploadFileField';
import ElementField from './fields/ElementField';
import { EnumsField } from './fields/EnumsField';

export const DEFAULT_REQUIRED_ERROR: string = 'Это обязательное поле';

const FieldSwitcher: React.FC<IFieldSwitcherProps> = React.memo(props => {
	const {
		field: { fieldType, fieldName },
		formHook: { unregister },
	} = props;

	React.useEffect(() => (): void => unregister(fieldName), [unregister, fieldName]);

	switch (fieldType) {
		// perfect
		case 'textarea':
			return <TextAreaField {...(props as ITextAreaFieldProps)} />;
		case 'input':
			return <InputField {...(props as IInputFieldProps)} />;
		case 'number':
			return <NumberField {...(props as IFloatInputFieldProps)} />;
		case 'inputMask':
			return <InputMaskField {...(props as IInputMaskFieldProps)} />;
		case 'checkbox':
			return <CheckboxField {...(props as ICheckboxFieldProps)} />;

		case 'element':
			return <ElementField {...(props as IElementFieldProps)} />;

		// perfect controlled
		case 'select':
			return <SelectField {...(props as ISelectFieldProps)} />;
		case 'date':
			return <DateField {...(props as IDateFieldProps)} />;

		// 50/50 controlled

		// need recreate
		case 'daData':
			return <DaDataField {...(props as IDaDataFieldProps)} />;
		case 'range':
			return <RangeField {...(props as IRangePickerFieldProps)} />;
		case 'upload':
			return <UploadFileField {...(props as IUploadFileFieldProps)} />;

		// need check
		case 'radio':
			return <RadioField {...(props as IRadioFieldProps)} />;

		case 'enums':
			return <EnumsField {...(props as IEnumsFieldProps)} />;
		// case "dateInterval":
		//   return <DateIntervalField {...props}/>
		default:
			return null;
	}
});

export const FieldsBuilder: React.FC<IForm> = props => {
	const { formHook, fields, isLoading, isDisabled, hotReload } = props;

	return (
		<StyledFormWrapper>
			{fields.map(fieldProps => {
				const { isHidden, label, field } = fieldProps;
				if (isHidden) return null;
				return (
					<FieldColumnWrapper key={field.fieldName} grid={field.grid}>
						<StyledFormField className='formField' grid={field.pseudoGrid} marginBottom={field.marginBottom}>
							{label && <Text4>{label}</Text4>}
							<FieldSwitcher
								field={{
									...field,
									isLoading: typeof isLoading === 'boolean' ? isLoading : field.isLoading,
									isDisabled: typeof isDisabled === 'boolean' ? isDisabled : field.isDisabled,
									hotReload: typeof hotReload === 'boolean' ? hotReload : field.hotReload,
								}}
								formHook={formHook}
							/>
						</StyledFormField>
					</FieldColumnWrapper>
				);
			})}
		</StyledFormWrapper>
	);
};

export default React.memo(FieldsBuilder);
