import React from 'react';
import DeleteGroupUi from './ui';
import { DeleteGroupContextProps, DeleteGroupContextProvider } from './ui/context';

const DeleteGroupIgniter: React.FC<DeleteGroupContextProps> = props => (
	<DeleteGroupContextProvider {...props}>
		<DeleteGroupUi />
	</DeleteGroupContextProvider>
);

export default React.memo(DeleteGroupIgniter);
