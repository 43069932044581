import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import {
	CreateUpdateRoleMutation,
	CreateUpdateRoleMutationVariables,
	GroupsQuery,
	GroupsQueryVariables,
	PermissionsQuery,
	PermissionsQueryVariables,
} from '../../../../../../../libs/api/graphqlTypes';
import { CREATE_UPDATE_ROLE } from '../../../../../../../libs/api/commands';
import { GROUPS, PERMISSIONS } from '../../../../../../../libs/api/queries';
import { IEnumItem } from '../../../../../../../libs/uiKit/enums/types';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

type CreateRoleContext = IModalProps & {
	isLoadingCreateRole: boolean;
	onFormSubmit: () => void;
	form: IForm;
};

type CreateRoleForm = {
	name: string;
	group?: ISelectItem;
	permissions: IEnumItem[];
};

const CreateRoleContext = createContext<CreateRoleContext>({} as CreateRoleContext);

export const useCreateRoleContext = (): CreateRoleContext => useContext(CreateRoleContext);

export const CreateRoleContextProvider: React.FC<IModalProps> = props => {
	const { onClose, children } = props;
	const {
		routes: { error500 },
	} = useGlobalContext();
	const { setNotification } = useNotification();
	const formHook = useForm<CreateRoleForm>({ mode: 'onBlur' });

	const { data: dataPermissions, loading: loadingPermissions } = useQuery<PermissionsQuery, PermissionsQueryVariables>(PERMISSIONS, { onError: error => error500(error) });
	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });
	const [createRole, { loading: loadingCreateRole }] = useMutation<CreateUpdateRoleMutation, CreateUpdateRoleMutationVariables>(CREATE_UPDATE_ROLE, { refetchQueries: ['roles'] });

	const selectGroups: ISelectItem[] = (dataGroups?.groups || []).map(group => ({ label: group?.name || 'unknown', value: String(group?.id) }));
	const enumPermissions: IEnumItem[] = (dataPermissions?.permissions || []).map(permission => ({
		label: permission?.name || 'unknown',
		value: String(permission?.id) || 'unknown',
	}));

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'name',
						isRequired: true,
						placeholder: 'Название роли',
						errorMessage: 'Введите название',
					},
				},
				{
					field: {
						fieldType: 'enums',
						fieldName: 'permissions',
						isRequired: true,
						placeholder: 'Поиск по функциям',
						isLoading: loadingPermissions,
						items: enumPermissions,
						errorMessage: 'Выберите функции',
					},
				},
			],
		}),
		[enumPermissions, formHook, loadingGroups, loadingPermissions, selectGroups]
	);

	const onFormSubmit = formHook.handleSubmit(data => {
		const { name, permissions, group } = data;
		createRole({
			variables: {
				input: {
					name,
					permissions: (permissions || []).map(({ value }: IEnumItem) => Number(value)),
				},
			},
		})
			.then(() => {
				onClose();
				setNotification({ type: 'success', text: 'Роль успешно создана' });
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const value: CreateRoleContext = React.useMemo(
		() => ({
			...props,
			onFormSubmit,
			isLoadingCreateRole: loadingCreateRole,
			form,
		}),
		[form, loadingCreateRole, onFormSubmit, props]
	);

	return <CreateRoleContext.Provider value={value}>{children}</CreateRoleContext.Provider>;
};
