import React from 'react';
import { useSendFillingContext } from './context';
import { FieldsBuilder } from '../../../../../../../../libs/uiKit/fieldsBuilder';
import { StyledAnketaTitle } from './styles';
import Button from '../../../../../../../../libs/uiKit/button';

const SendFillingUi: React.FC = () => {
	const { form, saveSendHandler, isLoading } = useSendFillingContext();

	return (
		<>
			<StyledAnketaTitle>Данные клиента</StyledAnketaTitle>
			<FieldsBuilder {...form} />
			<Button appearance='filled' isLoading={isLoading} onClick={saveSendHandler} style={{marginTop: '15px'}}>
				Сохранить
			</Button>
		</>
	);
};

export default React.memo(SendFillingUi);
