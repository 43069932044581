import React from 'react';
import CreateGroupUi from './ui';
import { CreateGroupContextProvider, CreateGroupProps } from './ui/context';

const CreateGroupIgniter: React.FC<CreateGroupProps> = props => (
	<CreateGroupContextProvider {...props}>
		<CreateGroupUi />
	</CreateGroupContextProvider>
);

export default React.memo(CreateGroupIgniter);
