import React from "react";
import { StyledLoader } from "./styles";
import { ILoaderProps } from "./types";
import { LoaderIcon } from "../icons/Loader";

const Loader: React.FC<ILoaderProps> = props => {
  const { loaderWidth } = props;
  return (
    <StyledLoader width={loaderWidth}>
      <LoaderIcon />
    </StyledLoader>
  );
};

export default React.memo(Loader)
