import React from 'react';
import { StyledFeature, StyledFeatureIcon } from './styles';
import { Text4 } from '../../../../libs/uiKit/globalStyles';

const Feature: React.FC<{ text: string }> = ({ text }) => (
	<StyledFeature>
		<StyledFeatureIcon />
		<Text4>{text}</Text4>
	</StyledFeature>
);

export default React.memo(Feature);
