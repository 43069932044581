import React from 'react';
import RoleByIdUi from './ui';
import RoleByIdContextProvider from './ui/context';

const RoleByIdPage: React.FC = () => (
	<RoleByIdContextProvider>
		<RoleByIdUi />
	</RoleByIdContextProvider>
);

export default React.memo(RoleByIdPage);
