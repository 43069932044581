import React from 'react';
import { useDashboardContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { StatisticsCardsWrapper, StyledCardHeader, StyledCardItem, StyledStatisticsCard, StyledStatisticsHeader } from './styles';
import { Heading2, Heading3, Text3 } from '../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../libs/uiKit/button';
import FlyButtons from '../../../../libs/uiKit/flyButtons';
import DropdownMenu from '../../../../libs/uiKit/dropdownMenu';
import SkipPrecalculationIgniter from '../../../widgets/skipPrecalculation';
import UserWarning from "../../../widgets/userWarning";

const DashboardUi: React.FC = () => {
	const { cards, isCanCreateAgreement, dropDownItems, isOpenSkipPrecalculation, closeSkipPrecalculationonHandler, skipPrecalculationCallback, banksOk } = useDashboardContext();

	return (
		<MainLayoutIgniter>
			<StyledStatisticsHeader>
				<Heading2>Главная</Heading2>
				<FlyButtons>
					<DropdownMenu
						trigger={
							<Button isDisabled={!isCanCreateAgreement} appearance='filled'>
								Создать заявку
							</Button>
						}
						items={dropDownItems}
					/>
				</FlyButtons>
			</StyledStatisticsHeader>
			{!banksOk && <UserWarning />}
			<StatisticsCardsWrapper>
				{cards.map(({ title, clickHandler, linkText, items, isDisabled }, index) => (
					<StyledStatisticsCard key={`dashboard-card-${index}`}>
						<StyledCardHeader>
							<Heading3>{title}</Heading3>
							<Button appearance='link' onClick={clickHandler} isDisabled={isDisabled}>
								{linkText}
							</Button>
						</StyledCardHeader>
						{items.map(({ status, count }, indexStatus) => (
							<StyledCardItem key={`main-card-item-${indexStatus}`}>
								<Text3>{status}</Text3>
								<Text3>{count}</Text3>
							</StyledCardItem>
						))}
					</StyledStatisticsCard>
				))}
			</StatisticsCardsWrapper>
			<SkipPrecalculationIgniter isOpen={isOpenSkipPrecalculation} onClose={closeSkipPrecalculationonHandler} skipCallback={skipPrecalculationCallback} />
		</MainLayoutIgniter>
	);
};

export default React.memo(DashboardUi);
