import React from 'react';
import OnboardingUi from './ui';
import { OnboardingContextProvider } from './ui/context';

const OnboardingIgniter: React.FC = () => (
	<OnboardingContextProvider>
		<OnboardingUi />
	</OnboardingContextProvider>
);

export default React.memo(OnboardingIgniter);
