import React from 'react';
import { StyledFlyButtons } from './styles';

interface IFlyButtonsProps {
	isWhiteBackground?: boolean;
}

export const FlyButtons: React.FC<IFlyButtonsProps> = ({ children, isWhiteBackground }) => <StyledFlyButtons isWhiteBackground={!!isWhiteBackground}>{children}</StyledFlyButtons>;

export default FlyButtons;
