import React, {useState} from "react";
import {useWindowWidth} from "@react-hook/window-size";
import {useMutation} from "@apollo/client";
import {IModalProps} from "../../../../../libs/uiKit/modal/types";
import {PrintMutation, PrintMutationVariables, PrintTypeEnum} from "../../../../../libs/api/graphqlTypes";
import {PRINT_MUTATION} from "../../../../../libs/api/commands";
import useNotification from "../../../../widgets/notifier/ui/hooks/useNitification";
import {fileDownloader} from "../../../../../libs/utils/fileDownloader";
import {Modal} from "../../../../../libs/uiKit/modal";
import {StyledBigPremiumHeader, StyledGrayLabel, StyledPremiumBlock} from "../../ui/styles";
import {stringToMoney} from "../../../../../libs/uiKit/utils";
import {Text3} from "../../../../../libs/uiKit/globalStyledComponents";
import DropdownMenu from "../../../../../libs/uiKit/dropdownMenu";
import Button from "../../../../../libs/uiKit/button";
import {SelectArrowIcon} from "../../../../../libs/uiKit/icons/SelectArrow";
import {DownloadIcon} from "../../../../../icons/ui/Download";
import {SendIcon} from "../../../../../icons/ui/Send";
import {StyledButtonWrapper, StyledSaveAgreementWrapper} from "./styles";
import {SendDocumentModal} from "../sendDocumentModal";

const GetInvoiceModal = (props: IModalProps) => {

    const { isOpen, onClose, isLoading, data } = props;

    const { invoice } = data;

    const windowWidth = useWindowWidth({ wait: 300 });

    const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
        fetchPolicy: 'network-only',
    });

    const [stateDropDownIsOpen, setDropDownIsOpen] = useState<boolean>(false)
    const [stateDropDownSendIsOpen, setDropDownSendIsOpen] = useState<boolean>(false)
    const [selectedTemplateIsn, setSelectedTemplateIsn] = useState<string|null>(null);

    const { setNotification } = useNotification();

    const [stateSendDocumentModalIsOpen, setSendDocumentModalIsOpen] = React.useState<boolean>(false);

    const closeSendDocumentModalHandler = React.useCallback(() => setSendDocumentModalIsOpen(false), []);

    const dowloadInvoiceHandler = (documentIsn: number, templateIsn: string, sendByEmail: boolean) => {
        if(sendByEmail){
            setDropDownSendIsOpen(false);
            setSelectedTemplateIsn(templateIsn);
            setSendDocumentModalIsOpen(true);
            return;
        }

        setDropDownIsOpen(false);

        downloadDoc({
            variables: {
                isn: documentIsn,
                type: PrintTypeEnum.Document,
                templateIsn: +templateIsn
            },
        }).then(({ data }) => {
            if (!data?.print) return setNotification({ type: 'error', text: 'no doc' });
            fileDownloader(data.print.url, data.print.filename);
        }).catch(error => setNotification({ type: 'error', text: error }))
    }

    return (
        <StyledSaveAgreementWrapper>
            <Modal isOpen={isOpen} onClose={() => onClose()} header='Формирование счета'>
                <StyledPremiumBlock>
                    <StyledGrayLabel>{invoice?.summName}</StyledGrayLabel>
                    <StyledBigPremiumHeader>{stringToMoney(invoice?.invoiceSum)} RUB</StyledBigPremiumHeader>
                </StyledPremiumBlock>
                <div style={{marginBottom: '2rem'}}>
                    <StyledGrayLabel>Назначение платежа:</StyledGrayLabel>
                    <Text3>{invoice?.invoiceCaption}</Text3>
                </div>
                <StyledButtonWrapper>
                    <DropdownMenu
                        trigger={
                            <Button iconAfter={<SelectArrowIcon direction='down' />} appearance='filled' isLoading={isLoading}>
                                <DownloadIcon/> Скачать
                            </Button>
                        }
                        direction={windowWidth < 768 ? 'up' : 'down'}
                        items={(invoice?.types || []).map((type: any) => ({
                            label: type.name, isDisabled: loadingDownloadDoc, onClick: () => dowloadInvoiceHandler(invoice.invoiceIsn, type.isn, false)
                        }))}
                        isOpen={stateDropDownIsOpen}
                    />
                </StyledButtonWrapper>
                <StyledButtonWrapper>
                    <DropdownMenu
                        trigger={
                            <Button iconAfter={<SelectArrowIcon direction='down' />} appearance='filled' isLoading={isLoading}>
                                <SendIcon/> Отправить на почту
                            </Button>
                        }
                        direction={windowWidth < 768 ? 'up' : 'down'}
                        items={(invoice?.types || []).map((type: any) => ({
                            label: type.name, isDisabled: loadingDownloadDoc, onClick: () => dowloadInvoiceHandler(invoice.invoiceIsn, type.isn, true)
                        }))}
                        isOpen={stateDropDownSendIsOpen}
                    />
                </StyledButtonWrapper>
            </Modal>
            <SendDocumentModal onClose={closeSendDocumentModalHandler} isOpen={stateSendDocumentModalIsOpen} data={{
                ...data,
                templateIsn: selectedTemplateIsn
            }}/>
        </StyledSaveAgreementWrapper>
    );
};

export default GetInvoiceModal;