import React from 'react';
import AnketaViewUi from './ui';
import { AnketaViewContextProps, AnketaViewContextProvider } from './ui/context';

const AnketaViewIgniter: React.FC<AnketaViewContextProps> = props => (
	<AnketaViewContextProvider {...props}>
		<AnketaViewUi />
	</AnketaViewContextProvider>
);

export default React.memo(AnketaViewIgniter);
