import React from 'react';
import DeleteUserUi from './ui';
import { DeleteUserContextProps, DeleteUserContextProvider } from './ui/context';

const DeleteUserIgniter: React.FC<DeleteUserContextProps> = props => (
	<DeleteUserContextProvider {...props}>
		<DeleteUserUi />
	</DeleteUserContextProvider>
);

export default React.memo(DeleteUserIgniter);
