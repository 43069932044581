import { ISelectItem } from '../uiKit/select/types';

export const getSelectDefaultValue = (defaultValue?: string | number | null, items?: ISelectItem[]): ISelectItem | undefined => {
	if (!defaultValue) return undefined;
	return (items || []).find(({ value }) => String(defaultValue) === value);
};

export const getClearSelectValue = (value?: ISelectItem | null): ISelectItem | null => {
	if (!value) return null;
	return { label: value.label, value: value.value };
};
