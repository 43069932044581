import React from 'react';
import DisapproveUi from './ui';
import { DisapproveContextProps, DisapproveContextProvider } from './ui/context';

const DisapproveIgniter: React.FC<DisapproveContextProps> = props => (
	<DisapproveContextProvider {...props}>
		<DisapproveUi />
	</DisapproveContextProvider>
);

export default React.memo(DisapproveIgniter);
