import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useMainLayoutContext } from './context';
import { StyledContent, StyledContentArea, StyledLayout, StyledMobileShadow } from './styles';
import LeftMenuIgniter from '../widgets/leftMenu';
import HeaderIgniter from '../widgets/header';

const MainLayoutUi: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isLeftMenuOpen, switchLeftMenuHandler, isWhiteBackground } = useMainLayoutContext();
	const windowWidth = useWindowWidth({ wait: 300 });

	return (
		<StyledLayout>
			<LeftMenuIgniter />
			<StyledContentArea isMenuOpen={isLeftMenuOpen}>
				{windowWidth < 768 && isLeftMenuOpen && <StyledMobileShadow onClick={switchLeftMenuHandler} />}
				<HeaderIgniter />
				<StyledContent isBackground={!isWhiteBackground}>{children}</StyledContent>
			</StyledContentArea>
		</StyledLayout>
	);
};

export default React.memo(MainLayoutUi);
