import React, { useEffect, useRef, useState } from 'react';
import { DropDownSelectContentProps } from './types';
import { Heading4, Text4 } from '../../globalStyles';
import { useKeyPress } from '../../hooks/useKeysPress';
import Loader from '../../loader';
import { StyledSelectEmpty, StyledSelectItem, StyledSelectItemsWrapper } from '../styles';

const DropDownSelectContent: React.FC<DropDownSelectContentProps> = ({ dropDownHelpMessage, isLoading, emptyMessage, filterItems, selectItemHandler, height, stateValue }) => {
	const dropDownContent = useRef<HTMLDivElement | null>(null);

	const downPress = useKeyPress('ArrowDown');
	const upPress = useKeyPress('ArrowUp');
	const enterPress = useKeyPress('Enter');
	const [cursor, setCursor] = useState<number>(0);

	useEffect(() => {
		if (!filterItems?.length || !downPress || !dropDownContent) return;
		dropDownContent.current?.focus();
		setCursor(prevState => (prevState < filterItems.length - 1 ? prevState + 1 : prevState));
	}, [filterItems, downPress, dropDownContent]);

	useEffect(() => {
		if (filterItems?.length && upPress) {
			setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
		}
	}, [filterItems, upPress]);

	useEffect(() => {
		if (filterItems?.length && enterPress) {
			selectItemHandler(filterItems[cursor]);
		}
	}, [filterItems, cursor, enterPress, selectItemHandler]);

	useEffect(() => {
		setCursor(0);
	}, [filterItems]);

	return (
		<StyledSelectItemsWrapper dropDownHelpMessage={dropDownHelpMessage} ref={dropDownContent} tabIndex={0} height={height || 30}>
			{!!isLoading && <Loader loaderWidth={3} />}
			{!isLoading && (
				<>
					{!!filterItems?.length &&
						filterItems.map((item, index) => (
							<StyledSelectItem
								key={index}
								onMouseEnter={(): void => setCursor(index)}
								onClick={(): void => selectItemHandler(item)}
								hover={index === cursor}
								isActive={stateValue?.value === item.value}
							>
								<Text4>{item.label}</Text4>
							</StyledSelectItem>
						))}
					{!filterItems?.length && (
						<StyledSelectEmpty>
							<Heading4>{emptyMessage || 'Нет элементов'}</Heading4>
						</StyledSelectEmpty>
					)}
				</>
			)}
		</StyledSelectItemsWrapper>
	);
};

export default React.memo(DropDownSelectContent);
