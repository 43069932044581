import { useQuery } from '@apollo/react-hooks';
import { DictKeyEnum, DictQuery, DictQueryVariables } from '../../../../libs/api/graphqlTypes';
import { ISelectItem } from '../../../../libs/uiKit/select/types';
import { DICTI } from '../../../../libs/api/queries';
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";

const useGetDicti = (key: DictKeyEnum): { data: ISelectItem[]; loading: boolean } => {
	const { showError } = useNotification();
	const { data, loading } = useQuery<DictQuery, DictQueryVariables>(DICTI, {
		variables: {
			key,
		},
		onError: (err) => showError(err.message),
	});

	return {
		data: (data?.dict || []).reduce<ISelectItem[]>((prev, item) => {
			if (!item) return prev;
			return [...prev, { label: item.name, value: item.isn, extra: item.extra }];
		}, []),
		loading,
	};
};

export default useGetDicti;
