import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './styles';
import { darkTheme, lightTheme } from './themes';
import { ThemeContextType } from './types';

export const ThemeContext = React.createContext({} as ThemeContextType);

const ThemeContainer: React.FC = ({ children }) => {
	const [stateTheme, setTheme] = useState('light');
	const themeMode = stateTheme === 'light' ? lightTheme : darkTheme;

	const contextValue: ThemeContextType = React.useMemo(
		() => ({
			toggleTheme: (): void => (stateTheme === 'light' ? setTheme('dark') : setTheme('light')),
		}),
		[stateTheme]
	);

	return (
		<ThemeProvider theme={themeMode}>
			<ThemeContext.Provider value={contextValue}>
				<>
					<GlobalStyles />
					{children}
				</>
			</ThemeContext.Provider>
		</ThemeProvider>
	);
};

export default ThemeContainer;
