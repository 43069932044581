import { useMemo } from 'react';

const getRange = (start: number, end: number): number[] =>
	Array(end - start + 1)
		.fill(0)
		.map((value, index) => index + start);

export const useGetPagesToRender = (current: number, length: number, delta: number = 4): (string | number)[] =>
	useMemo(() => {
		const range = {
			start: Math.round(current + 2 - delta / 2),
			end: Math.round(current + delta / 2),
		};

		if (range.start - 1 === 1 || range.end + 1 === length) {
			range.start += 1;
			range.end += 1;
		}

		let pages: (string | number)[] = current + 1 > delta ? getRange(Math.min(range.start, length - delta), Math.min(range.end, length)) : getRange(1, Math.min(length, delta + 1));

		const withDots = (value: number, pair: (string | number)[]): (string | number)[] => (pages.length + 1 !== length ? pair : [value]);

		if (pages[0] !== 1) {
			pages = withDots(1, [1, '...']).concat(pages);
		}

		if (pages[pages.length - 1] < length) {
			pages = pages.concat(withDots(length, ['...', length]));
		}

		return pages;
	}, [current, delta, length]);
