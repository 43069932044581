import React from 'react';
import PropertyUi from './ui';
import PropertyContextProvider from './ui/context';

const PropertyPage: React.FC = () => (
	<PropertyContextProvider>
		<PropertyUi />
	</PropertyContextProvider>
);

export default React.memo(PropertyPage);
