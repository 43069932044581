import React from "react";
import {Modal} from "../../../libs/uiKit/modal";
import {StyledButtonsGroup, StyledDeleteRoleModalBody} from "../../pages/roles/widgets/deleteRole/ui/styles";
import {Heading3} from "../../../libs/uiKit/globalStyledComponents";
import Button from "../../../libs/uiKit/button";
import {IModalProps} from "../../../libs/uiKit/modal/types";

export interface ConfirmPopupProps extends IModalProps {
  id?: number;
  text: string;
  confirmCallback?: () => void;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ text, confirmCallback, isOpen, onClose }) => {
  const yesHandler = () => {
    onClose();
    if(confirmCallback){
      confirmCallback();
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} header="">
      <StyledDeleteRoleModalBody>
        <Heading3>{text}</Heading3>
        <StyledButtonsGroup>
          <Button appearance='filled' onClick={yesHandler}>
            Да
          </Button>
          <Button onClick={onClose} appearance='transparent'>
            Нет
          </Button>
        </StyledButtonsGroup>
      </StyledDeleteRoleModalBody>
    </Modal>
  );
};

export default React.memo(ConfirmPopup);