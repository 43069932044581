import React from 'react';
import { useController } from 'react-hook-form';
import { IDaDataFieldProps } from '../types';
import DaData from '../../daData';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import getFieldError from '../utils/getFieldError';
import { fieldsValidate } from '../utils/fieldValidation';
import { IDaDataValue } from '../../daData/types';

const DaDataField: React.FC<IDaDataFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			value,
			hotReload,
			onChange,
			onBlur,
			// for validation
			validationType,
			isRequired,
			errorMessage,
			// other
			...rest
		},
		formHook: { control, setValue, trigger },
	} = props;

	const validate = React.useCallback(
		(validateValue: IDaDataValue) =>
			fieldsValidate({
				value: validateValue,
				validationType,
			}),
		[validationType]
	);

	const {
		field,
		formState: { errors },
	} = useController({
		name: fieldName,
		defaultValue: defaultValue || value,
		control,
		rules: {
			validate,
			required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
		},
	});

	const message = getFieldError(fieldName, errors);

	const registrationWithUserProps = React.useMemo(
		() => ({
			...field,
			onChange: (fieldValue: IDaDataValue | null): void => {
				if (onChange) onChange(fieldValue);
				field.onChange(fieldValue);
				setTimeout(() => {
					trigger(fieldName);
				}, 10);
			},
			onBlur: (ev: React.FocusEvent<HTMLInputElement>): void => {
				if (onBlur) onBlur(ev);
				field.onBlur();
			},
		}),
		[onBlur, onChange, field]
	);

	React.useEffect(() => {
		if (!hotReload || defaultValue === undefined || defaultValue === null) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, setValue, hotReload]);

	return <DaData {...rest} {...registrationWithUserProps} isInvalid={!!message} isRequired={isRequired} errorMessage={message} />;
};

export default React.memo(DaDataField);
