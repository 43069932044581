import styled from 'styled-components';
import { DEFAULT_SHADOW, grey, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledStepWrapper = styled.div`
	padding: 0 15rem;
	@media screen and (max-width: 1600px) {
        padding: 0 5%;	    
	}
	@media screen and (max-width: 767px) {
		padding: 0;
	}
`;

export const StyledStepFormWrapper = styled.div`
	padding: 4rem;
	background: ${white};
	box-shadow: ${DEFAULT_SHADOW};
	margin-bottom: 5rem;

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
		margin-bottom: 2rem;
	}
`;

export const StyledStepFormHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 3rem;
	p {
		white-space: nowrap;
		color: ${grey};
	}

	@media screen and (max-width: 767px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		p {
			margin-bottom: 2rem;
		}
		margin-bottom: 2rem;
	}
`;

export const StyledStepsHeader = styled.div`
	h2 {
		margin: 1rem 0 4rem 0;
	}
	@media screen and (max-width: 767px) {
		h2 {
			margin: 0.5rem 0 2rem 0;
		}
	}
`;

export const StyledPaginationButtonWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 4rem;
	grid-row-gap: 1.5rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;
