import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledPaginationButton, Ellipsis, PaginationStyled, StyledListWrapper } from './styles';
import { PaginationProps } from './types';
import { useGetPagesToRender } from './hooks/useGetPagesToRender';
import Button from '../button';
import { ArrowIcon } from '../../../icons/ui/Arrow';
import { Text4 } from '../globalStyledComponents';

const DEFAULT_ELLIPSIS = '...';

const Pagination: React.FC<PaginationProps> = props => {
	const { pages, defaultPage = 0, onChange, selectedPage, delta } = props;

	const [currentPageIndex, setCurrentPageIndex] = useState<number>(defaultPage);

	const pagesToRender = useGetPagesToRender(currentPageIndex, pages.length, delta);

	const onChangePagination = useCallback(
		(value: number) => {
			if (value === currentPageIndex) return;
			setCurrentPageIndex(value);
			if (onChange) onChange(value + 1);
		},
		[currentPageIndex, onChange]
	);

	useEffect(() => {
		if (selectedPage === undefined) return;
		setCurrentPageIndex(selectedPage);
	}, [selectedPage, onChange]);

	const memoPaginationList = useMemo(
		() =>
			pagesToRender.map((page, key) => (
				<div key={key}>
					{page === DEFAULT_ELLIPSIS ? (
						<Ellipsis>&#8230;</Ellipsis>
					) : (
						<StyledPaginationButton onClick={(): void => onChangePagination(Number(page) - 1)} isCurrent={page === pages[currentPageIndex]}>
							<Text4>{page}</Text4>
						</StyledPaginationButton>
					)}
				</div>
			)),
		[currentPageIndex, onChangePagination, pages, pagesToRender]
	);

	const prevBtnHandle = useCallback(() => onChangePagination(currentPageIndex - 1), [currentPageIndex, onChangePagination]);
	const nextBtnHandle = useCallback(() => onChangePagination(currentPageIndex + 1), [currentPageIndex, onChangePagination]);

	return (
		<PaginationStyled>
			<Button isDisabled={currentPageIndex === 0} appearance='flag' icon={<ArrowIcon direction='left' />} onClick={(): void => prevBtnHandle()} />
			<StyledListWrapper>{memoPaginationList}</StyledListWrapper>
			<Button isDisabled={pages.length - 1 === currentPageIndex} appearance='flag' icon={<ArrowIcon direction='right' />} onClick={(): void => nextBtnHandle()} />
		</PaginationStyled>
	);
};

export default React.memo(Pagination);
