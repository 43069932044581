import React from 'react';
import { StyledStatus } from './styles';
import { IStatusProps } from './types';
import { StatusActionIcon } from '../../../icons/ui/StatusAction';
import { green, grey, lightBlue, red, Text3 } from '../globalStyledComponents';

const STATUSES: { code: string; label: string; color: string }[] = [
	{
		code: 'AGREED',
		label: 'Согласован',
		color: green,
	},
	{
		code: 'NOT_AGREED',
		label: 'Не согласован',
		color: red,
	},
	{
		code: 'UNDERWRITING',
		label: 'Согласование',
		color: lightBlue,
	},
];

const Status: React.FC<IStatusProps> = props => {
	const { status, hideIcon, iconAfter, color: statusColorProps, code } = props;

	const color = statusColorProps || STATUSES.find(({ code: hardCode }) => hardCode === code)?.color || grey;
	const label = status || STATUSES.find(({ code: hardCode }) => hardCode === code)?.label || 'Не известный';

	return (
		<StyledStatus color={color}>
			{!hideIcon && <StatusActionIcon />}
			<Text3>{label}</Text3>
			{iconAfter}
		</StyledStatus>
	);
};

export default React.memo(Status);
