import React from 'react';
import { StyledButtonTooltip } from './styles';
import { TextCaption } from '../globalStyles';
import { ITooltipProps } from './types';

const Tooltip: React.FC<ITooltipProps> = ({ tooltip }) => {
	if (!tooltip) return null;

	return (
		<StyledButtonTooltip className='buttonTooltip'>
			<TextCaption>{tooltip}</TextCaption>
		</StyledButtonTooltip>
	);
};

export default React.memo(Tooltip);
