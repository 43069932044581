import React from 'react';
import { useOnboardingContext } from './context';
import { Modal } from '../../../../libs/uiKit/modal';
import { StyledButtonsGroupAutoSize, StyledOnboardingEventsWrapper, StyledOnboardingNavigationItem, StyledOnboardingStepsNavigation, StyledStepBody } from './styles';
import { Heading3 } from '../../../../libs/uiKit/globalStyles';
import ImagePreview from '../../../../libs/uiKit/imagePreview';
import Button from '../../../../libs/uiKit/button';

const OnboardingUi: React.FC = () => {
	const { isOpen, onClose, step, stepsCount, activeStep, changeStepHandler } = useOnboardingContext();
	const isLastStep: boolean = stepsCount === activeStep;
	const isFirstStep: boolean = activeStep === 1;
	return (
		<Modal onClose={onClose} isOpen={isOpen} header='Переход к заявке'>
			<ImagePreview src={step.img} />
			<StyledStepBody>
				<Heading3>{step.title}</Heading3>
				{step.description}
			</StyledStepBody>
			<StyledOnboardingEventsWrapper>
				{isFirstStep && (
					<Button appearance='filled' onClick={() => changeStepHandler(activeStep + 1)}>
						Начать
					</Button>
				)}
				{!isFirstStep && (
					<StyledButtonsGroupAutoSize>
						<Button appearance='transparent' onClick={() => changeStepHandler(activeStep - 1)}>
							Назад
						</Button>
						<Button
							appearance='filled'
							onClick={() => {
								if (isLastStep) return onClose();
								changeStepHandler(activeStep + 1);
							}}
						>
							{isLastStep ? 'Завершить' : 'Далее'}
						</Button>
					</StyledButtonsGroupAutoSize>
				)}

				<StyledOnboardingStepsNavigation>
					{Array.from({ length: stepsCount }).map((_, index) => (
						<StyledOnboardingNavigationItem key={`nav-${index}`} onClick={() => changeStepHandler(index + 1)} isActive={activeStep === index + 1} />
					))}
				</StyledOnboardingStepsNavigation>
			</StyledOnboardingEventsWrapper>
		</Modal>
	);
};

export default React.memo(OnboardingUi);
