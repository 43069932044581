import { v4 } from 'uuid';
import dayjs from 'dayjs';

export const FORMAT_DATE = 'DD.MM.YYYY';

export const getDecimalDigits = (value: number): number => {
	const decimals = value.toString().split('.')[1];
	if (!decimals) return 0;

	return decimals.length;
};

export const getValueByStep = (value: number, step: number): number => {
	if (!step) return value;

	const steps = Math.floor(value / step);
	const diff = value - step * steps;

	const result = diff / step >= 0.5 ? step * (steps + 1) : step * steps;

	return +result.toFixed(getDecimalDigits(step));
};

export const stringToNumber = (value: string | number | undefined | null): string => {
	const isNegative: boolean = String(value)[0] === '-';
	const number: string = String(
		String(value)
			.replace(',', '.')
			.replace(/\s+/g, '')
			.trim()
			.replace(',', '.')
			.replace(/[^\d.]/g, '')
			.replace(/\./, 'x')
			.replace(/\./g, '')
			.replace(/x/, '.')
	);
	return Number.isNaN(Number(number)) ? '0' : String(isNegative ? `-${number}` : number);
};

export const stringToNumberDigits = (value: string | number | undefined | null, digitsAfterDot: number): string => {
	const isNegative: boolean = String(value)[0] === '-';
	const number: string = String(
		String(value)
			.replace(',', '.')
			.replace(/\s+/g, '')
			.trim()
			.replace(',', '.')
			.replace(/[^\d.]/g, '')
			.replace(/\./, 'x')
			.replace(/\./g, '')
			.replace(/x/, '.')
	);
	const checkDigit = ((number.split('.') || [])[1] || '').length > digitsAfterDot ? number.substring(0, number.length - 1) : number;
	return Number.isNaN(Number(checkDigit)) ? '0' : String(isNegative ? `-${checkDigit}` : checkDigit);
};

export const stringToMoney = (value: string | number | undefined | null, decimalCount: number = 2, decimal: string = '.', thousands: string = ' '): string => {
	const valueToNumber: number = Number(stringToNumberDigits(value, decimalCount));
	const negativeSign: string = valueToNumber < 0 ? '-' : '';

	const valueToFixed: string = String(valueToNumber.toFixed(decimalCount));
	const thousandsCount: number = valueToFixed.length > 3 ? valueToFixed.length % 3 : 0;
	const valueNumberToAbs: number = parseInt(String(Math.abs(Number(valueToFixed) || 0)), 10);
	const valueNumberToAbsToString: string = String(valueNumberToAbs);

	if (Number(valueToFixed) === 0) return Number(0).toFixed(decimalCount);

	return `${`${negativeSign}${thousandsCount ? `${valueNumberToAbsToString.substring(0, thousandsCount)}${thousands}` : ''}${valueNumberToAbsToString.substring(
		thousandsCount
	)}`.replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
		decimalCount
			? `${decimal}${Math.abs(Number(valueToFixed) - valueNumberToAbs)
					.toFixed(decimalCount)
					.slice(2)}`
			: ''
	}`;
};

export const uuidv4 = (): string => v4();

export const formatDayJsToString = (value: dayjs.Dayjs, formatString?: string): string | undefined => {
	if (!value) return;
	return value.format(formatString || FORMAT_DATE);
};
