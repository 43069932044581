import React from 'react';
import { useAgreementChangeLogContext } from './context';
import Table from '../../../../libs/uiKit/table';
import { Modal } from '../../../../libs/uiKit/modal';
import { StyledHistoryWrapper } from './styles';

const AgreementChangeLogUi: React.FC = () => {
	const { head, rows, isLoading, isOpen, onClose } = useAgreementChangeLogContext();

	return (
		<Modal width={130} onClose={onClose} isOpen={isOpen} header='История изменений' isLoading={isLoading}>
			<StyledHistoryWrapper>
				<Table head={head} rows={rows} />
			</StyledHistoryWrapper>
		</Modal>
	);
};

export default React.memo(AgreementChangeLogUi);
