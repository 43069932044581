import React, {useCallback, useState} from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import {AgreementId} from "../../../../ui/context";
import Button from "../../../../../../../libs/uiKit/button";
import {DownloadIcon} from "../../../../../../../icons/ui/Download";
import Tabs from "../../../../../../../libs/uiKit/tabs";
import {ITabItem} from "../../../../../../../libs/uiKit/tabs/types";
import {ContragentField, ContragentId} from "../../../contragent/ui/context";
import {
    ContragentFillingTypeEnum,
    PrintTemplateEnum,
    PrintTypeEnum,
    SendAnketaSmsMutation,
    SendAnketaSmsMutationVariables,
    SignAnketaMutation,
    SignAnketaMutationVariables,
    UpdateContragentFillingMutation,
    UpdateContragentFillingMutationVariables
} from "../../../../../../../libs/api/graphqlTypes";
import {SEND_SMS, SIGN_ANKETA, UPDATE_CONTRAGENT_FILLING} from "../../../../../../../libs/api/commands";
import useNotification from "../../../../../../widgets/notifier/ui/hooks/useNitification";
import {usePrintMutation} from "../../../../../../../libs/api/hooks/usePrintMutation";
import UploadFilling from "../uploadFilling";
import SendSms, {SendSmsForm} from "../sendSms";
import {AGREEMENT_DRAFT} from "../../../../../../../libs/api/queries";
import {StyledDeclarationInfoText} from "./styles";

type DeclarationFillingProps = {
    agreementId: AgreementId;
    personId: ContragentId;
    successFillingCallback: () => void;
    defaultValue?: Omit<SendSmsForm, 'smsCode'>;
    agreementMode: boolean|undefined;
    showAnketa: boolean;
    showDeclaration: boolean;
    onlyBySms?: boolean;
    person?: ContragentField;
}

const DeclarationFilling: React.FC<DeclarationFillingProps> = props => {
    const formHook = useForm<SendSmsForm>({mode: "onBlur"});
    const {agreementId, personId, agreementMode, showAnketa, showDeclaration, onlyBySms, person} = props;

    const VARIANT_TABS = [
        {label: `Анкета`, value: 'anketa'},
        {label: 'Декларация', value: 'decl'}
    ];

    const { setNotification } = useNotification();
    const [variantStateTab, setVariantStateTab] = useState<ITabItem | undefined>(VARIANT_TABS[0]);
    const [declSigned, setDeclSigned] = useState<boolean>(false);
    const [signAnketa, { data: dataSignAnketa, loading: loadingSignAnketa }] = useMutation<SignAnketaMutation, SignAnketaMutationVariables>(SIGN_ANKETA);
    const [getCode, { loading: loadingGetCode }] = useMutation<SendAnketaSmsMutation, SendAnketaSmsMutationVariables>(SEND_SMS);
    const [updateContragentFilling, { loading: updateContragentLoading }] = useMutation<UpdateContragentFillingMutation, UpdateContragentFillingMutationVariables>(UPDATE_CONTRAGENT_FILLING, {
        refetchQueries: [
            {
                query: AGREEMENT_DRAFT,
                variables: {
                    id: agreementId
                },
            },
        ],
        awaitRefetchQueries: true,
    });

    const isDeclarationMode = (showAnketa && showDeclaration)
      ? (variantStateTab?.value === 'decl')
      : showDeclaration;

    const subjectName = isDeclarationMode ? 'декларацию' : 'анкету';

    const ACTION_TABS = [
        {label: `Загрузить ${subjectName}`, value: 'declUpload'},
        {label: 'Подписать по смс', value: 'declSms'}
    ];
    const [actionStateTab, setActionStateTab] = useState<ITabItem | undefined>(ACTION_TABS[0]);

    const {doPrint, isPrintLoading} = usePrintMutation();

    const codeEnteredHandler = useCallback((code: string) => {
        if (!agreementId || !personId || loadingSignAnketa) return;
        //formHook.setValue('smsCode', '');
        signAnketa({
            variables: {
                id: agreementId,
                contragentIsn: personId,
                code,
                isDeclaration: isDeclarationMode,
            },
        })
            .then(() => {
                setDeclSigned(true);
                setNotification({ type: 'success', text: isDeclarationMode ? 'Декларация успешно подписана' : 'Анкета успешно подписана' });
            })
            .catch(error => {
                setNotification({ type: 'error', text: error });
            });
    }, [agreementId, personId]);

    const getCodeHandler = (afterSend: () => void) => {
        if (!agreementId || !personId) return;
        getCode({
            variables: {
                id: agreementId,
                contragentIsn: personId,
                isDeclaration: isDeclarationMode,
            },
        })
            .then(() => {
                setNotification({type: 'success', text: 'Код успешно отправлен'});
                afterSend();
            })
            .catch(error => setNotification({ type: 'error', text: error }));
    }

    const downloadDeclaration = () => {
        if(!agreementId) return;
        doPrint(
          agreementId,
          PrintTypeEnum.B2B,
          isDeclarationMode ? PrintTemplateEnum.Declaration : PrintTemplateEnum.Anketa,
          undefined,
          personId
        );
    }

    const saveDeclFilling = () => {
        if (!agreementId || !personId) return;
        updateContragentFilling({
            variables: {
                id: agreementId,
                contragentIsn: personId,
                fillingType: isDeclarationMode ? ContragentFillingTypeEnum.Declaration : ContragentFillingTypeEnum.Send
            }
        }).then(() => {
            props.successFillingCallback();
        })
    }

    return <>
        {showAnketa && showDeclaration &&
            <Tabs items={VARIANT_TABS} value={variantStateTab} onChange={(tab) => setVariantStateTab(tab)}/>
        }
        {(!isDeclarationMode || agreementMode) && <>
            <div style={{margin: '15px 0'}}>
                <Button
                  appearance="filled"
                  iconBefore={<DownloadIcon/>}
                  isLoading={isPrintLoading}
                  onClick={downloadDeclaration}>
                    Скачать {subjectName}
                </Button>
            </div>
            {!onlyBySms &&
                <Tabs items={ACTION_TABS} value={actionStateTab} onChange={(tab) => setActionStateTab(tab)}/>
            }
            {actionStateTab?.value === 'declUpload' && !onlyBySms &&
              <UploadFilling
                agreementId={props.agreementId}
                personId={props.personId|| null}
                successFillingCallback={props.successFillingCallback}
                fillingType={isDeclarationMode? "declaration" : "upload"}
                agreementMode={agreementMode || false}
                person={person}
              />
            }
            {(actionStateTab?.value === 'declSms' || onlyBySms) &&
              <>
                  <SendSms isLoading={loadingSignAnketa || loadingGetCode} isDisabled={loadingSignAnketa} codeEnteredHandler={codeEnteredHandler} getCodeHandler={getCodeHandler} defaultValue={props.defaultValue || {sendSmsPhone: ''}} agreementMode={agreementMode} />
                  <Button isDisabled={!declSigned} isLoading={updateContragentLoading} appearance='filled' style={{marginTop: '15px'}} onClick={() => saveDeclFilling() }>
                      Сохранить
                  </Button>
              </>
            }
        </>}
        {isDeclarationMode && !agreementMode && <>
            <StyledDeclarationInfoText>
                Декларация будет доступна на форме договора для просмотра и подписания
            </StyledDeclarationInfoText>
            <Button isLoading={updateContragentLoading} appearance='filled' style={{marginTop: '15px'}} onClick={() => saveDeclFilling() }>
                Сохранить
            </Button>
        </>}

    </>
}

export default DeclarationFilling;