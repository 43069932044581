import React from "react";
import {DocTypeEnum} from "../../../libs/api/graphqlTypes";
import DocsLog from "./DocsLog";
import {StyledAgreementsLogHeader} from "../agreementsLog/ui/styles";
import {Heading2,Heading3} from "../../../libs/uiKit/globalStyledComponents";
import MainLayoutIgniter from "../common/mainLayout";

const UnderwriterLogPage: React.FC = () => (
  <MainLayoutIgniter>
    <StyledAgreementsLogHeader>
      <Heading2>Журнал согласований</Heading2>
    </StyledAgreementsLogHeader>
    <Heading3>Назначены мне</Heading3>
    <DocsLog type={DocTypeEnum.AssignedToMe}/>
    <Heading3>Общие</Heading3>
    <DocsLog type={DocTypeEnum.Underwriting}/>
  </MainLayoutIgniter>
)
export default UnderwriterLogPage;