import React from 'react';
import { useContragentContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const ContragentUi: React.FC = () => {
	const { form } = useContragentContext();

	return <FieldsBuilder {...form} />;
};

export default React.memo(ContragentUi);
