import React from 'react';
import { Heading1 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { useRecoveryPasswordContext } from './context';
import { StyledPasswordRecoveryForm } from './styles';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';

const RecoveryPasswordUi: React.FC = () => {
	const { form, recoveryHandler, isLoadingRecovery } = useRecoveryPasswordContext();

	return (
		<StyledPasswordRecoveryForm>
			<Heading1>Восстановление пароля</Heading1>
			<form onSubmit={recoveryHandler}>
				<FieldsBuilder {...form} />
				<Button type='submit' appearance='filled' isLoading={isLoadingRecovery}>
					Получить пароль
				</Button>
			</form>
		</StyledPasswordRecoveryForm>
	);
};

export default React.memo(RecoveryPasswordUi);
