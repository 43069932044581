import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../../../libs/uiKit/fieldsBuilder/types';
import { AgreementId } from '../../../../../../ui/context';
import { ContragentId } from '../../../../../contragent/ui/context';
import useNotification from '../../../../../../../../widgets/notifier/ui/hooks/useNitification';
import { QueriesCreateAgreement } from '../../../../../../ds/repositories/queries';
import { CommandsCreateAgreement } from '../../../../../../ds/repositories/commands';
import { useGlobalContext } from '../../../../../../../../../apps/main/ui/GlobalContext';

type SendFillingContext = {
	form: IForm;
	isLoading?: boolean;
	saveSendHandler: () => void;
};

export type SendFillingContextProps = {
	agreementId: AgreementId;
	personId: ContragentId;
	children?: React.ReactNode;
	defaultValue?: SendFillingForm;
	successFillingCallback: () => void;
};

export type SendFillingForm = {
	phone: string;
};

const SendFillingContext = createContext<SendFillingContext>({} as SendFillingContext);

export const useSendFillingContext = (): SendFillingContext => useContext(SendFillingContext);

const SendFillingContextProvider: React.FC<SendFillingContextProps> = props => {
	const { children, defaultValue, successFillingCallback, personId, agreementId } = props;
	const { api } = useGlobalContext();
	const formHook = useFormContext<SendFillingForm>();
	const { setNotification } = useNotification();
	const queriesCreateAgreements = React.useMemo(() => QueriesCreateAgreement(api), [api]);
	const commandsCreateAgreements = React.useMemo(() => CommandsCreateAgreement(api, queriesCreateAgreements), [api, queriesCreateAgreements]);

	const [stateLoadingAnketa, setLoadingAnketa] = React.useState<boolean>(false);

	const saveSendHandler = React.useCallback(() => {
		if (!personId) return setNotification({ type: 'error', text: 'no person id' });
		setLoadingAnketa(true);
		commandsCreateAgreements.sendFilling({
			id: agreementId,
			personId,
			fillingType: 'send',
			successCallback: () => {
				setLoadingAnketa(false);
				successFillingCallback();
			},
			errorCallback: error => {
				setNotification({ type: 'error', text: error });
				setLoadingAnketa(false);
			},
		});
	}, [agreementId, commandsCreateAgreements, personId, setNotification, successFillingCallback]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'inputMask',
						isRequired: true,
						errorMessage: 'Введите телефон',
						mask: '+7 (999) 999-99-99',
						defaultValue: defaultValue?.phone,
						fieldName: 'sendPhone',
						placeholder: 'Телефон',
					},
				},
			],
		}),
		[defaultValue?.phone, formHook]
	);

	const value: SendFillingContext = React.useMemo(
		() => ({
			form,
			isLoading: stateLoadingAnketa,
			saveSendHandler,
		}),
		[form, saveSendHandler, stateLoadingAnketa]
	);

	return <SendFillingContext.Provider value={value}>{children}</SendFillingContext.Provider>;
};

export default React.memo(SendFillingContextProvider);
