import React from 'react';
import CreateRoleUi from './ui';
import { CreateRoleContextProvider } from './ui/context';
import { IModalProps } from '../../../../../libs/uiKit/modal/types';

const CreateRoleIgniter: React.FC<IModalProps> = props => (
	<CreateRoleContextProvider {...props}>
		<CreateRoleUi />
	</CreateRoleContextProvider>
);

export default React.memo(CreateRoleIgniter);
