import { FieldErrors } from 'react-hook-form';

const getFieldError = (fieldName: string, errors: FieldErrors): string | undefined => {
	const isNotArray: boolean = !fieldName.includes('.');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
	const notArrayError: string | undefined = errors[fieldName]?.message;
	if (isNotArray) return typeof notArrayError === 'string' ? notArrayError : undefined;
	const [, name]: string[] = fieldName.split('.');
	const [arrayName, indexPart]: string[] = fieldName.split('[');
	const fieldIndex: number = Number(indexPart[0]);
	if (!name || !arrayName || Number.isNaN(fieldIndex)) return undefined;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
	const arrayError: string | undefined = ((errors[arrayName] || [])[fieldIndex] || {})[name]?.message;
	return typeof arrayError === 'string' ? arrayError : undefined;
};

export default getFieldError;
