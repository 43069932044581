export const fileDownloader = (url: string | undefined | null, name: string | undefined | null) => {
	if (!url) return;
	if (!name) return window.open(url);

	console.log('download file', url, name);

	const link = document.createElement('a');
	link.setAttribute('download', name);
	link.setAttribute('href', url);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
