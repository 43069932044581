import styled from 'styled-components';

export const StyledSkeletonTdItem = styled.td`
	padding: 10px;
`;

export const StyledSkeletonTdEmptyItem = styled.div`
	height: 3rem;
	background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
	background-size: 600px;
	animation: shine-lines-table 1.6s infinite linear;
	min-width: 15px;

	@keyframes shine-lines-table {
		0% {
			opacity: 0.4;
		}
		40% {
			opacity: 0.8;
		}
		100% {
			opacity: 0.4;
		}
	}
`;

export const StyledSkeletonTrItem = styled.tr`
	padding: 1rem;
	& > td {
		border: 0;
	}
`;
