import React, { MouseEvent } from 'react';
import { TextButton } from '../globalStyles';
import { StyledFilledButton, StyledTransparentButton, StyledLinkButton, StyledTextButton, StyledIconButton, StyledFlagButton, StyledButtonLoading } from './styles';
import { LoaderIcon } from '../icons/Loader';
import { IButtonProps, TypeButtonRef } from './types';
import Tooltip from '../tooltip';

const Button: React.FC<IButtonProps> = React.forwardRef((props, ref: TypeButtonRef) => {
	const { appearance, status = 'default', isLoading, type, isSelected, onClick, tooltip, iconAfter, icon, iconBefore, children, isDisabled, ...rest } = props;

	const loader = isLoading ? (
		<StyledButtonLoading>
			<LoaderIcon />
		</StyledButtonLoading>
	) : null;

	const buttonChildren = (
		<>
			{iconBefore ? <span className='iconBefore'>{iconBefore}</span> : null}
			<TextButton>{children}</TextButton>
			<Tooltip tooltip={tooltip} />
			{iconAfter ? <span className='iconAfter'>{iconAfter}</span> : null}
		</>
	);

	const clickHandler = React.useCallback(
		(ev: MouseEvent<HTMLButtonElement>): void => {
			if (isDisabled) ev.preventDefault();
			if (onClick && !isDisabled) onClick(ev);
		},
		[isDisabled, onClick]
	);

	const buttonProps = {
		...rest,
		ref,
		type: type || 'button',
		status,
		isLoading: !!isLoading,
		disabled: !!isDisabled || !!isLoading,
		className: `${appearance}Button`,
		onClick: clickHandler,
	};

	switch (appearance) {
		case 'filled':
			return (
				<StyledFilledButton {...buttonProps}>
					{loader}
					{buttonChildren}
				</StyledFilledButton>
			);
		case 'transparent':
			return (
				<StyledTransparentButton {...buttonProps}>
					{loader}
					{buttonChildren}
				</StyledTransparentButton>
			);
		case 'link':
			return (
				<StyledLinkButton {...buttonProps}>
					{loader}
					{buttonChildren}
				</StyledLinkButton>
			);
		case 'text':
			return (
				<StyledTextButton {...buttonProps}>
					{loader}
					{buttonChildren}
				</StyledTextButton>
			);
		case 'icon':
			return (
				<StyledIconButton {...buttonProps}>
					{loader}
					<Tooltip tooltip={tooltip} />
					<span className='buttonIcon'>{icon}</span>
				</StyledIconButton>
			);
		case 'flag':
			return (
				<StyledFlagButton {...buttonProps} isSelected={!!isSelected}>
					{loader}
					<Tooltip tooltip={tooltip} />
					<span className='buttonIcon'>{icon}</span>
				</StyledFlagButton>
			);
		default:
			return null;
	}
});

export default React.memo(Button);
