import React, {createContext, useContext} from 'react';
import {useFormContext} from 'react-hook-form';
import {useLazyQuery, useMutation} from '@apollo/client';
import {IForm, IFormField} from '../../../../../../../../../libs/uiKit/fieldsBuilder/types';
import {ANKETA} from '../../../../../../../../../libs/api/queries';
import {
	AnketaQuery,
	AnketaQueryVariables,
	PrintTemplateEnum,
	PrintTypeEnum,
	SendAnketaSmsMutation,
	SendAnketaSmsMutationVariables,
	SignAnketaMutation,
	SignAnketaMutationVariables,
} from '../../../../../../../../../libs/api/graphqlTypes';
import {useGlobalContext} from '../../../../../../../../../apps/main/ui/GlobalContext';
import {
	HIDDEN_ALCOHOL,
	HIDDEN_EXTREME,
	HIDDEN_SMOKE,
	HIDDEN_SPORT,
	HIDDEN_WEIGHT,
	ISN_ALCOHOL,
	ISN_DISEASES,
	ISN_EXTREME,
	ISN_HOSPITAL,
	ISN_POLICE,
	ISN_SMOKE,
	ISN_SPORT,
	ISN_SUGAR,
	ISN_WEIGHT,
	RADIO_BOOLEAN,
	REQUIRED_ANKETA_FIELDS,
} from '../../../../../../../../../libs/utils/staticData';
import {ISelectItem} from '../../../../../../../../../libs/uiKit/select/types';
import {AgreementId, CreateAgreementForm} from '../../../../../../ui/context';
import {ContragentId} from '../../../../../contragent/ui/context';
import {getSelectDefaultValue} from '../../../../../../../../../libs/utils/getSelectdefaultValue';
import {QueriesCreateAgreement} from '../../../../../../ds/repositories/queries';
import {CommandsCreateAgreement} from '../../../../../../ds/repositories/commands';
import {SEND_SMS, SIGN_ANKETA} from '../../../../../../../../../libs/api/commands';
import useNotification from '../../../../../../../../widgets/notifier/ui/hooks/useNitification';
import {usePrintMutation} from "../../../../../../../../../libs/api/hooks/usePrintMutation";
import {SendSmsProps} from "../../../sendSms";

type SaveAnketaType = 'download' | 'code';

type InsuranceFormItem = {
	title: string;
	form: IForm;
};

type OnlineFillingContext = {
	forms: InsuranceFormItem[];
	isFull: boolean;
	downloadAnketaHandler: () => void;
	isDisabledSuccessFilled: boolean;
	successFillingCallback: () => void;
} & SendSmsProps;

export type OnlineFillingContextProps = {
	agreementId: AgreementId;
	personId: ContragentId;
	isAlreadySigned?: boolean;
	isFull: boolean;
	successFillingCallback: () => void;
} & Pick<SendSmsProps, 'defaultValue'>;

type HealthFormType = {
	[key: string]: string | number | ISelectItem;
};
export type OnlineFillingForm = CreateAgreementForm & HealthFormType;

const OnlineFillingContext = createContext<OnlineFillingContext>({} as OnlineFillingContext);

export const useOnlineFillingContext = (): OnlineFillingContext => useContext(OnlineFillingContext);

const getRemarkPlaceholder = (name: string) => {
	switch (name) {
		case ISN_HOSPITAL:
			return 'Укажите сколько раз, по поводу какого заболевания';
		case ISN_SUGAR:
			return 'Укажите свой уровень глюкозы в крови';
		default:
			if (ISN_DISEASES.includes(name)) return 'Укажите даты, диагнозы, симптомы, жалобы, опишите подробности';
			return 'Укажите подробности';
	}
};

const OnlineFillingContextProvider: React.FC<OnlineFillingContextProps> = props => {
	const { children, agreementId, personId, isAlreadySigned, isFull, successFillingCallback, defaultValue } = props;
	const {
		api,
		routes: { error500 },
	} = useGlobalContext();
	const formHook = useFormContext<OnlineFillingForm>();
	const { setNotification } = useNotification();
	const queriesCreateAgreements = React.useMemo(() => QueriesCreateAgreement(api), [api]);
	const commandsCreateAgreements = React.useMemo(() => CommandsCreateAgreement(api, queriesCreateAgreements), [api, queriesCreateAgreements]);

	const [stateRemarks, setRemarks] = React.useState<string[]>([]);
	const [stateLoadingAnketa, setLoadingAnketa] = React.useState<boolean>(false);
	const [getCode, { loading: loadingGetCode }] = useMutation<SendAnketaSmsMutation, SendAnketaSmsMutationVariables>(SEND_SMS);
	const [signAnketa, { data: dataSignAnketa, loading: loadingSignAnketa }] = useMutation<SignAnketaMutation, SignAnketaMutationVariables>(SIGN_ANKETA);

	const [getAnketaQuestions, { data: dataAnketaQuestions }] = useLazyQuery<AnketaQuery, AnketaQueryVariables>(ANKETA, { onError: error => error500(error) });

	React.useEffect(() => {
		if (!agreementId || !personId) return;
		getAnketaQuestions({ variables: { id: agreementId, contragentIsn: personId } });
	}, [agreementId, personId, getAnketaQuestions]);

	const isSigned: boolean = isAlreadySigned || dataSignAnketa?.signAnketa === 'ok';
	const [isnWeightValue, isnSmokeValue, isnAlcoholValue, isnSportValue, isnExtremeValue] =
		formHook.watch([ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME]);
	const isWeight: boolean = isnWeightValue === 'true';
	const isSmoke: boolean = isnSmokeValue === 'true';
	const isSport: boolean = isnSportValue === 'true';
	const isExtreme: boolean = isnExtremeValue === 'true';

	const {doPrint, isPrintLoading} = usePrintMutation();

	const isAlcohol: boolean = isnAlcoholValue === 'true';

	const downloadAnketaHandler = (anketaIsn: number) => {
		doPrint(anketaIsn, PrintTypeEnum.Document, PrintTemplateEnum.Anketa);
	}

	const getCodeHandler = React.useCallback((afterSend?: () => void) => {
		if (!agreementId || !personId) return setNotification({ type: 'error', text: 'no person id or agreement Id' });
		getCode({
			variables: {
				id: agreementId,
				contragentIsn: personId,
			},
		})
			.then(() => {
				setNotification({type: 'success', text: 'Код успешно отправлен'});
				if(afterSend !== undefined){
					afterSend();
				}
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [agreementId, getCode, personId, setNotification]);

	const codeEnteredHandler = React.useCallback(
		(code: string) => {
			if (!agreementId || !personId) return setNotification({ type: 'error', text: 'no person id or agreement Id' });
			signAnketa({
				variables: {
					id: agreementId,
					contragentIsn: personId,
					code,
				},
			})
				.then(() => setNotification({ type: 'success', text: 'Анкета успешно подписана' }))
				.catch(error => setNotification({ type: 'error', text: error }));
		},
		[agreementId, personId, setNotification, signAnketa]
	);

	const saveAnketa = React.useCallback(
		async (type: SaveAnketaType, afterSend?: () => void) => {
			debugger;
			const result = await formHook.trigger();
			if (!result) return;
			const currentFormData: OnlineFillingForm = formHook.getValues() as OnlineFillingForm;
			if (!personId) return setNotification({ type: 'error', text: 'no person id' });
			setLoadingAnketa(true);
			commandsCreateAgreements.onlineFilling({
				id: agreementId,
				isFull,
				personId,
				fillingType: 'online',
				currentFormData,
				successCallback: anketaIsn => {
					setLoadingAnketa(false);
					if (type === 'download') return downloadAnketaHandler(anketaIsn);
					if (type === 'code') {
						return getCodeHandler(afterSend);
					}
				},
				errorCallback: error => {
					setNotification({ type: 'error', text: error });
					setLoadingAnketa(false);
				},
			});
		},
		[agreementId, commandsCreateAgreements, downloadAnketaHandler, formHook, getCodeHandler, isFull, personId, setNotification]
	);

	const checkIsHidden = (name: string, isRadio: boolean): boolean => {
		if (HIDDEN_WEIGHT.includes(name)) return !isWeight;
		if (HIDDEN_SMOKE.includes(name)) return !isSmoke;
		if (HIDDEN_ALCOHOL.includes(name)) return !isAlcohol;
		if (HIDDEN_SPORT.includes(name)) return !isSport;
		if (HIDDEN_EXTREME.includes(name)) return !isExtreme;
		if (![ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(name) && isRadio) return !stateRemarks.includes(name);
		return false;
	};

	const forms: InsuranceFormItem[] = (dataAnketaQuestions?.anketa || []).map(part => ({
		title: part?.text || '',
		form: {
			isDisabled: isSigned,
			formHook,
			fields: (part?.questions || []).reduce<IFormField[]>((prev, question) => {
				if (!question) return prev;
				const { isn, type, text, dicti, value, remark } = question;
				const fieldName = `question-${isn}`;
				const placeholder = text || undefined;
				const items: ISelectItem[] = (dicti || []).map(dict => ({ label: dict?.name || 'unknown', value: dict?.isn || 'unknown' }));
				const isValidate: boolean = REQUIRED_ANKETA_FIELDS.includes(fieldName);

				if (type === 'INTEGER')
					return [
						...prev,
						{
							isHidden: checkIsHidden(fieldName, false),
							field: {
								fieldType: 'number',
								fieldName,
								placeholder,
								defaultValue: value || undefined,
								...(isValidate
									? {
											validationType: 'minMax',
											minValue: 1,
											maxValue: 999,
											isRequired: true,
									  }
									: {}),
							},
						},
					];

				if (type === 'CHECKBOX' && [ISN_ALCOHOL].includes(fieldName))
					return [
						...prev,
						{
							isHidden: checkIsHidden(fieldName, true),
							label: placeholder,
							field: {
								fieldType: 'radio',
								items: RADIO_BOOLEAN,
								defaultValue: isAlcohol ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
								fieldName,
							},
						},
					];


				if (type === 'CHECKBOX' && [ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(fieldName))
					return [
						...prev,
						{
							isHidden: checkIsHidden(fieldName, true),
							label: placeholder,
							field: {
								fieldType: 'radio',
								items: RADIO_BOOLEAN,
								defaultValue: value === '1' ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
								fieldName,
							},
						},
					];

				if (type === 'CHECKBOX' && ![ISN_WEIGHT, ISN_SMOKE, ISN_ALCOHOL, ISN_SPORT, ISN_EXTREME, ISN_POLICE].includes(fieldName))
					return [
						...prev,
						{
							label: placeholder,
							field: {
								fieldType: 'radio',
								items: RADIO_BOOLEAN,
								defaultValue: value === '1' ? RADIO_BOOLEAN[0].value : RADIO_BOOLEAN[1].value,
								onChange: ev => setRemarks(state => (ev.target.value === 'true' ? [...state, fieldName] : state.filter(name => name !== fieldName))),
								fieldName,
							},
						},
						{
							isHidden: checkIsHidden(fieldName, true),
							field: {
								fieldType: 'input',
								fieldName: `${fieldName}-remark`,
								defaultValue: remark || undefined,
								placeholder: getRemarkPlaceholder(fieldName),
							},
						},
					];

				if (type === 'TEXT')
					return [
						...prev,
						{
							isHidden: checkIsHidden(fieldName, false),
							field: {
								fieldType: 'input',
								fieldName,
								defaultValue: value || undefined,
								placeholder,
							},
						},
					];
				if (type === 'SQL')
					return [
						...prev,
						{
							isHidden: checkIsHidden(fieldName, false),
							field: {
								fieldType: 'select',
								fieldName,
								defaultValue: getSelectDefaultValue(value, items),
								placeholder,
								items,
								...(isValidate
									? {
										isRequired: true,
									}
									: {}),
							},
						},
					];

				return prev;
			}, []),
		},
	}));

	const value: OnlineFillingContext = React.useMemo(
		() => ({
			forms,
			isFull,
			downloadAnketaHandler: () => saveAnketa('download'),
			getCodeHandler: (afterSend: () => void) => saveAnketa('code', afterSend),
			isLoading: isPrintLoading || loadingSignAnketa || loadingGetCode || stateLoadingAnketa,
			isDisabled: isSigned,
			isDisabledSuccessFilled: !isSigned,
			successFillingCallback,
			codeEnteredHandler,
			defaultValue,
			agreementMode: false
		}),
		[codeEnteredHandler, defaultValue, forms, isFull, isSigned, isPrintLoading, loadingGetCode, loadingSignAnketa, saveAnketa, stateLoadingAnketa, successFillingCallback]
	);

	return <OnlineFillingContext.Provider value={value}>{children}</OnlineFillingContext.Provider>;
};

export default React.memo(OnlineFillingContextProvider);
