import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../notifier/ui/hooks/useNitification';
import { LOAD_MESSAGES } from '../../../../../libs/api/queries';
import {
	CommentMutation,
	CommentMutationVariables,
	LoadMessagesQuery,
	LoadMessagesQueryVariables,
	ViewCommentMutation,
	ViewCommentMutationVariables,
} from '../../../../../libs/api/graphqlTypes';
import { SAVE_MESSAGE, VIEW_MESSAGE } from '../../../../../libs/api/commands';

type Message = {
	id: number;
	user: string;
	date: string;
	text: string;
	isNew: boolean;
};

type DiscussionContext = {
	form: IForm;
	onFormSubmit: () => void;
	isLoading: boolean;
	isLoadingAccordion: boolean;
	isShowAddMessage: boolean;
	switchAddMessage: (isOpen: boolean) => void;
	messages: Message[];
	newItemsCount: number;
	isOpenAccordion: boolean;
	switchIsOpenAccordion: (isOpen: boolean) => void;
};

export type DiscussionProps = { id: number | null; isOpenDefault?: boolean };

const DiscussionContext = createContext<DiscussionContext>({} as DiscussionContext);

type DiscussionForm = {
	message: string;
};

export const useDiscussionContext = (): DiscussionContext => useContext(DiscussionContext);

export const DiscussionContextProvider: React.FC<DiscussionProps> = props => {
	const { children, id, isOpenDefault } = props;
	const { setNotification } = useNotification();
	const formHook = useForm<DiscussionForm>({
		mode: 'onBlur',
	});

	const [stateIsShowAddMessage, setIsShowAddMessage] = React.useState<boolean>(false);
	const [stateIsOpen, setIsOpen] = React.useState<boolean>(isOpenDefault || false);

	const [getMessages, { data: dataMessages, loading: loadingMessages }] = useLazyQuery<LoadMessagesQuery, LoadMessagesQueryVariables>(LOAD_MESSAGES, {
		onError: error => setNotification({ type: 'error', text: error }),
	});

	const [saveMessage, { loading: loadingSaveMessage }] = useMutation<CommentMutation, CommentMutationVariables>(SAVE_MESSAGE, { refetchQueries: ['loadMessages'] });
	const [viewMessage] = useMutation<ViewCommentMutation, ViewCommentMutationVariables>(VIEW_MESSAGE, { refetchQueries: ['loadMessages'] });

	React.useEffect(() => {
		if (!stateIsOpen || !id) return;
		getMessages({
			variables: {
				id,
			},
		});
	}, [getMessages, id, stateIsOpen]);

	const messages: Message[] = (dataMessages?.loadAgreement?.comments || []).map(message => ({
		id: message?.id || 0,
		user: message?.user?.full_name || 'неизвестно',
		date: message?.date || 'неизвестно',
		text: message?.text || 'неизвестно',
		isNew: !!message?.isNew,
	}));

	const newItems: Message[] = messages.filter(({ isNew }) => isNew);
	const newItemsCount: number = newItems.length;

	const switchAddMessage = React.useCallback((isOpen: boolean) => setIsShowAddMessage(isOpen), []);
	const switchIsOpenAccordion = React.useCallback(
		(isOpen: boolean) => {
			setIsOpen(isOpen);
			if (!isOpen || newItemsCount === 0) return;
			viewMessage({
				variables: {
					id: newItems.map(({ id: messageId }) => messageId),
				},
			}).catch(error => setNotification({ type: 'error', text: error }));
		},
		[newItems, newItemsCount, setNotification, viewMessage]
	);

	const onFormSubmit = formHook.handleSubmit(data => {
		debugger;
		if (!id) return setNotification({ type: 'error', text: 'no agreement id' });
		saveMessage({
			variables: {
				id,
				text: data.message,
			},
		})
			.then(() => {
				formHook.setValue('message', '');
				setNotification({ type: 'success', text: 'Сообщение успешно добавлено' });
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'textarea',
						fieldName: 'message',
						placeholder: 'Ваш комментарий',
						isRequired: true,
						errorMessage: 'Введите комментарий',
					},
				},
			],
		}),
		[formHook]
	);

	const value: DiscussionContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			newItemsCount,
			isLoading: loadingSaveMessage,
			isLoadingAccordion: loadingMessages,
			messages,
			switchAddMessage,
			isShowAddMessage: stateIsShowAddMessage,
			switchIsOpenAccordion,
			isOpenAccordion: stateIsOpen,
		}),
		[form, loadingMessages, loadingSaveMessage, messages, newItemsCount, onFormSubmit, stateIsOpen, stateIsShowAddMessage, switchAddMessage, switchIsOpenAccordion]
	);

	return <DiscussionContext.Provider value={value}>{children}</DiscussionContext.Provider>;
};
