import React from 'react';
import Inputmask from 'inputmask';
import { IInputMaskProps } from './types';
import Input from '../input';

const MaskInput: React.FC<IInputMaskProps> = React.forwardRef((props, ref) => {
	const { mask, ...rest } = props;

	const setupRefs = React.useCallback(
		(node: HTMLInputElement | null) => {
			if (node) Inputmask({ mask, showMaskOnHover: false }).mask(node);
			if (!ref) return;
			if (typeof ref === 'function') {
				ref(node);
			} else {
				ref.current = node;
			}
		},
		[mask, ref]
	);

	return <Input ref={setupRefs} {...rest} />;
});

export default React.memo(MaskInput);
