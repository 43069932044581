import React from 'react';
import { useController } from 'react-hook-form';
import { IEnumsFieldProps } from '../types';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import Enums from '../../enums';
import { IEnumItem } from '../../enums/types';
import getFieldError from '../utils/getFieldError';

export const EnumsField: React.FC<IEnumsFieldProps> = React.memo(props => {
	const {
		field: { fieldName, onChange, isRequired, defaultValue, hotReload, errorMessage, ...rest },
		formHook: { control, setValue },
	} = props;
	const {
		field,
		formState: { errors },
	} = useController({
		name: fieldName,
		defaultValue,
		control,
		rules: {
			required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
		},
	});
	const message: string | undefined = getFieldError(fieldName, errors);
	const registrationWithUserProps = {
		...field,
		onChange: (fieldValue: IEnumItem[]): void => {
			if (onChange) onChange(fieldValue);
			field.onChange(fieldValue);
		},
	};

	React.useEffect(() => {
		if (!Array.isArray(defaultValue) || !hotReload) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, hotReload, setValue]);

	return <Enums {...rest} {...registrationWithUserProps} isInvalid={!!message} isRequired={isRequired} errorMessage={message} />;
});
