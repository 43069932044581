import styled from 'styled-components';
import { blue, lightBlue, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledFeature = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledFeatureIcon = styled.div`
	margin-right: 8px;
	height: 6px;
	width: 6px;
	background: ${blue};
	border-radius: 50%;
`;

export const StyledStepBody = styled.div`
	margin-top: 2rem;
	min-height: 20rem;

	> h3 {
		margin-bottom: 1rem;
	}
`;

export const StyledOnboardingStepsNavigation = styled.div`
	display: flex;
	align-items: flex-end;

	@media screen and (max-width: 767px) {
		margin-top: 2rem;
	}
`;

export const StyledOnboardingNavigationItem = styled.div<{ isActive: boolean }>`
	height: 8px;
	width: 8px;
	border: 1px solid ${blue};
	border-radius: 50%;
	transition: all 0.3s;
	margin-right: 8px;
	cursor: pointer;
	:last-child {
		margin: 0;
	}

	${({ isActive }) =>
		isActive
			? `
            border-color: ${lightBlue};
            background: ${lightBlue};
            `
			: `
            background: ${white};
            border-color: ${blue};
            `}
`;

export const StyledOnboardingEventsWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	> .filledButton {
		width: auto;
	}

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
`;

export const StyledButtonsGroupAutoSize = styled.div`
	display: flex;
	button {
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
	@media screen and (max-width: 767px) {
		button {
			width: calc(50% - 0.5rem);
			margin-right: 1rem;
		}

		button:last-child {
			margin: 0;
		}
	}
`;
