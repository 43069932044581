import React from 'react';
import TitleUi from './ui';
import TitleContextProvider, { TitleContextProps } from './ui/context';

const TitleIgniter: React.FC<TitleContextProps> = props => (
	<TitleContextProvider {...props}>
		<TitleUi />
	</TitleContextProvider>
);

export default React.memo(TitleIgniter);
