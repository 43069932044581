import React from 'react';
import { useOnlineFillingContext } from './context';
import { FieldsBuilder } from '../../../../../../../../libs/uiKit/fieldsBuilder';
import JobPage from '../../../../../pages/job';
import { StyledAnketaTitle } from './styles';
import Button from '../../../../../../../../libs/uiKit/button';
import { DownloadIcon } from '../../../../../../../../icons/ui/Download';
import SendSms from "../../sendSms";

const OnlineFillingUi: React.FC = () => {
	const { forms, isFull, downloadAnketaHandler, isLoading, isDisabled, getCodeHandler, codeEnteredHandler, defaultValue, isDisabledSuccessFilled, successFillingCallback } =
		useOnlineFillingContext();

	return (
		<>
			{isFull && (
				<>
					<StyledAnketaTitle>Сведения о занятости созаемщика</StyledAnketaTitle>
					<JobPage isDisabled={isDisabled} />
				</>
			)}
			{forms.map(({ title, form }, index) => (
				<React.Fragment key={`insurance-form-${index}`}>
					<StyledAnketaTitle>{title}</StyledAnketaTitle>
					<FieldsBuilder {...form} />
				</React.Fragment>
			))}
			<Button isLoading={isLoading} appearance='link' iconBefore={<DownloadIcon />} onClick={downloadAnketaHandler}>
				Скачать анкету
			</Button>
			<SendSms isLoading={isLoading} isDisabled={isDisabled} codeEnteredHandler={codeEnteredHandler} getCodeHandler={getCodeHandler} defaultValue={defaultValue} agreementMode={false} />
			<Button appearance='filled' isDisabled={isDisabledSuccessFilled} isLoading={isLoading} onClick={successFillingCallback} style={{marginTop: '15px'}}>
				Сохранить
			</Button>
		</>
	);
};

export default React.memo(OnlineFillingUi);
