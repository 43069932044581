import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { IDateFieldProps } from '../types';
import DatePicker from '../../datePicker';
import { DEFAULT_REQUIRED_ERROR } from '../index';
import { fieldsValidate } from '../utils/fieldValidation';
import getFieldError from '../utils/getFieldError';

const DateField: React.FC<IDateFieldProps> = props => {
	const {
		field: {
			// for field
			fieldName,
			defaultValue,
			hotReload,
			onChange,
			onBlur,
			// for validation
			minDate,
			maxDate,
			validationType,
			isRequired,
			errorMessage,
			// other
			...rest
		},
		formHook: { control, setValue },
	} = props;

	const validate = useCallback(
		(validateValue: string) =>
			fieldsValidate({
				value: validateValue,
				validationType,
				minDate,
				maxDate,
			}),
		[validationType, minDate, maxDate]
	);
	const {
		field,
		formState: { errors },
	} = useController({
		name: fieldName,
		defaultValue,
		control,
		rules: {
			validate,
			required: isRequired ? errorMessage || DEFAULT_REQUIRED_ERROR : false,
		},
	});
	const message: string | undefined = getFieldError(fieldName, errors);
	const registrationWithUserProps = {
		...field,
		onChange: (fieldValue: Date | null): void => {
			if (onChange) onChange(fieldValue);
			field.onChange(fieldValue);
		},
		onBlur: (ev: React.FocusEvent<HTMLInputElement>): void => {
			if (onBlur) onBlur(ev);
			// field.onBlur();
		},
	};

	React.useEffect(() => {
		if (!hotReload || defaultValue === undefined || defaultValue === null) return;
		setValue(fieldName, defaultValue);
	}, [fieldName, defaultValue, setValue, hotReload]);

	return <DatePicker {...rest} {...registrationWithUserProps} minDate={minDate} maxDate={maxDate} isInvalid={!!message} isRequired={isRequired} errorMessage={message} />;
};

export default React.memo(DateField);
