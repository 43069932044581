import React, { createContext, useContext } from 'react';
import { useMutation } from '@apollo/client';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import { DELETE_ROLE } from '../../../../../../../libs/api/commands';
import { DeleteRoleMutation, DeleteRoleMutationVariables } from '../../../../../../../libs/api/graphqlTypes';

export interface DeleteRoleContextProps extends IModalProps {
	id?: number;
	deleteCallback?: () => void;
}

interface DeleteRoleContext extends IModalProps {
	deleteHandler: () => void;
	isLoading: boolean;
}

const DeleteRoleContext = createContext<DeleteRoleContext>({} as DeleteRoleContext);

export const useDeleteRoleContext = (): DeleteRoleContext => useContext(DeleteRoleContext);

export const DeleteRoleContextProvider: React.FC<DeleteRoleContextProps> = ({ children, id, deleteCallback, isOpen, onClose }) => {
	const { setNotification } = useNotification();

	const [deleteRole, { loading: loadingDeleteRole }] = useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DELETE_ROLE, { refetchQueries: ['roles'] });

	const deleteHandler = React.useCallback(() => {
		if (Number.isNaN(Number(id))) return setNotification({ type: 'error', text: 'Не верный id' });
		deleteRole({
			variables: {
				id: Number(id),
			},
		})
			.then(() => {
				onClose();
				if (deleteCallback) deleteCallback();
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [id, deleteCallback, onClose, deleteRole, setNotification]);

	const value: DeleteRoleContext = React.useMemo(
		() => ({
			deleteHandler,
			onClose,
			isOpen,
			isLoading: loadingDeleteRole,
		}),
		[deleteHandler, isOpen, onClose, loadingDeleteRole]
	);

	return <DeleteRoleContext.Provider value={value}>{children}</DeleteRoleContext.Provider>;
};
