import React from 'react';
import ContragentUi from './ui';
import ContragentContextProvider, { ContragentContextProps } from './ui/context';

const ContragentIgniter: React.FC<ContragentContextProps> = props => (
	<ContragentContextProvider {...props}>
		<ContragentUi />
	</ContragentContextProvider>
);

export default React.memo(ContragentIgniter);
