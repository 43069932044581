import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ITabItem, ITabsProps } from './types';
import { StyledTab, StyledTabsWrapper, StyledTabWatcher } from './styles';
import { Text3 } from '../globalStyles';
import { uuidv4 } from '../utils';

let timeout: ReturnType<typeof setTimeout>;

const Tabs: React.FC<ITabsProps> = props => {
	const { items, onChange, value } = props;
	const [stateSelectedTab, setSelectedTab] = useState<ITabItem>(items[1]);
	const { value: stateValue } = stateSelectedTab;
	const { value: propsValue } = value || {};

	const [stateTargetRect, setTargetRect] = useState<{ left: number; width: number; height: number } | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const selectedRef = useRef<HTMLDivElement | null>(null);
	const isFirstRender = useRef<boolean>(true);

	const onResize = (): void => {
		if (!selectedRef.current || !wrapperRef.current) return;

		const targetRect = selectedRef.current.getBoundingClientRect();
		const wrapperRect = wrapperRef.current.getBoundingClientRect();
		const relativeRect = {
			height: targetRect.height,
			width: targetRect.width,
			left: targetRect.left - wrapperRect.left,
		};

		setTargetRect(relativeRect);
	};

	useEffect(() => {
		if (!value || !propsValue || propsValue === stateValue) return;
		setSelectedTab(value);
		// eslint-disable-next-line
	}, [propsValue]);

	useEffect(() => onResize(), [stateSelectedTab.value]);

	const changeTabHandler = useCallback(
		(tab: ITabItem) => {
			setSelectedTab(tab);
			if (isFirstRender.current) isFirstRender.current = false;
			if (!onChange) return;
			onChange(tab);
		},
		[onChange]
	);

	const handleEventResize = useCallback((): void => {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			onResize();
		}, 200);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', () => handleEventResize());
		return (): void => window.removeEventListener('resize', () => handleEventResize());
	}, [handleEventResize]);

	return (
		<StyledTabsWrapper ref={wrapperRef} className='tabsWrapper'>
			{items.map(tab => (
				<StyledTab key={`tab-${uuidv4()}`} ref={tab.value === stateValue ? selectedRef : null} onClick={(): void => changeTabHandler(tab)}>
					<Text3>{tab.label}</Text3>
				</StyledTab>
			))}
			<StyledTabWatcher
				width={stateTargetRect?.width || null}
				height={stateTargetRect?.height || null}
				left={stateTargetRect?.left || null}
				isFirstRender={isFirstRender.current}
			/>
		</StyledTabsWrapper>
	);
};

export default React.memo(Tabs);
