import React from 'react';

export const TimeReloadIcon: React.FC = () => (
	<svg width='2.3rem' height='2.1rem' viewBox='0 0 23 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3.34971 11.2462C3.33058 11 3.32083 10.7511 3.32083 10.5C3.32083 5.25329 7.57413 1 12.8208 1C18.0675 1 22.3208 5.25329 22.3208 10.5C22.3208 15.7467 18.0675 20 12.8208 20C9.68653 20 6.90677 18.4821 5.17647 16.1414L5.90402 15.6035C7.51575 17.7839 10.0582 19.0952 12.8208 19.0952C17.5679 19.0952 21.4161 15.247 21.4161 10.5C21.4161 5.75298 17.5679 1.90476 12.8208 1.90476C8.07381 1.90476 4.22559 5.75298 4.22559 10.5C4.22559 10.7314 4.23474 10.9615 4.25287 11.1901L5.71522 9.72774L6.35502 10.3675L3.77321 12.9493L1.19141 10.3675L1.83121 9.72774L3.34971 11.2462ZM13.2732 5.52381V10.7492L17.1928 14.2333L16.5917 14.9096L12.3685 11.1555V5.52381H13.2732Z'
			fill='currentColor'
			stroke='currentColor'
			strokeWidth='0.5'
		/>
	</svg>
);
