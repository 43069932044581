import styled from 'styled-components';
import { Heading3 } from '../../../../../../../libs/uiKit/globalStyledComponents';

export const StyledAnketaTitle = styled(Heading3)`
	margin: 3rem 0 3rem 0;
`;

export const StyledSendSmsWrapper = styled.div`
	> button {
		margin: 2rem 0 2rem 0;
	}
`;
