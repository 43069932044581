import React from 'react';

export const ProfileIcon: React.FC = () => (
	<svg width='1.9rem' height='1.9rem' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width='19' height='19' fill='white' />
		<circle cx='9.50067' cy='7.35321' r='1.4202' stroke='#7F7F7F' strokeWidth='1.2' />
		<path d='M12.8857 13.0673H6.10917C6.39303 11.4533 7.80201 10.227 9.49743 10.227C11.1928 10.227 12.6018 11.4533 12.8857 13.0673Z' stroke='currentColor' strokeWidth='1.2' />
		<circle cx='9.5' cy='9.5' r='8.5' stroke='currentColor' strokeWidth='1.2' />
	</svg>
);
