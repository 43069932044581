import React from 'react';
import { useRiskLifeContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const RiskLifeUi: React.FC = () => {
	const { form } = useRiskLifeContext();

	return <FieldsBuilder {...form} />;
};

export default React.memo(RiskLifeUi);
