import React, { useCallback, useMemo } from 'react';
import { StyledBodyEmpty, StyledBodyTr, StyledHeadTh, StyledTable, StyledTableWrapper } from './styles';
import { TableProps } from './types';
import { SkeletonTable } from './skeleton';
import { useSortableData } from './hooks/useSortableData';
import Pagination from '../pagination';
import { Heading3 } from '../globalStyledComponents';

const getTotalPages = (total: number | undefined, rowsPerPage: number | undefined): number => {
	if (total === undefined || rowsPerPage === undefined) return 0;
	return Math.ceil(total / rowsPerPage);
};

const Table: React.FC<TableProps> = React.forwardRef((props, ref) => {
	const { rows, head, isLoading, emptyView, onChange, selectedPage, total, rowsPerPage, delta } = props;

	const { sortedRows, requestSort, sortConfig } = useSortableData({ items: rows });

	const getDirectionForHead = useCallback(
		(index: number) => {
			if (!sortConfig) {
				return;
			}
			return sortConfig.key === index ? sortConfig.direction : undefined;
		},
		[sortConfig]
	);

	const isShowPagination: boolean = !!total && !!rowsPerPage && !isLoading && total > rowsPerPage;

	const pages: number[] = Array.from({ length: getTotalPages(total, rowsPerPage) || 0 }).map((i, index) => index + 1);

	const memoHead = useMemo(
		() =>
			head ? (
				<thead>
					<tr>
						{head.cells.map(({ content, isSortable, key, sortType, width }, index) =>
							isSortable && sortType && requestSort ? (
								<StyledHeadTh isSortable={isSortable} direction={getDirectionForHead(index)} onClick={(): void => requestSort(sortType, index)} key={key ?? index} width={width}>
									{content}
								</StyledHeadTh>
							) : (
								<StyledHeadTh key={key ?? index} width={width}>
									{content}
								</StyledHeadTh>
							)
						)}
					</tr>
				</thead>
			) : null,
		[getDirectionForHead, head, requestSort]
	);

	return (
		<>
			<StyledTableWrapper>
				<StyledTable cellPadding={0} ref={ref}>
					{memoHead}
					<tbody>
						{isLoading && <SkeletonTable length={head?.cells.length ?? 5} />}
						{!isLoading &&
							!!sortedRows?.length &&
							sortedRows.map(row => (
								<StyledBodyTr key={row.key} {...row}>
									{row.cells.map(cell => (
										<td key={cell.key}>{cell.content}</td>
									))}
								</StyledBodyTr>
							))}
					</tbody>
				</StyledTable>
				{!isLoading && !sortedRows?.length && (
					<StyledBodyEmpty>
						<Heading3>{emptyView || 'Нет данных'}</Heading3>
					</StyledBodyEmpty>
				)}
			</StyledTableWrapper>
			{isShowPagination && <Pagination pages={pages} selectedPage={selectedPage} delta={delta} onChange={onChange} />}
		</>
	);
});

export default React.memo(Table);
