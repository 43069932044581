import React from "react";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import FormModal from "../../../widgets/FormModal";
import {IFormField} from "../../../../libs/uiKit/fieldsBuilder/types";
import {ADDENDUM} from "../../../../libs/api/commands";
import {AddendumMutation, AddendumMutationVariables, AddendumReasonEnum} from "../../../../libs/api/graphqlTypes";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";



const ChangeRemoveModal = (props: IModalProps) => {

  const {data} = props;
  const {life_risks, property_risks, titul_risks} = data;
  const { id } = useParams<{ id?: string }>();

  const {setNotification} = useNotification();

  const [addendum, {loading: loadingAddendum}] = useMutation<AddendumMutation, AddendumMutationVariables>(ADDENDUM, {
    refetchQueries: ['loadContract'], awaitRefetchQueries: true
  });

  const risks = [
    ...(life_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: `${risk?.type} ${risk?.fio}`
    })),
    ...(property_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: risk?.type
    })),
    ...(titul_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: risk?.type
    })),
  ];

  const riskFields : IFormField[] = risks.map((risk) => ({
    field: {
      fieldType: "checkbox",
      fieldName: `risk-${risk.isn}`,
      label: risk?.label,
    }
  }));

  const onSubmit = (formData: any) => {
    if(!id) return;
    const riskIsns: string[] = [];
    Object.keys(formData).forEach((key) => {
      if(key.startsWith('risk') && formData[key]){
        riskIsns.push(key.substring(5))
      }
    })
    addendum({
      variables: {
        id: parseInt(id, 10),
        reason: AddendumReasonEnum.Exclude,
        exclude: {
          date: formatDateToStringRu(formData.endDate),
          risks: riskIsns,
        }
      }
    }).then((data) => {
      console.log('done');
    })
      .catch(error => setNotification({type: "error", text: error}))
  }

  return (
    <FormModal {...props}
      header="Исключение рисков"
      fields={[
        ...riskFields,
        {
          field: {
            fieldName: "endDate",
            fieldType: "date",
            isRequired: true,
            validationType: "minMaxDate",
            minDate: dayjs().toDate(),
            errorMessage: "Выберите дату подписания",
            placeholder: "Дата подписания"
          }
        },
      ]}
      onSubmit={onSubmit}
      submitButton="Исключить выбранное"
      isLoading={loadingAddendum}
    />

  );
}

export default ChangeRemoveModal;