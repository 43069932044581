import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {IModalProps} from "../../../../libs/uiKit/modal/types";
import FormModal from "../../../widgets/FormModal";
import {useEnterAddendumModeMutation} from "../../../../libs/api/graphqlTypes";
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";

const ChangeAddModal = (props: IModalProps) => {

  const { id } = useParams<{ id?: string }>();

  const { routes : {
    createAgreementStep
  }} = useGlobalContext();

  const navigate = useNavigate();

  const {data} = props;
  const {life_risks, property_risks, titul_risks} = data;
  console.log('cam', data);

  const [ enterAddendumMode, {loading : enterAddendumLoading }  ] = useEnterAddendumModeMutation();

  const onSubmit = (formData: any) => {
    if(!id) return;
    enterAddendumMode({
      variables: {
        id: parseInt(id, 10),
        life: formData?.life,
        property: formData?.property,
        titul: formData?.titul,
      }
    }).then((data) => {
      navigate(createAgreementStep(id, 'propertyStep'));
      props.onClose();
    })
  }

  return (
    <>
      <FormModal {...props}
                 header="Дострахование рисков"
                 fields={[
                   {
                     field: {
                       fieldType: "checkbox",
                       fieldName: "life",
                       label: "Жизнь",
                       grid: 4,
                       isDisabled: life_risks,
                       checked: life_risks || undefined
                     }
                   },
                   {
                     field: {
                       fieldType: "checkbox",
                       fieldName: "property",
                       label: "Имущество",
                       grid: 4,
                       isDisabled: property_risks,
                       checked: property_risks || undefined
                     }
                   },
                   {
                     field: {
                       fieldType: "checkbox",
                       fieldName: "titul",
                       label: "Титул",
                       grid: 4,
                       isDisabled: titul_risks,
                       checked: titul_risks || undefined,
                     }
                   },
                 ]}
                 onSubmit={onSubmit}
                 submitButton="Продолжить"
                 isLoading={enterAddendumLoading}
      />
    </>


  );
}

export default ChangeAddModal;