import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';

export type ChangePasswordContextProps = {
	children?: React.ReactNode;
} & IModalProps;

type ChangePasswordContext = {
	form: IForm;
	onFormSubmit: () => void;
	isLoading: boolean;
} & IModalProps;

type ChangePasswordForm = {
	oldPassword: string;
	newPassword: string;
};

const ChangePasswordContext = createContext<ChangePasswordContext>({} as ChangePasswordContext);

export const useChangePasswordContext = (): ChangePasswordContext => useContext(ChangePasswordContext);

export const ChangePasswordContextProvider: React.FC<ChangePasswordContextProps> = props => {
	const { children, isOpen, onClose } = props;
	const formHook = useForm<ChangePasswordForm>({ mode: 'onBlur' });

	const onFormSubmit = formHook.handleSubmit(data => {
		console.log(data);
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'oldPassword',
						type: 'password',
						placeholder: 'Старый пароль',
						isRequired: true,
						errorMessage: 'Введите старый пароль',
						validationType: 'password',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'newPassword',
						type: 'password',
						placeholder: 'Новый пароль',
						isRequired: true,
						errorMessage: 'Введите новый пароль',
						validationType: 'password',
					},
				},
			],
		}),
		[]
	);

	const value: ChangePasswordContext = React.useMemo(
		() => ({
			isLoading: false,
			onFormSubmit,
			form,
			isOpen,
			onClose,
		}),
		[form, isOpen, onClose, onFormSubmit]
	);

	return <ChangePasswordContext.Provider value={value}>{children}</ChangePasswordContext.Provider>;
};
