import React from 'react';
import CreateUserUi from './ui';
import { CreateUserContextProvider, CreateUserProps } from './ui/context';

const CreateUserIgniter: React.FC<CreateUserProps> = props => (
	<CreateUserContextProvider {...props}>
		<CreateUserUi />
	</CreateUserContextProvider>
);

export default React.memo(CreateUserIgniter);
