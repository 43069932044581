import React, {useState} from 'react';
import {useForm, useFormContext} from "react-hook-form";
import dayjs from "dayjs";
import {StyledAnketaTitle, StyledSendSmsWrapper} from "./styles";
import {FieldsBuilder} from "../../../../../../../libs/uiKit/fieldsBuilder";
import Button from "../../../../../../../libs/uiKit/button";
import {IForm} from "../../../../../../../libs/uiKit/fieldsBuilder/types";

export type SendSmsProps = {
	children?: React.ReactNode;
	isDisabled?: boolean;
	isLoading?: boolean;
	defaultValue: Omit<SendSmsForm, 'smsCode'>;
	getCodeHandler: (afterSend: () => void) => void;
	codeEnteredHandler: (code: string) => void;
	agreementMode: boolean|undefined;
};

export type SendSmsForm = {
	sendSmsPhone: string;
	smsCode: string;
};

const SendSms: React.FC<SendSmsProps> = props => {

	const { children, defaultValue, getCodeHandler, codeEnteredHandler, isDisabled, isLoading, agreementMode } = props;
	const formHook = useForm<SendSmsForm>();
	const [code] = formHook.watch(['smsCode']);


	const [cooldown, setCooldown] = useState<string|null>(null);
	const [cooldownTime, setCooldownTime] = useState<any>(null);
	const [cooldownTimer, setCooldownTimer] = useState<any>(null);
	const [nowTime, setNowTime] = useState<any>(null);

	React.useEffect(() => {
		const clearCode = (code || '').replace('_', '');
		const maxLength = agreementMode ? 4 : 6;
		if (clearCode.length !== maxLength) return;
		debugger;
		codeEnteredHandler(clearCode);
	}, [code]);

	const startCooldown = () => {
		setCooldownTime(dayjs());
		const timer = setInterval(() => {
			setNowTime(dayjs());
		}, 200);
		setCooldownTimer(timer);
		setTimeout(() => {
			setCooldownTime(dayjs());
		}, 300);
	}

	const formatSecondsToTime = (seconds: number) => new Date(seconds * 1000).toISOString().slice(14, 19)

	const cooldownSeconds = nowTime && cooldownTime ? cooldownTime.add(300, 'second').diff(nowTime, 'seconds') : null;
	if(cooldownSeconds !== null && cooldownSeconds <= 0 && cooldownTime){
		setCooldownTime(null);
		console.log('clear!');
		clearInterval(cooldownTimer);
	}

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						grid: 6,
						fieldType: 'inputMask',
						isRequired: true,
						isDisabled: true,
						errorMessage: 'Введите телефон',
						mask: '+7 (999) 999-99-99',
						defaultValue: defaultValue?.sendSmsPhone,
						fieldName: 'sendPhone',
						placeholder: 'Телефон',
					},
				},
				{
					field: {
						grid: 6,
						fieldType: 'inputMask',
						errorMessage: 'Введите телефон',
						mask: agreementMode ? '9999' : '999999',
						fieldName: 'smsCode',
						placeholder: 'Код из смс',
						isDisabled,
					},
				},
			],
		}),
		[defaultValue?.sendSmsPhone, formHook, isDisabled]
	);

	return (
		<StyledSendSmsWrapper>
			<StyledAnketaTitle>Введите номер телефона, чтобы мы могли выслать Вам смс с кодом подтверждения</StyledAnketaTitle>
			<FieldsBuilder {...form} />
			<Button appearance='filled' onClick={() => {getCodeHandler(startCooldown)}} isDisabled={isDisabled || !!cooldownSeconds} isLoading={isLoading}>
				{cooldownSeconds ? formatSecondsToTime(cooldownSeconds) : "Выслать код"}
			</Button>
		</StyledSendSmsWrapper>
	);
};

export default SendSms;
