import React from 'react';
import AgreementChangeLogUi from './ui';
import { AgreementChangeLogContextProps, AgreementChangeLogContextProvider } from './ui/context';

const AgreementChangeLogIgniter: React.FC<AgreementChangeLogContextProps> = props => (
	<AgreementChangeLogContextProvider {...props}>
		<AgreementChangeLogUi />
	</AgreementChangeLogContextProvider>
);

export default React.memo(AgreementChangeLogIgniter);
