import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {useLazyQuery, useMutation} from '@apollo/client';
import { IForm, IFormField } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import {
	CalculationRisk,
	DisapproveMutation, DisapproveMutationVariables,
	LoadAgreementUnderwritingQuery,
	LoadAgreementUnderwritingQueryVariables
} from '../../../../../../../libs/api/graphqlTypes';
import { LOAD_AGREEMENT } from '../../../../../../../libs/api/queries';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';
import {DISAPPROVE} from "../../../../../../../libs/api/commands";

export type DisapproveContextProps = {
	id: number | null;
	children?: React.ReactNode;
} & IModalProps;

type DisapproveContext = {
	onFormSubmit: () => void;
	form: IForm;
	isLoading: boolean;
	isHaveRisks: boolean;
} & IModalProps;

type DisapproveForm = {
	[key: string]: boolean;
};

const DisapproveContext = createContext<DisapproveContext>({} as DisapproveContext);

export const useDisapproveContext = (): DisapproveContext => useContext(DisapproveContext);

const getRisksField = (risks: CalculationRisk[], type: 'жизнь' | 'имущество' | 'титул'): IFormField[] =>
	risks.map(({ isn, status, type: riskType }) => ({
		isHidden: status === 'UNDERWRITING',
		field: {
			label: `Риск ${type} ${riskType}`,
			fieldType: 'checkbox',
			fieldName: `risk-${isn}`,
		},
	}));

export const DisapproveContextProvider: React.FC<DisapproveContextProps> = props => {
	const { children, id, isOpen, onClose } = props;
	const { setNotification } = useNotification();
	const {
		routes: { error500 },
	} = useGlobalContext();
	const formHook = useForm<DisapproveForm>({ mode: 'onBlur' });

	const [getAgreement, { data: dataAgreement, loading: loadingAgreement }] = useLazyQuery<LoadAgreementUnderwritingQuery, LoadAgreementUnderwritingQueryVariables>(LOAD_AGREEMENT, {
		onError: error => error500(error),
	});

	const [disapprove, {loading: loadingDisapprove}] = useMutation<DisapproveMutation, DisapproveMutationVariables>(DISAPPROVE, {
		refetchQueries: ['loadAgreementUnderwriting'],
		awaitRefetchQueries: true
	})

	const { calculation } = dataAgreement?.loadAgreement || {};
	const { life_risks, property_risks, titul_risks } = calculation || {};

	const risksLife: CalculationRisk[] = (life_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksProperty: CalculationRisk[] = (property_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksTitle: CalculationRisk[] = (titul_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);

	React.useEffect(() => {
		if (!id || !isOpen) return;
		getAgreement({
			variables: {
				id,
			},
		});
	}, [getAgreement, id, isOpen]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [...getRisksField(risksLife, 'жизнь'), ...getRisksField(risksProperty, 'имущество'), ...getRisksField(risksTitle, 'титул')],
		}),
		[formHook, risksLife, risksProperty, risksTitle]
	);

	const isHaveRisks: boolean = !!form.fields.filter(({ isHidden }) => !isHidden).length;

	const onFormSubmit = formHook.handleSubmit(data => {
		if(!id) return;
		const risk_isns = Object.entries(data).reduce<number[]>((prev, [key, value]) => {
			if (!value) return prev;
			return [...prev, parseInt(key.split('-')[1], 10)];
		}, [])
		if (!risk_isns.length) return setNotification({type: "error", text: "Риски не выбраны"});
		disapprove({
			variables: {
				id,
				risk_isns
			}
		}).then(() => {
			setNotification({ type: 'success', text: "Риски отправлены на согласование" });
			onClose();
		}).catch(error => setNotification({ type: 'error', text: error }))
	});

	const value: DisapproveContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isHaveRisks,
			isLoading: loadingAgreement || loadingDisapprove,
			isOpen,
			onClose,
		}),
		[form, isHaveRisks, isOpen, loadingAgreement, onClose, onFormSubmit]
	);

	return <DisapproveContext.Provider value={value}>{children}</DisapproveContext.Provider>;
};
