import React from 'react';
import PrecalculationUi from './ui';
import PrecalculationContextProvider from './ui/context';

const PrecalculationPage: React.FC = () => (
	<PrecalculationContextProvider>
		<PrecalculationUi />
	</PrecalculationContextProvider>
);

export default React.memo(PrecalculationPage);
