import React, { ChangeEvent } from 'react';
import { RangePickerFooter, StyledRangePicker } from './styles';
import { IRangePickerProps } from './types';
import NativeRange from '../nativeRange';
import FloatInput from '../floatInput/NumberInput';
import { TypeInputRef } from '../input/types';
import { Text4 } from '../globalStyles';
import { stringToNumber } from '../utils';

const RangePicker: React.FC<IRangePickerProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { min, max, step, minMaxText, placeholder, defaultValue = min, value = min, onChange, ...rest } = props;

	const [stateInputValue, setInputValue] = React.useState<string>(stringToNumber(String(defaultValue)));

	React.useEffect(() => {
		if (typeof value === 'undefined') return;
		setInputValue(stringToNumber(String(value)));
	}, [value]);

	const onInputChange = React.useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			setInputValue(ev.target.value);
			if (onChange) onChange(ev);
		},
		[setInputValue, onChange]
	);

	return (
		<StyledRangePicker>
			<FloatInput {...rest} ref={ref} placeholder={placeholder} value={stateInputValue} onChange={onInputChange} digitsAfterDot={2} />
			<NativeRange {...rest} ref={ref} min={min} max={max} step={step} value={stateInputValue} onChange={onInputChange} />
			<RangePickerFooter>
				<Text4>
					{min} {minMaxText}
				</Text4>
				<Text4>
					{max} {minMaxText}
				</Text4>
			</RangePickerFooter>
		</StyledRangePicker>
	);
});

export default React.memo(RangePicker);
