export const isMoRegion = (address: any) => (address?.data?.region_iso_code === "RU-MOS" || address?.data?.region_iso_code === "RU-MOW")

export const isNewRegion = (address: any) => {
  const newRegions = [
    "9400000000000", // ЛНР
    "9300000000000", // ДНР
    "9500000000000", // Херсонская
    "9000000000000", // Запорожская
  ];
  return  newRegions.includes(address?.data?.region_kladr_id);
}