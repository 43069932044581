import React from 'react';
import OnlineFillingUi from './ui';
import OnlineFillingContextProvider, { OnlineFillingContextProps } from './ui/context';

const OnlineFillingIgniter: React.FC<OnlineFillingContextProps> = props => (
	<OnlineFillingContextProvider {...props}>
		<OnlineFillingUi />
	</OnlineFillingContextProvider>
);

export default React.memo(OnlineFillingIgniter);
