import React, { createContext, useContext } from 'react';
import { IModalProps } from '../../../../../libs/uiKit/modal/types';
import img1 from '../static/1.png';
import img2 from '../static/2.png';
import img3 from '../static/3.png';
import img4 from '../static/4.png';
import img5 from '../static/5.png';
import img6 from '../static/6.png';
import img7 from '../static/7.png';
import img8 from '../static/8.png';
import img9 from '../static/9.png';
import img10 from '../static/10.png';
import img11 from '../static/11.png';
import img12 from '../static/12.png';
import img13 from '../static/13.png';
import { Text4 } from '../../../../../libs/uiKit/globalStyles';
import { useUserRights } from '../../../../pages/common/hooks/useUserRights';
import useOnboarding from '../../hooks/useNitification';
import Feature from '../Feature';

type Step = {
	type: ('seller' | 'underwriter')[];
	header: string;
	img: string;
	title: string;
	description: React.ReactNode;
};

type OnboardingContext = {
	step: Step;
	stepsCount: number;
	activeStep: number;
	changeStepHandler: (step: number) => void;
} & IModalProps;

export type OnboardingProps = { children?: React.ReactNode };

const OnboardingContext = createContext<OnboardingContext>({} as OnboardingContext);

export const useOnboardingContext = (): OnboardingContext => useContext(OnboardingContext);

const STEPS: Step[] = [
	{
		type: ['seller', 'underwriter'],
		header: 'Добро пожаловать в Абсолют Страхование!',
		img: img1,
		title: 'Начните обучение',
		description: (
			<>
				<Text4>Мы расскажем вам как пользоваться программой.</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Главная',
		img: img2,
		title: 'Статистика содержит',
		description: (
			<>
				<Feature text='Договоры' />
				<Feature text='Заявки' />
				<br />
				<Text4>
					Чтобы оформить предварителую заявку и рассчитать стоимость, необходимо нажать на кнопку Создать заявку. Вы можете оформить предварительный расчет или перейти к
					расширенной заявке.
				</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Предварительная заявка',
		img: img3,
		title: 'Создание заявки включает ввод данных',
		description: (
			<>
				<Feature text='Вид договора' />
				<Feature text='Размер кредита и кредитная организация' />
				<Feature text='Строящееся или готовое жилье' />
				<Feature text='Доля заемщика в общем доходе' />
				<Feature text='Сведения о здоровье и наличии созаемщиков' />
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Предварительная заявка',
		img: img4,
		title: 'Результаты расчета содержат',
		description: (
			<>
				<Feature text='Наименование кредитной организации' />
				<Feature text='Сумму страховой премии и страховую сумму' />
				<Feature text='Параметры предложения' />
				<br />
				<Text4>
					Доступна выгрузка всех предложений. Нажав на чекбокс, вы сможете выгрузить данное предложение и все выбранные. Чтобы получить подробный расчет по конкретному предложению,
					необходимо нажать на кнопку Выбрать.
				</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Расширенная заявка',
		img: img5,
		title: 'Для создания заявки необходимо внести',
		description: (
			<>
				<Feature text='Общие сведения о страхователе' />
				<Feature text='Сведения о занятости страхователя' />
				<Feature text='Основные положения кредитного договора' />
				<br />
				<Text4>После ввода этих данных появляется окно со всеми участниками договора.</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Расширенная заявка',
		img: img6,
		title: 'Страхование жизни',
		description: (
			<>
				<Text4>Варианты заполнения анкеты заемщика/созаемщика:</Text4>
				<br />
				<Feature text='Заполнить онлайн' />
				<Feature text='Загрузить документ' />
				<Feature text='Отправить анкету клиенту' />
				<br />
				<Text4>После ввода всех данных необходимо нажать на кнопку Создать.</Text4>
			</>
		),
	},

	{
		type: ['underwriter'],
		header: 'Журнал заявок',
		img: img7,
		title: 'Заявка ожидает андеррайтинга ',
		description: (
			<>
				<Text4>Для взаимодействия с заявкой необходимо нажать на поле заявки.</Text4>
			</>
		),
	},
	{
		type: ['underwriter'],
		header: 'Андеррайтинг заявки',
		img: img8,
		title: 'Вы можете посмотреть общие сведения, а также',
		description: (
			<>
				<Feature text='Скачать прикрепленные документы' />
				<Feature text='Оставить сообщение в блоке Обсуждения ' />
				<Feature text='Изменить повышающий коэффициент и одобрить риски' />
				<Feature text='Предоставить скидку андеррайтера' />
				<br />
				<Text4>Для дальнейшего шага необходимо Пересчитать данные либо Согласовать заявку.</Text4>
			</>
		),
	},
	{
		type: ['underwriter'],
		header: 'Андеррайтинг заявки',
		img: img9,
		title: 'Согласование заявки',
		description: (
			<>
				<Text4>При нажатии на Согласование заявки возникает выбор действия:</Text4>
				<br />
				<Feature text='Согласовать' />
				<Feature text='Не согласовывать' />
				<Feature text='Не согласовывать' />
				<br />
				<Text4>При согласовании заявки работа с ней завершается.</Text4>
			</>
		),
	},

	// {
	//   type: ["seller"],
	//   header: "Журнал заявок",
	//   img: img7,
	//   title: "Заявка отправляется на андеррайтинг",
	//   description: <div></div>
	// },

	{
		type: ['seller'],
		header: 'Согласование заявки',
		img: img10,
		title: 'Вы можете посмотреть данные, а также',
		description: (
			<>
				<Feature text='Применить скидку за счет комиссионного вознаграждения агента' />
				<Feature text='Отправить на повторное согласование' />
				<Feature text='Оставить сообщение в обсуждении' />
				<br />
				<Text4>Для дальнейшего шага необходимо нажать на кнопку Создать договор.</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Формирование договора',
		img: img11,
		title: 'Вы можете посмотреть данные, а также',
		description: (
			<>
				<Feature text='Скачать проект договора' />
				<Feature text='Пересчитать премию' />
				<Feature text='Перейти к подписанию договора' />
				<br />
				<Text4>Для дальнейшего шага необходимо нажать на кнопку Подписать договор.</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Формирование договора',
		img: img12,
		title: 'После подписания договора вы можете',
		description: (
			<>
				<Feature text='Получить документы по договору' />
				<Feature text='Внести изменения' />
				<br />
				<Text4>Для дальнейшего шага необходимо нажать на кнопку Получить документы.</Text4>
			</>
		),
	},
	{
		type: ['seller'],
		header: 'Договор страхования',
		img: img13,
		title: 'На этапе сформированного договора вы можете',
		description: (
			<>
				<Feature text='Посмотреть общие сведения и документы' />
				<Feature text='Создать счет и получить его печатную форму' />
				<Feature text='Получить печатную форму договора' />
				<br />
				<Text4>Для выставления счета клиенту необходимо Сформировать счет на оплату.</Text4>
			</>
		),
	},
];

export const OnboardingContextProvider: React.FC<OnboardingProps> = props => {
	const { children } = props;
	const { rightsHandler } = useUserRights();
	const { switchOnboarding, isOpen } = useOnboarding();
	const [stateActiveStep, setActiveStep] = React.useState<number>(1);
	const isSeller: boolean = rightsHandler('applicationPage');
	const isUnderwriter: boolean = rightsHandler('underwritingPage');
	const isSellerAndUnderwriter: boolean = isSeller && isUnderwriter;

	const currentSteps: Step[] = isSellerAndUnderwriter
		? STEPS
		: isUnderwriter
		? STEPS.filter(({ type }) => type.includes('underwriter'))
		: STEPS.filter(({ type }) => type.includes('seller'));

	const value: OnboardingContext = React.useMemo(
		() => ({
			isOpen,
			onClose: (): void => switchOnboarding(false),
			changeStepHandler: step => setActiveStep(step),
			activeStep: stateActiveStep,
			stepsCount: currentSteps.length,
			step: currentSteps[stateActiveStep - 1],
		}),
		[currentSteps, isOpen, stateActiveStep, switchOnboarding]
	);

	return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};
