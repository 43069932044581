import React from 'react';
import { useBankContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import ContragentIgniter from '../../../widgets/contragent';
import Button from '../../../../../../libs/uiKit/button';
import { AddIcon } from '../../../../../../icons/ui/Add';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { StyledCoBorrowerHeader } from './styles';

const BankUi: React.FC = () => {
	const { form, isHaveCoBorrowerForm, isShowCoBorrowers, coBorrowers, addCoBorrowerHandler, deleteCoBorrowerHandler, isDisabledAddCoBorrower } = useBankContext();

	return (
		<>
			<FieldsBuilder {...form} />
			<FieldsBuilder {...isHaveCoBorrowerForm} />
			{isShowCoBorrowers && (
				<div>
					{coBorrowers.map(coBorrower => (
						<div key={`coBorrower-${coBorrower.number}`}>
							<StyledCoBorrowerHeader>
								<Heading3>Созаемщик {coBorrower.number + 1}</Heading3>
								<Button appearance='icon' onClick={(): void => deleteCoBorrowerHandler(coBorrower.number)} icon={<TrashIcon />} />
							</StyledCoBorrowerHeader>
							<ContragentIgniter {...coBorrower} />
						</div>
					))}
					<Button appearance='link' onClick={addCoBorrowerHandler} isDisabled={isDisabledAddCoBorrower} iconBefore={<AddIcon />}>
						Добавить созаемщика
					</Button>
				</div>
			)}
		</>
	);
};

export default React.memo(BankUi);
