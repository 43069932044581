import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export type NotificationId = string;
export type NotificationTypes = 'success' | 'error' | 'warn' | 'loading';

export type NotificationModel = {
	id: NotificationId;
	title?: string;
	description?: string;
	type: NotificationTypes;
};

export const notificationAdapter = createEntityAdapter<NotificationModel>({
	selectId: ({ id }) => id,
});
export const notificationSlice = createSlice({
	name: 'notification',
	initialState: notificationAdapter.getInitialState(),
	reducers: {
		addOne: notificationAdapter.addOne,
		removeOne: notificationAdapter.removeOne,
	},
});

export const notificationReducer: typeof notificationSlice.reducer = notificationSlice.reducer;
