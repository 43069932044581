import { blue, blue7 } from '../../../../../../../libs/uiKit/globalStyledComponents';
import styled from 'styled-components';

export const StyledLeftMenuWrapper = styled.div<{ isOpen: boolean }>`
	z-index: 30;
	will-change: width;
	transition: width 0.3s;
	overflow: hidden;
	${({ isOpen }) =>
		isOpen
			? `
      width: 26rem;
      `
			: `
      width: 0;
      `}
`;
export const StyledLeftMenu = styled.div`
	border-right: 1px solid #e6ecf3;
	min-height: calc(100% - 4rem);
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
export const StyledLogo = styled.div`
	padding: 0 4rem;
	cursor: pointer;
	margin-bottom: 3rem;
`;
export const StyledNavigationLinksWrapper = styled.div<{ activeLink: number | undefined }>`
	position: relative;

	::before {
		content: '';
		position: absolute;
		left: 0;
		pointer-events: none;
		cursor: pointer;
		transition: all 0.3s;
		${({ activeLink }) =>
			typeof activeLink === 'number'
				? `
      top: ${activeLink * 5}rem;
      `
				: 'display: none;'}
		height: 5rem;
		width: 0.5rem;
		background: ${blue};
	}
`;
export const StyledNavigationLink = styled.div<{ isActive: boolean }>`
	height: 5rem;
	width: calc(100% - 4rem);
	display: flex;
	align-items: center;
	padding-left: 4rem;
	cursor: pointer;
	white-space: nowrap;
	font-family: Verdana;
	font-size: 1.4rem;
	line-height: 2rem;

	${({ isActive }) =>
		isActive
			? `
  transition: background 0.3s;
  transition-delay: 0.3s;
  font-weight: bold;
  background: ${blue7};
  `
			: `
opacity: 0.5;
`}
	:hover {
		opacity: 1;
	}
`;
