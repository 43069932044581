import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../../apps/main/ds/redux';
import { leftMenuSlice } from '../leftMenu/ds/redux/leftMenu';

const useLeftMenu = (): { isOpen: boolean; switchLeftMenu: () => void } => {
	const { isOpen } = useTypedSelector(store => store.leftMenu);
	const dispatch = useDispatch();

	const switchLeftMenu = React.useCallback((): void => {
		dispatch(leftMenuSlice.actions.switchLeftMenu());
	}, [dispatch]);

	return {
		isOpen,
		switchLeftMenu,
	};
};

export default useLeftMenu;
