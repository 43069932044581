import React from 'react';
import RecoveryPasswordUi from './ui';
import { RecoveryPasswordContextProvider } from './ui/context';

const RecoveryPasswordIgniter: React.FC = () => (
	<RecoveryPasswordContextProvider>
		<RecoveryPasswordUi />
	</RecoveryPasswordContextProvider>
);

export default React.memo(RecoveryPasswordIgniter);
