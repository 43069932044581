import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import useLeftMenu from '../../widgets/hooks/useLeftMenu';
import { PAGES_WITHOUT_BACKGROUND } from '../../../../../../libs/utils/staticData';

type MainLayoutContextProps = {
	children: React.ReactNode;
};

type MainLayoutContext = {
	isLeftMenuOpen: boolean;
	switchLeftMenuHandler: () => void;
	isWhiteBackground: boolean;
};

const MainLayoutContext = createContext<MainLayoutContext>({} as MainLayoutContext);

export const useMainLayoutContext = (): MainLayoutContext => useContext(MainLayoutContext);

const MainLayoutContextProvider: React.FC<MainLayoutContextProps> = ({ children }) => {
	const { isOpen, switchLeftMenu } = useLeftMenu();
	const location = useLocation();
	const currentPathname: string = location.pathname.split('/')[1];

	const value: MainLayoutContext = React.useMemo(
		() => ({
			isLeftMenuOpen: isOpen,
			switchLeftMenuHandler: switchLeftMenu,
			isWhiteBackground: PAGES_WITHOUT_BACKGROUND.includes(currentPathname),
		}),
		[currentPathname, isOpen, switchLeftMenu]
	);

	return <MainLayoutContext.Provider value={value}>{children}</MainLayoutContext.Provider>;
};

export default React.memo(MainLayoutContextProvider);
