import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './ds/redux';
import { GlobalContextProvider } from './ui/GlobalContext';
import ThemeContainer from '../../libs/uiKit/themeContainer';
import { client } from '../../libs/api/Apollo';
import AuthorizationPage from '../../modules/pages/authorization';
import NotifierIgniter from '../../modules/widgets/notifier';
import DashboardPage from '../../modules/pages/dashboard';
import RolesPage from '../../modules/pages/roles';
import ReportsPage from '../../modules/pages/reports';
import AgreementsLogPage from '../../modules/pages/agreementsLog';
import PrecalculationPage from '../../modules/pages/precalculation';
import CreateAgreementPage from '../../modules/pages/createAgreement';
import OnboardingIgniter from '../../modules/widgets/onboarding';
import { BASE_URL } from '../../libs/utils/staticData';
import SettingsPage from '../../modules/pages/setting';
import UnderwritingPage from '../../modules/pages/underwriting';
import SellerPage from '../../modules/pages/seller';
import ProfilePage from '../../modules/pages/profile';
import ContractPage from '../../modules/pages/contract';
import InvoicePage from "../../modules/pages/invoice";
import UnderwriterLogPage from "../../modules/pages/underwriterLog";
import ContractAnketaPage from "../../modules/pages/contractAnketa";
import NotFoundPage from "../../modules/pages/notfound";
import Error500Page from "../../modules/pages/500";

const App: React.FC = () => (
	<ThemeContainer>
		<ApolloProvider client={client}>
			<Provider store={store}>
				<BrowserRouter basename={BASE_URL}>
					<GlobalContextProvider>
						<Routes>
							<Route path='/authorization' element={<AuthorizationPage type='default' />} />
							<Route path='/authorization/client' element={<AuthorizationPage type='client' />} />
							<Route path='/' element={<DashboardPage />} />
							<Route path='/roles/*' element={<RolesPage />} />
							<Route path='/reports' element={<ReportsPage />} />
							<Route path='/settings' element={<SettingsPage />} />
							<Route path='/agreementsLog' element={<AgreementsLogPage type='CALCULATION' />} />
							<Route path='/contractsLog' element={<AgreementsLogPage type='AGREEMENT' />} />
							<Route path='/prolongationLog' element={<AgreementsLogPage type='PROLONGATION' />} />
							<Route path='/underwriterLog' element={<UnderwriterLogPage />} />
							<Route path='/precalculation' element={<PrecalculationPage />} />
							<Route path='/createAgreement/:id/*' element={<CreateAgreementPage />} />
							<Route path='/underwriting/:id/' element={<UnderwritingPage />} />
							<Route path='/agreement/:id/' element={<SellerPage />} />
							<Route path='/contract/:id/' element={<ContractPage />} />
							<Route path='/contract/:id/anketa' element={<ContractAnketaPage />} />
							<Route path='/createInvoice/:id/' element={<InvoicePage />} />
							<Route path='/profile' element={<ProfilePage />} />
							<Route path='/error500' element={<Error500Page />} />
							<Route path='*' element={<NotFoundPage />} />
						</Routes>
					</GlobalContextProvider>
					<NotifierIgniter />
					<OnboardingIgniter />
				</BrowserRouter>
			</Provider>
		</ApolloProvider>
	</ThemeContainer>
);

export default App;
