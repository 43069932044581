import React from 'react';
import { useRiskTitleContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const RiskTitleUi: React.FC = () => {
	const { form } = useRiskTitleContext();

	return <FieldsBuilder {...form} />;
};

export default React.memo(RiskTitleUi);
