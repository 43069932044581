export type ErrorType = any;

export const getGraphqlError = (error: ErrorType): string => {
	const defaultError: string = 'Произошла ошибка';
	if (!error) return defaultError;
	if (typeof error === 'string') return error || defaultError;
	return ((((error || {}).graphQLErrors || [])[0] || {}).debugMessage || (((error || {}).graphQLErrors || [])[0] || {}).message || defaultError)
		.replace('KIAS:', '')
		.replace('ORA*', '');
};
