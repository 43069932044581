import { CalculationRisk, RiskStatusEnum } from '../../../../libs/api/graphqlTypes';
import { IFormField, TypeFormHook } from '../../../../libs/uiKit/fieldsBuilder/types';
import { RADIO_UNDERWRITING } from '../../../../libs/utils/staticData';
import { stringToMoney } from '../../../../libs/uiKit/utils';
import { RiskForm, RiskFormItem } from '../ui/context';

type RiskFormBuilderCmd = {
	risks: CalculationRisk[];
	formHook: TypeFormHook;
	arrayName: string;
	isDisabled: boolean;
	maxDiscount: number;
	totalTariff: boolean;
};

export const formatUnderwritingStatusToBack = (status: string | undefined): RiskStatusEnum | null => {
	switch (status) {
		case RADIO_UNDERWRITING[0].value:
			return RiskStatusEnum.Agreed;
		case RADIO_UNDERWRITING[1].value:
			return RiskStatusEnum.NotAgreed;
		case RADIO_UNDERWRITING[2].value:
			return RiskStatusEnum.Underwriting;
		default:
			return null;
	}
};

export const getRisksField = (risks: CalculationRisk[], arrayName: string, type: 'жизнь' | 'имущество' | 'титул'): IFormField[] =>
	risks.reduce<IFormField[]>((prev, risk, index) => {
		if (!risk) return prev;
		return [
			...prev,
			{
				label: `Риск ${type} ${risk.type}`,
				isHidden: !(risk.autoscoring && risk.underwriting_allowed),
				field: {
					fieldType: 'radio',
					fieldName: `${arrayName}[${index}].status`,
					items: RADIO_UNDERWRITING,
					// template: '1fr',
					defaultValue: RADIO_UNDERWRITING.find(({ value }) => value === risk?.status)?.value || RADIO_UNDERWRITING[0].value,
				},
			},
		];
	}, []);

export const formRiskLifeBuilder = ({ risks, formHook, isDisabled, arrayName, maxDiscount, totalTariff }: RiskFormBuilderCmd): RiskFormItem => {

	const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
		if (!risk) return prev;
		const { remark, fio, birthday, type, high_tariff_height, high_tariff_press, base_tariff, share, discount, raising_factor, tariff } = risk;

		return [
			...prev,
			{
				informationItems: [
					{ label: 'ФИО', value: fio || 'неизвестно' },
					{ label: 'Дата рождения', value: birthday || 'неизвестно' },
					{ label: 'Тип', value: type || 'неизвестно' },
				],
				remark,
				form: {
					formHook,
					fields: [
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].heightWeight`,
								defaultValue: high_tariff_height || 0,
								isDisabled,
								placeholder: 'Повышающий коэф (рост/вес) %',
								errorMessage: 'Введите повышающий коэф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].pressure`,
								defaultValue: high_tariff_press || 0,
								isDisabled,
								placeholder: 'Повышающий коэф давление %',
								errorMessage: 'Введите повышающий коэф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].base_tariff`,
								defaultValue: base_tariff || undefined,
								isDisabled: true,
								digitsAfterDot: 4,
								placeholder: 'Базовый тариф',
								errorMessage: 'Введите тариф',
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'range',
								fieldName: `${arrayName}[${index}].share`,
								defaultValue: share || undefined,
								isRequired: true,
								placeholder: 'Доля %',
								isDisabled: true,
								min: 0,
								max: 100,
								step: 1,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].discount`,
								defaultValue: Math.abs(discount || 0),
								isDisabled,
								placeholder: 'Скидка',
								errorMessage: 'Введите скидку',
								digitsAfterDot: 2,
								validationType: 'minMax',
								maxValue: maxDiscount,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].allowance`,
								isDisabled,
								placeholder: 'Повышающий коэф %',
								defaultValue: raising_factor || 0,
								errorMessage: 'Введите повышающий коэф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
						{
							isHidden: !totalTariff,
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].tariff`,
								isDisabled,
								placeholder: 'Итоговый тариф',
								defaultValue: tariff || 0,
								errorMessage: 'Введите итоговый тариф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
					],
				},
			},
		];
	}, []);

	return {
		title: 'Риск Жизнь',
		forms,
	};
};
export const formRiskPropertyBuilder = ({ risks, formHook, isDisabled, arrayName, maxDiscount }: RiskFormBuilderCmd): RiskFormItem => {
	const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
		if (!risk) return prev;
		const { address, type, property_cost, remark, raising_factor, base_tariff, discount } = risk;
		return [
			...prev,
			{
				informationItems: [
					{ label: 'Адрес имущества', value: address || 'неизвестно' },
					{ label: 'Тип имущества', value: type || 'неизвестно' },
					{ label: 'Стоимость', value: stringToMoney(property_cost) },
				],
				remark,
				form: {
					formHook,
					isDisabled,
					fields: [
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].tariff`,
								defaultValue: base_tariff || undefined,
								isRequired: true,
								digitsAfterDot: 4,
								placeholder: 'Базовый тариф',
								errorMessage: 'Введите тариф',
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].allowance`,
								placeholder: 'Повышающий коэф %',
								defaultValue: raising_factor || 0,
								errorMessage: 'Введите повышающий коэф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].discount`,
								defaultValue: Math.abs(discount || 0),
								placeholder: 'Скидка',
								errorMessage: 'Введите скидку',
								digitsAfterDot: 2,
								validationType: 'minMax',
								maxValue: maxDiscount,
								grid: 4,
							},
						},
					],
				},
			},
		];
	}, []);

	return {
		title: 'Риск Имущество',
		forms,
	};
};
export const formRiskTitleBuilder = ({ risks, formHook, isDisabled, arrayName, maxDiscount }: RiskFormBuilderCmd): RiskFormItem => {
	const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
		if (!risk) return prev;
		const { type, address, remark, raising_factor, base_tariff, discount } = risk;
		return [
			...prev,
			{
				informationItems: [
					{ label: 'Титульный адрес', value: address || 'неизвестно' },
					{ label: 'Тип титула', value: type || 'неизвестно' },
				],
				remark,
				form: {
					formHook,
					isDisabled,
					fields: [
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].tariff`,
								defaultValue: base_tariff || undefined,
								isRequired: true,
								digitsAfterDot: 4,
								placeholder: 'Базовый тариф',
								errorMessage: 'Введите тариф',
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].allowance`,
								defaultValue: raising_factor || 0,
								placeholder: 'Повышающий коэф %',
								errorMessage: 'Введите повышающий коэф',
								digitsAfterDot: 2,
								grid: 4,
							},
						},
						{
							field: {
								fieldType: 'number',
								fieldName: `${arrayName}[${index}].discount`,
								defaultValue: Math.abs(discount || 0),
								placeholder: 'Скидка',
								errorMessage: 'Введите скидку',
								digitsAfterDot: 2,
								validationType: 'minMax',
								maxValue: maxDiscount,
								grid: 4,
							},
						},
					],
				},
			},
		];
	}, []);

	return {
		title: 'Риск Титул',
		forms,
	};
};
