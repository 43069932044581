import React from 'react';
import PropertyUi from './ui';
import PropertyContextProvider, { PropertyContextProps } from './ui/context';

const PropertyIgniter: React.FC<PropertyContextProps> = props => (
	<PropertyContextProvider {...props}>
		<PropertyUi />
	</PropertyContextProvider>
);

export default React.memo(PropertyIgniter);
