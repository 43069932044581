import React from 'react';
import ReportsUi from './ui';
import ReportsContextProvider from './ui/context';

const ReportsPage: React.FC = () => (
	<ReportsContextProvider>
		<ReportsUi />
	</ReportsContextProvider>
);

export default React.memo(ReportsPage);
