import styled from 'styled-components';

export const StyledDeleteRoleModalBody = styled.div`
	margin: 0 6rem;
	h3 {
		margin: 0 0 4rem 0;
		text-align: center;
	}
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StyledButtonsGroup = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
	}
`;
