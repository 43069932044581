import React, { useCallback, useEffect, useState } from "react";
import { Image, StyledClosePreview, StyledImagePreviewWrapper, StyledPopup } from "./styles";
import { IImagePreviewProps } from "./types";
import Button from "../button";
import { CloseIcon } from "../icons/Close";
import ClickAway from "../clickAway";

const ImagePreview: React.FC<IImagePreviewProps> = props => {
  const { onChange, isOpen = false, src, alt } = props;
  const [statePreviewIsOpen, setPreviewIsOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setPreviewIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!onChange) return;
    onChange(statePreviewIsOpen);
  }, [statePreviewIsOpen, onChange]);

  const handleOpen = useCallback(() => setPreviewIsOpen(true), [setPreviewIsOpen]);

  const handleClose = useCallback(
    evt => {
      evt.stopPropagation();
      setPreviewIsOpen(false);
    },
    [setPreviewIsOpen]
  );

  return (
    <StyledImagePreviewWrapper onClick={handleOpen}>
      <ClickAway onClickAway={() => setPreviewIsOpen(false)} isOpen={statePreviewIsOpen}>
        <StyledPopup isOpen={statePreviewIsOpen}>
          <StyledClosePreview isOpen={statePreviewIsOpen}>
            <Button appearance="icon" icon={<CloseIcon />} onClick={handleClose} />
          </StyledClosePreview>
          <Image src={src} alt={alt} />
        </StyledPopup>
      </ClickAway>
    </StyledImagePreviewWrapper>
  );
};

export default React.memo(ImagePreview);
