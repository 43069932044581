import React from 'react';

export const TrashIcon: React.FC = () => (
	<svg width='2rem' height='2rem' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M2.7 3.70007L4.05 19.0001H15.75L17.1 3.70007M0 3.70007H19.8' stroke='currentColor' strokeWidth='1.5' />
		<path d='M13.4997 3.7V1H6.29968V3.7' stroke='currentColor' strokeWidth='1.5' />
		<path d='M7.60974 16.3L6.95456 6.72717' stroke='currentColor' strokeWidth='1.5' />
		<path d='M12.0267 16.3001L12.6819 6.72729' stroke='currentColor' strokeWidth='1.5' />
	</svg>
);
