import styled, { css } from 'styled-components';
import { ANIMATION_SEC, Z_INDEX_MODAL } from '../globalStyles';
import { TDropdownDirections } from './types';

const getPosition = (props: { direction: TDropdownDirections; offset: number }) => {
	switch (props.direction) {
		case 'down':
			return css`
				top: calc(100% + ${props.offset}px);
				right: 0;
			`;
		case 'up':
			return css`
				bottom: calc(100% + ${props.offset}px);
				right: 0;
			`;
		case 'left':
			return css`
				right: calc(100% + ${props.offset}px);
				top: -50%;
			`;
		case 'right':
			return css`
				left: calc(100% + ${props.offset}px);
				top: -50%;
			`;
	}
};

export const StyledMenuWrapper = styled.div`
	position: relative;
`;
export const StyledDropDownTrigger = styled.div``;

export const StyledMenuList = styled.div<{ isOpen: boolean; offset: number; direction: TDropdownDirections }>`
	position: absolute;
	z-index: ${Z_INDEX_MODAL};
	background: ${({ theme }) => theme.dropdownMenu.bg};
	display: ${props => (props.isOpen ? 'block' : 'none')};
	padding: 0.5rem 0;
	box-shadow: ${({ theme }) => theme.dropdownMenu.shadow};
	transition: all ${ANIMATION_SEC}s ease;

	${props => getPosition(props)}
`;

export const StyledMenuItem = styled.button`
	display: block;
	width: 100%;
	padding: 1rem;
	white-space: nowrap;
	color: ${({ theme }) => theme.dropdownMenu.item.color};
	transition: all ${ANIMATION_SEC}s ease;
	border: none;
	background: none;

	&:hover {
		background: ${({ theme }) => theme.dropdownMenu.item.hover.bg};
		color: ${({ theme }) => theme.dropdownMenu.item.hover.color};
	}

	&:active {
		background: ${({ theme }) => theme.dropdownMenu.item.active.bg};
		color: ${({ theme }) => theme.dropdownMenu.item.active.color};
	}
`;
