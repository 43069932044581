import styled, { css } from 'styled-components';
import { Z_INDEX_LITTLE_BIT, white } from '../../globalStyles';

export const WrapperDropDownDaDataContent = styled.div<{ height: number; dropDownHelpMessage?: string }>`
	position: absolute;
	width: 100%;
	background: ${({ theme }) => theme.select.bg};
	box-sizing: border-box;
	border: 1px solid ${({ theme }) => theme.select.border};
	margin-top: -1px;
	padding-top: 0.5rem;
	overflow-y: auto;
	z-index: ${Z_INDEX_LITTLE_BIT};
	max-height: ${({ height }) => height}rem;
	.highlight {
		background-color: #79b8ff;
	}
	// &:after {
	// 	content: '${({ dropDownHelpMessage }) => dropDownHelpMessage || 'Для навигации можно использовать \u2191, \u2193 и Enter'}';
	// 	position: sticky;
	// 	bottom: 0;
	// 	padding: 5px 0 5px 2rem;
	// 	font-weight: bold;
	// 	font-size: 1.3rem;
	// 	background-color: ${white};
	// 	display: block;
	// 	width: 100%;
	// 	box-sizing: border-box;
	// }
`;

export const SelectItemDropDownDaDataContent = styled.div<{ active: boolean }>`
	padding: 1.5rem 1rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;

	${({ active }) =>
		active &&
		css`
			background: ${({ theme }) => theme.select.item.hover.bg};
			color: ${({ theme }) => theme.select.item.hover.color};
		`}
`;

export const DropDownDaDataContentEmpty = styled.div`
	padding: 1rem;
	text-align: center;
`;
