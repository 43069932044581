import React, { createContext, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { DeleteUserMutation, DeleteUserMutationVariables } from '../../../../../../../libs/api/graphqlTypes';
import { DELETE_USER } from '../../../../../../../libs/api/commands';

export interface DeleteUserContextProps extends IModalProps {
	id?: number;
	deleteCallback?: () => void;
}

interface DeleteUserContext extends IModalProps {
	deleteHandler: () => void;
	isLoading: boolean;
}

const DeleteUserContext = createContext<DeleteUserContext>({} as DeleteUserContext);

export const useDeleteUserContext = (): DeleteUserContext => useContext(DeleteUserContext);

export const DeleteUserContextProvider: React.FC<DeleteUserContextProps> = ({ children, id, deleteCallback, isOpen, onClose }) => {
	const { setNotification } = useNotification();

	const [deleteUser, { loading: loadingDeleteUser }] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER, { refetchQueries: ['users'] });

	const deleteHandler = React.useCallback(() => {
		if (Number.isNaN(Number(id))) return setNotification({ type: 'error', text: 'Не верный id' });
		deleteUser({
			variables: {
				id: Number(id),
			},
		})
			.then(() => {
				onClose();
				if (deleteCallback) deleteCallback();
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [id, deleteCallback, deleteUser, setNotification, onClose]);

	const value: DeleteUserContext = React.useMemo(
		() => ({
			deleteHandler,
			onClose,
			isOpen,
			isLoading: loadingDeleteUser,
		}),
		[deleteHandler, isOpen, onClose, loadingDeleteUser]
	);

	return <DeleteUserContext.Provider value={value}>{children}</DeleteUserContext.Provider>;
};
