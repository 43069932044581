import React from 'react';
import { UserItem } from './context';
import { StyledGroupToggle, StyledGroupToggleHeader } from './styles';
import { Text3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../../../libs/uiKit/button';
import { TrashIcon } from '../../../../../../icons/ui/Trash';
import { useRolesContext } from '../../../ui/context';

const GroupUserItem: React.FC<UserItem> = user => {
	const { openCreateUserHandler, openDeleteUserHandler } = useRolesContext();
	const { id, fullName, isEnabled } = user;

	return (
		<StyledGroupToggle>
			<StyledGroupToggleHeader onClick={(): void => openCreateUserHandler({ ...user, isEdit: true })} isEnabled={isEnabled}>
				<Text3>
					{fullName}
					{!isEnabled && ' (Не активен)'}
				</Text3>
			</StyledGroupToggleHeader>
			<Button appearance='icon' icon={<TrashIcon />} onClick={(): void => openDeleteUserHandler(id)} />
		</StyledGroupToggle>
	);
};

export default React.memo(GroupUserItem);
