import React from "react";
import {StyledLoadingString} from "./styles";
import {ILoadingStringProps} from "./types";

export const LoadingString: React.FC<ILoadingStringProps> = React.memo(props => {
  const {width, height, isLoading, children} = props;
  if (isLoading) return (
    <StyledLoadingString width={typeof width === "number" ? width : 12}
                         height={typeof height === "number" ? height : 1.5}/>
  )
  return <>{children}</>
});

export default LoadingString;