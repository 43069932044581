import { CalculationRisk, LoadContractQuery } from '../../../../../libs/api/graphqlTypes';
import { getClearSelectValue } from '../../../../../libs/utils/getSelectdefaultValue';
import { formatStringRuToDate } from '../../../../../libs/utils/gormatDateToUsa';
import { DEFAULT_CONTRAGENT_DATA, formatPersonDataToDataForm } from '../../../createAgreement/ds/repositories/commands';
import { TypeFormHook } from '../../../../../libs/uiKit/fieldsBuilder/types';
import { RiskLifeField } from '../../widgets/riskLife/ui/context';
import { RiskPropertyField } from '../../widgets/riskProperty/ui/context';
import { RiskTitleField } from '../../widgets/riskTitle/ui/context';
import {ContractForm} from "../../index";

export const setDataToContractForm = (data: ContractForm, formHook: TypeFormHook) => {
	Object.entries(data).forEach(([key, value]) => {
		if (value === null || value === undefined) return;
		formHook.setValue(key, value);
	});
};

const formatContractRiskLifeToFormRiskLife = (risk: CalculationRisk): RiskLifeField => ({
	type: risk.type || undefined,
	share: risk.share || undefined,
	name: risk.fio || undefined,
	summ: risk.limit_sum || 0,
	heightWeight: risk.high_tariff_height || 0,
	pressure: risk.high_tariff_press || 0,
	discount: risk.discount || 0,
	allowance: risk.raising_factor || 0,
	tariff: risk.tariff || 0,
	premium: risk.premium_sum || 0,
});
const formatContractRiskPropertyToFormRiskProperty = (risk: CalculationRisk): RiskPropertyField => ({
	type: risk.type || undefined,
	address: risk.address || undefined,
	summ: risk.limit_sum || undefined,
	discount: risk.discount || 0,
	basicTariff: risk.base_tariff || undefined,
	premium: risk.premium_sum || undefined,
	allowance: risk.raising_factor || 0,
	permanentSum: risk.permanent_sum || false,
	oneTimePay: risk.one_time_pay || false,
});
const formatContractRiskTitleToFormRiskTitle = (risk: CalculationRisk): RiskTitleField => ({
	address: risk.address || undefined,
	dateEnd: risk.date_end ? formatStringRuToDate(risk.date_end) : null,
	summ: risk.limit_sum || undefined,
	tariff: risk.tariff || undefined,
	premium: risk.premium_sum || undefined,
	permanentSum: risk.permanent_sum || false,
	oneTimePay: risk.one_time_pay || false,
});

export const formatContractDataToFormData = (contractData: LoadContractQuery): ContractForm => {
	const { agreement } = contractData.loadAgreement || {};
	const {
		agreement_no,
		date_begin,
		date_end,
		date_sign,
		credit_agreement_number,
		credit_months,
		sale_channel,
		policyholder,
		currency,
		ins_purpose,
		credit_bank_text,
		credit_sum,
		credit_annuitet_sum,
		credit_rate,
		credit_agreement_date,
		ins_previous,
		life_risks,
		property_risks,
		titul_risks,
	} = agreement || {};

	return {
		contragents: policyholder ? [formatPersonDataToDataForm(policyholder)] : [DEFAULT_CONTRAGENT_DATA],
		risksLife: (life_risks || []).reduce<RiskLifeField[]>((prev, risk) => (risk ? [...prev, formatContractRiskLifeToFormRiskLife(risk)] : prev), []),
		risksProperty: (property_risks || []).reduce<RiskPropertyField[]>((prev, risk) => (risk ? [...prev, formatContractRiskPropertyToFormRiskProperty(risk)] : prev), []),
		risksTitle: (titul_risks || []).reduce<RiskTitleField[]>((prev, risk) => (risk ? [...prev, formatContractRiskTitleToFormRiskTitle(risk)] : prev), []),
		contractNumber: agreement_no || '',
		creditNumber: credit_agreement_number || '',
		dateSign: date_sign ? formatStringRuToDate(date_sign) : null,
		borrower: {
			value: policyholder?.fio || '',
		},
		dateBeg: date_begin ? formatStringRuToDate(date_begin) : null,
		creditTermsInfo: credit_months || undefined,
		creditTerms: credit_months || undefined,
		dateEnd: date_end ? formatStringRuToDate(date_end) : null,
		exactDateEnd: date_end ? formatStringRuToDate(date_end) : null,
		curIsn: currency || '',
		annuityPay: credit_annuitet_sum || undefined,
		insureType: getClearSelectValue(ins_purpose),
		prevInsuranceCompany: getClearSelectValue(ins_previous), // todo
		creditOrganizationName: credit_bank_text || undefined,
		summ: credit_sum || undefined,
		creditRate: credit_rate || undefined,
		creditIssueDate: null, // todo
		firstPayDate: null, // todo
		agreementDate: credit_agreement_date ? formatStringRuToDate(credit_agreement_date) : null,
		salesChannel: getClearSelectValue(sale_channel),
		useExactDateEnd: false,
	};
};
