import React from 'react';
import { GroupItem, useGroupTabContext, UserItem } from './context';
import { StyledEmptyGroup, StyledGroupToggleBody } from './styles';
import { TextButton } from '../../../../../../libs/uiKit/globalStyledComponents';
import GroupToggle from './GroupToggle';
import GroupUserItem from './GroupUser';

const GroupToggleBody: React.FC<{ currentGroup: GroupItem }> = ({ currentGroup }) => {
	const { groups, users } = useGroupTabContext();
	const { specialType } = currentGroup || {};

	const groupsToRender: GroupItem[] = specialType === 'withoutUsers' ? [] : groups.filter(({ parentId }) => parentId === currentGroup.id);
	const usersToRender: UserItem[] = specialType === 'withoutUsers' ? users.filter(({ groupId }) => !groupId) : users.filter(({ groupId }) => groupId === currentGroup.id);

	const items: number = groupsToRender.length + usersToRender.length;

	return (
		<StyledGroupToggleBody>
			{items === 0 && (
				<StyledEmptyGroup>
					<TextButton>В этой группе пока пусто</TextButton>
				</StyledEmptyGroup>
			)}
			{!!usersToRender.length && usersToRender.map(user => <GroupUserItem key={user.id} {...user} />)}
			{!!groupsToRender.length && groupsToRender.map(group => <GroupToggle key={group.id} group={group} />)}
		</StyledGroupToggleBody>
	);
};

export default React.memo(GroupToggleBody);
