import React from 'react';
import { useSkipPrecalculationContext } from './context';
import { Modal } from '../../../../libs/uiKit/modal';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../libs/uiKit/button';
import { StyledButtonsGroup } from './styles';

const SkipPrecalculationUi: React.FC = () => {
	const { isOpen, onClose, form, isLoading, onFormSubmit, isDisabled } = useSkipPrecalculationContext();

	return (
		<Modal onClose={onClose} isOpen={isOpen} header='Переход к заявке'>
			<form onSubmit={onFormSubmit}>
				<FieldsBuilder {...form} />
				<StyledButtonsGroup>
					<Button type='submit' isDisabled={isDisabled} isLoading={isLoading} appearance='filled'>
						Создать
					</Button>
					<Button onClick={onClose} isDisabled={isDisabled} isLoading={isLoading} appearance='transparent'>
						Отмена
					</Button>
				</StyledButtonsGroup>
			</form>
		</Modal>
	);
};

export default React.memo(SkipPrecalculationUi);
