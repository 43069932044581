import styled, { css } from 'styled-components';
import { ANIMATION_SEC, Z_INDEX_LITTLE_BIT } from '../globalStyles';

export const StyledUploadFileArea = styled.div<{ isInvalid: boolean; isDisabled: boolean }>`
	cursor: pointer;
	position: relative;
	min-height: 12rem;
	width: 100%;
	transition: all ${ANIMATION_SEC}s;
	border: 1px dashed ${({ theme }) => theme.uploadFile.border};
	color: ${({ theme }) => theme.uploadFile.color};
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ isInvalid, isDisabled }) =>
		isDisabled
			? css`
					border-color: ${({ theme }) => theme.uploadFile.disabled.border};
					background: ${({ theme }) => theme.uploadFile.disabled.bg};
					cursor: no-drop;

					// placeholder
					& > span {
						color: ${({ theme }) => theme.uploadFile.placeholder.disabled.color};
					}
			  `
			: isInvalid
			? css`
					border-color: ${({ theme }) => theme.uploadFile.invalid.border};
					background: ${({ theme }) => theme.uploadFile.invalid.bg};

					// placeholder
					& > span {
						color: ${({ theme }) => theme.uploadFile.placeholder.invalid.color};
					}
			  `
			: css`
					:hover {
						border-color: ${({ theme }) => theme.uploadFile.hover.border};
						background: ${({ theme }) => theme.uploadFile.hover.bg};

						// placeholder
						& > span {
							color: ${({ theme }) => theme.uploadFile.placeholder.hover.color};
						}
					}
			  `}
`;

export const StyledPlaceholder = styled.span<{ isRequired?: boolean }>`
	color: ${({ theme }) => theme.uploadFile.placeholder.color};
	pointer-events: none;
	position: absolute;
	left: 1rem;
	top: 1rem;
	font-size: 1.4rem;
	line-height: 2rem;
	transition: all ${ANIMATION_SEC}s;

	${({ isRequired }) =>
		isRequired &&
		css`
			::before {
				content: '* ';
				color: ${({ theme }) => theme.uploadFile.requiredColor};
			}
		`}
`;

export const StyledUploadFileInput = styled.input`
	top: 0;
	left: 0;
	width: 100%;
	cursor: inherit;
	height: 100%;
	margin: 0;
	opacity: 0;
	padding: 0;
	z-index: ${Z_INDEX_LITTLE_BIT};
	position: absolute;

	:disabled {
		cursor: no-drop;
	}
`;

export const StyledFilesWrapper = styled.div`
	padding: 1.5rem;
`;

export const StyledFileItem = styled.div`
	display: inline-flex;
	align-items: center;

	> p {
		margin-right: 1rem;
	}

	padding: 0.5rem;
	margin: 0 1rem 1rem 0;

	> .iconButton {
		z-index: ${Z_INDEX_LITTLE_BIT + 1};
	}
`;

export const StyledUploadFileWrapper = styled.div`
	position: relative;
`;
