import React from 'react';

export const ContactPersonIcon: React.FC = () => (
	<svg width='1.3rem' height='1.5rem' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g>
			<path
				d='M9.39533 3.70154C9.39533 5.30576 8.09485 6.60623 6.49063 6.60623C4.88641 6.60623 3.58594 5.30576 3.58594 3.70154C3.58594 2.09732 4.88641 0.796844 6.49063 0.796844C8.09485 0.796844 9.39533 2.09732 9.39533 3.70154Z'
				fill='currentColor'
			/>
			<path d='M6.5 7.83514C2.91015 7.83514 0 10.6133 0 14.2031H13C13 10.6133 10.0899 7.83514 6.5 7.83514Z' fill='currentColor' />
		</g>
	</svg>
);
