import React from 'react';

export const SaveFileIcon: React.FC = () => (
	<svg width='1.9rem' height='1.9rem' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3 4C3 3.44772 3.44772 3 4 3H13.5858C13.851 3 14.1054 3.10536 14.2929 3.29289L17.7071 6.70711C17.8946 6.89464 18 7.149 18 7.41421V17C18 17.5523 17.5523 18 17 18H4C3.44772 18 3 17.5523 3 17V4Z'
			stroke='currentColor'
			strokeWidth='1.2'
		/>
		<circle cx='10.5' cy='13.5' r='1.9' stroke='currentColor' strokeWidth='1.2' />
		<rect x='6' y='3' width='8' height='6' stroke='currentColor' strokeWidth='1.2' strokeLinejoin='round' />
	</svg>
);
