import styled from "styled-components";

export const StyledButtonsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
`;