import styled from 'styled-components';
import { blue7, DEFAULT_SHADOW, white } from '../../../../../../libs/uiKit/globalStyledComponents';

export const StyledRoleByIdHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4rem;

	@media screen and (max-width: 767px) {
		margin-bottom: 3rem;
	}
`;

export const StyledRoleByIdTitle = styled.div`
	display: flex;
	flex-direction: column;

	h2 {
		margin-top: 1rem;
	}
`;

export const StyledRoleContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 4rem;
	grid-row-gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;
export const StyledRoleForm = styled.div`
	h3 {
		margin-bottom: 3rem;
	}
`;

export const StyledRoleName = styled.div`
	margin-bottom: 4rem;
	h3 {
		margin-bottom: 3rem;
	}
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 4rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		h3 {
			margin-bottom: 2rem;
		}
	}
`;

export const StyledRoleFunctions = styled.div`
	h3 {
		margin-bottom: 3rem;
	}
`;

export const StyledSelectedFunctionsArea = styled.div`
	box-shadow: ${DEFAULT_SHADOW};
	background: ${white};
	padding: 2rem;

	.linkButton {
		margin-top: 1rem;
	}
`;

export const StyledSelectedFunctionItem = styled.div`
	display: inline-flex;
	align-items: center;
	white-space: nowrap;

	.iconButton {
		margin-left: 0.5rem;
	}
	padding: 0.5rem 0.8rem;
	margin: 0 1rem 1rem 0;
	background: ${blue7};
`;
