import React from 'react';
import AuthorizationUi from './ui';
import AuthorizationContextProvider, { AuthorizationType } from './ui/context';

const AuthorizationPage: React.FC<{ type: AuthorizationType }> = props => {
	const { type } = props;

	return (
		<AuthorizationContextProvider type={type}>
			<AuthorizationUi />
		</AuthorizationContextProvider>
	);
};

export default React.memo(AuthorizationPage);
