import React from 'react';
import DeleteRoleUi from './ui';
import { DeleteRoleContextProps, DeleteRoleContextProvider } from './ui/context';

const DeleteRoleIgniter: React.FC<DeleteRoleContextProps> = props => (
	<DeleteRoleContextProvider {...props}>
		<DeleteRoleUi />
	</DeleteRoleContextProvider>
);

export default React.memo(DeleteRoleIgniter);
