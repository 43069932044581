import React from 'react';
import { useAnketaViewContext } from './context';
import { StyledQuestion } from './styles';
import Accordion from '../../../../../../libs/uiKit/accordion';
import { Text4 } from '../../../../../../libs/uiKit/globalStyledComponents';

const AnketaViewUi: React.FC = () => {
	const { parts, isLoading } = useAnketaViewContext();

	return (
		<>
			{parts.map(({ title, questions }, index) => (
				<Accordion header={title} isLoading={isLoading} key={`anketa-accordion-${index}`}>
					{questions.map(({ question, answer }, questionIndex) => (
						<StyledQuestion key={`anketa-question-${questionIndex}-${index}`}>
							<Text4>{question}</Text4>
							<Text4>{answer || 'Не заполнено'}</Text4>
						</StyledQuestion>
					))}
				</Accordion>
			))}
		</>
	);
};

export default React.memo(AnketaViewUi);
