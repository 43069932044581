import React from 'react';
import { useAuthorizationContext } from './context';
import { Heading1, Text2 } from '../../../../libs/uiKit/globalStyledComponents';
import { StyledFeatures, StyledFeaturesWrapper, StyledLoginForm, StyledLoginFormLayout, StyledLoginFormWrapper, StyledLoginLogo } from './styles';
import { LogoDesktop } from '../../../../icons/logo/LogoDesktop';
import LoginDefaultIgniter from '../widgets/loginDefault';
import LoginClientIgniter from '../widgets/loginClient';
import RecoveryPasswordIgniter from '../widgets/recoveryPassword';
import Button from '../../../../libs/uiKit/button';
import AuthorizationLayout from '../../common/authorizationLayout';
import CopyrightIgniter from '../../../widgets/copyright';

const AuthorizationUi: React.FC = () => {
	const { isShowRecoverPasswordButton, isShowBackToLoginButton, changeIsShowRecoverPassword, isShowRecoverPassword, isShowLoginDefault, isShowLoginClient } =
		useAuthorizationContext();

	return (
		<AuthorizationLayout>
			<StyledLoginFormWrapper>
				<StyledLoginFormLayout>
					<StyledLoginLogo>
						<LogoDesktop />
					</StyledLoginLogo>
					<StyledLoginForm>
						<Heading1>Авторизация</Heading1>
						{isShowLoginDefault && <LoginDefaultIgniter />}
						{isShowLoginClient && <LoginClientIgniter />}
						{isShowRecoverPassword && <RecoveryPasswordIgniter />}
						{isShowRecoverPasswordButton && (
							<Button onClick={(): void => changeIsShowRecoverPassword(true)} appearance='link'>
								Забыли пароль?
							</Button>
						)}
						{isShowBackToLoginButton && (
							<Button onClick={(): void => changeIsShowRecoverPassword(false)} appearance='link'>
								Уже помню! Войти
							</Button>
						)}
					</StyledLoginForm>
					<CopyrightIgniter isAuthorization={true} />
				</StyledLoginFormLayout>
			</StyledLoginFormWrapper>
			<StyledFeaturesWrapper>
				<StyledFeatures>
					<Heading1>Уважаемый клиент!</Heading1>
					<Text2>
						<span>
							Мы заботимся о том, чтобы работа в системе Абсолют была максимально удобна для вас.
							<br />
							Система Абсолют поможет вам комфортно оформить полис для каждого клиента.
							<br />
							Если у вас есть пожелания и предложения, вы можете написать нам на почту{' '}
							<a style={{ color: '#003D81' }} href='mailto:info@absolutins.ru'>
								info@absolutins.ru
							</a>
							.<br />С уважением, команда Абсолют Страхование.
						</span>
					</Text2>
				</StyledFeatures>
			</StyledFeaturesWrapper>
		</AuthorizationLayout>
	);
};

export default React.memo(AuthorizationUi);
