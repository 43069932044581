import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import {useMutation} from "@apollo/client";
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import {ApplyKvMutation, ApplyKvMutationVariables} from "../../../../../../../libs/api/graphqlTypes";
import {APPLY_KV} from "../../../../../../../libs/api/commands";

export type DiscountContextProps = {
	id: number | null;
	children?: React.ReactNode;
} & IModalProps &
	DiscountForm;

type DiscountContext = {
	onFormSubmit: () => void;
	form: IForm;
	isLoading: boolean;
} & IModalProps;

type DiscountForm = {
	discount: number;
};

const DiscountContext = createContext<DiscountContext>({} as DiscountContext);

export const useDiscountContext = (): DiscountContext => useContext(DiscountContext);

export const DiscountContextProvider: React.FC<DiscountContextProps> = props => {
	const { children, id, isOpen, onClose } = props;
	const { setNotification } = useNotification();
	const formHook = useForm<DiscountForm>({ mode: 'onBlur' });

	const [applyKv, {loading: applyKvLoading}] = useMutation<ApplyKvMutation, ApplyKvMutationVariables>(APPLY_KV, {
		refetchQueries: ['loadAgreementUnderwriting'],
		awaitRefetchQueries: true
	})

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'number',
						fieldName: 'discount',
						digitsAfterDot: 2,
						// defaultValue: discount,
						placeholder: 'Скидка за счет КВ, %',
						errorMessage: 'Введите скидку',
						validationType: 'minMax',
						minValue: 0,
						maxValue: 100,
					},
				},
			],
		}),
		[formHook]
	);

	const onFormSubmit = formHook.handleSubmit(data => {
		if(!id) return;
		applyKv({
			variables: {
				id,
				kv: data.discount
			}
		}).then(() => {
			setNotification({ type: 'success', text: 'Скидка применена' });
			onClose();
		}).catch(error => setNotification({ type: 'error', text: error }))
	});

	const value: DiscountContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isLoading: applyKvLoading,
			isOpen,
			onClose,
		}),
		[form, isOpen, onClose, onFormSubmit, applyKvLoading]
	);

	return <DiscountContext.Provider value={value}>{children}</DiscountContext.Provider>;
};
