import styled                         from 'styled-components';
import {ANIMATION_SEC, Z_INDEX_MODAL} from '../globalStyles';

export const StyledButtonTooltip = styled.span`
  pointer-events: none;
  z-index: ${Z_INDEX_MODAL};
  text-decoration: unset!important;
  opacity: 0!important;
  top: calc(100% + 0.5rem);
  position: absolute;
  padding: 2px 0.5rem;
  background: ${({theme}) => theme.tooltip.bg};
  color: ${({theme}) => theme.tooltip.color};
  left: 50%;
  transform: translate(-50%);
  transition: opacity ${ANIMATION_SEC}s;
  border-radius: 0.5rem;  
  p {
    white-space: nowrap;
  }
`;
