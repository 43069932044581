import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AccordionProps } from './types';
import { Heading3 } from '../globalStyles';
import { BodyWrapper, StyledAccordion, StyledBody, StyledEmptyToggle, StyledHeader, StyledToggle } from './styles';
import { Heading5 } from '../globalStyledComponents';
import Loader from '../loader';
import {StyledFillingTypeBadge, StyledFillingTypeBadgeContainer} from "../../../modules/pages/underwriting/ui/styles";

const Accordion: React.FC<AccordionProps> = props => {
	const { header, children, isOpen, onChange, isNotUnmount, emptyMessage, isLoading, badge } = props;

	const headerRef = React.useRef<HTMLDivElement | null>(null);

	const [stateIsOpen, setIsOpen] = useState<boolean>(!!isOpen);
	const [stateBodyHeight, setBodyHeight] = useState<number>(1);
	const contentAccordion = useRef(null);

	useEffect(() => {
		if (typeof isOpen !== 'boolean') return;
		setIsOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		if (!stateIsOpen || !headerRef.current) return;
		const rect = headerRef.current.getBoundingClientRect();

		if (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
			return;

		console.log('scroll to', rect.top + window.scrollY - 150);

		/* window.scrollTo({
			left: 0,
			top: rect.top + window.scrollY - 150,
			behavior: 'smooth',
		}); */
	}, [stateIsOpen]);

	useEffect(() => {
		if (contentAccordion.current) {
			const { clientHeight: height } = contentAccordion.current;
			setBodyHeight(height);
		}
	}, [stateIsOpen, children]);

	const onChangeHandler = useCallback(() => {
		setIsOpen(!stateIsOpen);
		if (!onChange) return;
		onChange(!stateIsOpen);
	}, [stateIsOpen, onChange]);

	return (
		<StyledAccordion className='accordion' isOpen={stateIsOpen}>
			<StyledHeader onClick={onChangeHandler} className='accordionHeader' ref={headerRef}>
				{typeof header === 'string' && (
					<>
						<Heading3>{header}</Heading3>
						{badge &&
							<StyledFillingTypeBadgeContainer>
								<StyledFillingTypeBadge>{badge}</StyledFillingTypeBadge>
							</StyledFillingTypeBadgeContainer>
						}
						<StyledToggle isOpen={stateIsOpen} />
					</>
				)}
				{typeof header !== 'string' && (
					<>
						{header}
						<StyledToggle isOpen={stateIsOpen} />
					</>
				)}
			</StyledHeader>
			<BodyWrapper isOpen={stateIsOpen} resultHeight={stateBodyHeight}>
				<StyledBody ref={contentAccordion}>
					<CSSTransition timeout={300} classNames='acc' in={stateIsOpen} mountOnEnter={!isNotUnmount} unmountOnExit={!isNotUnmount}>
						<>
							{isLoading && <Loader loaderWidth={3} />}
							{!isLoading && (
								<>
									{children}
									{emptyMessage && (
										<StyledEmptyToggle className='emptyToggleMessage'>
											<Heading5>{emptyMessage}</Heading5>
										</StyledEmptyToggle>
									)}
								</>
							)}
						</>
					</CSSTransition>
				</StyledBody>
			</BodyWrapper>
		</StyledAccordion>
	);
};

export default React.memo(Accordion);
