import React from 'react';
import TitleUi from './ui';
import TitleContextProvider from './ui/context';

const TitlePage: React.FC = () => (
	<TitleContextProvider>
		<TitleUi />
	</TitleContextProvider>
);

export default React.memo(TitlePage);
