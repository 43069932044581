import styled from 'styled-components';
// heading
export const Heading1 = styled.h1`
	font-family: 'Futura PT Demi';
	font-size: 4rem;
	line-height: 5rem;
	font-weight: 600;
	@media screen and (max-width: 767px) {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}
`;
export const Heading2 = styled.h2`
	font-family: 'Futura PT Demi', serif;
	font-size: 3.2rem;
	line-height: 4rem;
	font-weight: 600;
	@media screen and (max-width: 767px) {
		font-size: 2.2rem;
		line-height: 3rem;
	}
`;
export const Heading3 = styled.h3`
	font-family: 'Futura PT Demi', serif;
	font-size: 2.4rem;
	line-height: 3rem;
	font-weight: 600;
`;
export const Heading4 = styled.h4`
	font-family: 'Verdana';
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
export const Heading5 = styled.h5`
	font-family: 'Verdana';
	font-weight: bold;
	font-size: 1.4rem;
	line-height: 2rem;
`;

// text
export const Text1 = styled.p`
	font-family: 'Verdana';
	font-size: 2rem;
	line-height: 3.2rem;
`;
export const Text2 = styled.p`
	font-family: 'Verdana';
	font-size: 1.6rem;
	line-height: 2.4rem;
`;
export const Text3 = styled.p`
	font-family: 'Verdana';
	font-size: 1.4rem;
	line-height: 2.2rem;
`;
export const Text4 = styled.p`
	font-family: 'Verdana';
	font-size: 1.2rem;
	line-height: 2rem;
	white-space: pre-line;
`;
export const TextButton = styled.p`
	font-family: 'Verdana';
	font-size: 1.4rem;
	line-height: 2rem;
`;
export const TextSubtitle = styled.p`
	font-family: 'Verdana';
	font-weight: bold;
	font-size: 1.1rem;
	line-height: 1.5rem;
`;
export const TextCaption = styled.p`
	font-family: 'Verdana';
	font-size: 1rem;
	line-height: 1.5rem;
`;

// colors
export const black = '#000000';
export const darkGrey = '#464646';
export const greyNotification = '#E6E6E6';
export const black70 = 'rgba(0, 0, 0, 0.7)';
export const black50 = 'rgba(0, 0, 0, 0.5)';
export const black40 = 'rgba(0, 0, 0, 0.4)';
export const black20 = 'rgba(0, 0, 0, 0.2)';
export const black10 = 'rgba(0, 0, 0, 0.1)';
export const black3 = 'rgba(0, 0, 0, 0.03)';
export const white = '#ffffff';
export const grey20 = 'rgba(204, 204, 204, 0.2)';
export const grey40 = 'rgba(204, 204, 204, 0.4)';
export const grey = '#cccccc';
export const halfDarkGrey = '#999999';
export const lightBlue = '#41B6E6';
export const lightBlueHover = '#299DCD';
export const lightBlueClick = '#037BAC';
export const blue = '#003D81';
export const blueClick = '#012752';
export const blue7 = 'rgba(0,61,129,0.07)';
export const blue3 = 'rgba(0, 61, 129, 0.03)';
export const blue10 = 'rgba(0, 61, 129, 0.1)';
export const blue5 = 'rgba(0, 61, 129, 0.05)';
export const red = '#D23630';
export const red20 = 'rgba(210, 54, 48, 0.2)';
export const yellow = '#F3AB46';
export const green = '#397E2D';
export const lightGreen = '#62B653';

export const DEFAULT_SHADOW: string = '0px 2px 20px rgba(0, 0, 0, 0.08)';
