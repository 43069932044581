import styled from 'styled-components';
import { blue3, red, red20 } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledCommentToggleWrapper = styled.div`
	.emptyToggleMessage {
		padding-left: 4rem;
	}

	@media screen and (max-width: 767px) {
		.emptyToggleMessage {
			padding-left: 1.5rem;
		}
	}
`;

export const StyledComments = styled.div`
	max-height: 50rem;
	overflow: auto;
	> .linkButton {
		margin: 0 0 1.5rem 4rem;
	}

	@media screen and (max-width: 767px) {
		> .linkButton {
			margin: 0 0 1.5rem 0;
		}
		margin: 0 0 1.5rem 1.5rem;
	}
`;

export const StyledCommentItem = styled.div<{ isNew: boolean }>`
	display: flex;
	align-items: flex-start;
	padding: 1.5rem 4rem 1.5rem 4rem;
	transition: background-color 0.5s;
	background: ${({ isNew }) => (isNew ? blue3 : 'unset')};

	@media screen and (max-width: 767px) {
		padding: 1.5rem;
	}
`;
export const StyledCommentHeader = styled.div`
	display: flex;
	align-items: center;

	h5:last-child {
		opacity: 0.5;
		margin-left: 1rem;
	}

	margin-bottom: 1.5rem;
`;

export const StyledProfileLogo = styled.div`
	min-width: 1.9rem;
	max-width: 1.9rem;
	height: 1.9rem;
	border-radius: 50%;
	margin-right: 1rem;
	position: relative;

	> img {
		width: 100%;
	}
	> svg {
		opacity: 0.5;
	}
`;

export const StyledCommentBody = styled.div``;

export const StyledAddNewComment = styled.div`
	padding: 0 4rem;
	@media screen and (max-width: 767px) {
		padding: 0 1.5rem 0 0;
	}
`;

export const StyledButtonsGroup = styled.div`
	width: 100%;

	display: flex;

	button {
		width: calc(50% - 0.5rem);
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
`;

export const StyledToggleNewItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;
export const StyledToggleNewItems = styled.div`
	color: ${red};
	background: ${red20};
	border-radius: 30rem;
	padding: 0.5rem 1rem;
	margin-left: 2rem;
`;
