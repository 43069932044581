import React from 'react';
import {useWindowWidth} from "@react-hook/window-size";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {useForm} from "react-hook-form";
import MainLayoutIgniter from "../common/mainLayout";
import {
	StyledCalculationDataWrapper,
	StyledEventsButtons, StyledFillingTypeBadge,
	StyledHeaderEvents,
	StyledHeaderInformation,
	StyledHeaderWrapper,
	StyledImportantNumber,
	StyledInformationItem, StyledRemark, StyledRiskFormWrapper, StyledRiskInformation,
	StyledSignetAgreementButtons,
	StyledSumm,
	StyledSummsWrapper, StyledTableAreaWrapper, StyledToggleAreaLeft, StyledToggleAreaWrapper,
	StyledUnderwritingHeader,
	StyledUnderwritingWrapper
} from "../underwriting/ui/styles";
import Button from "../../../libs/uiKit/button";
import {ArrowIcon} from "../../../icons/ui/Arrow";
import {Heading2, Heading3, Heading4, Text4, TextButton} from "../../../libs/uiKit/globalStyles";
import DropdownMenu from "../../../libs/uiKit/dropdownMenu";
import {MoreIcon} from "../../../icons/ui/More";
import FlyButtons from "../../../libs/uiKit/flyButtons";
import LoadingString from "../../../libs/uiKit/loadingString";
import Accordion from "../../../libs/uiKit/accordion";
import {FieldsBuilder} from "../../../libs/uiKit/fieldsBuilder";
import {QuestionIcon} from "../../../icons/ui/Question";
import AgreementTableIgniter from "../../widgets/agreementTable";
import AnketaViewIgniter from "./widgets/anketaView";
import DocumentsWidget from "../../widgets/documents";
import DiscussionIgniter from "../../widgets/disscusion";
import AgreementChangeLogIgniter from "../../widgets/agreementChangeLog";
import DiscountIgniter from "./widgets/discount";
import DisapproveIgniter from "./widgets/disapprove";
import {useGlobalContext} from "../../../apps/main/ui/GlobalContext";
import {useUserRights} from "../common/hooks/useUserRights";
import useNotification from "../../widgets/notifier/ui/hooks/useNitification";
import {AgreementId} from "../createAgreement/ui/context";
import {
	CalculationRisk, ChangeCalculationMutation, ChangeCalculationMutationVariables,
	LoadAgreementUnderwritingQuery,
	LoadAgreementUnderwritingQueryVariables,
	RegisterAgreementMutation, RegisterAgreementMutationVariables, ResultDocument, useRegisterAgreementMutation
} from "../../../libs/api/graphqlTypes";
import {LOAD_AGREEMENT} from "../../../libs/api/queries";
import {CHANGE_UNDERWRITING, REGISTER_CONTRACT} from "../../../libs/api/commands";
import {RiskForm, RiskFormItem} from "../underwriting/ui/context";
import {DiscountIcon} from "../../../icons/ui/Discount";
import {TimeReloadIcon} from "../../../icons/ui/TimeReload";
import {PersonWithArrowIcon} from "../../../icons/ui/PersonWithArrow";
import {stringToMoney} from "../../../libs/uiKit/utils";
import {TypeFormHook} from "../../../libs/uiKit/fieldsBuilder/types";
import {formatUnderwritingStatusToBack} from "../underwriting/ds/commands";
import SendToSbModal from "./widgets/SendToSbModal";

type HeaderButton = { label: string; event: () => void; icon: React.ReactNode; isLoading?: boolean; isDisabled?: boolean, isHidden?: boolean };

type InformationItem = { title: string; description: string; color?: string | null; isStatus?: boolean };

type RiskField = {
	discount?: number;
	allowance?: number;
};

type SellerForm = {
	risksLife: RiskField[];
	risksProperty: RiskField[];
	risksTitle: RiskField[];
};

type RiskFormBuilderCmd = {
	risks: CalculationRisk[];
	formHook: TypeFormHook;
	arrayName: string;
	isDisabled: boolean;
};

const SellerPage: React.FC = () => {

	const {
		user: {
			restrictions: {
				maxDiscount
			}
		},
		routes: { contractById, agreementsLog },
	} = useGlobalContext();

	const { rightsHandler } = useUserRights();
	const { id } = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const { setNotification, showError } = useNotification();

	const agreementId: AgreementId = Number.isNaN(Number(id)) ? null : Number(id);
	const [stateChangeLogModalIsOpen, setChangeLogModalIsOpen] = React.useState<boolean>(false);
	const [stateDiscountModalIsOpen, setDiscountModalIsOpen] = React.useState<boolean>(false);
	const [stateDisapproveModalIsOpen, setDisapproveModalIsOpen] = React.useState<boolean>(false);

	const { data: dataAgreement, loading: loadingAgreement } = useQuery<LoadAgreementUnderwritingQuery, LoadAgreementUnderwritingQueryVariables>(LOAD_AGREEMENT, {
		variables: { id: agreementId },
		onError: error => setNotification({ type: 'error', text: error }),
	});

	const [registerContract, { loading: loadingRegisterContract }] = useRegisterAgreementMutation({
		refetchQueries: ['loadAgreementUnderwriting'],
	});

	const [changeCalculation, { loading: loadingChangeCalculation }] = useMutation<ChangeCalculationMutation, ChangeCalculationMutationVariables>(CHANGE_UNDERWRITING, {
		refetchQueries: ['loadAgreementUnderwriting'],
		awaitRefetchQueries: true,
	});

	const { number, added, user, status, calculation, relatedAgreementId: contractId, resultDocuments, fillingTypeText } = dataAgreement?.loadAgreement || {};
	const { premium_sum, premium_sum_with_kv, seller_discount_due_kv, life_risks, property_risks, titul_risks, policyholder_email } = calculation || {};

	const isCanceled: boolean = ['annul', 'clientRefusal'].includes(status?.value || '');
	const risksLife: CalculationRisk[] = (life_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksProperty: CalculationRisk[] = (property_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const risksTitle: CalculationRisk[] = (titul_risks || []).reduce<CalculationRisk[]>((prev, risk) => (risk ? [...prev, risk] : prev), []);
	const isReadyToRegister: boolean = ![...risksLife, ...risksProperty, ...risksTitle].find(risk => risk.status === 'UNDERWRITING');

	const goToAgreementHandler = () => {
		navigate(contractById(String(contractId)))
	}
	const goBackHandler = React.useCallback(() => navigate(agreementsLog()), [navigate]);

	const formHook = useForm<SellerForm>({ mode: 'onBlur' });

	const isFormBlocked = isCanceled  || !!contractId;

	const canApplyAllowance = rightsHandler('applyAllowance');

	const formRiskLifeBuilder = ({ risks, formHook, isDisabled, arrayName }: RiskFormBuilderCmd): RiskFormItem => {
		const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
			if (!risk) return prev;
			const { remark, fio, birthday, type, high_tariff_height, high_tariff_press, base_tariff, share, discount, raising_factor } = risk;

			return [
				...prev,
				{
					informationItems: [
						{ label: 'ФИО', value: fio || 'неизвестно' },
						{ label: 'Дата рождения', value: birthday || 'неизвестно' },
						{ label: 'Тип', value: type || 'неизвестно' },
					],
					remark,
					form: {
						formHook,
						fields: [
							{
								isHidden: !canApplyAllowance,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].allowance`,
									isDisabled,
									placeholder: 'Повышающий коэф %',
									defaultValue: raising_factor || 0,
									errorMessage: 'Введите повышающий коэф',
									digitsAfterDot: 2,
									grid: 4,
								},
							},
							{
								isHidden: !maxDiscount,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].discount`,
									defaultValue: Math.abs(discount || 0),
									isDisabled,
									placeholder: 'Скидка',
									errorMessage: 'Введите скидку',
									digitsAfterDot: 2,
									validationType: 'minMax',
									maxValue: maxDiscount,
									grid: 4,
								},
							},
						],
					},
				},
			];
		}, []);

		return {
			title: 'Риск Жизнь',
			forms,
		};
	};
	const formRiskPropertyBuilder = ({ risks, formHook, isDisabled, arrayName }: RiskFormBuilderCmd): RiskFormItem => {
		const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
			if (!risk) return prev;
			const { address, type, property_cost, remark, raising_factor, base_tariff, discount } = risk;
			return [
				...prev,
				{
					informationItems: [
						{ label: 'Адрес имущества', value: address || 'неизвестно' },
						{ label: 'Тип имущества', value: type || 'неизвестно' },
						{ label: 'Стоимость', value: stringToMoney(property_cost) },
					],
					remark,
					form: {
						formHook,
						isDisabled,
						fields: [
							{
								isHidden: !canApplyAllowance,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].allowance`,
									placeholder: 'Повышающий коэф %',
									defaultValue: raising_factor || 0,
									errorMessage: 'Введите повышающий коэф',
									digitsAfterDot: 2,
									grid: 4,
								},
							},
							{
								isHidden: !maxDiscount,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].discount`,
									defaultValue: Math.abs(discount || 0),
									placeholder: 'Скидка',
									errorMessage: 'Введите скидку',
									digitsAfterDot: 2,
									validationType: 'minMax',
									maxValue: maxDiscount,
									grid: 4,
								},
							},
						],
					},
				},
			];
		}, []);

		return {
			title: 'Риск Имущество',
			forms,
		};
	};
	const formRiskTitleBuilder = ({ risks, formHook, isDisabled, arrayName }: RiskFormBuilderCmd): RiskFormItem => {
		const forms = risks.reduce<RiskForm[]>((prev, risk, index) => {
			if (!risk) return prev;
			const { type, address, remark, raising_factor, base_tariff, discount } = risk;
			return [
				...prev,
				{
					informationItems: [
						{ label: 'Титульный адрес', value: address || 'неизвестно' },
						{ label: 'Тип титула', value: type || 'неизвестно' },
					],
					remark,
					form: {
						formHook,
						isDisabled,
						fields: [
							{
								isHidden: !canApplyAllowance,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].allowance`,
									defaultValue: raising_factor || 0,
									placeholder: 'Повышающий коэф %',
									errorMessage: 'Введите повышающий коэф',
									digitsAfterDot: 2,
									grid: 4,
								},
							},
							{
								isHidden: !maxDiscount,
								field: {
									fieldType: 'number',
									fieldName: `${arrayName}[${index}].discount`,
									defaultValue: Math.abs(discount || 0),
									placeholder: 'Скидка',
									errorMessage: 'Введите скидку',
									digitsAfterDot: 2,
									validationType: 'minMax',
									maxValue: maxDiscount,
									grid: 4,
								},
							}
						],
					},
				},
			];
		}, []);

		return {
			title: 'Риск Титул',
			forms,
		};
	};

	const riskFormItems: RiskFormItem[] = React.useMemo(
		() => [
			{ ...formRiskLifeBuilder({ risks: risksLife, formHook, isDisabled: isFormBlocked, arrayName: 'risksLife' }) },
			{ ...formRiskPropertyBuilder({ risks: risksProperty, formHook, isDisabled: isFormBlocked, arrayName: 'risksProperty' }) },
			{ ...formRiskTitleBuilder({ risks: risksTitle, formHook, isDisabled: isFormBlocked, arrayName: 'risksTitle' }) },
		],
		[formHook, isFormBlocked, risksLife, risksProperty, risksTitle]
	);

	const createContractHandler = () => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		registerContract({
			variables: {
				id: agreementId,
			},
		})
			.then(({ data }) => {
				if (!data?.registerAgreement) return setNotification({ type: 'error', text: 'no contract id' });
				navigate(contractById(String(data.registerAgreement)));
			})
			.catch(error => showError(error.message));
	}

	const headerButtons: HeaderButton[] = React.useMemo(
		() => [
			{
				label: `Скидка за счет КВ: ${seller_discount_due_kv || 0} %`,
				icon: <DiscountIcon />,
				event: () => setDiscountModalIsOpen(true),
				isDisabled: !rightsHandler('setDiscount') || isCanceled,
				isHidden: isFormBlocked,
			},
			{ label: 'История изменений', icon: <TimeReloadIcon />, event: () => setChangeLogModalIsOpen(true) },
			{
				label: 'Согласовать повторно',
				icon: <PersonWithArrowIcon />,
				event: () => setDisapproveModalIsOpen(true),
				isHidden: isFormBlocked
			},
		],
		[seller_discount_due_kv, isCanceled, rightsHandler]
	);

	const informationItems: InformationItem[] = React.useMemo(
		() => [
			{ title: 'Номер заявки', description: number || 'Неизвестно' },
			{
				title: 'Статус',
				description: status?.label || 'Неизвестно',
				color: status?.color,
				isStatus: true,
			},
			{
				title: 'Создана',
				description: `${user?.username || 'Не известный'} ${added}`,
			},
			{
				title: 'Точка продаж (группа)',
				description: `${user?.isSuper ? 'Администратор' : `${user?.group?.name || 'неизвестно'}`}`,
			},
		],
		[added, number, status?.color, status?.label, user?.group?.name, user?.isSuper, user?.username]
	);

	const isAtSb = status?.value === 'waitingForSb' || status?.value === 'canceledBySb';

	const showCreateContract = !isCanceled && rightsHandler('createAgreement') && !contractId && isReadyToRegister && !isAtSb;

	const isLoadingCreateContract = loadingRegisterContract;

	const isDisabledGoToAgreement = !contractId;

	const totalSumm = `${stringToMoney(premium_sum)} RUB`;

	const totalSummWithDiscount = `${stringToMoney(premium_sum_with_kv)} RUB`;

	const discount = String(seller_discount_due_kv || 0);

	const defaultDiscount = seller_discount_due_kv || 0;

	const windowWidth = useWindowWidth({ wait: 300 });

	const onFormSubmit = formHook.handleSubmit(
		data => {
			console.log(data);
			changeCalculation({
				variables: {
					id: agreementId,
					input: {
						risks: [
							...(data.risksLife || []).map((risk, index) => ({
								isn: risksLife[index].isn,
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
							})),
							...(data.risksProperty || []).map((risk, index) => ({
								isn: risksProperty[index].isn,
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
							})),
							...(data.risksTitle || []).map((risk, index) => ({
								isn: risksTitle[index].isn,
								raising_factor: risk.allowance,
								discount: -Math.abs(risk.discount || 0),
							})),
						],
					},
				},
			})
				.then(() => setNotification({ type: 'success', text: 'Данные успешно обновлены' }))
				.catch(error => setNotification({ type: 'error', text: error }));
		}
	);

	return (
		<MainLayoutIgniter>
			<StyledUnderwritingHeader>
				<Button appearance='text' onClick={goBackHandler} iconBefore={<ArrowIcon direction='left' />}>
					Заявки
				</Button>
				<Heading2>Согласование заявки</Heading2>
			</StyledUnderwritingHeader>
			<StyledUnderwritingWrapper>
				<form onSubmit={onFormSubmit}>
					<StyledHeaderWrapper>
						<StyledHeaderEvents>
							<Heading3>Общие сведения</Heading3>
							<StyledEventsButtons>
								{windowWidth < 768 && <DropdownMenu trigger={<Button icon={<MoreIcon />} appearance='flag' />} items={headerButtons} />}
								{windowWidth >= 768 &&
									headerButtons.filter(({ isHidden }) => !isHidden).map(({ label, event, icon, isDisabled, isLoading }, index) => (
										<Button key={`headerButton-${index}`} isLoading={isLoading} tooltip={label} isDisabled={isDisabled} appearance='icon' icon={icon} type='button' onClick={event} />
									))}

								<FlyButtons isWhiteBackground={true}>
									<StyledSignetAgreementButtons>
										{(maxDiscount || canApplyAllowance) && !isFormBlocked &&
											<Button isLoading={loadingChangeCalculation} appearance='filled' type="submit">
												Пересчитать
											</Button>
										}

										{contractId &&
											<Button isLoading={loadingAgreement} onClick={goToAgreementHandler} appearance='transparent'>
												Перейти к договору
											</Button>
										}
										{showCreateContract &&
											<Button isLoading={isLoadingCreateContract || loadingChangeCalculation} appearance='filled' onClick={createContractHandler}>
												Создать договор
											</Button>
										}
									</StyledSignetAgreementButtons>
								</FlyButtons>
							</StyledEventsButtons>
						</StyledHeaderEvents>

						<StyledHeaderInformation>
							{informationItems.map(({ title, description }, index) => (
								<StyledInformationItem key={`info-item-${index}`}>
									<TextButton>{title}</TextButton>
									<TextButton>{description}</TextButton>
								</StyledInformationItem>
							))}
							<StyledSummsWrapper>
								<StyledSumm>
									<LoadingString width={20} isLoading={loadingAgreement}>
										<TextButton>Итого</TextButton>
										<StyledImportantNumber>
											<TextButton>{totalSumm}</TextButton>
										</StyledImportantNumber>
									</LoadingString>
								</StyledSumm>
								<StyledSumm>
									<LoadingString width={20} isLoading={loadingAgreement}>
										<TextButton>Со скидкой КВ ({discount}%)</TextButton>
										<StyledImportantNumber>
											<TextButton>{totalSummWithDiscount}</TextButton>
										</StyledImportantNumber>
									</LoadingString>
								</StyledSumm>
							</StyledSummsWrapper>
						</StyledHeaderInformation>
					</StyledHeaderWrapper>

					<Accordion header='Общие сведения по заявке' badge={fillingTypeText} isNotUnmount={true} isOpen={true}>
						<StyledCalculationDataWrapper>
							{riskFormItems.map(({ title, forms }, indexRisk) => {
								if ((forms || []).length === 0) return null;
								return (
									<StyledRiskFormWrapper key={`riskForm-${indexRisk}`}>
										<Heading4>{title}</Heading4>
										{forms.map(({ form, informationItems: informationItemsRisk, remark }, indexRiskForm) => (
											<React.Fragment key={`currentRiskForm-${indexRiskForm}`}>
												<StyledRiskInformation>
													{informationItemsRisk.map(({ label, value }, indexInformationRisk) => (
														<StyledInformationItem key={`info-item-${indexInformationRisk}`}>
															<Text4>{label}</Text4>
															<Text4>{value}</Text4>
														</StyledInformationItem>
													))}
												</StyledRiskInformation>
												<FieldsBuilder {...form} />
											</React.Fragment>
										))}
									</StyledRiskFormWrapper>
								);
							})}
						</StyledCalculationDataWrapper>
					</Accordion>
				</form>

				<StyledTableAreaWrapper>
					<Heading3>Тарифы, страховые суммы, премии</Heading3>
					<AgreementTableIgniter id={agreementId} />
				</StyledTableAreaWrapper>

				<StyledToggleAreaWrapper>
					<StyledToggleAreaLeft>
						<AnketaViewIgniter id={agreementId} />
					</StyledToggleAreaLeft>

					<div>
						<DocumentsWidget
							id={agreementId}
							isShowHint={true}
							resultDocuments={resultDocuments||[]}
							email={policyholder_email||""}
						/>
						<DiscussionIgniter id={agreementId} />
					</div>
				</StyledToggleAreaWrapper>
			</StyledUnderwritingWrapper>
			<AgreementChangeLogIgniter id={agreementId} onClose={() => setChangeLogModalIsOpen(false)} isOpen={stateChangeLogModalIsOpen} />
			<DiscountIgniter id={agreementId} discount={defaultDiscount} isOpen={stateDiscountModalIsOpen} onClose={() => setDiscountModalIsOpen(false)} />
			<DisapproveIgniter id={agreementId} isOpen={stateDisapproveModalIsOpen} onClose={() => setDisapproveModalIsOpen(false)} />
		</MainLayoutIgniter>
	);
}

export default SellerPage;
