import React from 'react';
import UnderwritingUi from './ui';
import UnderwritingContextProvider from './ui/context';

const UnderwritingPage: React.FC = () => (
	<UnderwritingContextProvider>
		<UnderwritingUi />
	</UnderwritingContextProvider>
);

export default React.memo(UnderwritingPage);
