import React from 'react';
import JobUi from './ui';
import JobContextProvider, { JobContextProps } from './ui/context';

const JobPage: React.FC<JobContextProps> = props => (
	<JobContextProvider {...props}>
		<JobUi />
	</JobContextProvider>
);

export default React.memo(JobPage);
