import React from 'react';

export const BurgerIcon: React.FC = () => (
	<svg width='2rem' height='1.6rem' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g>
			<rect width='20' height='1.8' fill='currentColor' />
			<rect y='7' width='20' height='1.8' fill='currentColor' />
			<rect y='14' width='20' height='1.8' fill='currentColor' />
		</g>
	</svg>
);
