import React from 'react';
import BankUi from './ui';
import BankContextProvider from './ui/context';

const BankPage: React.FC = () => (
	<BankContextProvider>
		<BankUi />
	</BankContextProvider>
);

export default React.memo(BankPage);
