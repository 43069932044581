import React from 'react';
import CopyrightUi from './ui';
import { CopyrightContextProvider } from './ui/context';

const CopyrightIgniter: React.FC<{ isAuthorization: boolean }> = ({ isAuthorization }) => (
	<CopyrightContextProvider isAuthorization={isAuthorization}>
		<CopyrightUi />
	</CopyrightContextProvider>
);

export default React.memo(CopyrightIgniter);
