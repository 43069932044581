import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import useGetDicti from '../../../common/hooks/useGetDicti';
import {
	CreateReportMutation, CreateReportMutationVariables,
	DictKeyEnum,
	GroupsQuery,
	GroupsQueryVariables,
	useMockMutation
} from '../../../../../libs/api/graphqlTypes';
import { GROUPS } from '../../../../../libs/api/queries';
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';
import {useMutation} from "@apollo/client";
import {CREATE_REPORT} from "../../../../../libs/api/commands";
import {fileDownloader} from "../../../../../libs/utils/fileDownloader";
import useNotification from "../../../../widgets/notifier/ui/hooks/useNitification";

type ReportsContext = {
	form: IForm;
	onFormSubmit: () => void;
	isLoading: boolean;
};

const ReportsContext = createContext<ReportsContext>({} as ReportsContext);

export const useReportsContext = (): ReportsContext => useContext(ReportsContext);

const SELECT_REPORT_TYPE: ISelectItem[] = [{ label: 'Отчет по заключенным договорам', value: '1' }];

const ReportsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		routes: { error500 },
	} = useGlobalContext();
	const formHook = useForm({ mode: 'onBlur' });

	const { data: selectBanks, loading: loadingBanks } = useGetDicti(DictKeyEnum.CreditBank);
	const { data: agentlist, loading: loadingAgentlist } = useGetDicti(DictKeyEnum.Agentlist);
	const [ createReport, {loading: isReportLoading} ] = useMutation<CreateReportMutation, CreateReportMutationVariables>(CREATE_REPORT, {
		fetchPolicy: 'network-only',
	});

	const { setNotification } = useNotification();

	const onFormSubmit = formHook.handleSubmit(data => {
		createReport({
			variables: {
				dateBeg: data.dateBeg,
				dateEnd: data.dateEnd,
				frontNodeIsn: data.frontNodeIsn?.value ? Number(data.frontNodeIsn.value) : null,
			}
		}).then(({data}) => {
			if (!data?.createReport) return;
			fileDownloader(data.createReport.url, data.createReport.filename);
		}).catch(error => {
			setNotification({ type: 'error', text: error })
		});
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: 'reportType',
						placeholder: 'Отчет',
						isRequired: true,
						errorMessage: 'Выберите отчет',
						defaultValue: SELECT_REPORT_TYPE[0],
						items: SELECT_REPORT_TYPE,
					},
				},
				{
					field: {
						grid: 6,
						fieldType: 'date',
						fieldName: 'dateBeg',
						placeholder: 'Дата начала',
						isRequired: true,
						errorMessage: 'Выберите дату начала',
					},
				},
				{
					field: {
						grid: 6,
						fieldType: 'date',
						fieldName: 'dateEnd',
						placeholder: 'Дата окончания',
						isRequired: true,
						errorMessage: 'Выберите дату окончания',
					},
				},
				{
					isHidden: !agentlist,
					field: {
						fieldType: 'select',
						fieldName: 'frontNodeIsn',
						isLoading: loadingAgentlist,
						items: agentlist,
						placeholder: 'Точка продаж',
						isSearchAvailable: true,
						isClearable: true,
					},
				},
			],
		}),
		[formHook, agentlist, loadingBanks, loadingAgentlist, selectBanks]
	);

	// todo ADD CREATE REPORT METHOD
	const value: ReportsContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isLoading: isReportLoading,
		}),
		[form, onFormSubmit]
	);

	return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};

export default React.memo(ReportsContextProvider);
