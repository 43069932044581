import React from 'react';

export const LoaderIcon: React.FC = () => (
	<svg width='4.4rem' height='4.4rem' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13.8691 42.4423C18.4412 44.2609 23.491 44.4942 28.2114 43.1049C32.9317 41.7157 37.0501 38.7841 39.9082 34.7787C42.7663 30.7734 44.1992 25.9255 43.9777 21.0099C43.7563 16.0944 41.8933 11.3949 38.6867 7.66279C35.48 3.93063 31.1147 1.38122 26.2886 0.422041C21.4624 -0.537141 16.454 0.149285 12.0639 2.3716C7.6738 4.59392 4.15543 8.22383 2.07116 12.6811C-0.0131079 17.1384 -0.542942 22.1658 0.566344 26.9597L3.69235 26.2363C2.74485 22.1416 3.19741 17.8475 4.97769 14.0402C6.75798 10.233 9.76321 7.13252 13.513 5.23432C17.2629 3.33612 21.5408 2.7498 25.6631 3.56909C29.7854 4.38838 33.514 6.56597 36.253 9.75381C38.992 12.9416 40.5832 16.9557 40.7724 21.1543C40.9615 25.353 39.7376 29.4938 37.2964 32.915C34.8551 36.3362 31.3374 38.8403 27.3055 40.0269C23.2736 41.2135 18.9602 41.0142 15.0549 39.4609L13.8691 42.4423Z'
			fill='currentColor'
		/>
	</svg>
);
