import {useMutation} from "@apollo/client";
import {PrintMutation, PrintMutationVariables, PrintTemplateEnum, PrintTypeEnum} from "../graphqlTypes";
import {PRINT_MUTATION} from "../commands";
import {fileDownloader} from "../../utils/fileDownloader";
import useNotification from "../../../modules/widgets/notifier/ui/hooks/useNitification";

export const usePrintMutation = () => {

    const [print, {loading: isPrintLoading}] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
        fetchPolicy: 'network-only',
    });
    const { setNotification } = useNotification();

    const doPrint = (isn: number, type: PrintTypeEnum, template: PrintTemplateEnum, templateIsn?: number, contragentIsn?: number|null) =>
        print( {
            variables: {
                isn,
                type,
                template,
                templateIsn,
                contragentIsn
            }
        } ).then(({data}) => {
            if (!data?.print) return;
            fileDownloader(data.print.url, data.print.filename);
        }).catch(error => setNotification({ type: 'error', text: error }))


    return {
        doPrint,
        isPrintLoading
    }
}