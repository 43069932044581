import React from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {StyledGroupHistoryHeaderItem, StyledGroupHistoryTableWrapper, StyledGroupHistoryWrapper} from "./styles";
import {IModalProps} from "../../../../../libs/uiKit/modal/types";
import {USER_GROUP_HISTORY} from "../../../../../libs/api/queries";
import {Modal} from "../../../../../libs/uiKit/modal";
import {Text1, Text4, Heading3} from "../../../../../libs/uiKit/globalStyles";


const headsList = ["Измененное поле", "Старое значение", "Новое значение"];

export const ChangeLogModal = ({onClose, header, data, isOpen}: IModalProps) => {
  const {id, model} = data || {};

  const [getHistory, {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory
  }] = useLazyQuery(USER_GROUP_HISTORY);

  React.useEffect(() => {
    if (!isOpen || !id) return;
    getHistory({
      variables: {
        id,
        model
      }
    })
  }, [id, model, isOpen, getHistory])

  // @ts-ignore
  const rows = (dataHistory?.changeLog || []).map(({createdAt, fields, user, comment}) => ({
    title: `Изменения от: ${createdAt}`,
    user: `Пользователь: ${user?.name}`,
    comment,
    // @ts-ignore
    rows: (fields || []).map((({label, oldValue, newValue}) => ({
      label,
      oldValue: oldValue || "-",
      newValue: newValue || "-"
    })))
  }))


  // @ts-ignore
  return (
    <Modal width={120} onClose={onClose} isOpen={isOpen} header={header} isLoading={loadingHistory}>
      <StyledGroupHistoryWrapper>
        <StyledGroupHistoryHeaderItem><Text4>Дата/Пользователь</Text4></StyledGroupHistoryHeaderItem>
        <StyledGroupHistoryTableWrapper>
          {headsList.map((head, index) => <StyledGroupHistoryHeaderItem
            key={`history-head-${index}`}><Text4>{head}</Text4></StyledGroupHistoryHeaderItem>)}
        </StyledGroupHistoryTableWrapper>
        {!rows.length && <Heading3>Нет изменений</Heading3>}
        {!!rows.length &&
            <>
              {rows.map((row: any, index: number) => <React.Fragment key={`history-row-${index}`}>
                <div>
                  <Text4>{row?.title}</Text4>
                  <Text4>{row?.user}</Text4>
                  <Text4 style={{fontStyle: "italic"}}>{row?.comment}</Text4>
                </div>
                <StyledGroupHistoryTableWrapper>
                  {row?.rows.map((subrow: any) => <>
                    <Text4>{subrow?.label}</Text4>
                    <Text4>{subrow?.oldValue}</Text4>
                    <Text4>{subrow?.newValue}</Text4>
                  </>)}
                </StyledGroupHistoryTableWrapper>
              </React.Fragment>)}
            </>
        }
      </StyledGroupHistoryWrapper>
    </Modal>
  )
};

export default ChangeLogModal;