import React from 'react';
import { useDiscountContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';
import { StyledButtonsGroup } from './styles';
import { Modal } from '../../../../../../libs/uiKit/modal';

const DiscountUi: React.FC = () => {
	const { isLoading, isOpen, onClose, onFormSubmit, form } = useDiscountContext();

	return (
		<Modal onClose={onClose} isOpen={isOpen} header='Применить скидку'>
			<form onSubmit={onFormSubmit}>
				<FieldsBuilder {...form} />
				<StyledButtonsGroup>
					<Button type='submit' appearance='filled' isLoading={isLoading}>
						Пересчитать
					</Button>
					<Button onClick={() => onClose()} appearance='transparent' isLoading={isLoading}>
						Отмена
					</Button>
				</StyledButtonsGroup>
			</form>
		</Modal>
	);
};

export default React.memo(DiscountUi);
