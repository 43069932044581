import React from 'react';
import { useUploadFilesContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import { Heading4, Text4 } from '../../../../../../libs/uiKit/globalStyledComponents';
import { StylesDocsHintWrapper } from './styles';

const lifeDocs: string[] = ['паспорт Страхователя'];
const propertyDocs: string[] = ['технический паспорт на объект недвижимости', 'выписка из ЕГРН', 'отчет об оценке недвижимости (обязательно! при страховании ДОМОВ)'];
const titleDocs: string[] = ['правоустанавливающие документы (документ-основание права собственности на объект)'];

export const DocumentsHint = ({ riskProperty, riskTitle }: { riskProperty: boolean; riskTitle: boolean }) => {
	const docsList: string[] = [...lifeDocs, ...(riskProperty ? propertyDocs : []), ...(riskTitle ? titleDocs : [])];

	return (
		<StylesDocsHintWrapper>
			<Heading4>Необходимые документы</Heading4>
			{docsList.map((doc, index) => (
				<Text4 key={`file-hint-${index}`}>- {doc}</Text4>
			))}
		</StylesDocsHintWrapper>
	);
};

const UploadFilesUi: React.FC = () => {
	const { form, riskProperty, riskTitle } = useUploadFilesContext();

	return (
		<>
			<DocumentsHint riskProperty={riskProperty} riskTitle={riskTitle} />
			<FieldsBuilder {...form} />
		</>
	);
};

export default React.memo(UploadFilesUi);
