import React from 'react';
import NotifierUi from './ui';
import { NotifierContextProvider } from './ui/context';

const NotifierIgniter: React.FC = () => (
	<NotifierContextProvider>
		<NotifierUi />
	</NotifierContextProvider>
);

export default React.memo(NotifierIgniter);
