import { ApolloClient, concat, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { RetryLink } from '@apollo/client/link/retry';

const endPoint: string = `${process.env.REACT_APP_ENDPOINT_DEFAULT}`;

const uploadLink = createUploadLink({ uri: endPoint, credentials: 'include' });

const link = uploadLink;

const retryLink = new RetryLink({
	delay: {
		initial: 300,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 10,
		retryIf: error => !!error,
	},
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
	cache: new InMemoryCache(),
	link: concat(retryLink, link),
});
