import React, { forwardRef } from 'react';
import { IRadioProps } from './types';
import { StyledCircle, StyledRadioBox, StyledRadioInput, StyledRadioItemsWrapper, StyledRadioWrapper } from './styles';
import { TypeInputRef } from '../input/types';
import { TextButton } from '../globalStyles';
import FormMessage from '../formMessage';

const Radio: React.FC<IRadioProps> = forwardRef((props, ref: TypeInputRef) => {
	const { items, value, defaultValue, isDisabled, isInvalid, template, rowGap, columnGap, errorMessage, ...rest } = props;

	return (
		<StyledRadioWrapper>
			<StyledRadioItemsWrapper template={template} rowGap={rowGap} columnGap={columnGap}>
				{items.map(({ label, value: valueItem }) => (
					<StyledRadioBox key={`radio-${valueItem}`} isDisabled={!!isDisabled} isInvalid={!!isInvalid}>
						<StyledRadioInput
							{...rest}
							ref={ref}
							type='radio'
							value={valueItem}
							defaultChecked={typeof defaultValue === 'undefined' ? undefined : defaultValue === valueItem}
							checked={typeof value === 'undefined' ? undefined : value === valueItem}
							disabled={!!isDisabled}
						/>
						<StyledCircle />
						<TextButton>{label}</TextButton>
					</StyledRadioBox>
				))}
			</StyledRadioItemsWrapper>
			{errorMessage && <FormMessage message={errorMessage} type='error' />}
		</StyledRadioWrapper>
	);
});
export default React.memo(Radio);
