import { light, dark } from './colors';
import { black, blue, blue7, grey, halfDarkGrey, lightBlue, lightBlueClick, lightBlueHover, red, white } from '../globalStyles';
import { blue3, blueClick, grey20 } from '../globalStyledComponents';

export const lightTheme = {
	// general
	text: {
		primary: '#24292e',
		secondary: '#586069',
		placeholder: '#6a737d',
		disabled: '#959da5',
		link: '#0366d6',
		danger: '#cb2431',
		success: '#22863a',
		warning: '#b08800',
		info: '#1074e7',
	},

	bg: {
		primary: '#ffffff',
		secondary: '#fafbfc',
		danger: '#ffeef0',
		success: '#dcffe4',
		warning: '#fff5b1',
		info: '#f1f8ff',
	},

	// components

	accordion: {
		border: light.grey4,
		open: {
			border: light.grey6,
		},
		icon: {
			color: light.grey5,
			hover: {
				color: light.grey3,
			},
		},
	},

	btn: {
		common: {
			// from success
			filled: {
				color: '#ffffff',
				bg: light.green5,
				border: light.green5,
				disabled: {
					bg: light.grey5,
					border: light.grey5,
					color: light.grey3,
				},
				hover: {
					bg: light.green4,
					border: light.green4,
				},
				active: {
					bg: light.green6,
					border: light.green6,
				},
			},
			transparent: {
				color: light.green6,
				bg: 'transparent',
				border: light.green6,
				hover: {
					bg: light.green6,
					color: '#ffffff',
				},
				active: {
					bg: light.green7,
					border: light.green8,
					color: '#ffffff',
				},
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
			},
			link: {
				color: light.green6,
				hover: {
					color: light.green4,
				},
				active: {
					color: light.green8,
				},
				disabled: {
					color: light.grey5,
				},
			},
			text: {
				color: light.green3,
				hover: {
					color: light.green6,
				},
				active: {
					color: light.green8,
				},
				disabled: {
					color: light.grey5,
				},
			},
			icon: {
				color: light.green3,
				hover: {
					color: light.green6,
				},
				active: {
					color: light.green8,
				},
				disabled: {
					color: light.grey5,
				},
			},
			// diff
			flag: {
				color: light.grey2,
				bg: 'transparent',
				hover: {
					bg: light.grey8,
					color: light.grey0,
				},
				active: {
					bg: light.grey7,
					color: light.grey1,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey8,
				},
				selected: {
					bg: light.grey8,
					color: light.pink5,
				},
			},
		},
		default: {
			filled: {
				color: white,
				bg: lightBlue,
				border: lightBlue,
				disabled: {
					bg: light.grey5,
					border: light.grey6,
					color: light.grey3,
				},
				hover: {
					bg: lightBlueHover,
					border: lightBlueHover,
				},
				active: {
					bg: lightBlueClick,
					border: lightBlueClick,
				},
			},
			transparent: {
				color: blue,
				bg: 'transparent',
				border: blue,
				hover: {
					bg: blue,
					color: white,
				},
				active: {
					bg: blueClick,
					border: blueClick,
					color: white,
				},
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
			},
			link: {
				color: blue,
				hover: {
					color: lightBlue,
				},
				active: {
					color: lightBlue,
				},
				disabled: {
					color: light.grey5,
				},
			},
			text: {
				color: grey,
				hover: {
					color: black,
				},
				active: {
					color: black,
				},
				disabled: {
					color: light.grey5,
				},
			},
			icon: {
				color: grey,
				hover: {
					color: black,
				},
				active: {
					color: black,
				},
				disabled: {
					color: light.grey5,
				},
			},
			flag: {
				color: light.grey6,
				bg: 'transparent',
				hover: {
					bg: light.grey1,
					color: light.grey7,
				},
				active: {
					bg: light.grey2,
					color: light.grey8,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey1,
				},
				selected: {
					bg: 'transparent',
					color: light.grey9,
				},
			},
		},
		success: {
			filled: {
				color: '#ffffff',
				bg: light.green5,
				border: light.green5,
				disabled: {
					bg: light.grey5,
					border: light.grey5,
					color: light.grey3,
				},
				hover: {
					bg: light.green4,
					border: light.green4,
				},
				active: {
					bg: light.green6,
					border: light.green6,
				},
			},
			transparent: {
				color: light.green6,
				bg: 'transparent',
				border: light.green6,
				hover: {
					bg: light.green6,
					color: '#ffffff',
				},
				active: {
					bg: light.green7,
					border: light.green8,
					color: '#ffffff',
				},
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
			},
			link: {
				color: light.green5,
				hover: {
					color: light.green3,
				},
				active: {
					color: light.green7,
				},
				disabled: {
					color: light.grey5,
				},
			},
			text: {
				color: light.green5,
				hover: {
					color: light.green3,
				},
				active: {
					color: light.green7,
				},
				disabled: {
					color: light.grey5,
				},
			},
			icon: {
				color: light.green5,
				hover: {
					color: light.green3,
				},
				active: {
					color: light.green7,
				},
				disabled: {
					color: light.grey5,
				},
			},
			flag: {
				color: light.grey6,
				bg: 'transparent',
				hover: {
					bg: light.green0,
					color: light.green4,
				},
				active: {
					bg: light.green1,
					color: light.green7,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey1,
				},
				selected: {
					bg: 'transparent',
					color: light.green5,
				},
			},
		},
		info: {
			filled: {
				color: '#ffffff',
				bg: light.blue5,
				border: light.blue5,
				disabled: {
					bg: light.grey5,
					border: light.grey5,
					color: light.grey3,
				},
				hover: {
					bg: light.blue4,
					border: light.blue4,
				},
				active: {
					bg: light.blue6,
					border: light.blue6,
				},
			},
			transparent: {
				color: light.blue6,
				bg: 'transparent',
				border: light.blue6,
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
				hover: {
					bg: light.blue6,
					color: '#ffffff',
				},
				active: {
					bg: light.blue7,
					border: light.blue8,
					color: '#ffffff',
				},
			},
			link: {
				color: light.blue5,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.blue3,
				},
				active: {
					color: light.blue7,
				},
			},
			text: {
				color: light.blue5,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.blue3,
				},
				active: {
					color: light.blue7,
				},
			},
			icon: {
				color: light.blue5,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.blue3,
				},
				active: {
					color: light.blue7,
				},
			},
			flag: {
				color: light.grey6,
				bg: 'transparent',
				hover: {
					bg: light.blue0,
					color: light.blue4,
				},
				active: {
					bg: light.blue1,
					color: light.blue7,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey1,
				},
				selected: {
					bg: 'transparent',
					color: light.blue5,
				},
			},
		},
		warning: {
			filled: {
				color: '#ffffff',
				bg: light.yellow5,
				border: light.yellow5,
				disabled: {
					bg: light.grey5,
					border: light.grey5,
					color: light.grey3,
				},
				hover: {
					bg: light.yellow4,
					border: light.yellow4,
				},
				active: {
					bg: light.yellow6,
					border: light.yellow6,
				},
			},
			transparent: {
				color: light.yellow6,
				bg: 'transparent',
				border: light.yellow6,
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
				hover: {
					bg: light.yellow6,
					color: '#ffffff',
				},
				active: {
					bg: light.yellow7,
					border: light.yellow8,
					color: '#ffffff',
				},
			},
			link: {
				color: light.yellow6,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.yellow4,
				},
				active: {
					color: light.yellow8,
				},
			},
			text: {
				color: light.yellow6,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.yellow4,
				},
				active: {
					color: light.yellow8,
				},
			},
			icon: {
				color: light.yellow6,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.yellow4,
				},
				active: {
					color: light.yellow8,
				},
			},
			flag: {
				color: light.grey6,
				bg: 'transparent',
				hover: {
					bg: light.yellow0,
					color: light.yellow4,
				},
				active: {
					bg: light.yellow1,
					color: light.yellow7,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey1,
				},
				selected: {
					bg: 'transparent',
					color: light.yellow5,
				},
			},
		},
		danger: {
			filled: {
				color: '#ffffff',
				bg: light.red5,
				border: light.red5,
				disabled: {
					bg: light.grey5,
					border: light.grey5,
					color: light.grey3,
				},
				hover: {
					bg: light.red4,
					border: light.red4,
				},
				active: {
					bg: light.red6,
					border: light.red6,
				},
			},
			transparent: {
				color: light.red6,
				bg: 'transparent',
				border: light.red6,
				disabled: {
					bg: 'transparent',
					color: light.grey5,
					border: light.grey5,
				},
				hover: {
					bg: light.red6,
					color: '#ffffff',
				},
				active: {
					bg: light.red7,
					border: light.red8,
					color: '#ffffff',
				},
			},
			link: {
				color: light.red6,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.red4,
				},
				active: {
					color: light.red8,
				},
			},
			text: {
				color: light.red3,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.red6,
				},
				active: {
					color: light.red8,
				},
			},
			icon: {
				color: light.red5,
				disabled: {
					color: light.grey5,
				},
				hover: {
					color: light.red3,
				},
				active: {
					color: light.red7,
				},
			},
			flag: {
				color: light.grey6,
				bg: 'transparent',
				hover: {
					bg: light.red0,
					color: light.red4,
				},
				active: {
					bg: light.red1,
					color: light.red7,
				},
				disabled: {
					color: light.grey5,
					bg: light.grey1,
				},
				selected: {
					bg: 'transparent',
					color: light.red5,
				},
			},
		},
	},

	tooltip: {
		bg: light.grey9,
		color: light.grey0,
	},

	checkbox: {
		color: black,
		hover: {
			color: black,
		},
		active: {
			color: black,
		},
		disabled: {
			color: light.grey5,
		},
		invalid: {
			color: red,
		},
		requiredColor: red,

		box: {
			border: grey,
			bg: 'transparent',
			color: 'transparent',
			hover: {},
			active: {},
			checked: {
				border: blue,
				bg: blue,
				color: white,
			},
			disabled: {
				border: light.grey2,
				bg: light.grey2,
				color: light.grey5,
			},
			invalid: {
				border: red,
			},
		},
	},

	daData: {
		bg: light.grey0,
		border: light.blue4,
		hover: {
			bg: light.grey2,
		},
		highlighted: light.blue6,
	},

	// datePicker: {},

	dropdownMenu: {
		bg: '#ffffff',
		shadow: 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px;',
		item: {
			color: light.grey8,
			hover: {
				bg: light.grey0,
				color: light.grey7,
			},
			active: {
				bg: light.grey1,
				color: light.grey9,
			},
		},
	},

	formMessage: {
		error: {
			color: light.red5,
		},
		help: {
			color: grey,
		},
		success: {
			color: light.green5,
		},
		default: {
			color: '#000000',
		},
	},

	// imagePreview: {},

	input: {
		color: light.grey9,
		border: light.grey3,
		requiredColor: red,
		bg: 'transparent',
		hover: {
			border: lightBlue,
		},
		focus: {
			border: lightBlue,
		},
		invalid: {
			border: red,
			bg: 'transparent',
		},
		disabled: {
			color: light.grey5,
			border: light.grey2,
			bg: light.grey0,
		},
		icon: {
			color: light.grey7,
			hover: {
				color: lightBlue,
			},
			focus: {
				color: lightBlue,
			},
		},
		placeholder: {
			color: light.grey4,
			hover: {
				color: light.grey4,
			},
			focus: {
				color: light.grey4,
			},
			withValue: {
				color: light.grey4,
			},
			invalid: {
				color: red,
			},
			disabled: {
				color: light.grey4,
			},
		},
	},

	modal: {
		backdrop: 'rgba(0,0,0,0.4)',
		bg: '#ffffff',
		color: light.grey9,
		icon: {
			color: light.grey6,
			hover: {
				color: light.grey5,
			},
			active: {
				color: light.grey7,
			},
		},
	},

	// range: {},

	pagination: {
		btn: {
			color: blue,
			hover: {
				bg: blue,
				color: white,
			},
			current: {
				bg: blue,
				color: white,
				hover: {
					bg: blue,
				},
			},
		},
		arrow: {
			color: blue,
		},
	},

	radio: {
		color: light.grey8,
		hover: {
			color: black,
		},
		active: {
			color: black,
		},
		disabled: {
			color: light.grey5,
		},
		invalid: {
			color: red,
		},

		circle: {
			border: grey,
			checked: {
				bg: blue,
			},
			invalid: {
				border: red,
			},
		},
	},

	select: {
		bg: light.grey0,
		border: lightBlue,
		item: {
			color: light.grey4,
			hover: {
				bg: light.grey2,
				color: black,
			},
			selected: {
				color: black,
			},
		},
	},

	table: {
		header: {
			color: halfDarkGrey,
			bg: grey20,
		},
		row: {
			border: light.blue5,
			oddBg: blue3,
			hover: {
				bg: blue7,
			},
		},
	},

	tabs: {
		item: {
			hover: {
				bg: light.grey0,
			},
		},
		selected: {
			border: blue,
		},
	},

	textArea: {
		color: light.grey9,
		border: light.grey3,
		requiredColor: red,
		bg: 'transparent',
		hover: {
			border: lightBlue,
		},
		focus: {
			border: lightBlue,
		},
		invalid: {
			border: red,
			bg: 'transparent',
		},
		disabled: {
			color: light.grey5,
			border: light.grey2,
		},
		icon: {
			color: light.grey7,
			hover: {
				color: light.blue3,
			},
			focus: {
				color: light.blue4,
			},
		},
		placeholder: {
			color: light.grey4,
			hover: {
				color: light.grey4,
			},
			focus: {
				color: light.grey4,
			},
			withValue: {
				color: light.grey4,
			},
			invalid: {
				color: red,
			},
			disabled: {
				color: light.grey4,
			},
		},
	},

	toggleCheckbox: {
		border: '#003D81',
		checked: {
			bg: '#003D81',
		},
		circle: {
			border: '#003D81',
			bg: '#ffffff',
		},
	},

	uploadFile: {
		color: light.grey9,
		border: light.grey3,
		requiredColor: red,
		hover: {
			border: lightBlue,
			bg: white,
		},
		disabled: {
			color: light.grey5,
			border: light.grey2,
			bg: light.grey0,
		},
		invalid: {
			border: red,
			bg: white,
		},
		placeholder: {
			color: light.grey4,
			hover: {
				color: lightBlue,
			},
			invalid: {
				color: red,
			},
			disabled: {
				color: light.grey4,
			},
		},
	},
};

export const darkTheme = {
	text: {
		primary: '#adbac7',
		secondary: '#768390',
		placeholder: '#545d68',
		disabled: '#545d68',
		link: '#539bf5',
		danger: '#e5534b',
		success: '#6bc46d',
		warning: '#daaa3f',
		info: '#3877d5',
	},

	bg: {
		primary: '#22272e',
		secondary: '#22272e',
		danger: 'rgba(229,83,75,0.1)',
		success: 'rgba(70,149,74,0.1)',
		warning: 'rgba(174,124,20,0.1)',
		info: 'rgba(65,132,228,0.1)',
	},

	// components
	accordion: {
		border: dark.grey5,
		open: {
			border: dark.grey3,
		},
		icon: {
			color: dark.grey4,
			hover: {
				color: dark.grey2,
			},
		},
	},

	btn: {
		common: {
			// from success
			filled: {
				color: '#ffffff',
				bg: dark.green5,
				border: dark.green5,
				disabled: {
					bg: dark.grey5,
					border: dark.grey5,
					color: dark.grey3,
				},
				hover: {
					bg: dark.green4,
					border: dark.green4,
				},
				active: {
					bg: dark.green6,
					border: dark.green6,
				},
			},
			transparent: {
				color: dark.green6,
				bg: 'transparent',
				border: dark.green6,
				hover: {
					bg: dark.green6,
					color: '#ffffff',
				},
				active: {
					bg: dark.green7,
					border: dark.green8,
					color: '#ffffff',
				},
				disabled: {
					bg: 'transparent',
					color: dark.grey5,
					border: dark.grey5,
				},
			},
			link: {
				color: dark.green6,
				hover: {
					color: dark.green4,
				},
				active: {
					color: dark.green8,
				},
				disabled: {
					color: dark.grey5,
				},
			},
			text: {
				color: dark.green3,
				hover: {
					color: dark.green6,
				},
				active: {
					color: dark.green8,
				},
				disabled: {
					color: dark.grey5,
				},
			},
			icon: {
				color: dark.green3,
				hover: {
					color: dark.green6,
				},
				active: {
					color: dark.green8,
				},
				disabled: {
					color: dark.grey5,
				},
			},
			// diff
			flag: {
				color: dark.grey2,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.grey0,
				},
				active: {
					bg: dark.grey7,
					color: dark.grey1,
				},
				disabled: {
					color: dark.grey5,
					bg: dark.grey7,
				},
				selected: {
					bg: dark.grey9,
					color: dark.pink4,
				},
			},
		},
		default: {
			filled: {
				color: dark.grey0,
				bg: dark.grey5,
				border: dark.grey5,
				disabled: {
					bg: dark.grey7,
					border: dark.grey7,
					color: dark.grey3,
				},
				hover: {
					bg: dark.grey4,
					border: dark.grey4,
				},
				active: {
					bg: dark.grey6,
					border: dark.grey6,
				},
			},
			transparent: {
				color: dark.grey5,
				bg: 'transparent',
				border: dark.grey5,
				hover: {
					bg: dark.grey5,
					color: '#ffffff',
				},
				active: {
					bg: dark.grey7,
					border: dark.grey7,
					color: '#ffffff',
				},
				disabled: {
					bg: 'transparent',
					color: dark.grey7,
					border: dark.grey7,
				},
			},
			link: {
				color: dark.grey3,
				hover: {
					color: dark.grey1,
				},
				active: {
					color: dark.grey5,
				},
				disabled: {
					color: dark.grey7,
				},
			},
			text: {
				color: dark.grey3,
				hover: {
					color: dark.grey1,
				},
				active: {
					color: dark.grey5,
				},
				disabled: {
					color: dark.grey7,
				},
			},
			icon: {
				color: dark.grey5,
				hover: {
					color: dark.grey3,
				},
				active: {
					color: dark.grey7,
				},
				disabled: {
					color: dark.grey8,
				},
			},
			flag: {
				color: dark.grey5,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.grey3,
				},
				active: {
					bg: dark.grey9,
					color: dark.grey7,
				},
				disabled: {
					color: dark.grey8,
					bg: dark.grey6,
				},
				selected: {
					bg: dark.grey8,
					color: dark.grey0,
				},
			},
		},
		success: {
			filled: {
				color: '#ffffff',
				bg: dark.green5,
				border: dark.green5,
				disabled: {
					bg: dark.grey7,
					border: dark.grey7,
					color: dark.grey3,
				},
				hover: {
					bg: dark.green4,
					border: dark.green4,
				},
				active: {
					bg: dark.green6,
					border: dark.green6,
				},
			},
			transparent: {
				color: dark.green4,
				bg: 'transparent',
				border: dark.green4,
				hover: {
					bg: dark.green4,
					color: '#ffffff',
				},
				active: {
					bg: dark.green6,
					border: dark.green7,
					color: '#ffffff',
				},
				disabled: {
					bg: 'transparent',
					color: dark.grey7,
					border: dark.grey7,
				},
			},
			link: {
				color: dark.green4,
				hover: {
					color: dark.green2,
				},
				active: {
					color: dark.green6,
				},
				disabled: {
					color: dark.grey7,
				},
			},
			text: {
				color: dark.green4,
				hover: {
					color: dark.green2,
				},
				active: {
					color: dark.green6,
				},
				disabled: {
					color: dark.grey7,
				},
			},
			icon: {
				color: dark.green5,
				hover: {
					color: dark.green3,
				},
				active: {
					color: dark.green7,
				},
				disabled: {
					color: dark.grey8,
				},
			},
			flag: {
				color: dark.grey5,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.green3,
				},
				active: {
					bg: dark.grey9,
					color: dark.green7,
				},
				disabled: {
					color: dark.grey8,
					bg: dark.grey6,
				},
				selected: {
					bg: dark.grey8,
					color: dark.green5,
				},
			},
		},
		info: {
			filled: {
				color: '#ffffff',
				bg: dark.blue5,
				border: dark.blue5,
				disabled: {
					bg: dark.grey7,
					border: dark.grey7,
					color: dark.grey3,
				},
				hover: {
					bg: dark.blue4,
					border: dark.blue4,
				},
				active: {
					bg: dark.blue6,
					border: dark.blue6,
				},
			},
			transparent: {
				color: dark.blue6,
				bg: 'transparent',
				border: dark.blue6,
				disabled: {
					bg: 'transparent',
					color: dark.grey7,
					border: dark.grey7,
				},
				hover: {
					bg: dark.blue6,
					color: '#ffffff',
				},
				active: {
					bg: dark.blue7,
					border: dark.blue8,
					color: '#ffffff',
				},
			},
			link: {
				color: dark.blue5,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.blue3,
				},
				active: {
					color: dark.blue7,
				},
			},
			text: {
				color: dark.blue5,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.blue3,
				},
				active: {
					color: dark.blue7,
				},
			},
			icon: {
				color: dark.blue5,
				disabled: {
					color: dark.grey8,
				},
				hover: {
					color: dark.blue3,
				},
				active: {
					color: dark.blue7,
				},
			},
			flag: {
				color: dark.grey5,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.blue3,
				},
				active: {
					bg: dark.grey9,
					color: dark.blue7,
				},
				disabled: {
					color: dark.grey8,
					bg: dark.grey6,
				},
				selected: {
					bg: dark.grey8,
					color: dark.blue5,
				},
			},
		},
		warning: {
			filled: {
				color: '#ffffff',
				bg: dark.yellow3,
				border: dark.yellow3,
				disabled: {
					bg: dark.grey7,
					border: dark.grey7,
					color: dark.grey3,
				},
				hover: {
					bg: dark.yellow2,
					border: dark.yellow2,
				},
				active: {
					bg: dark.yellow4,
					border: dark.yellow4,
				},
			},
			transparent: {
				color: dark.yellow3,
				bg: 'transparent',
				border: dark.yellow3,
				disabled: {
					bg: 'transparent',
					color: dark.grey7,
					border: dark.grey7,
				},
				hover: {
					bg: dark.yellow2,
					color: '#ffffff',
				},
				active: {
					bg: dark.yellow4,
					border: dark.yellow5,
					color: '#ffffff',
				},
			},
			link: {
				color: dark.yellow3,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.yellow1,
				},
				active: {
					color: dark.yellow5,
				},
			},
			text: {
				color: dark.yellow3,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.yellow1,
				},
				active: {
					color: dark.yellow5,
				},
			},
			icon: {
				color: dark.yellow3,
				disabled: {
					color: dark.grey8,
				},
				hover: {
					color: dark.yellow1,
				},
				active: {
					color: dark.yellow5,
				},
			},
			flag: {
				color: dark.grey5,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.yellow1,
				},
				active: {
					bg: dark.grey9,
					color: dark.yellow5,
				},
				disabled: {
					color: dark.grey8,
					bg: dark.grey6,
				},
				selected: {
					bg: dark.grey8,
					color: dark.yellow3,
				},
			},
		},
		danger: {
			filled: {
				color: '#ffffff',
				bg: dark.red5,
				border: dark.red5,
				disabled: {
					bg: dark.grey7,
					border: dark.grey7,
					color: dark.grey3,
				},
				hover: {
					bg: dark.red4,
					border: dark.red4,
				},
				active: {
					bg: dark.red6,
					border: dark.red6,
				},
			},
			transparent: {
				color: dark.red6,
				bg: 'transparent',
				border: dark.red6,
				disabled: {
					bg: 'transparent',
					color: dark.grey7,
					border: dark.grey7,
				},
				hover: {
					bg: dark.red6,
					color: '#ffffff',
				},
				active: {
					bg: dark.red7,
					border: dark.red8,
					color: '#ffffff',
				},
			},
			link: {
				color: dark.red5,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.red3,
				},
				active: {
					color: dark.red7,
				},
			},
			text: {
				color: dark.red5,
				disabled: {
					color: dark.grey7,
				},
				hover: {
					color: dark.red3,
				},
				active: {
					color: dark.red7,
				},
			},
			icon: {
				color: dark.red5,
				disabled: {
					color: dark.grey8,
				},
				hover: {
					color: dark.red3,
				},
				active: {
					color: dark.red7,
				},
			},
			flag: {
				color: dark.grey5,
				bg: 'transparent',
				hover: {
					bg: dark.grey8,
					color: dark.red3,
				},
				active: {
					bg: dark.grey9,
					color: dark.red7,
				},
				disabled: {
					color: dark.grey8,
					bg: dark.grey6,
				},
				selected: {
					bg: dark.grey8,
					color: dark.red5,
				},
			},
		},
	},

	tooltip: {
		bg: dark.grey0,
		color: dark.grey9,
	},

	checkbox: {
		color: dark.grey1,
		hover: {
			color: dark.grey0,
		},
		active: {
			color: dark.grey2,
		},
		disabled: {
			color: dark.grey6,
		},
		invalid: {
			color: dark.red5,
		},

		requiredColor: dark.red5,

		box: {
			border: dark.blue5,
			bg: 'transparent',
			color: 'transparent',
			hover: {},
			active: {},
			checked: {
				border: dark.blue5,
				bg: dark.blue5,
				color: '#ffffff',
			},
			disabled: {
				border: dark.grey6,
				bg: dark.grey6,
				color: dark.grey3,
			},
			invalid: {
				border: dark.red5,
			},
		},
	},

	daData: {
		bg: dark.grey8,
		border: dark.blue5,
		hover: {
			bg: dark.grey7,
		},
		highlighted: dark.blue5,
	},

	// datePicker: {},

	dropdownMenu: {
		bg: dark.grey8,
		shadow: 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 31%) 0px 0px 1px;',
		item: {
			color: dark.grey1,
			hover: {
				bg: dark.grey7,
				color: dark.grey0,
			},
			active: {
				bg: dark.grey9,
				color: dark.grey0,
			},
		},
	},

	formMessage: {
		error: {
			color: dark.red4,
		},
		success: {
			color: dark.green4,
		},
		default: {
			color: '#ffffff',
		},
	},

	// imagePreview: {},

	input: {
		color: dark.grey0,
		border: dark.grey5,
		requiredColor: dark.red5,
		bg: dark.grey9,
		hover: {
			border: dark.blue3,
		},
		focus: {
			border: dark.blue4,
		},
		invalid: {
			border: dark.red5,
			bg: 'transparent',
		},
		disabled: {
			color: dark.grey5,
			border: dark.grey8,
		},
		icon: {
			color: dark.grey6,
			hover: {
				color: dark.blue2,
			},
			focus: {
				color: dark.blue3,
			},
		},
		placeholder: {
			color: dark.grey3,
			hover: {
				color: dark.blue2,
			},
			focus: {
				color: dark.blue3,
			},
			withValue: {
				color: dark.grey2,
			},
			invalid: {
				color: dark.red2,
			},
			disabled: {
				color: dark.grey5,
			},
		},
	},

	textArea: {
		color: dark.grey0,
		border: dark.grey5,
		requiredColor: dark.red5,
		bg: dark.grey9,
		hover: {
			border: dark.blue3,
		},
		focus: {
			border: dark.blue4,
		},
		invalid: {
			border: dark.red5,
			bg: 'transparent',
		},
		disabled: {
			color: dark.grey5,
			border: dark.grey8,
		},
		icon: {
			color: dark.grey6,
			hover: {
				color: dark.blue2,
			},
			focus: {
				color: dark.blue3,
			},
		},
		placeholder: {
			color: dark.grey3,
			hover: {
				color: dark.blue2,
			},
			focus: {
				color: dark.blue3,
			},
			withValue: {
				color: dark.grey2,
			},
			invalid: {
				color: dark.red2,
			},
			disabled: {
				color: dark.grey5,
			},
		},
	},

	toggleCheckbox: {
		border: dark.green2,
		checked: {
			bg: dark.green2,
		},
		circle: {
			border: dark.green2,
			bg: '#ffffff',
		},
	},

	modal: {
		backdrop: 'rgba(255,255,255,0.4)',
		bg: dark.grey9,
		color: dark.grey0,
		icon: {
			color: dark.grey4,
			hover: {
				color: dark.grey2,
			},
			active: {
				color: dark.grey6,
			},
		},
	},

	// range: {},
	pagination: {
		btn: {
			color: dark.blue5,
			hover: {
				bg: dark.grey8,
			},
			current: {
				bg: dark.blue5,
				color: '#ffffff',
				hover: {
					bg: dark.blue4,
				},
			},
		},
		arrow: {
			color: dark.blue5,
		},
	},

	radio: {
		color: dark.grey1,
		hover: {
			color: dark.grey0,
		},
		active: {
			color: dark.grey2,
		},
		disabled: {
			color: dark.grey5,
		},
		invalid: {
			color: dark.red5,
		},

		circle: {
			border: dark.blue5,
			checked: {
				bg: dark.blue5,
			},
			invalid: {
				border: dark.red5,
			},
		},
	},

	select: {
		bg: dark.grey8,
		border: dark.blue4,
		item: {
			color: dark.grey1,
			hover: {
				bg: dark.grey7,
				color: dark.grey0,
			},
			selected: {
				color: dark.grey0,
			},
		},
	},

	table: {
		header: {
			color: dark.grey0,
			bg: dark.blue5,
		},
		row: {
			border: dark.grey4,
			bg: dark.grey8,
			oddBg: dark.grey7,
			hover: {
				bg: dark.blue5_20,
			},
		},
	},

	tabs: {
		item: {
			hover: {
				bg: dark.grey8,
			},
		},
		selected: {
			border: dark.blue5,
		},
	},

	uploadFile: {
		color: dark.grey1,
		border: dark.grey6,
		requiredColor: dark.red5,
		hover: {
			border: dark.blue4,
			bg: dark.grey6,
		},
		disabled: {
			color: dark.grey6,
			border: dark.grey7,
			bg: dark.grey8,
		},
		invalid: {
			border: dark.red4,
			bg: 'transparent',
		},
		placeholder: {
			color: dark.grey4,
			hover: {
				color: dark.blue3,
			},
			invalid: {
				color: dark.red3,
			},
			disabled: {
				color: dark.grey6,
			},
		},
	},
};
