import React, { useCallback } from 'react';
import { IFloatInputProps } from './types';
import { TypeInputRef } from '../input/types';
import { stringToMoney, stringToNumber, stringToNumberDigits } from '../utils';
import Input from '../input';

let timeout: ReturnType<typeof setTimeout>;

const FloatInput: React.FC<IFloatInputProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { onChange, digitsAfterDot = 2, debounceMs, ...rest } = props;

	const onChangeDebounce = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (!debounceMs) return;
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (onChange) onChange(ev);
			}, debounceMs);
		},
		[debounceMs, onChange]
	);

	const inputChangeHandler = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			const {
				target: { value },
			} = ev;
			ev.target.value = stringToNumberDigits(value, digitsAfterDot);
			if (typeof debounceMs === 'number') return onChangeDebounce(ev);
			if (onChange) onChange(ev);
		},
		[onChange, debounceMs, onChangeDebounce, digitsAfterDot]
	);

	const startEditing = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = ev;
		ev.target.value = stringToNumber(value);
	}, []);

	const endEditing = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value },
			} = ev;
			ev.target.value = stringToMoney(stringToNumber(value), digitsAfterDot);
		},
		[digitsAfterDot]
	);

	return <Input {...rest} ref={ref} onChange={inputChangeHandler} onBlur={endEditing} onFocus={startEditing} />;
});

export default React.memo(FloatInput);
