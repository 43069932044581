import React from 'react';
import { useGroupTabContext } from './context';
import { StyledSearch, StylesGroupTabWrapper } from './styles';
import Input from '../../../../../../libs/uiKit/input';
import { SearchIcon } from '../../../../../../icons/ui/Search';
import Loader from '../../../../../../libs/uiKit/loader';
import { Heading3, Text4 } from '../../../../../../libs/uiKit/globalStyledComponents';
import GroupToggle from './GroupToggle';
import GroupUserItem from './GroupUser';

const GroupTabUi: React.FC = () => {
	const { isLoading, searchHandler, isHaveFilters, filterGroups, filterUsers } = useGroupTabContext();

	return (
		<StylesGroupTabWrapper>
			<StyledSearch>
				<Input placeholder='Поиск по названию группы' debounceMs={500} onChange={searchHandler} iconAfter={<SearchIcon />} />
			</StyledSearch>
			{isLoading && <Loader />}
			{!isLoading && isHaveFilters && <Heading3>Найдено групп</Heading3>}
			{!isLoading && !!filterGroups.length && filterGroups.map(group => <GroupToggle key={group.id} group={group} />)}
			{!isLoading && !filterGroups.length && isHaveFilters && <Text4>Групп не найдено</Text4>}
			{!isLoading && isHaveFilters && <Heading3>Найдено пользователей</Heading3>}
			{!isLoading && !!filterUsers.length && isHaveFilters && filterUsers.map(user => <GroupUserItem {...user} />)}
			{!isLoading && !filterUsers.length && isHaveFilters && <Text4>Пользователей не найдено</Text4>}
		</StylesGroupTabWrapper>
	);
};

export default React.memo(GroupTabUi);
