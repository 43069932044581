import styled from "styled-components";
import {grey} from "../../../../libs/uiKit/globalStyledComponents";

export const StyledToggleAreaWrapper = styled.div`
	border-top: 1px solid ${grey};
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;

export const StyledToggleAreaLeft = styled.div`
	border-right: 1px solid ${grey};
	padding: 2rem;
`;

export const StyledPremiumBlock = styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem;
`;

export const StyledGrayLabel = styled.p`
    opacity: 0.5;
    font-family: Verdana;
    font-size: 1.4rem;
    line-height: 2rem;
`;

export const StyledBigPremiumHeader = styled.h1`
    font-family: "Futura PT Demi";
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 600;
`;

export const StyledButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 10px;
`;