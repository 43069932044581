import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useMutation} from "@apollo/react-hooks";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {Modal} from "../../../../libs/uiKit/modal";
import {StyledInvoiceDescription} from "../ui/styles";
import {StyledBigPremiumHeader, StyledGrayLabel, StyledPremiumBlock} from "../../invoice/ui/styles";
import {Text4} from "../../../../libs/uiKit/globalStyledComponents";
import {FieldsBuilder} from "../../../../libs/uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../invoice/widgets/sendPayLinkModal/styles";
import Button from "../../../../libs/uiKit/button";
import {RADIO_BOOLEAN} from "../../../../libs/utils/staticData";
import {stringToMoney} from "../../../../libs/uiKit/utils";
import {PREPARE_INVOICE} from "../../../../libs/api/commands";
import GetInvoiceModal from "../../invoice/widgets/getInvoiceModal";

const PayPeriodModal = (props: IModalProps) => {
	const {id} = useParams();
	const {data} = props;

	console.log('data', data);

	const {sum, number, email, invoice} = data;
	const {setNotification} = useNotification();
	const [stateModalIsOpen, setModalIsOpen] = useState(undefined)
	const [stateShowCondError, setShowCondError] = useState(false);

	const [stateInvoiceModalIsOpen, setInvoiceModalIsOpen] = React.useState<boolean>(false);

	const formHook = useForm({mode: "onBlur"});
	const {handleSubmit, watch} = formHook;

	const [createInvoice, {loading: loadingCreateInvoice}] = useMutation(PREPARE_INVOICE, {
		refetchQueries: ['loadContract'], awaitRefetchQueries: true
	});

	const onFormSubmit = handleSubmit((formData) => {
		if(formData.hasEarlyPays === 'true' || formData.hasHealthIssues === 'true'){
			setShowCondError(true);
			return;
		}
		setShowCondError(false);

		createInvoice({
			variables: {
				id: parseInt(id || "", 10)
			}
		}).then(() => {
			setInvoiceModalIsOpen(true);
		}).catch(error => setNotification({type: "error", text: error}));

	});

	return (
		<Modal {...props} header="Оплата очередного взноса">
			<StyledPremiumBlock>
				<StyledGrayLabel>Сумма к оплате</StyledGrayLabel>
				<StyledBigPremiumHeader>{stringToMoney(sum)} RUB</StyledBigPremiumHeader>
			</StyledPremiumBlock>
			<StyledInvoiceDescription>
				<StyledGrayLabel>Назначение платежа:</StyledGrayLabel>
				<Text4>Страховая премия по договору {number}</Text4>
			</StyledInvoiceDescription>

			<form onSubmit={onFormSubmit}>
				<FieldsBuilder formHook={formHook} fields={[{
					label: "Были ли у клиента проблемы со здоровьем за текущий период страхования",
					field: {
						fieldType: 'radio',
						isRequired: true,
						fieldName: 'hasHealthIssues',
						items: RADIO_BOOLEAN,
					},
				}, {
					label: "Были досрочные платежи",
					field: {
						fieldType: 'radio',
						isRequired: true,
						fieldName: 'hasEarlyPays',
						items: RADIO_BOOLEAN,
					},
				}]}/>
				{stateShowCondError && <Text4 style={{color:"red", marginBottom: "10px"}}>Продлить договор через платформу невозможно. Пожалуйста, свяжитесь с куратором</Text4>}
				<StyledButtonsGroup>
					<Button
						type="submit"
						isLoading={loadingCreateInvoice}
						appearance="filled">
						Оплатить
					</Button>
					<Button onClick={() => props.onClose()} appearance="transparent">
						Отмена
					</Button>
				</StyledButtonsGroup>
			</form>
			<GetInvoiceModal isOpen={stateInvoiceModalIsOpen} isLoading={false} onClose={() => { setInvoiceModalIsOpen(false); props.onClose() }} data={{
				invoice,
				email,
				subject: `Счет ${invoice?.invoiceNo} по договору ${number}`
			}}/>
		</Modal>
	)
};

export default PayPeriodModal;