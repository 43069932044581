import React from 'react';
import { ApolloError } from 'apollo-client';
import { useDispatch } from 'react-redux';
import { notificationSlice, NotificationTypes } from '../../ds/redux/notification';
import { uuidv4 } from '../../../../../libs/uiKit/utils';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';

interface INotificationProps {
	type: NotificationTypes;
	text: ApolloError | string;
}

const useNotification = (): {
	setNotification: (props: INotificationProps) => void,
	showError: (error: string) => void,
} => {
	const {
		routes: { checkError500Handler, redirectToAuthorization },
	} = useGlobalContext();
	const dispatch = useDispatch();



	const setNotification = React.useCallback(
		(props: INotificationProps): void => {
			checkError500Handler({
				error: props.text,
				needAuthCallback: redirectToAuthorization,
				justErrorCallback: error =>
					dispatch(
						notificationSlice.actions.addOne({
							id: uuidv4(),
							description: error,
							type: props.type,
						})
					),
			});
		},
		[checkError500Handler, dispatch, redirectToAuthorization]
	);

	const showError = (error: string) => {
		dispatch(
			notificationSlice.actions.addOne({
				id: uuidv4(),
				description: error,
				type: 'error',
			})
		);
	}

	return {
		setNotification,
		showError
	};
};

export default useNotification;
