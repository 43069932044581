import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';

type ProfileContext = {
	username: string;
	form: IForm;
	onFormSubmit: () => void;
	isShowSave: boolean;
	isShowChangePassword: boolean;
	isLoading: boolean;
	changePasswordIsOpen: boolean;
	switchChangePasswordHandler: (isOpen: boolean) => void;
};

const ProfileContext = createContext<ProfileContext>({} as ProfileContext);

export const useProfileContext = (): ProfileContext => useContext(ProfileContext);

const ProfileContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		user: { username, email, phone, type },
	} = useGlobalContext();
	const formHook = useForm({ mode: 'onBlur' });

	const [stateChangePasswordModalIsOpen, setChangePasswordModalIsOpen] = React.useState<boolean>(false);

	const switchChangePasswordHandler = React.useCallback((isOpen: boolean) => setChangePasswordModalIsOpen(isOpen), []);

	const onFormSubmit = formHook.handleSubmit(data => {
		console.log(data);
	});

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: 'fullName',
						placeholder: 'ФИО',
						isDisabled: type !== 'user',
						defaultValue: username,
						errorMessage: 'Введите ФИО',
						grid: type === 'admin' ? undefined : 6,
					},
				},
				{
					isHidden: type !== 'admin',
					field: {
						fieldType: 'input',
						fieldName: 'phone',
						mask: '+7 (999) 999-99-99',
						validationType: 'phone',
						placeholder: 'Телефон',
						isDisabled: true,
						defaultValue: phone,
						errorMessage: 'Введите телефон',
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'email',
						validationType: 'email',
						placeholder: 'E-mail',
						isDisabled: true,
						defaultValue: email,
						errorMessage: 'Введите e-mail',
						grid: 6,
					},
				},
			],
		}),
		[email, formHook, phone, type, username]
	);

	const value: ProfileContext = React.useMemo(
		() => ({
			username: username || 'Неизвестно',
			form,
			onFormSubmit,
			isShowSave: type === 'user',
			isLoading: false,
			isShowChangePassword: true,
			// isShowChangePassword: type === 'user',
			changePasswordIsOpen: stateChangePasswordModalIsOpen,
			switchChangePasswordHandler,
		}),
		[form, onFormSubmit, stateChangePasswordModalIsOpen, switchChangePasswordHandler, type, username]
	);

	return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default React.memo(ProfileContextProvider);
