import styled from "styled-components";
import { blue, grey, white } from "../globalStyles";

export const StyledInputRange = styled.input<{ percent: number }>`
  width: 100%;
  margin: 0;
  -webkit-appearance: none;

  height: 3px;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: ${props => `linear-gradient(90deg, ${blue} 0%, ${blue} ${props.percent}%, ${grey} ${props.percent}%, ${grey} 100%)`};
  }

  &::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: ${props => `linear-gradient(90deg, ${blue} 0%, ${blue} ${props.percent}%, ${grey} ${props.percent}%, ${grey} 100%)`};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${grey};
    border-radius: 50%;
    background: ${white};
    margin-top: -0.6rem;
  }
`;
