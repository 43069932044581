import React from 'react';
import ChangePasswordUi from './ui';
import { ChangePasswordContextProps, ChangePasswordContextProvider } from './ui/context';

const ChangePasswordIgniter: React.FC<ChangePasswordContextProps> = props => (
	<ChangePasswordContextProvider {...props}>
		<ChangePasswordUi />
	</ChangePasswordContextProvider>
);

export default React.memo(ChangePasswordIgniter);
