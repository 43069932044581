import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../apps/main/ds/redux';
import { notificationAdapter, NotificationModel, notificationSlice } from '../../ds/redux/notification';

type NotifierContext = {
	notificationItems: NotificationModel[];
	deleteNotification: (id: string) => void;
};

const NotifierContext = createContext<NotifierContext>({} as NotifierContext);

export const useNotifierContext = (): NotifierContext => useContext(NotifierContext);

export const NotifierContextProvider: React.FC = ({ children }) => {
	const storeNotifications = useTypedSelector(store => store.notify);
	const dispatch = useDispatch();

	const notifications: NotificationModel[] = notificationAdapter.getSelectors().selectAll(storeNotifications);

	const deleteNotification = React.useCallback(
		(id: string): void => {
			if (!id) return;
			dispatch(notificationSlice.actions.removeOne(id));
		},
		[dispatch]
	);

	React.useEffect(() => {
		if (!notifications.length) return;
	
		const interval = setTimeout(() => {
			const notification = notifications[(notifications || []).length - 1];
			const deleteId: string | undefined = notification?.id;
			if(notification?.type === 'success'){
				deleteNotification(deleteId);
			}
		}, 3000);
	
		return () => {
			clearTimeout(interval);
		};
	}, [notifications, deleteNotification]);

	const value: NotifierContext = React.useMemo(
		() => ({
			notificationItems: notifications,
			deleteNotification,
		}),
		[notifications, deleteNotification]
	);

	return <NotifierContext.Provider value={value}>{children}</NotifierContext.Provider>;
};
