import React from 'react';
import RiskPropertyUi from './ui';
import RiskPropertyContextProvider, { RiskPropertyContextProps } from './ui/context';

const RiskPropertyIgniter: React.FC<RiskPropertyContextProps> = props => (
	<RiskPropertyContextProvider {...props}>
		<RiskPropertyUi />
	</RiskPropertyContextProvider>
);

export default React.memo(RiskPropertyIgniter);
