import styled, { css } from 'styled-components';
import { black, black10, black40, blue7 } from '../globalStyledComponents';

export const StyledTableWrapper = styled.div`
	overflow-y: hidden;
	overflow-x: auto;
`;

export const StyledTable = styled.table`
	width: 100%;
	table-layout: auto;
	overflow: hidden;

	thead {
		border-bottom: 1px solid ${black10};
	}

	thead th {
		text-align: left;
		font-family: Verdana, sans-serif;
		font-style: normal;
		font-weight: bold;
		text-transform: uppercase;
		white-space: nowrap;
		font-size: 1.1rem;
		line-height: 1.5rem;
		padding: 1.3rem 1rem;
		color: ${black40};
	}

	tbody tr {
		border-bottom: 1px solid ${black10};
	}

	tbody td {
		vertical-align: middle;
		white-space: nowrap;
		padding: 1.8rem 1rem;
		text-transform: capitalize;
		font-family: Verdana, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 2rem;
		color: ${black};
	}

	tr:hover {
		transition: background-color 0.2s;
		background-color: ${blue7};
	}

	td,
	th {
		cursor: pointer;
		position: relative;
	}

	td::after {
		content: '';
		pointer-events: none;
		position: absolute;
		transition: background-color 0.2s;
		left: 0;
		top: -5000px;
		height: 10000rem;
		width: 100%;
	}

	td:hover::after {
		background-color: ${blue7};
	}
`;

export const StyledHeadTh = styled.th<{ width?: number; direction?: 'ascending' | 'descending'; isSortable?: boolean }>`
	${({ width }) =>
		width &&
		css`
			width: ${width}%;
		`}

	${({ direction }) => {
		if (!direction) return;
		return direction === 'ascending'
			? css`
					&:after {
						content: '\\2193';
					}
			  `
			: css`
					&:after {
						content: '\\2191';
					}
			  `;
	}}
	
	${({ isSortable }) =>
		isSortable &&
		css`
			&:hover {
				cursor: pointer;
				background-color: #c2c2c2;
			}
		`}
`;

export const StyledBodyTr = styled.tr`
	&:hover {
		background-color: #c2c2c2;
	}
`;

export const StyledBodyEmpty = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 5rem;
`;
