import styled from 'styled-components';
import { black10, blue10, blue5, DEFAULT_SHADOW } from '../../../../../../libs/uiKit/globalStyledComponents';

export const StyledBorrowersWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-row-gap: 1.5rem;
	}
`;

export const StyledBorrowerItem = styled.div`
	cursor: pointer;
	width: calc(100% - 4rem);
	border: 1px solid ${blue10};
	background: ${blue5};
	padding: 2rem;
	box-shadow: unset;
	transition: box-shadow 0.2s;
	h4 {
		margin-bottom: 1rem;
	}

	:hover {
		box-shadow: ${DEFAULT_SHADOW};
	}

	@media screen and (max-width: 767px) {
		width: calc(100% - 3rem);
		padding: 1.5rem;
	}
`;

export const StyledBorrowerFooter = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 2.5rem;
	margin-top: 2.5rem;
	border-top: 1px solid ${blue10};

	@media screen and (max-width: 767px) {
		padding-top: 1.5rem;
		margin-top: 1.5rem;
	}
`;
