import React, {useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import { useWindowWidth } from '@react-hook/window-size';
import { useUnderwritingContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import {
	StyledCalculationDataWrapper,
	StyledEventsButtons, StyledFillingTypeBadge,
	StyledHeaderEvents,
	StyledHeaderInformation,
	StyledHeaderWrapper,
	StyledImportantNumber,
	StyledInformationItem,
	StyledRemark,
	StyledRiskFormWrapper,
	StyledRiskInformation,
	StyledSignetAgreementButtons,
	StyledSumm,
	StyledSummsWrapper,
	StyledTableAreaWrapper,
	StyledToggleAreaLeft,
	StyledToggleAreaWrapper,
	StyledUnderwritingHeader,
	StyledUnderwritingHeaderFields,
	StyledUnderwritingRisksForm,
	StyledUnderwritingWrapper,
} from './styles';
import { Heading2, Heading3, Heading4, Text4, TextButton } from '../../../../libs/uiKit/globalStyles';
import Button from '../../../../libs/uiKit/button';
import { MoreIcon } from '../../../../icons/ui/More';
import DropdownMenu from '../../../../libs/uiKit/dropdownMenu';
import FlyButtons from '../../../../libs/uiKit/flyButtons';
import { SelectArrowIcon } from '../../../../libs/uiKit/icons/SelectArrow';
import LoadingString from '../../../../libs/uiKit/loadingString';
import Status from '../../../../libs/uiKit/status';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import { ArrowIcon } from '../../../../icons/ui/Arrow';
import Accordion from '../../../../libs/uiKit/accordion';
import { QuestionIcon } from '../../../../icons/ui/Question';
import DiscussionIgniter from '../../../widgets/disscusion';
import DocumentsWidget from '../../../widgets/documents';
import AgreementTableIgniter from '../../../widgets/agreementTable';
import AgreementChangeLogIgniter from '../../../widgets/agreementChangeLog';
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {useRegisterAgreementMutation} from "../../../../libs/api/graphqlTypes";
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";
import {useUserRights} from "../../common/hooks/useUserRights";
import SendToSbModal from "../../seller/widgets/SendToSbModal";

const UnderwritingUi: React.FC = () => {
	const {
		risksDropdownIsOpen,
		switchRisksDropDown,
		headerButtons,
		isShowGoToAgreement,
		isDisabledGoToAgreement,
		isLoadingGoToAgreement,
		goToAgreementHandler,
		changeLogIsOpen,
		closeChangeLogHandler,
		informationItems,
		isDisabledRecalc,
		isDisabledChangeRisks,
		riskForm,
		isLoadingEvents,
		isLoadingAgreement,
		totalSumm,
		totalSummWithDiscount,
		discount,
		discountDueKv,
		headerForm,
		goBackHandler,
		riskFormItems,
		onFormSubmit,
		switchAccordion,
		accordionIsOpen,
		agreementId,
		resultDocuments,
		email,
		isAssignedToMe,
		assignToMeHandler,
		fillingTypeText,
		isAtUnderwriting,
		calculation,
		status
	} = useUnderwritingContext();
	const windowWidth = useWindowWidth({ wait: 300 });

	const cachedHeaderForm = useMemo(() => <FieldsBuilder {...headerForm} />, [headerForm]);

	const [stateSendToSbModalIsOpen, setSendToSbModalIsOpen] = React.useState<boolean>(false);

	const { setNotification, showError } = useNotification();
	const navigate = useNavigate();

	const {
		routes: { contractById },
	} = useGlobalContext();

	const { rightsHandler } = useUserRights();

	const isAtSb = status === 'waitingForSb' || status === 'canceledBySb';

	const showCreateContract = rightsHandler('createAgreement') && !isShowGoToAgreement && !isAtUnderwriting && !isAtSb;

	const [registerContract, { loading: loadingRegisterContract }] = useRegisterAgreementMutation({
		refetchQueries: ['loadAgreementUnderwriting'],
	});

	const createContractHandler = () => {
		if (!agreementId) return setNotification({ type: 'error', text: 'no agreement id' });
		registerContract({
			variables: {
				id: agreementId,
			},
		})
			.then(({ data }) => {
				if (!data?.registerAgreement) return setNotification({ type: 'error', text: 'no contract id' });
				navigate(contractById(String(data.registerAgreement)));
			})
			.catch(error => showError(error.message));
	}

	return (
		<MainLayoutIgniter>
			<StyledUnderwritingHeader>
				<Button appearance='text' onClick={goBackHandler} iconBefore={<ArrowIcon direction='left' />}>
					Заявки
				</Button>
				<Heading2>Андеррайтинг заявки</Heading2>
			</StyledUnderwritingHeader>
			<StyledUnderwritingWrapper>
				<form onSubmit={onFormSubmit}>
					<StyledHeaderWrapper>
						<StyledHeaderEvents>
							<Heading3>Общие сведения</Heading3>
							<StyledEventsButtons>
								{windowWidth < 768 && <DropdownMenu trigger={<Button icon={<MoreIcon />} appearance='flag' />} items={headerButtons} />}
								{windowWidth >= 768 &&
									headerButtons.map(({ label, event, icon, isDisabled }, index) => (
										<Button key={`headerButton-${index}`} tooltip={label} appearance='icon' icon={icon} isLoading={isLoadingAgreement} isDisabled={isDisabled} onClick={event} />
									))}

								<FlyButtons isWhiteBackground={true}>
									{isShowGoToAgreement && (
										<Button isLoading={isLoadingGoToAgreement} isDisabled={isDisabledGoToAgreement} onClick={goToAgreementHandler} appearance='transparent'>
											Перейти к договору
										</Button>
									)}
									{isAtUnderwriting && !isShowGoToAgreement && !isAssignedToMe && (
										<Button isLoading={isLoadingEvents} onClick={assignToMeHandler} appearance='transparent'>
											Взять в работу
										</Button>
									)}
									{!isShowGoToAgreement && (isAssignedToMe || !isAtUnderwriting) && (
										<StyledSignetAgreementButtons>
											<Button isLoading={isLoadingEvents} isDisabled={isDisabledRecalc} appearance='transparent' type='submit'>
												Пересчитать
											</Button>
											{status !== 'waitingForSb' && status !== 'canceledBySb' &&
												<Button isLoading={isLoadingEvents} appearance='transparent' onClick={() => { setSendToSbModalIsOpen(true) }}>
													Направить в СБ
												</Button>
											}
											{showCreateContract &&
												<Button isLoading={isLoadingAgreement || loadingRegisterContract} appearance='filled' onClick={createContractHandler}>
													Создать договор
												</Button>
											}
											<DropdownMenu
												trigger={
													<Button
														iconAfter={<SelectArrowIcon direction={risksDropdownIsOpen ? 'top' : 'down'} />}
														onClick={(): void => {
															if(isDisabledChangeRisks) return;
															switchRisksDropDown(true);
														}}
														isLoading={isLoadingEvents}
														isDisabled={isDisabledChangeRisks}
														appearance='filled'
													>
														Согласовать
													</Button>
												}
												isOpen={risksDropdownIsOpen}
												onChange={switchRisksDropDown}
											>
												<StyledUnderwritingRisksForm>
													<FieldsBuilder {...riskForm} />
													<Button isLoading={isLoadingEvents} type='submit' appearance='filled'>
														Применить
													</Button>
												</StyledUnderwritingRisksForm>
											</DropdownMenu>
										</StyledSignetAgreementButtons>
									)}
								</FlyButtons>
							</StyledEventsButtons>
						</StyledHeaderEvents>
						<StyledHeaderInformation>
							{informationItems.map(({ title, description, color, isStatus, description2 }, index) => (
								<StyledInformationItem key={`info-item-${index}`}>
									<Text4>{title}</Text4>
									<LoadingString isLoading={isLoadingAgreement}>{isStatus ? <Status status={description} color={color} /> : <TextButton>{description}</TextButton>}</LoadingString>
									{description2 &&
										<LoadingString isLoading={isLoadingAgreement}><TextButton>{description2}</TextButton></LoadingString>
									}
								</StyledInformationItem>
							))}
							<StyledSummsWrapper>
								<StyledSumm>
									<LoadingString width={20} isLoading={isLoadingAgreement}>
										<Text4>Итого</Text4>
										<StyledImportantNumber>
											<Text4>{totalSumm}</Text4>
										</StyledImportantNumber>
									</LoadingString>
								</StyledSumm>
								<StyledSumm>
									<LoadingString width={20} isLoading={isLoadingAgreement}>
										<Text4>Со скидкой КВ ({discountDueKv}%)</Text4>
										<StyledImportantNumber>
											<Text4>{totalSummWithDiscount}</Text4>
										</StyledImportantNumber>
									</LoadingString>
								</StyledSumm>
							</StyledSummsWrapper>
						</StyledHeaderInformation>

						<StyledUnderwritingHeaderFields>
							{cachedHeaderForm}
						</StyledUnderwritingHeaderFields>
					</StyledHeaderWrapper>

					<Accordion header='Общие сведения по заявке' badge={fillingTypeText} isNotUnmount={true} isOpen={accordionIsOpen} onChange={switchAccordion}>
						<StyledCalculationDataWrapper>
							{riskFormItems.map(({ title, forms }, indexRisk) => {
								if ((forms || []).length === 0) return null;
								return (
									<StyledRiskFormWrapper key={`riskForm-${indexRisk}`}>
										<Heading4>{title}</Heading4>
										{forms.map(({ form, informationItems: informationItemsRisk, remark }, indexRiskForm) => (
											<React.Fragment key={`currentRiskForm-${indexRiskForm}`}>
												<StyledRiskInformation>
													{informationItemsRisk.map(({ label, value }, indexInformationRisk) => (
														<StyledInformationItem key={`info-item-${indexInformationRisk}`}>
															<Text4>{label}</Text4>
															<Text4>{value}</Text4>
														</StyledInformationItem>
													))}
												</StyledRiskInformation>
												<FieldsBuilder {...form} />
												{!!remark && (
													<StyledRemark>
														<QuestionIcon />
														<Text4>{remark}</Text4>
													</StyledRemark>
												)}
											</React.Fragment>
										))}
									</StyledRiskFormWrapper>
								);
							})}
						</StyledCalculationDataWrapper>
					</Accordion>
				</form>
				<StyledTableAreaWrapper>
					<Heading3>Тарифы, страховые суммы, премии</Heading3>
					<AgreementTableIgniter id={agreementId} />
				</StyledTableAreaWrapper>

				<StyledToggleAreaWrapper>
					<StyledToggleAreaLeft>
						<DocumentsWidget
							id={agreementId}
							isShowHint={true}
							resultDocuments={resultDocuments}
							email={email}
						/>
					</StyledToggleAreaLeft>
					<DiscussionIgniter id={agreementId} />
				</StyledToggleAreaWrapper>
			</StyledUnderwritingWrapper>
			<AgreementChangeLogIgniter id={agreementId} onClose={closeChangeLogHandler} isOpen={changeLogIsOpen} />
			<SendToSbModal
				onClose={() => setSendToSbModalIsOpen(false)}
				isOpen={stateSendToSbModalIsOpen}
				data={{
					life_risks: calculation?.life_risks,
					property_risks: calculation?.property_risks,
					titul_risks: calculation?.titul_risks,
				}}
			/>
		</MainLayoutIgniter>
	);
};

export default React.memo(UnderwritingUi);
