import React from 'react';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Calendar from 'react-calendar';
import { TypeInputRef } from '../input/types';
import { IDateProps } from './types';
import ClickAway from '../clickAway';
import { StyledDatePickerWrapper } from './styles';
import Button from '../button';
import { DataPickerIcon } from '../icons/DataPicker';
import { CloseIcon } from '../icons/Close';
import InputMask from '../inputMask';
import 'react-calendar/dist/Calendar.css';
import { formatDateToStringRu, formatStringRuToDate } from '../../utils/gormatDateToUsa';

dayjs.extend(customParseFormat);

const DatePicker: React.FC<IDateProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { value, defaultValue, minDate, maxDate, onChange, isDisabled, ...rest } = props;
	// in state saved date in usa format
	const [stateDate, setDate] = React.useState<Date | null>(defaultValue || null);
	const [stateIsOpen, setIsOpen] = React.useState<boolean>(false);
	const [stateInputValue, setInputValue] = React.useState<string>('');

	React.useEffect(() => {
		if (value === undefined || value === null) return;
		setDate(value);
		setInputValue(formatDateToStringRu(value));
	}, [value]);

	const onChangeHandler = React.useCallback(
		(currentValue: Date | null) => {
			if (currentValue === null) {
				if (onChange) onChange(null);
				setInputValue('');
				return setDate(null);
			}
			if (onChange) onChange(currentValue);
			setDate(currentValue);
			setInputValue(formatDateToStringRu(currentValue));
		},
		[onChange]
	);

	const onInputChangeHandler = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value: currentValue },
			} = ev;
			setInputValue(currentValue);
			if (!currentValue) return onChangeHandler(null);
			if (currentValue.endsWith('_')) return;
			const stringToDate: Date = formatStringRuToDate(currentValue);
			if(!Number.isNaN(stringToDate.getTime())){
				onChangeHandler(stringToDate);
			}
			if (stateIsOpen) setIsOpen(false);
		},
		[stateIsOpen, onChangeHandler]
	);

	const onCalendarChangeHandler = React.useCallback(
		(currentValue: Date) => {
			onChangeHandler(currentValue);
			setIsOpen(false);
		},
		[onChangeHandler]
	);

	const clearCalendarHandler = React.useCallback(() => onChangeHandler(null), [onChangeHandler]);

	const toggleCalendarHandler = React.useCallback(
		(isOpen?: boolean): void => {
			if (isOpen === undefined) return setIsOpen(!stateIsOpen);
			setIsOpen(isOpen);
		},
		[stateIsOpen]
	);

	const onClickAwayClick = (ev: Event) => {
		if(ev.target instanceof Element && (ev.target.classList.contains('react-calendar__tile') || ev.target?.parentElement?.classList.contains('react-calendar__tile'))){
			toggleCalendarHandler(true);
		} else {
			toggleCalendarHandler(false);
		}
	}

	return (
		<StyledDatePickerWrapper>
			<ClickAway onClickAway={(ev): void => onClickAwayClick(ev)} isOpen={stateIsOpen}>
				<InputMask
					{...rest}
					mask='99.99.9999'
					ref={ref}
					autoComplete='off'
					value={stateInputValue}
					isDisabled={isDisabled}
					onChange={onInputChangeHandler}
					onClick={(): void => toggleCalendarHandler(true)}
					iconAfter={
						stateDate ? (
							<Button isDisabled={isDisabled} appearance='icon' onClick={clearCalendarHandler} icon={<CloseIcon />} />
						) : (
							<Button isDisabled={isDisabled} appearance='icon' onClick={() => toggleCalendarHandler(true)} icon={<DataPickerIcon />} />
						)
					}
				/>
				<CSSTransition
					in={stateIsOpen}
					timeout={300}
					mountOnEnter
					unmountOnExit
					classNames={{
						enterActive: 'calendarShow',
						enterDone: 'calendarShow',
						exitActive: 'calendarHide',
						exitDone: 'calendarHide',
					}}
				>
					<Calendar next2Label={null} prev2Label={null} maxDate={maxDate || undefined} minDate={minDate || undefined} value={stateDate} onChange={onCalendarChangeHandler} />
				</CSSTransition>
			</ClickAway>
		</StyledDatePickerWrapper>
	);
});

export default React.memo(DatePicker);
