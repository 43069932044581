import React, { createContext, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { IModalProps } from '../../../../../../../libs/uiKit/modal/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { DeleteGroupMutation, DeleteGroupMutationVariables } from '../../../../../../../libs/api/graphqlTypes';
import { DELETE_GROUP } from '../../../../../../../libs/api/commands';

export interface DeleteGroupContextProps extends IModalProps {
	id?: number;
	deleteCallback?: () => void;
}

interface DeleteGroupContext extends IModalProps {
	deleteHandler: () => void;
	isLoading: boolean;
}

const DeleteGroupContext = createContext<DeleteGroupContext>({} as DeleteGroupContext);

export const useDeleteGroupContext = (): DeleteGroupContext => useContext(DeleteGroupContext);

export const DeleteGroupContextProvider: React.FC<DeleteGroupContextProps> = ({ children, id, deleteCallback, isOpen, onClose }) => {
	const { setNotification } = useNotification();

	const [deleteGroup, { loading: loadingDeleteGroup }] = useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DELETE_GROUP, { refetchQueries: ['groups'] });

	const deleteHandler = React.useCallback(() => {
		if (Number.isNaN(Number(id))) return setNotification({ type: 'error', text: 'Не верный id' });
		deleteGroup({
			variables: {
				id: Number(id),
			},
		})
			.then(() => {
				onClose();
				if (deleteCallback) deleteCallback();
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	}, [id, deleteCallback, deleteGroup, setNotification, onClose]);

	const value: DeleteGroupContext = React.useMemo(
		() => ({
			deleteHandler,
			onClose,
			isOpen,
			isLoading: loadingDeleteGroup,
		}),
		[deleteHandler, isOpen, onClose, loadingDeleteGroup]
	);

	return <DeleteGroupContext.Provider value={value}>{children}</DeleteGroupContext.Provider>;
};
