import React, { useCallback } from 'react';
import { IInputProps, IStylesInputProps, TypeInputRef } from './types';
import { StyledInput, StyledInputLabel, StyledInputWrapper } from './styles';
import FormMessage from '../formMessage';
import Loader from '../loader';

let timeout: ReturnType<typeof setTimeout>;

const Input: React.FC<IInputProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { placeholder, errorMessage, helpMessage, isInvalid, isLoading, debounceMs, isDisabled, iconBefore, iconAfter, isRequired, onChange, ...rest } = props;

	const stylesProps: IStylesInputProps = {
		iconAfter: !!iconAfter,
		iconBefore: !!iconBefore,
		isDisabled: !!isDisabled,
		isInvalid: !!isInvalid,
		isRequired: !!isRequired,
	};

	const onChangeDebounce = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (!debounceMs) return;
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (onChange) onChange(ev);
			}, debounceMs);
		},
		[debounceMs, onChange]
	);

	const inputChangeHandler = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (typeof debounceMs === 'number') return onChangeDebounce(ev);
			if (onChange) onChange(ev);
		},
		[onChange, debounceMs, onChangeDebounce]
	);

	return (
		<>
			<StyledInputWrapper {...stylesProps} className='inputWrapper'>
				{iconBefore ? <span className='iconBefore'>{iconBefore}</span> : null}
				<StyledInput
					className='nivaInput'
					{...stylesProps}
					{...rest}
					disabled={!!isDisabled}
					placeholder=' '
					autoComplete='off'
					onChange={onChange ? inputChangeHandler : undefined}
					ref={ref}
				/>
				<StyledInputLabel {...stylesProps}>{placeholder}</StyledInputLabel>
				{isLoading && (
					<span className='iconAfter'>
						<Loader loaderWidth={2} />
					</span>
				)}
				{!isLoading && !!iconAfter && <span className='iconAfter'>{iconAfter}</span>}
				{errorMessage && <FormMessage message={errorMessage} type='error' />}
				{helpMessage && !errorMessage && <FormMessage message={helpMessage} type='helper' />}
			</StyledInputWrapper>
		</>
	);
});

export default React.memo(Input);
