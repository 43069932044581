import React from "react";
import {useForm} from "react-hook-form";
import {IModalProps} from "../../../libs/uiKit/modal/types";
import {IForm, IFormField} from "../../../libs/uiKit/fieldsBuilder/types";
import {Modal} from "../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../libs/uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../pages/invoice/widgets/sendPayLinkModal/styles";
import Button from "../../../libs/uiKit/button";

type IFormModalProps = {
  submitButton?: string;
  cancelButton?: string;
  fields: IFormField[];
  onSubmit: (formData: any) => void;
} & IModalProps;

const FormModal = (props: IFormModalProps) => {

  const {submitButton, cancelButton, header, isLoading, onSubmit, fields} = props;

  const formHook = useForm({mode: "onBlur"});

  const {handleSubmit, watch} = formHook;

  const form: IForm = {
    formHook,
    hotReload: true,
    fields,
  };

  const onFormSubmit = handleSubmit(onSubmit)

  return (
    <Modal {...props} header={header} isLoading={false}>
      <form onSubmit={onFormSubmit}>
        <FieldsBuilder {...form}/>
        <StyledButtonsGroup>
          <Button isLoading={isLoading} appearance="filled" type="submit">{submitButton || "Отправить"}</Button>
          <Button appearance="transparent" onClick={props.onClose}>{cancelButton || "Отмена"}</Button>
        </StyledButtonsGroup>
      </form>
    </Modal>
  );
}

export default FormModal;