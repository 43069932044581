import styled from 'styled-components';
import { grey } from '../../../../libs/uiKit/globalStyledComponents';
import { blue, DEFAULT_SHADOW, white } from '../../../../libs/uiKit/globalStyles';

export const StyledHeaderWrapper = styled.div`
	padding: 4rem;
	width: calc(100% - 8rem);
	@media screen and (max-width: 767px) {
		padding: 1.5rem;
		width: calc(100% - 3rem);
	}

	border-bottom: 1px solid ${grey};
`;

export const StyledContractHeader = styled.div`
	display: flex;
	justify-content: space-between;
	.linkButton {
		margin-right: 2rem;
	}
	padding-bottom: 4rem;
	border-bottom: 1px solid ${grey};

	@media screen and (max-width: 767px) {
		.linkButton {
			margin-right: 0;
		}
	}
`;

export const StyledEventsButtons = styled.div`
	display: flex;
	align-items: center;
	.iconButton {
		margin-right: 2.5rem;
	}
	.linkButton {
		margin-right: 2.5rem;
	}
`;

export const StyledSignetAgreementButtons = styled.div`
	display: flex;

	> button {
		margin-right: 1rem;
	}

	@media screen and (max-width: 767px) {
		> button {
			width: calc(50% - 0.5rem);
			margin-right: 1rem;
		}
		.nivanaviDropDown {
			width: calc(50% - 0.5rem);
		}
	}
`;

export const StyledHeaderInformation = styled.div`
	margin-top: 3rem;
	display: grid;
	grid-template-columns: 0.9fr 0.9fr 0.9fr 0.9fr 1.4fr;
	grid-row-gap: 2rem;
	grid-column-gap: 1.5rem;
	@media screen and (max-width: 767px) {
		margin-top: 1.5rem;
		grid-template-columns: 1fr;
	}
`;
export const StyledInformationItem = styled.div`
	display: flex;
	flex-direction: column;
	> p:first-child {
		opacity: 0.5;
		margin-bottom: 1rem;
	}
`;

export const StyledTotalInformation = styled.div``;

export const StyledImportantNumber = styled.div`
	color: ${blue};
	font-weight: bold;
`;
export const StyledTotalInformationItem = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.8rem;

	> p {
		white-space: nowrap;
	}

	> p:first-child {
		opacity: 0.5;
	}
`;

export const StyledAgreementNumber = styled.div`
	display: flex;
	flex-direction: column;
	.linkButton {
		margin-top: 1rem;
	}
`;

export const StyledContractWrapper = styled.div`
	background: ${white};
	margin-top: 4rem;
	box-shadow: ${DEFAULT_SHADOW};
	padding-bottom: 2rem;

	@media screen and (max-width: 767px) {
		margin-top: 2rem;
	}
`;

export const StyledContractRegistrationForm = styled.div`
	padding: 0 4rem 4rem 4rem;

	> h3 {
		margin: 6rem 0 3rem 0;
	}

	@media screen and (max-width: 767px) {
		padding: 0 1.5rem 3rem 1.5rem;
		> h3 {
			margin: 3rem 0 2rem 0;
		}
	}
	.toggleHeader {
		padding: 0;
	}
`;

export const StyledContractDocuments = styled.div`
	padding: 0 4rem 4rem 4rem;

	.toggleHeader {
		padding: 0;
	}
`;

export const StyledRisksInformation = styled.div`

  padding: 2rem 0;

  p {
    white-space: nowrap;
  }

  margin-bottom: 4rem;
  border-bottom: 1px solid ${grey};

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 1.5rem;
    grid-column-gap: 2.5rem;
  }
}
`;

export const StyledButtonsGroupAutoSize = styled.div`
	display: flex;
	button {
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
	@media screen and (max-width: 767px) {
		button {
			width: calc(50% - 0.5rem);
			margin-right: 1rem;
		}

		button:last-child {
			margin: 0;
		}
	}
`;

export const StyledInvoiceCurrency = styled.div`
  margin-top: 3rem;
  > p {
    opacity: 0.5;
  }
`;
export const StyledInvoiceDescription = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  > p:first-child {
    opacity: 0.5;
  }
`;

export const StyledInvoiceEventsButtons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  .linkButton {
    margin-right: 2rem;
  }
`;
export const StyledInvoiceDropDown = styled.div`
  .nivanaviDropDown {
    margin-top: 2rem;
  }
`;

export const StyledPayPeriodModalButtons = styled.div`
  margin-top: 2rem;
`;

export const StyledAgrInfoLine = styled.div`
  margin-bottom: 1.5rem;
  p {
    display: inline-block;
    font-size: 1.4rem;
    margin-right: 0.5rem;
  }
  p:first-child {
    opacity: 0.7;
  }
`;