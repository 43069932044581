import React from 'react';
import { useDeleteRoleContext } from './context';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { StyledButtonsGroup, StyledDeleteRoleModalBody } from './styles';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../../../libs/uiKit/button';

const DeleteRoleUi: React.FC = () => {
	const { onClose, isOpen, deleteHandler, isLoading, header } = useDeleteRoleContext();

	return (
		<Modal onClose={onClose} isOpen={isOpen} header={header}>
			<StyledDeleteRoleModalBody>
				<Heading3>Вы действительно хотите удалить роль?</Heading3>
				<StyledButtonsGroup>
					<Button appearance='filled' onClick={deleteHandler} isLoading={isLoading}>
						Да
					</Button>
					<Button onClick={onClose} appearance='transparent' isLoading={isLoading}>
						Нет
					</Button>
				</StyledButtonsGroup>
			</StyledDeleteRoleModalBody>
		</Modal>
	);
};

export default React.memo(DeleteRoleUi);
