import styled from 'styled-components';

export const StyledButtonsGroup = styled.div`
	width: 100%;
	display: flex;
	button {
		width: calc(50% - 0.5rem);
		margin-right: 1rem;
	}

	button:last-child {
		margin: 0;
	}
`;
