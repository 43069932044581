import React from 'react';
import RiskTitleUi from './ui';
import RiskTitleContextProvider, { RiskTitleContextProps } from './ui/context';

const RiskTitleIgniter: React.FC<RiskTitleContextProps> = props => (
	<RiskTitleContextProvider {...props}>
		<RiskTitleUi />
	</RiskTitleContextProvider>
);

export default React.memo(RiskTitleIgniter);
