import React from 'react';
import { usePropertyContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const PropertyUi: React.FC = () => {
	const { form } = usePropertyContext();

	return <FieldsBuilder {...form} />;
};

export default React.memo(PropertyUi);
