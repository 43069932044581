import React from 'react';
import ProfileUi from './ui';
import ProfileContextProvider from './ui/context';

const ProfilePage: React.FC = () => (
	<ProfileContextProvider>
		<ProfileUi />
	</ProfileContextProvider>
);

export default React.memo(ProfilePage);
