import React from 'react';
import { usePrecalculationContext } from './context';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';

const HouseInProgress: React.FC = () => {
	const { borrowersItems, borrowerForm } = usePrecalculationContext();

	return (
		<>
			{borrowersItems.map(({ id, form }, index) => (
				<React.Fragment key={id}>
					<FieldsBuilder {...form} />
					{index === 0 && <FieldsBuilder {...borrowerForm} />}
				</React.Fragment>
			))}
		</>
	);
};

export default React.memo(HouseInProgress);
