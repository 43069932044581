import styled  from "styled-components";
import {white} from "../../uiKit/globalStyles";

export const StyledFlyButtons = styled.div<{isWhiteBackground: boolean}>`
  @media screen and (max-width: 767px) {
    position: fixed;
    transition: left 0.3s;
    z-index: 5;
    ${({isWhiteBackground}) => 
      isWhiteBackground ?
        `
        padding: 1rem 1.5rem;
        width: calc(100% - 3rem);
        background: ${white};
        bottom: 0;
        `
        :
        `
        bottom: 2rem;
        width: calc(100% - 6rem);
        padding: 0 3rem;
        `
    }
  }
`;