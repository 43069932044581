import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GROUPS, USERS } from '../../../../../../../libs/api/queries';
import { GroupsQuery, GroupsQueryVariables, UsersQuery, UsersQueryVariables } from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

export type GroupItem = {
	id: number | null | undefined;
	name?: string | undefined;
	leaderId?: number | undefined;
	curatorId?: number | undefined;
	parentId?: number | undefined;
	email?: string | undefined;
	specialType?: 'withoutUsers'|string;
};

export type UserItem = {
	id: number | undefined;
	fullName?: string;
	username?: string;
	email?: string;
	phone?: string;
	isSuper?: boolean;
	groupId?: number;
	roleId?: number;
	showKv?: boolean;
	isEnabled: boolean;
	token: string;
	type: string;
};

type GroupTabContext = {
	isLoading: boolean;
	users: UserItem[];
	groups: GroupItem[];
	filterUsers: UserItem[];
	filterGroups: GroupItem[];
	searchHandler: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	isHaveFilters: boolean;
};

const GroupTabContext = createContext<GroupTabContext>({} as GroupTabContext);

export const useGroupTabContext = (): GroupTabContext => useContext(GroupTabContext);

export const GroupTabContextProvider: React.FC = ({ children }) => {
	const {
		routes: { error500 },
		user: { isSuper },
	} = useGlobalContext();
	const [stateSearch, setSearch] = React.useState<string>('');
	const { data: dataUsers, loading: loadingUsers } = useQuery<UsersQuery, UsersQueryVariables>(USERS, { onError: error => error500(error) });
	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });

	const users: UserItem[] = (dataUsers?.users || []).map(user => ({
		id: Number(user?.id) || undefined,
		fullName: user?.full_name || undefined,
		username: user?.username || undefined,
		email: user?.email || undefined,
		phone: user?.phone || undefined,
		isSuper: user?.isSuper || undefined,
		groupId: user?.group_id || undefined,
		roleId: Number(user?.role?.id) || undefined,
		isEnabled: !!user?.is_enabled,
		showKv: !!user?.show_kv,
		token: user?.token || "",
		type: user?.type || "",
	}));

	const groups: GroupItem[] = React.useMemo(
		() => [
			...(dataGroups?.groups || []).map(group => ({
				id: Number(group?.id),
				name: group?.name || undefined,
				email: group?.email || undefined,
				leaderId: Number(group?.leader?.id) || undefined,
				curatorId: Number(group?.curator?.id) || undefined,
				parentId: group?.parent_id || undefined,
			})),
			...isSuper ? [{
				id: -12,
				name: 'Пользователи без группы',
				email: undefined,
				leaderId: undefined,
				curatorId: undefined,
				parentId: undefined,
				specialType: 'withoutUsers',
			}] : [],
		],
		[dataGroups]
	);

	const mainGroups: GroupItem[] = groups.filter(({ parentId }) => !parentId);

	const filterGroups: GroupItem[] = stateSearch ? groups.filter(({ name }) => (name || '').toLowerCase().includes(stateSearch.toLowerCase())) : mainGroups;

	const filterUsers: UserItem[] = users.filter(({ fullName }) => (fullName || '').toLowerCase().includes(stateSearch.toLowerCase()));

	const value: GroupTabContext = React.useMemo(
		() => ({
			users,
			groups,
			filterUsers,
			filterGroups,
			isLoading: loadingUsers || loadingGroups,
			searchHandler: ev => setSearch(ev.target.value),
			isHaveFilters: !!stateSearch,
		}),
		[users, groups, filterUsers, filterGroups, loadingUsers, loadingGroups, stateSearch]
	);

	return <GroupTabContext.Provider value={value}>{children}</GroupTabContext.Provider>;
};
