import React, { ChangeEvent, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { StyledSelectArrowIcon, StyledSelectEvents, StyledSelectWrapper } from './styles';
import { ISelectItem, ISelectProps } from './types';
import { SelectArrowIcon } from '../icons/SelectArrow';
import Input from '../input';
import ClickAway from '../clickAway';
import Button from '../button';
import { CloseIcon } from '../icons/Close';
import DropDownSelectContent from './dropDownSelectContent';

// пропс
//   items: список элементов для отображения
//   value?: для того что бы контролировать селект передаем по харду значение
//   defaultValue?: значение по умрлчанию
//   onChange?: при изменении значения селекта
//   onChangeInput?: при изменении значения инпута в селекте
//   emptyMessage?: сообщение в селекте когда нет элементов
//   height?: высота списка элементов
//   isSearchAvailable?: дает возможность искать элементы путем ввода в инпут
//   isClearable?: дает возможность очищать выбранное значение

const Select: React.FC<ISelectProps> = React.forwardRef((props, ref) => {
	const { items, value, emptyMessage, defaultValue, height, dropDownHelpMessage, isClearable, isSearchAvailable, onChange, onChangeInput, isLoading, isDisabled, ...rest } = props;
	const [stateSelectIsOpen, setSelectIsOpen] = React.useState<boolean>(false);
	const [stateInputValue, setInputValue] = React.useState<string>('');
	const [stateValue, setValue] = React.useState<ISelectItem | null>(defaultValue || null);

	const isTouchedRef = React.useRef<boolean>(false);

	const isShowClear: boolean = !!stateValue?.value && !!isClearable;

	React.useEffect(() => {
		if (value === undefined) return;
		setValue(value);
		if (isSearchAvailable) setInputValue(value?.label || '');
	}, [value, isSearchAvailable]);

	const clearSelectHandler = useCallback(() => {
		if (onChange) onChange(null);
		if (onChangeInput) onChangeInput('');
		if (value !== undefined) return;
		setValue(null);
		if (isSearchAvailable) setInputValue('');
	}, [isSearchAvailable, value, onChange, onChangeInput]);

	const onChangeHandler = useCallback(
		(selectedValue: ISelectItem | null) => {
			if (onChange) onChange(selectedValue);
			if (onChangeInput) onChangeInput(selectedValue?.label || '');
			if (value !== undefined || stateValue?.value === selectedValue?.value) return;
			setValue(selectedValue);
			if (isSearchAvailable) setInputValue(selectedValue?.label || '');
		},
		[onChange, value, stateValue?.value, isSearchAvailable, onChangeInput]
	);

	const backInputValue = useCallback((): void => {
		isTouchedRef.current = false;
		if (isSearchAvailable && stateInputValue !== stateValue?.label) setInputValue(stateValue?.label || '');
	}, [isSearchAvailable, stateInputValue, stateValue?.label]);

	const toggleSelectHandler = useCallback(
		(isOpen?: boolean): void => {
			if (typeof isOpen === 'boolean') {
				if (!isOpen) backInputValue();
				return setSelectIsOpen(isOpen);
			}
			if (stateSelectIsOpen) backInputValue();
			return setSelectIsOpen(!stateSelectIsOpen);
		},
		[stateSelectIsOpen, backInputValue]
	);

	const inputChangeHandler = useCallback(
		({ target: { value: currentInputValue } }: ChangeEvent<HTMLInputElement>) => {
			isTouchedRef.current = true;
			if (!stateSelectIsOpen) setSelectIsOpen(true);
			if (!currentInputValue) onChangeHandler(null);
			if (onChangeInput) onChangeInput(currentInputValue);
			setInputValue(currentInputValue);
		},
		[onChangeHandler, onChangeInput, stateSelectIsOpen]
	);

	const selectItemHandler = useCallback(
		(item: ISelectItem) => {
			onChangeHandler(item);
			toggleSelectHandler(false);
		},
		[toggleSelectHandler, onChangeHandler]
	);

	const filterItems: ISelectItem[] =
		isSearchAvailable && isTouchedRef.current ? items.filter(({ label }) => String(label).toLowerCase().includes(stateInputValue.toLowerCase())) : items;

	return (
		<StyledSelectWrapper className='selectWrapper'>
			<ClickAway isOpen={stateSelectIsOpen} onClickAway={(): void => toggleSelectHandler(false)}>
				<Input
					{...rest}
					readOnly={!isSearchAvailable}
					onClick={(): void => toggleSelectHandler()}
					isDisabled={!!isDisabled}
					onChange={inputChangeHandler}
					value={isSearchAvailable ? stateInputValue : stateValue?.label || ''}
					iconAfter={
						<StyledSelectEvents>
							{isShowClear && <Button isDisabled={!!isDisabled} appearance='icon' onClick={(): void => clearSelectHandler()} icon={<CloseIcon />} />}
							<StyledSelectArrowIcon onClick={(): void => toggleSelectHandler(isDisabled ? !isDisabled : undefined)}>
								<SelectArrowIcon direction={stateSelectIsOpen ? 'top' : 'down'} />
							</StyledSelectArrowIcon>
						</StyledSelectEvents>
					}
				/>
				<CSSTransition
					in={stateSelectIsOpen}
					timeout={300}
					mountOnEnter
					unmountOnExit
					classNames={{
						enterActive: 'listShow',
						enterDone: 'listShow',
						exitActive: 'listHide',
						exitDone: 'listHide',
					}}
				>
					<DropDownSelectContent
						isLoading={isLoading}
						height={height}
						filterItems={filterItems}
						emptyMessage={emptyMessage}
						stateValue={stateValue}
						selectItemHandler={selectItemHandler}
						dropDownHelpMessage={dropDownHelpMessage}
					/>
				</CSSTransition>
			</ClickAway>
		</StyledSelectWrapper>
	);
});

export default React.memo(Select);
