import React from 'react';
import AnketaFillingUi from './ui';
import AnketaFillingContextProvider, { AnketaFillingContextProps } from './ui/context';

const AnketaFillingIgniter: React.FC<AnketaFillingContextProps> = props => (
	<AnketaFillingContextProvider {...props}>
		<AnketaFillingUi />
	</AnketaFillingContextProvider>
);

export default React.memo(AnketaFillingIgniter);
