import styled from 'styled-components';

export const StyledSubjectHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3rem;
	@media screen and (max-width: 767px) {
		margin-bottom: 2rem;
	}
`;
