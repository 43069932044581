import React from 'react';
import {useFormContext} from "react-hook-form";
import {CreateAgreementForm, useCreateAgreementContext} from "../../ui/context";
import useNotification from "../../../../widgets/notifier/ui/hooks/useNitification";
import {ContragentField, ContragentId} from "../../widgets/contragent/ui/context";
import {FillingType} from "../../widgets/anketaFilling/ui/context";
import {green, grey, Heading4, Text4} from "../../../../../libs/uiKit/globalStyledComponents";
import {StyledBorrowerFooter, StyledBorrowerItem, StyledBorrowersWrapper} from "./ui/styles";
import {OkIcon} from "../../../../../icons/ui/Ok";
import Status from '../../../../../libs/uiKit/status';
import {AttentionIcon} from "../../../../../icons/ui/Attention";
import AnketaFillingIgniter from "../../widgets/anketaFilling";

type Status = {
	color: string;
	label: string;
};

export type PeopleType = {
	type: 'contragent' | 'coBorrower';
	isFilled: boolean;
	status: Status;
} & ContragentField;

const getStatus = (fillingType?: FillingType): Status => {
	switch (fillingType) {
		case 'send':
			return {
				label: 'Отправлено на заполнение в лк',
				color: green,
			};
		case 'upload':
			return {
				label: 'Загружены документы',
				color: green,
			};
		case 'online':
			return {
				label: 'Заполнено онлайн',
				color: green,
			};
		case 'declaration':
			return {
				label: 'Заполнение через декларацию',
				color: green,
			};
		default:
			return {
				label: 'Не заполнено',
				color: grey,
			};
	}
};

const InsurancePage: React.FC = () => {
	const { agreementId, switchHiddenButtonsHandler, refreshFormDataHandler, isDeclarationAvailable } = useCreateAgreementContext();
	const { setNotification } = useNotification();
	const formHook = useFormContext<CreateAgreementForm>();

	const [stateFillingPerson, setFillingPerson] = React.useState<PeopleType | null>(null);

	const [contragents, coBorrowers] = formHook.watch(['contragents', 'coBorrowers']);

	const peoplesContragents: PeopleType[] = (contragents || []).map(contragent => ({
		...contragent,
		type: 'contragent',
		isFilled: !!contragent.anketaIsn && !!contragent.fillingType,
		status: getStatus(contragent.fillingType),
	}));
	const peoplesCoBorrowers: PeopleType[] = (coBorrowers || []).map(coBorrower => ({
		...coBorrower,
		type: 'coBorrower',
		isFilled: !!coBorrower.anketaIsn && !!coBorrower.fillingType,
		status: getStatus(coBorrower.fillingType),
	}));
	const peoples: PeopleType[] = React.useMemo(() => [...peoplesContragents, ...peoplesCoBorrowers], [peoplesCoBorrowers, peoplesContragents]);

	const setActivePersonHandler = React.useCallback(
		(id?: ContragentId) => {
			if (!id) return setNotification({ type: 'error', text: 'no user id' });
			const findContragent = peoples.find(({ isn }) => isn === id);
			if (!findContragent) return setNotification({ type: 'error', text: 'no findContragent' });
			setFillingPerson(findContragent);
			switchHiddenButtonsHandler(true);
		},
		[peoples, setNotification, switchHiddenButtonsHandler]
	);

	const cancelFillingHandler = React.useCallback(() => {
		setFillingPerson(null);
		switchHiddenButtonsHandler(false);
	}, [switchHiddenButtonsHandler]);

	const successFillingCallback = React.useCallback(() => {
		cancelFillingHandler();
		refreshFormDataHandler();
	}, [cancelFillingHandler, refreshFormDataHandler]);

	return (
		<>
			{!stateFillingPerson && (
				<StyledBorrowersWrapper>
					{(peoples || []).map(({ isn, fullName, type, isFilled, status }) => (
						<StyledBorrowerItem key={`person-${isn}`} onClick={() => setActivePersonHandler(isn)}>
							<Heading4>{fullName?.value}</Heading4>
							<Text4>{type === 'contragent' ? 'Заемщик' : 'Созаемщик'}</Text4>
							<StyledBorrowerFooter>
								<Status hideIcon={true} status={status.label} color={status.color} />
								{isFilled ? <OkIcon /> : <AttentionIcon />}
							</StyledBorrowerFooter>
						</StyledBorrowerItem>
					))}
				</StyledBorrowersWrapper>
			)}
			{!!stateFillingPerson && (
				<AnketaFillingIgniter
					successFillingCallback={successFillingCallback}
					cancelFillingHandler={cancelFillingHandler}
					agreementId={agreementId}
					person={stateFillingPerson}
					isFull={stateFillingPerson.type === 'coBorrower'}
					isDeclarationAvailable={isDeclarationAvailable}
				/>
			)}
		</>
	);
};

export default InsurancePage;
