import React from "react";
import {useNavigate, useParams} from "react-router-dom";

import {IModalProps} from "../../../../libs/uiKit/modal/types";
import {Modal} from "../../../../libs/uiKit/modal";
import {DownloadIcon} from "../../../../icons/ui/Download";
import Button from "../../../../libs/uiKit/button";
import {PrintTemplateEnum, PrintTypeEnum, useCopyAnketaForAgreementMutation} from "../../../../libs/api/graphqlTypes";
import {usePrintMutation} from "../../../../libs/api/hooks/usePrintMutation";
import {StyledButtonsGroup} from "../../invoice/widgets/sendPayLinkModal/styles";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";

const AgreementAnketaModal = (props: IModalProps) => {

  const { id } = useParams<{ id?: string }>();

  const {doPrint, isPrintLoading} = usePrintMutation();

  const [copyAnketaForAgreement, {loading: isCopyAnketaLoading}] = useCopyAnketaForAgreementMutation({
    refetchQueries: ['loadContract'], awaitRefetchQueries: true
  });

  const {setNotification} = useNotification();

  const navigate = useNavigate();

  const {
    routes: { contractAnketaById },
  } = useGlobalContext();

  const downloadAnketa = () => {
    doPrint(
      props.data?.anketaIsn,
      PrintTypeEnum.Document,
      PrintTemplateEnum.Anketa
    );
  }

  const continueWithNoChanges = () => {
    copyAnketaForAgreement({
      variables: {
        id: parseInt(id || "", 10),
        anketaIsn: props.data?.anketaIsn,
        forEdit: false
      }
    }).then(() => {
      props.onClose();
      props.data?.setDeclModalIsOpen(true);
    }).catch(error => setNotification({type: "error", text: error}));
  }

  const continueWithChanges = () => {
    copyAnketaForAgreement({
      variables: {
        id: parseInt(id || "", 10),
        anketaIsn: props.data?.anketaIsn,
        forEdit: true
      }
    }).then(() => {
      props.onClose();
      navigate(contractAnketaById(id || ""));
    }).catch(error => setNotification({type: "error", text: error}));
  }

  return (
    <Modal {...props} header="Анкета найдена" isLoading={false}>
      <Button
        appearance="filled"
        iconBefore={<DownloadIcon/>}
        isLoading={isPrintLoading}
        onClick={downloadAnketa}>
        Скачать анкету
      </Button>
      <br/><br/><br/>
      <StyledButtonsGroup>
        <Button appearance="filled" isLoading={isCopyAnketaLoading} onClick={continueWithNoChanges}>Нет изменений по анкете</Button>
        <Button appearance="filled" isLoading={isCopyAnketaLoading} onClick={continueWithChanges} >Есть изменения по анкете</Button>
      </StyledButtonsGroup>
    </Modal>
  )
}

export default AgreementAnketaModal;