import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';

type RecoveryPasswordContext = {
	form: IForm;
	recoveryHandler: () => void;
	isLoadingRecovery: boolean;
};

const RecoveryPasswordContext = createContext<RecoveryPasswordContext>({} as RecoveryPasswordContext);

export const useRecoveryPasswordContext = (): RecoveryPasswordContext => useContext(RecoveryPasswordContext);

export const RecoveryPasswordContextProvider: React.FC = ({ children }) => {
	const formHook = useForm({ mode: 'onBlur' });

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'inputMask',
						fieldName: 'phone',
						mask: '+7 (999) 999-99-99',
						errorMessage: 'Введите телефон',
						isRequired: true,
						placeholder: 'Введите телефон',
						validationType: 'phone',
					},
				},
			],
		}),
		[formHook]
	);

	const recoveryHandler = formHook.handleSubmit(data => {
		console.log(data);
	});

	const value: RecoveryPasswordContext = React.useMemo(
		() => ({
			form,
			recoveryHandler,
			isLoadingRecovery: false,
		}),
		[form, recoveryHandler]
	);

	return <RecoveryPasswordContext.Provider value={value}>{children}</RecoveryPasswordContext.Provider>;
};
