import React from "react";
import {useMutation} from "@apollo/client";
import {useForm} from "react-hook-form";
import {IModalProps} from "../../../../../libs/uiKit/modal/types";
import {Modal} from "../../../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../../../libs/uiKit/fieldsBuilder";
import Button from "../../../../../libs/uiKit/button";
import {StyledButtonsGroup} from "../sendPayLinkModal/styles";
import {IForm} from "../../../../../libs/uiKit/fieldsBuilder/types";
import {PrintMutation, PrintMutationVariables, PrintTypeEnum} from "../../../../../libs/api/graphqlTypes";
import {PRINT_MUTATION} from "../../../../../libs/api/commands";
import useNotification from "../../../../widgets/notifier/ui/hooks/useNitification";

export const SendDocumentModal = (props: IModalProps) => {

    const formHook = useForm({mode: "onBlur"});
    const {handleSubmit} = formHook;

    const { data } = props;

    const {invoice, email, templateIsn, subject} = data;

    const { setNotification } = useNotification();

    const [downloadDoc, { loading: loadingDownloadDoc }] = useMutation<PrintMutation, PrintMutationVariables>(PRINT_MUTATION, {
        fetchPolicy: 'network-only',
    });

    const form: IForm = {
        formHook,
        fields: [
            {
                field: {
                    fieldType: "input",
                    fieldName: "email",
                    isRequired: true,
                    defaultValue: email,
                    readOnly: false,
                    validationType: "email",
                    errorMessage: "Введите Email",
                    placeholder: "Email",
                }
            }
        ]
    };

    const onFormSubmit = handleSubmit((formData) => {
        downloadDoc({
            variables: {
                isn: invoice.invoiceIsn,
                type: PrintTypeEnum.Document,
                templateIsn: +templateIsn,
                email: formData.email,
                subject
            },
        }).then(({ data }) => {
            if (!data?.print) return setNotification({ type: 'error', text: 'no doc' });
            setNotification({type: 'success', text: 'Документ отправлен'});
            props.onClose();

        }).catch(error => setNotification({ type: 'error', text: error }))
    })

    return (
        <Modal {...props} header="Отправить документ на почту">
            <form onSubmit={onFormSubmit}>
                <FieldsBuilder {...form}/>
                <StyledButtonsGroup>
                    <Button isLoading={loadingDownloadDoc} appearance="filled" type="submit">Отправить</Button>
                    <Button isLoading={loadingDownloadDoc} appearance="transparent" onClick={props.onClose}>Отмена</Button>
                </StyledButtonsGroup>
            </form>
        </Modal>
    )
}