import React from "react";
import {useForm} from "react-hook-form";
import {Modal} from "../../../../../libs/uiKit/modal";
import {Text4} from "../../../../../libs/uiKit/globalStyledComponents";
import Button from "../../../../../libs/uiKit/button";
import {IModalProps} from "../../../../../libs/uiKit/modal/types";
import DeclarationFilling from "../anketaFilling/widgets/declarationFilling";
import {AgreementId} from "../../ui/context";
import {ContragentField, ContragentId} from "../contragent/ui/context";
import {StyledTextModalWrapper} from "../../../../../libs/uiKit/modal/styles";

type IDeclarationModalProps = {
  agreementId: AgreementId;
  personId: number|null|undefined;
  phone: string|null|undefined;
  agreementMode: boolean;
  showDeclaration: boolean;
  onlyBySms?: boolean;
} & IModalProps;

const DeclarationModal: React.FC<IDeclarationModalProps> = props => {

  const { isOpen, onClose, isLoading, agreementId, personId, phone, agreementMode, showDeclaration, onlyBySms } = props;

  const showAnketa = !agreementMode;

  return (
    <StyledTextModalWrapper>
      <Modal isOpen={isOpen} onClose={() => onClose()} header={showDeclaration && showAnketa ? "Вариант заполнения" : showDeclaration ? "Декларация" : "Анкета"}>
        <DeclarationFilling
          agreementId={agreementId}
          personId={personId||null}
          successFillingCallback={() => { onClose() }}
          defaultValue={{ sendSmsPhone: phone || '' }}
          showDeclaration={showDeclaration}
          showAnketa={showAnketa}
          agreementMode={agreementMode}
          onlyBySms={onlyBySms}
        />
      </Modal>
    </StyledTextModalWrapper>
  );
};

export default DeclarationModal;