import React, { createContext, useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TitleContextProps, TitleField } from '../../../../widgets/title/ui/context';
import { DEFAULT_TITLE_DATA } from '../../../../ds/repositories/commands';

type TitleContext = {
	titles: TitleContextProps[];
	deleteTitleHandler: (number: number) => void;
	addTitleHandler: () => void;
};

export type TitleForm = {
	titles: TitleField[];
};

const TitleContext = createContext<TitleContext>({} as TitleContext);

export const useTitleContext = (): TitleContext => useContext(TitleContext);

const TitleContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const formHook = useFormContext<TitleForm>();
	const { fields, append, remove } = useFieldArray({
		control: formHook.control,
		name: 'titles',
	});

	const deleteTitleHandler = React.useCallback((number: number) => remove(number), [remove]);
	const addTitleHandler = React.useCallback(() => append(DEFAULT_TITLE_DATA), [append]);

	const value: TitleContext = React.useMemo(
		() => ({
			titles: fields.map((field, index) => ({
				isFull: index === 0,
				arrayName: 'titles',
				defaultValues: field,
				number: index,
			})),
			deleteTitleHandler,
			addTitleHandler,
		}),
		[addTitleHandler, deleteTitleHandler, fields]
	);

	return <TitleContext.Provider value={value}>{children}</TitleContext.Provider>;
};

export default React.memo(TitleContextProvider);
