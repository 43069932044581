import styled from 'styled-components';

export const StyledContragent = styled.div`
	h3 {
		margin-bottom: 2.5rem;
	}
`;
export const StyledAnketaInfo = styled.div`
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  & b {
    font-weight: bold;
    font-size: 1.6rem;
  }
  & div {
    color: gray;
  }
  & button {
    float: right;
  }
`;
