import dayjs from 'dayjs';

export type IFilterState = {
	text?: string;
	interval: string;
	dateStart: string | null;
	dateEnd: string | null;
	banks: string[];
	statuses: string[];
	groups: string[];
	fillingTypes: string[];
};
export type PaginationState = {
	activePage: number;
};

const URL_FILTERS_NAME = 'activeFilters';
const URL_PAGINATION_NAME = 'pagination';

const defaultStart: string | null = null;
const defaultEnd: string | null = null;

export const DEFAULT_FILTER_VALUE: IFilterState = {
	interval: 'all',
	dateStart: defaultStart,
	dateEnd: defaultEnd,
	banks: [],
	statuses: [],
	groups: [],
	fillingTypes: [],
	text: '',
};

export const DEFAULT_PAGINATION_VALUE: PaginationState = {
	activePage: 1,
};

export const SearchUrlService = {
	toUrl: (activeFilters: IFilterState, paginationState: PaginationState): string =>
		`?${URL_FILTERS_NAME}=${JSON.stringify(activeFilters)}&${URL_PAGINATION_NAME}=${JSON.stringify(paginationState)}`,
	fromUrl: (url: string): [activeFilters: IFilterState | undefined, paginationState: PaginationState | undefined] => {
		if (!url) return [undefined, undefined];
		const searchParams = new URLSearchParams(url);
		const activeFiltersQuery = searchParams.get(URL_FILTERS_NAME);
		const paginationQuery = searchParams.get(URL_PAGINATION_NAME);
		return [activeFiltersQuery ? JSON.parse(activeFiltersQuery) : undefined, paginationQuery ? JSON.parse(paginationQuery) : undefined];
	},
};

export const isFilterCheck = (filter: IFilterState): boolean => {
	const { dateStart, dateEnd, statuses, banks, text } = filter || {};
	if (text) return true;
	if (statuses.length !== 0) return true;
	if (banks.length !== 0) return true;
	return dateStart !== defaultStart || dateEnd !== defaultEnd;
};

export const getShortName = (name: string): string => {
	if (!name) return 'unknown';
	return name.split(' ').reduce((prev, next, index) => {
		if (index === 0) return `${next}`;
		if(index === 1){
			return `${prev} ${next[0]}.`;
		}
		return `${prev}${next[0]}.`;
	}, '');
};
