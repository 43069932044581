import React from 'react';
import RiskLifeUi from './ui';
import RiskLifeContextProvider, { RiskLifeContextProps } from './ui/context';

const RiskLifeIgniter: React.FC<RiskLifeContextProps> = props => (
	<RiskLifeContextProvider {...props}>
		<RiskLifeUi />
	</RiskLifeContextProvider>
);

export default React.memo(RiskLifeIgniter);
