import styled from "styled-components";
import { TFormMessageTypes } from "./types";

export const StyledFormMessage = styled.div<{ type: TFormMessageTypes }>`
  position: absolute;
  ${({ type, theme }) => {
    switch (type) {
      case "error":
        return `color: ${theme.formMessage.error.color}`;     
      case "helper":
        return `color: ${theme.formMessage.help.color}`;
      case "success":
        return `color: ${theme.formMessage.success.color}`;
      default:
        return `color: ${theme.formMessage.default.color}`;
    }
  }}
`;
