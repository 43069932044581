import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import {rightsHandler, useUserRights} from "../../../../../common/hooks/useUserRights";

type RiskLifeContext = {
	form: IForm;
};

export type RiskLifeContextProps = {
	children?: React.ReactNode;
	number: number;
	defaultValues?: RiskLifeField;
	arrayName: string;
	isSigned: boolean;
	isDisabled: boolean;
	maxDiscount: number;
};

export type RiskLifeField = {
	type?: string;
	share?: number;
	name?: string;
	summ?: number;
	heightWeight?: number;
	pressure?: number;
	discount?: number;
	allowance?: number;
	tariff?: number;
	premium?: number;
};

const RiskLifeContext = createContext<RiskLifeContext>({} as RiskLifeContext);

export const useRiskLifeContext = (): RiskLifeContext => useContext(RiskLifeContext);

const RiskLifeContextProvider: React.FC<RiskLifeContextProps> = props => {
	const { children, defaultValues, number, arrayName, isSigned, isDisabled, maxDiscount } = props;
	const formHook = useFormContext();
	const { type, premium, summ, tariff, share, heightWeight, allowance, pressure, discount, name } = defaultValues || {};

	const { rightsHandler } = useUserRights();

	const showTotalTariff = rightsHandler('lifeTotalTariff');

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].type`,
						placeholder: 'Тип',
						isDisabled: true,
						defaultValue: type,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'range',
						fieldName: `${arrayName}[${number}].share`,
						placeholder: `Доля в общем доходе, %`,
						min: 0,
						max: 100,
						step: 1,
						isDisabled: true,
						defaultValue: share,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].name`,
						isDisabled: true,
						placeholder: 'ФИО',
						defaultValue: name,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].summ`,
						isDisabled: true,
						digitsAfterDot: 2,
						placeholder: 'Страховая сумма',
						defaultValue: summ,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].heightWeight`,
						placeholder: 'Повышающий коэф (рост/вес) %',
						errorMessage: 'Введите повышающий коэф',
						digitsAfterDot: 2,
						defaultValue: heightWeight,
						isDisabled: isSigned || isDisabled,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].pressure`,
						placeholder: 'Повышающий коэф давление %',
						errorMessage: 'Введите повышающий коэф',
						digitsAfterDot: 2,
						defaultValue: pressure,
						isDisabled: isSigned || isDisabled,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].discount`,
						placeholder: 'Скидка',
						errorMessage: 'Введите скидку',
						digitsAfterDot: 2,
						validationType: 'minMax',
						maxValue: (isSigned || isDisabled || !maxDiscount) ? 100 : maxDiscount,
						defaultValue: Math.abs(discount || 0),
						isDisabled: isSigned || isDisabled || !maxDiscount,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].allowance`,
						placeholder: 'Повышающий коэф %',
						errorMessage: 'Введите повышающий коэф',
						digitsAfterDot: 2,
						defaultValue: allowance,
						isDisabled: isSigned || isDisabled,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].tariff`,
						digitsAfterDot: 4,
						isDisabled: !showTotalTariff || isSigned || isDisabled,
						placeholder: showTotalTariff ? 'Итоговый тариф' : 'Тариф',
						defaultValue: tariff,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].premium`,
						digitsAfterDot: 2,
						isDisabled: true,
						placeholder: 'Премия',
						defaultValue: premium,
						grid: 6,
					},
				},
			],
		}),
		[allowance, arrayName, discount, formHook, heightWeight, name, number, premium, pressure, share, summ, tariff, type]
	);

	const value: RiskLifeContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <RiskLifeContext.Provider value={value}>{children}</RiskLifeContext.Provider>;
};
export default React.memo(RiskLifeContextProvider);
