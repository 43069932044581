import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import {UseFormReturn} from "react-hook-form/dist/types";
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useGetDicti from '../../../../../common/hooks/useGetDicti';
import { DictKeyEnum } from '../../../../../../../libs/api/graphqlTypes';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { IDaDataValue } from '../../../../../../../libs/uiKit/daData/types';
import { RADIO_BOOLEAN } from '../../../../../../../libs/utils/staticData';

type JobContext = {
	form: IForm;
};

export type JobContextProps = {
	isDisabled?: boolean;
	children?: React.ReactNode;
};

export type JobForm = {
	jobPlace?: ISelectItem | null;
	organizationName?: IDaDataValue | null;
	organizationAddress?: IDaDataValue | null;
	isOfficeJob?: string;
	isSoldier?: string;
	position?: string;
	field?: ISelectItem | null;
	profession?: ISelectItem | null;
	riskGroup?: ISelectItem | null;
};

const JobContext = createContext<JobContext>({} as JobContext);

export const useJobContext = (): JobContext => useContext(JobContext);

const changeOrganizationNameHandler = (ev: any, formHook: UseFormReturn) => {
	if(ev.data && ev.data.address){
		formHook.setValue('organizationAddress', ev.data.address);
	}
}

const JobContextProvider: React.FC<JobContextProps> = props => {
	const { isDisabled, children } = props;
	const formHook = useFormContext<JobForm>();
	const { data: selectActivity, loading: loadingActivity } = useGetDicti(DictKeyEnum.Industries);
	const { data: selectJobPlace, loading: loadingJobPlace } = useGetDicti(DictKeyEnum.JobPlace);
	const { data: selectRiskGroup } = useGetDicti(DictKeyEnum.RiskGroup);
	const { data: selectProfession } = useGetDicti(DictKeyEnum.Profession);

	const [jobPlace, riskGroup, profession] = formHook.watch(['jobPlace', 'riskGroup', 'profession']);
	const isHiddenJobFields: boolean = jobPlace?.value === '3';

	const form: IForm = React.useMemo(
		() => ({
			isDisabled,
			formHook,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: 'jobPlace',
						placeholder: 'Настоящее место работы',
						isLoading: loadingJobPlace,
						items: selectJobPlace,
						isRequired: true,
						errorMessage: 'Введите место работы',
						grid: 4,
					},
				},
				{
					isHidden: isHiddenJobFields,
					field: {
						fieldType: 'daData',
						daDataType: 'party',
						fieldName: 'organizationName',
						placeholder: 'Наименование организации',
						isRequired: true,
						onChange: (ev) => {
							changeOrganizationNameHandler(ev, formHook);
						},
						errorMessage: 'Введите наименование организации',
						grid: 8
					},
				},
				{
					isHidden: isHiddenJobFields,
					field: {
						fieldType: 'daData',
						daDataType: 'address',
						fieldName: 'organizationAddress',
						placeholder: 'Юридический адрес',
					},
				},
				{
					isHidden: isHiddenJobFields,
					label: 'Связана ли ваша трудовая деятельность с работой в офисе (офисный сотрудник)?',
					field: {
						fieldType: 'radio',
						isRequired: true,
						fieldName: 'isOfficeJob',
						items: RADIO_BOOLEAN,
					},
				},
				{
					isHidden: isHiddenJobFields,
					field: {
						fieldType: 'input',
						fieldName: 'position',
						placeholder: 'Должность',
						isRequired: true,
						errorMessage: 'Введите дложность',
						grid: 4,
					},
				},
				{
					isHidden: isHiddenJobFields,
					field: {
						fieldType: 'select',
						fieldName: 'field',
						isLoading: loadingActivity,
						placeholder: 'Укажите сферу деятельности вашей организации',
						items: selectActivity,
						isRequired: true,
						errorMessage: 'Укажите сферу деятельности',
						isSearchAvailable: true,
						isClearable: true,
						grid: 8,
					},
				},
				{
					isHidden: isHiddenJobFields,
					label: 'Является военнослужащим(ей)?',
					field: {
						fieldType: 'radio',
						isRequired: true,
						fieldName: 'isSoldier',
						items: RADIO_BOOLEAN,
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'riskGroup',
						placeholder: 'Группа риска заемщика',
						errorMessage: 'Выберите группу риска',
						items: selectRiskGroup,
						isClearable: true,
						isDisabled: !!profession,
						grid: 4,
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: 'profession',
						placeholder: 'Профессия',
						errorMessage: 'Выберите профессию',
						items: selectProfession,
						isSearchAvailable: true,
						isClearable: true,
						isDisabled: !!riskGroup,
						grid: 8,
					},
				},
			],
		}),
		[formHook, isDisabled, isHiddenJobFields, loadingActivity, loadingJobPlace, selectActivity, selectJobPlace]
	);

	const value: JobContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default React.memo(JobContextProvider);
