import React from 'react';
import { useReportsContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { StyledSettingsWrapper, StyledStepFormWrapper, StyledStepWrapper } from './styles';
import { Heading2 } from '../../../../libs/uiKit/globalStyledComponents';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../libs/uiKit/button';

const ReportsUi: React.FC = () => {
	const { form, onFormSubmit, isLoading } = useReportsContext();

	return (
		<MainLayoutIgniter>
			<StyledSettingsWrapper>
				<StyledStepWrapper>
					<Heading2>Отчеты</Heading2>
					<form onSubmit={onFormSubmit}>
						<StyledStepFormWrapper>
							<FieldsBuilder {...form} />
						</StyledStepFormWrapper>
						<Button isLoading={isLoading} type='submit' appearance='filled'>
							Скачать отчет
						</Button>
					</form>
				</StyledStepWrapper>
			</StyledSettingsWrapper>
		</MainLayoutIgniter>
	);
};

export default React.memo(ReportsUi);
