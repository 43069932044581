import React, { createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { JSEncrypt } from 'jsencrypt';
import { useNavigate } from 'react-router-dom';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import useNotification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { AUTH } from '../../../../../../../libs/api/commands';
import { SignInMutation, SignInMutationVariables } from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY || 'unknown';
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

type FormType = {
	username: string;
	password: string;
};

type LoginClientContext = {
	form: IForm;
	loginHandler: () => void;
	isLoadingLogin: boolean;
};

const LoginClientContext = createContext<LoginClientContext>({} as LoginClientContext);

export const useLoginClientContext = (): LoginClientContext => useContext(LoginClientContext);

export const LoginClientContextProvider: React.FC = ({ children }) => {
	const {
		routes: { policies },
	} = useGlobalContext();
	const navigate = useNavigate();
	const { setNotification } = useNotification();
	const formHook = useForm<FormType>({ mode: 'onBlur' });

	const [login, { loading: loadingLogin }] = useMutation<SignInMutation, SignInMutationVariables>(AUTH, { refetchQueries: ['me'] });

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'inputMask',
						fieldName: 'username',
						mask: '+7 (999) 999-99-99',
						placeholder: 'Телефон',
						isRequired: true,
						errorMessage: 'Введите телефон',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: 'password',
						type: 'password',
						placeholder: 'Пароль',
						isRequired: true,
						errorMessage: 'Введите пароль',
						validationType: 'password',
					},
				},
			],
		}),
		[formHook]
	);

	const loginHandler = formHook.handleSubmit(data => {
		if (!data.username || !data.password) return setNotification({ type: 'error', text: 'no login or password' });
		const encryptedPassword: string | boolean = encrypt.encrypt(data.password);
		if (typeof encryptedPassword === 'boolean') return setNotification({ type: 'error', text: 'cant encrypt password' });

		login({
			variables: { username: data.username, password: encryptedPassword, isClient: true },
		})
			.then(dataSignIn => {
				if (!dataSignIn.data?.signIn?.id) return setNotification({ type: 'error', text: 'no user id' });
				navigate(policies());
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const value: LoginClientContext = React.useMemo(
		() => ({
			form,
			loginHandler,
			isLoadingLogin: loadingLogin,
		}),
		[form, loginHandler, loadingLogin]
	);

	return <LoginClientContext.Provider value={value}>{children}</LoginClientContext.Provider>;
};
