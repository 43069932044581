import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
	width: 100%;
	margin-top: 1rem;
`;

export const StyledSaveAgreementWrapper = styled.div`
	h2 {
		margin-bottom: 2rem;
	}
`;

export const StyledSaveAgreementText = styled.div`
	> p {
		margin-bottom: 3rem;
	}
`;
