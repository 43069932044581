import React from 'react';
import { StyledCheckbox, StyledPseudoCheckbox, StyledCheckboxWrapper } from './styles';
import { CheckboxIcon } from '../icons/Checkbox';
import { ICheckboxProps } from './types';
import { TextButton } from '../globalStyles';
import { TypeInputRef } from '../input/types';

const Checkbox: React.FC<ICheckboxProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { label, isInvalid, isRequired, isDisabled, ...rest } = props;

	return (
		<StyledCheckboxWrapper isInvalid={!!isInvalid} className='checkbox'>
			<StyledCheckbox {...rest} disabled={!!isDisabled} type='checkbox' required={isRequired} ref={ref} />
			<StyledPseudoCheckbox>
				<CheckboxIcon />
			</StyledPseudoCheckbox>
			{!!label && <TextButton>{label}</TextButton>}
		</StyledCheckboxWrapper>
	);
});

export default React.memo(Checkbox);
