import { ApolloQueryResult, FetchResult, MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions } from '@apollo/client';
import { Observable } from '@apollo/react-hooks';
import { client } from './Apollo';
import {AgreementId} from "../../modules/pages/createAgreement/ui/context";
import {LoadAgreementQuery, LoadAgreementQueryVariables} from "./graphqlTypes";

export type GQLApi = ReturnType<typeof GqlApi>;

export const GqlApi = () => ({
	mutate: async <T, TVariables = OperationVariables>(options: MutationOptions<T, TVariables>): Promise<FetchResult<T>> => client.mutate<T, TVariables>(options),
	subscribe: <T, TVariables = OperationVariables>(options: SubscriptionOptions<TVariables, T>): Observable<FetchResult<T>> => client.subscribe<T, TVariables>(options),
	query: async <T, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> => client.query<T, TVariables>(options),
});
