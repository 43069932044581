import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateAgreementUi from './ui';
import CreateAgreementContextProvider from './ui/context';
import ContragentsPage from './pages/contragents';
import JobPage from './pages/job';
import BankPage from './pages/bank';
import PropertyPage from './pages/property';
import TitlePage from './pages/title';
import InsurancePage from './pages/insurance';
import UploadFilesPage from './pages/uploadFiles';

const CreateAgreementPage: React.FC = () => (
	<CreateAgreementContextProvider>
		<CreateAgreementUi>
			<Routes>
				<Route path='/contragentStep' element={<ContragentsPage />} />
				<Route path='/jobStep' element={<JobPage />} />
				<Route path='/bankStep' element={<BankPage />} />
				<Route path='/propertyStep' element={<PropertyPage />} />
				<Route path='/titleStep' element={<TitlePage />} />
				<Route path='/insuranceStep' element={<InsurancePage />} />
				<Route path='/additionalFilesStep' element={<UploadFilesPage />} />
			</Routes>
		</CreateAgreementUi>
	</CreateAgreementContextProvider>
);

export default React.memo(CreateAgreementPage);
