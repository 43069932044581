import styled, { css } from 'styled-components';
import { ANIMATION_SEC } from '../globalStyles';
import { TypeButtonStatus } from './types';

const defaultIconsStyles = css`
	.iconBefore {
		display: block;
		margin-right: 1.2rem;
	}

	.iconAfter {
		display: block;
		margin-left: 1.2rem;
	}
`;

const defaultButtonsStyles = css<{ isLoading: boolean }>`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	> span {
		opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
	}

	:hover > .buttonTooltip {
		opacity: 1 !important;
	}
`;

export const StyledFilledButton = styled.button<{ isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	${defaultIconsStyles};
	justify-content: center;
	padding: 0 3.5rem;
	width: 100%;
	height: 5rem;
	color: ${({ theme, status }) => theme.btn[status].filled.color};
	transition: all ${ANIMATION_SEC}s;
	background: ${({ theme, status }) => theme.btn[status].filled.bg};
	border: 1px solid ${({ theme, status }) => theme.btn[status].filled.border};
	font: inherit;

	:hover,
	:focus {
		background: ${({ theme, status }) => theme.btn[status].filled.hover.bg};
		border: 1px solid ${({ theme, status }) => theme.btn[status].filled.hover.border};
	}

	:active {
		background: ${({ theme, status }) => theme.btn[status].filled.active.bg};
		border: 1px solid ${({ theme, status }) => theme.btn[status].filled.active.border};
	}

	:disabled {
		background: ${({ theme, status }) => theme.btn[status].filled.disabled.bg};
		border: 1px solid ${({ theme, status }) => theme.btn[status].filled.disabled.border};
		color: ${({ theme, status }) => theme.btn[status].filled.disabled.color};
		cursor: no-drop;
	}
`;

export const StyledTransparentButton = styled.button<{ isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	${defaultIconsStyles};
	justify-content: center;
	padding: 0 3.5rem;
	width: 100%;
	height: 5rem;
	box-sizing: border-box;
	color: ${({ theme, status }) => theme.btn[status].transparent.color};
	transition: all ${ANIMATION_SEC}s;
	background: ${({ theme, status }) => theme.btn[status].transparent.bg};
	border: 1px solid ${({ theme, status }) => theme.btn[status].transparent.border};
	font-weight: 500;

	:hover,
	:focus {
		background: ${({ theme, status }) => theme.btn[status].transparent.hover.bg};
		color: ${({ theme, status }) => theme.btn[status].transparent.hover.color};
	}

	:active {
		background: ${({ theme, status }) => theme.btn[status].transparent.active.bg};
		border: 1px solid ${({ theme, status }) => theme.btn[status].transparent.active.border};
		color: ${({ theme, status }) => theme.btn[status].transparent.active.color};
	}

	:disabled {
		background: ${({ theme, status }) => theme.btn[status].transparent.disabled.bg};
		cursor: no-drop;
		color: ${({ theme, status }) => theme.btn[status].transparent.disabled.color};
		border: 1px solid ${({ theme, status }) => theme.btn[status].transparent.disabled.border};
	}
`;

export const StyledLinkButton = styled.button<{ isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	${defaultIconsStyles};
	border: unset;
	background: unset;
	transition: all ${ANIMATION_SEC}s;
	color: ${({ theme, status }) => theme.btn[status].link.color};
	font-weight: 500;

	> span {
		text-decoration: underline;
	}

	:hover {
		color: ${({ theme, status }) => theme.btn[status].link.hover.color};
	}

	:active {
		color: ${({ theme, status }) => theme.btn[status].link.active.color};
	}

	:disabled {
		cursor: no-drop;
		color: ${({ theme, status }) => theme.btn[status].link.disabled.color};
	}
`;

export const StyledTextButton = styled.button<{ isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	${defaultIconsStyles};
	border: unset;
	background: unset;
	transition: all ${ANIMATION_SEC}s;
	color: ${({ theme, status }) => theme.btn[status].text.color};
	font-weight: 500;

	:hover {
		color: ${({ theme, status }) => theme.btn[status].text.hover.color};
	}

	:active {
		color: ${({ theme, status }) => theme.btn[status].text.active.color};
	}

	:disabled {
		cursor: no-drop;
		color: ${({ theme, status }) => theme.btn[status].text.disabled.color};
	}
`;

export const StyledIconButton = styled.button<{ isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	border: unset;
	background: unset;
	transition: all ${ANIMATION_SEC}s;
	color: ${({ theme, status }) => theme.btn[status].icon.color};

	svg {
		transition: all ${ANIMATION_SEC}s;
	}

	:hover {
		color: ${({ theme, status }) => theme.btn[status].icon.hover.color};
	}

	:active {
		color: ${({ theme, status }) => theme.btn[status].icon.active.color};
	}

	:disabled {
		cursor: no-drop;
		color: ${({ theme, status }) => theme.btn[status].icon.disabled.color};
	}
`;
export const StyledFlagButton = styled.button<{ isSelected: boolean; isLoading: boolean; status: TypeButtonStatus }>`
	${defaultButtonsStyles};
	width: 4rem;
	height: 4rem;
	border: unset;
	justify-content: center;
	transition: all ${ANIMATION_SEC}s;
	color: ${({ theme, status }) => theme.btn[status].flag.color};
	background: ${({ theme, status }) => theme.btn[status].flag.bg};

	svg {
		transition: all ${ANIMATION_SEC}s;
	}

	${({ isSelected, status }) =>
		isSelected
			? css`
					background: ${({ theme }) => theme.btn[status].flag.selected.bg};
					color: ${({ theme }) => theme.btn[status].flag.selected.color};
			  `
			: css`
					:hover {
						background: ${({ theme }) => theme.btn[status].flag.hover.bg};
						color: ${({ theme }) => theme.btn[status].flag.hover.color};

						svg {
							transform: scale(1.1);
						}
					}

					:active {
						background: ${({ theme }) => theme.btn[status].flag.active.bg};
						color: ${({ theme }) => theme.btn[status].flag.active.color};

						svg {
							transform: scale(1.05);
						}
					}
			  `};

	:disabled {
		cursor: no-drop;
		color: ${({ theme, status }) => theme.btn[status].flag.disabled.color};
		background: ${({ theme, status }) => theme.btn[status].flag.disabled.bg};

		svg {
			transform: none;
		}
	}
`;

export const StyledButtonLoading = styled.span`
  opacity: 1 !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;

  > svg {
    height: 50%;
    animation: rotate 1s infinite linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
`;
