import React from 'react';
import { useLoginDefaultContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';

const LoginDefaultUi: React.FC = () => {
	const { form, loginHandler, isLoadingLogin } = useLoginDefaultContext();

	return (
		<form onSubmit={loginHandler}>
			<FieldsBuilder {...form} />
			<Button appearance='filled' type='submit' isLoading={isLoadingLogin}>
				Войти
			</Button>
		</form>
	);
};

export default React.memo(LoginDefaultUi);
