import React, { useCallback, useEffect, useState } from 'react';
import { StyledDropDownTrigger, StyledMenuItem, StyledMenuList, StyledMenuWrapper } from './styles';
import { IDropdownProps } from './types';
import { TextButton } from '../globalStyles';
import ClickAway from '../clickAway';

const DropdownMenu: React.FC<IDropdownProps> = props => {
	const { trigger, items = [], children, isOpen, onChange, defaultOpen, direction = 'down' } = props;
	const [stateIsOpen, setIsOpen] = useState<boolean>(defaultOpen || false);

	useEffect(() => {
		if (typeof isOpen !== 'boolean') return;
		setIsOpen(isOpen);
	}, [isOpen]);

	const onChangeHandler = useCallback(() => {
		setIsOpen(!stateIsOpen);
		if (!onChange) return;
		onChange(!stateIsOpen);
	}, [onChange, stateIsOpen]);

	return (
		<StyledMenuWrapper>
			<ClickAway onClickAway={onChangeHandler} isOpen={stateIsOpen}>
				<StyledDropDownTrigger onClick={(e: React.MouseEvent) => {
						e.stopPropagation();
						onChangeHandler();
					}}>{trigger}</StyledDropDownTrigger>
				<StyledMenuList isOpen={stateIsOpen} offset={10} direction={direction}>
					{!!items.length &&
						items.map(({ label, onClick, type }) => (
							<StyledMenuItem key={label} onClick={(event: React.MouseEvent) => { event.stopPropagation(); setIsOpen(false); if(onClick) onClick() }} type={type || 'button'}>
								<TextButton>{label}</TextButton>
							</StyledMenuItem>
						))}
					{!items.length && children}
				</StyledMenuList>
			</ClickAway>
		</StyledMenuWrapper>
	);
};

export default React.memo(DropdownMenu);
