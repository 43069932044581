import React, {createContext, useContext, useState} from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { ContragentContextProps, ContragentField } from '../../../../widgets/contragent/ui/context';
import { DEFAULT_CONTRAGENT_DATA, formatPersonDataToDataForm } from '../../../../ds/repositories/commands';
import { ContragentFragment } from '../../../../../../../libs/api/graphqlTypes';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';
import {useCreateAgreementContext} from "../../../../ui/context";

type ContragentsContext = {
	isInsurerForm: IForm;
	contragents: ContragentContextProps[];
	anketas?: any;
};

export type ContragentsForm = {
	contragents: ContragentField[];
	isInsurer: boolean;
};

const ContragentsContext = createContext<ContragentsContext>({} as ContragentsContext);

export const useContragentsContext = (): ContragentsContext => useContext(ContragentsContext);

const ContragentsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		user: {
			restrictions: { contragentMaxAge },
		},
	} = useGlobalContext();
	const formHook = useFormContext<ContragentsForm>();
	const { fields, append, remove, update } = useFieldArray({
		control: formHook.control,
		name: 'contragents',
	});

	const { expressMode } = useCreateAgreementContext();


	const [anketas, setAnketas] = useState<any>(null);
	const [isInsurer] = formHook.watch(['isInsurer']);

	const searchSuccessCallback = React.useCallback(
		(contragent: ContragentFragment, number: number) => {
			const dataForForm = formatPersonDataToDataForm(contragent);
			console.log({ dataForForm });
			update(number, dataForForm);
			formHook.trigger();
			if(contragent?.anketas){
				setAnketas(contragent?.anketas);
			}
		},
		[update]
	);

	React.useEffect(() => {
		if (isInsurer && fields.length === 2) remove(1);
		if (!isInsurer && fields.length === 1) append(DEFAULT_CONTRAGENT_DATA);
	}, [fields.length, isInsurer, remove, append]);

	const isInsurerForm: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					field: {
						fieldType: 'checkbox',
						fieldName: 'isInsurer',
						label: 'Страхователь является заемщиком',
					},
				},
			],
		}),
		[formHook]
	);

	const value: ContragentsContext = React.useMemo(
		() => ({
			isInsurerForm,
			contragents: fields.map((field, index) => ({
				isShowShare: false,
				isShowSearchByIsn: true,
				arrayName: 'contragents',
				defaultValues: field,
				number: index,
				searchSuccessCallback,
				maxAge: contragentMaxAge,
				contragentDisabled: false,
				expressMode,
			})),
			anketas
		}),
		[contragentMaxAge, fields, isInsurerForm, searchSuccessCallback]
	);

	return <ContragentsContext.Provider value={value}>{children}</ContragentsContext.Provider>;
};

export default React.memo(ContragentsContextProvider);
