import React from 'react';
import { useTitleContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';

const TitleUi: React.FC = () => {
	const { form } = useTitleContext();

	return <FieldsBuilder {...form} />;
};

export default React.memo(TitleUi);
