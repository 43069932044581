import React from 'react';
import { useCreateUserContext } from './context';
import { Modal } from '../../../../../../libs/uiKit/modal';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../../../libs/uiKit/button';
import { StyledButtonsGroup } from './styles';
import ChangeLogModal from "../../changeLogModal";
import {TimeReloadIcon} from "../../../../../../icons/ui/TimeReload";

const CreateUserUi: React.FC = () => {
	const { isOpen, onClose, onFormSubmit, isDisabledEdit, isLoadingUpdateUser, header, isEdit, form, id, fullName } = useCreateUserContext();

	const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);

	return (
		<>
			<Modal width={60} isOpen={isOpen} onClose={onClose} header={header}
						 afterHeaderContent={isEdit ? <Button tooltip="История изменений" onClick={() => setIsOpenHistory(true)} appearance="icon"
																									icon={<TimeReloadIcon/>} style={{display: "inline"}} /> : null}
			>
				<form onSubmit={onFormSubmit}>
					<FieldsBuilder {...form} />
					<StyledButtonsGroup>
						<Button isDisabled={isDisabledEdit} type='submit' isLoading={isLoadingUpdateUser} appearance='filled'>
							{isEdit ? 'Сохранить' : 'Создать'}
						</Button>
						<Button onClick={onClose} isLoading={isLoadingUpdateUser} appearance='transparent'>
							Отмена
						</Button>
					</StyledButtonsGroup>
				</form>
			</Modal>
			<ChangeLogModal
				onClose={() => setIsOpenHistory(false)}
				isOpen={stateIsOpenHistory}
				data={{id, model: 'USER'}}
				header={`Изменения пользователя ${fullName}`}
			/>
		</>
	);
};

export default React.memo(CreateUserUi);
