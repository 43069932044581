import React from 'react';
import { useDiscussionContext } from './context';
import Accordion from '../../../../libs/uiKit/accordion';
import {
	StyledAddNewComment,
	StyledButtonsGroup,
	StyledCommentBody,
	StyledCommentHeader,
	StyledCommentItem,
	StyledComments,
	StyledCommentToggleWrapper,
	StyledProfileLogo,
	StyledToggleNewItems,
	StyledToggleNewItemsWrapper,
} from './styles';
import Button from '../../../../libs/uiKit/button';
import { AddIcon } from '../../../../icons/ui/Add';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import { ProfileIcon } from '../../../../icons/ui/Profile';
import { Heading3, Heading5, Text4 } from '../../../../libs/uiKit/globalStyledComponents';

const DiscussionUi: React.FC = () => {
	const { onFormSubmit, form, isLoading, isLoadingAccordion, isShowAddMessage, switchAddMessage, messages, newItemsCount, isOpenAccordion, switchIsOpenAccordion } =
		useDiscussionContext();

	return (
		<StyledCommentToggleWrapper>
			<Accordion
				header={
					<StyledToggleNewItemsWrapper>
						<Heading3>Обсуждение</Heading3>
						{!!newItemsCount && (
							<StyledToggleNewItems>
								<Text4>
									{newItemsCount} {newItemsCount === 1 ? 'новое сообщение' : 'новых сообщений'}
								</Text4>
							</StyledToggleNewItems>
						)}
					</StyledToggleNewItemsWrapper>
				}
				isOpen={isOpenAccordion}
				onChange={switchIsOpenAccordion}
				isLoading={isLoadingAccordion}
			>
				<StyledComments>
					{!isShowAddMessage && (
						<Button onClick={() => switchAddMessage(true)} appearance='link' iconBefore={<AddIcon />}>
							Добавить комментарий
						</Button>
					)}
					{isShowAddMessage && (
						<StyledAddNewComment>
							<form onSubmit={onFormSubmit}>
								<FieldsBuilder {...form} />
								<StyledButtonsGroup>
									<Button type='submit' appearance='filled' isLoading={isLoading}>
										Добавить
									</Button>
									<Button onClick={() => switchAddMessage(false)} appearance='transparent'>
										Отменить
									</Button>
								</StyledButtonsGroup>
							</form>
						</StyledAddNewComment>
					)}
					{messages.map(({ id, user, date, text, isNew }) => (
						<StyledCommentItem key={`comment-item-${id}`} isNew={isNew}>
							<StyledProfileLogo>
								<ProfileIcon />
							</StyledProfileLogo>
							<StyledCommentBody>
								<StyledCommentHeader>
									<Heading5>{user}</Heading5>
									<Heading5>{date}</Heading5>
								</StyledCommentHeader>
								<Text4>{text}</Text4>
							</StyledCommentBody>
						</StyledCommentItem>
					))}
				</StyledComments>
			</Accordion>
		</StyledCommentToggleWrapper>
	);
};

export default React.memo(DiscussionUi);
