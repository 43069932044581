import styled from 'styled-components';
import { ANIMATION_SEC, Z_INDEX_LITTLE_BIT } from '../globalStyles';

export const StyledDatePickerWrapper = styled.div`
	position: relative;
	.calendarShow {
		pointer-events: unset;
		opacity: 1;
		position: absolute;
		z-index: 1;
		border: none;
		box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
	}

	.react-calendar__navigation__arrow {
		order: 2;
		border-radius: 30px;
	}

	.react-calendar__navigation__label {
		order: 1;
	}

	.calendarHide {
		position: absolute;
		pointer-events: none;
		opacity: 0;
	}

	@media screen and (max-width: 767px) {
		.react-calendar {
			width: 100%;
		}
	}
`;

export const StyledDatePicker = styled.div`
	z-index: ${Z_INDEX_LITTLE_BIT};
	transition: opacity ${ANIMATION_SEC}s;
	opacity: 0;
`;
