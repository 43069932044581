import React from 'react';
import AgreementsLogUi from './ui';
import AgreementsLogContextProvider, { AgreementLogType } from './ui/context';

const AgreementsLogPage: React.FC<{ type: AgreementLogType }> = ({ type }) => (
	<AgreementsLogContextProvider type={type}>
		<AgreementsLogUi />
	</AgreementsLogContextProvider>
);

export default React.memo(AgreementsLogPage);
