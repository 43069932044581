import styled from 'styled-components';
import { black10, white } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledPrecalculationFormWrapper = styled.div`
	.textButton {
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 767px) {
		.textButton {
			margin-bottom: 0.5rem;
		}
	}
`;
export const StyledPrecalculationFormCardWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-column-gap: 1.5rem;
		grid-row-gap: 1.5rem;
	}
`;

export const StyledPrecalculationFormCard = styled.div`
	h2 {
		margin-bottom: 4rem;
	}

	.filledButton {
		margin-top: 4rem;
	}

	@media screen and (max-width: 767px) {
		h2 {
			margin-bottom: 2rem;
		}

		.filledButton {
			margin-top: 1.5rem;
		}
	}
`;

export const StyledPrecalculationForm = styled.div`
	background: ${white};
	padding: 4rem;
	width: calc(100% - 8rem);

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
		width: calc(100% - 3rem);
	}
`;

export const StyledRisksFormWrapper = styled.div`
	h4 {
		margin: 2rem 0 2rem 0;
	}

	@media screen and (max-width: 767px) {
		h4 {
			margin: 4rem 0 2rem 0;
		}
	}
`;

export const StyledResultButtonsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	margin-bottom: 4rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-column-gap: 1.5rem;
		grid-row-gap: 0.5rem;
		margin-bottom: 2rem;
	}
`;
export const StyledBankCardsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-column-gap: 1.5rem;
		grid-row-gap: 1.5rem;
	}
`;
export const StyledBankCard = styled.div`
	padding: 2rem;
	background: ${white};

	@media screen and (max-width: 767px) {
		padding: 2rem 1.5rem;
	}
`;
export const StyledBankCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledBankLogo = styled.div`
	width: 4rem;
	height: 4rem;
	position: relative;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		text-indent: -9999px;
	}
`;
export const StyledBankPremium = styled.div`
	margin: 2rem 0;
	padding: 2rem 0;
	border-top: 1px solid ${black10};
	border-bottom: 1px solid ${black10};
	h3 {
		margin-bottom: 1rem;
	}
	p {
		opacity: 0.5;
	}
`;
export const StyledBankTable = styled.div`
	margin-bottom: 3rem;
`;

export const StyledBankTableColumns = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1rem;
	align-items: center;
	.precalculationRow {
		margin-top: 1.5rem;
		opacity: 0.5;
		white-space: nowrap;
	}
`;

export const StyledBorrowerNeedOsm = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1.5rem;
	> p {
		opacity: 0.5;
	}
	> .iconButton {
		margin-left: 0.5rem;
	}
	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

export const StyledText4Oms = styled.span`
  color: white;
  background-color: #41B6E6;
  font-size: 1.5rem;
  padding: 0.4rem 0.7rem;
  border-radius: 10px;
`;

export const StyledText4OmsWrapper = styled.div`
  margin-top: -1rem;
  margin-bottom: 1.5rem;
`;