import React, {createContext, useContext, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { IModalProps } from '../../../../../libs/uiKit/modal/types';
import { IForm, IFormField } from '../../../../../libs/uiKit/fieldsBuilder/types';
import { SaveAgreementMutation, SaveAgreementMutationVariables } from '../../../../../libs/api/graphqlTypes';
import { SAVE_AGREEMENT_DRAFT } from '../../../../../libs/api/commands';
import useNotification from '../../../notifier/ui/hooks/useNitification';
import { DEFAULT_INSURANCE_TYPE, DEFAULT_RISKS, PROPERTY_TYPE, RISKS_SELECT } from '../../../../../libs/utils/staticData';

type SkipPrecalculationContext = {
	form: IForm;
	isLoading: boolean;
	isDisabled: boolean;
	onFormSubmit: () => void;
} & IModalProps;

export type SkipPrecalculationProps = { skipCallback: (id: string) => void; children?: React.ReactNode } & IModalProps;

const SkipPrecalculationContext = createContext<SkipPrecalculationContext>({} as SkipPrecalculationContext);

type SkipPrecalculatiomForm = {
	propertyType: string;
	life?: boolean;
	property?: boolean;
	title?: boolean;
};

export const useSkipPrecalculationContext = (): SkipPrecalculationContext => useContext(SkipPrecalculationContext);

export const SkipPrecalculationContextProvider: React.FC<SkipPrecalculationProps> = props => {
	const { skipCallback, children, ...modalProps } = props;
	const { setNotification } = useNotification();
	const formHook = useForm<SkipPrecalculatiomForm>({
		mode: 'onBlur',
		defaultValues: {
			propertyType: PROPERTY_TYPE[0].value,
		},
	});

	const [saveAgreement, { loading: loadingSaveAgreement }] = useMutation<SaveAgreementMutation, SaveAgreementMutationVariables>(SAVE_AGREEMENT_DRAFT);

	const onFormSubmit = formHook.handleSubmit(formData => {
		const risks =
			formData.propertyType === '1'
				? {
						riskLife: formData.life,
						riskTitle: formData.title,
						riskProperty: formData.property,
				  }
				: DEFAULT_RISKS;
		saveAgreement({
			variables: {
				calcForm: {
					...risks,
					isHouseFinished: formData.propertyType === '1',
					insuranceType: DEFAULT_INSURANCE_TYPE,
				},
			},
		})
			.then(({ data }) => {
				if (!data?.saveAgreement) return setNotification({ type: 'error', text: 'empty agreement id' });
				skipCallback(data.saveAgreement);
			})
			.catch(error => setNotification({ type: 'error', text: error }));
	});

	const changeRisksHandler = React.useCallback(
		(checked: boolean, risk: string | number): void => {
			if (!checked && risk === 'property') formHook.setValue('title', false);
		},
		[formHook]
	);

	const [riskLife, riskProperty, riskTitle, propertyType] = formHook.watch(['life', 'property', 'title', 'propertyType']);
	const isDisabledPrecalculation: boolean = !(riskLife || riskProperty || riskTitle) && propertyType === '1';

	useEffect(() => {
		if(propertyType === undefined){
			formHook.setValue('propertyType', PROPERTY_TYPE[0].value);
		}
	}, [propertyType])

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			fields: [
				{
					label: 'Тип жилья',
					field: {
						fieldType: 'radio',
						fieldName: 'propertyType',
						items: PROPERTY_TYPE,
					},
				},
				...RISKS_SELECT.map<IFormField>(({ label, value }) => ({
					isHidden: propertyType === '0',
					field: {
						fieldType: 'checkbox',
						fieldName: String(value),
						label,
						grid: 4,
						isDisabled: value === 'title' && !riskProperty,
						onChange: ({ target: { checked } }) => changeRisksHandler(checked, value),
					},
				})),
			],
		}),
		[changeRisksHandler, formHook, propertyType, riskProperty]
	);

	const value: SkipPrecalculationContext = React.useMemo(
		() => ({
			form,
			onFormSubmit,
			isLoading: loadingSaveAgreement,
			isDisabled: isDisabledPrecalculation,
			...modalProps,
		}),
		[form, isDisabledPrecalculation, loadingSaveAgreement, modalProps, onFormSubmit]
	);

	return <SkipPrecalculationContext.Provider value={value}>{children}</SkipPrecalculationContext.Provider>;
};
