import React from 'react';

export const PhoneIcon: React.FC = () => (
	<svg width='1.4rem' height='1.4rem' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0 4.19132C0.001181 3.6153 0.11869 3.00818 0.383234 2.42886C0.641577 1.86275 1.01005 1.36737 1.50371 0.958126C1.79955 0.712912 2.10424 0.476781 2.40983 0.241475C2.56011 0.125886 2.73224 0.0444229 2.9274 0.0124983C3.14795 -0.0235545 3.35285 0.0202042 3.54565 0.120381C3.8096 0.257987 4.01126 0.459442 4.19727 0.676584C4.44734 0.967759 4.64929 1.28948 4.86512 1.6024C5.02278 1.83082 5.17572 2.06173 5.30061 2.30749C5.42787 2.55793 5.52914 2.81608 5.56073 3.09432C5.60413 3.47907 5.49754 3.82363 5.21499 4.10765C5.05437 4.26893 4.86719 4.40681 4.69476 4.5579C4.52499 4.70651 4.33131 4.82513 4.14383 4.95228C3.92121 5.10309 3.74967 5.29189 3.67733 5.54673C3.61651 5.76112 3.63216 5.97524 3.68501 6.18825C3.75705 6.47695 3.88873 6.74308 4.04315 7.00013C4.31832 7.45753 4.65608 7.87365 5.01511 8.27546C5.35464 8.65553 5.70953 9.02238 6.09542 9.36227C6.44264 9.66803 6.80757 9.95343 7.23243 10.1637C7.4642 10.2787 7.70631 10.3643 7.97173 10.3803C8.28175 10.3995 8.54718 10.3027 8.77777 10.1147C8.82884 10.0734 8.88022 10.0316 8.92391 9.9837C9.12085 9.76683 9.35793 9.59042 9.58498 9.40383C9.71548 9.29677 9.84686 9.19081 9.99862 9.10935C10.3674 8.9123 10.7533 8.88863 11.1539 9.00642C11.4834 9.10357 11.779 9.2632 12.0471 9.46135C12.3712 9.70106 12.6845 9.95343 13.0007 10.2028C13.2248 10.3792 13.4371 10.568 13.6222 10.7813C13.7557 10.9348 13.8708 11.0989 13.9414 11.2866C14.0604 11.6036 13.996 11.8923 13.7684 12.1452C13.6624 12.263 13.5357 12.3651 13.4141 12.4697C13.2213 12.6359 13.0261 12.7997 12.8292 12.9615C12.4651 13.2609 12.055 13.4921 11.6077 13.6669C11.1956 13.8281 10.7666 13.9291 10.3228 13.9734C9.90119 14.0158 9.48046 14.0067 9.06032 13.9514C8.28795 13.8496 7.56872 13.6033 6.88315 13.2639C6.24512 12.948 5.65846 12.5624 5.10309 12.1328C4.49665 11.6639 3.93715 11.15 3.40777 10.6068C2.93301 10.1194 2.48216 9.61409 2.06468 9.08293C1.57958 8.46618 1.14232 7.82163 0.786545 7.13113C0.50901 6.59171 0.286687 6.03413 0.147625 5.44876C0.0531449 5.0519 0.00147625 4.65064 0 4.19132Z'
			fill='currentColor'
		/>
	</svg>
);
