import React from 'react';
import { IElementFieldProps } from '../types';

const ElementField: React.FC<IElementFieldProps> = props => {
	const {
		field: { element },
	} = props;
	return <>{element}</>;
};

export default React.memo(ElementField);
