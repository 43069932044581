import React from 'react';
import { usePrecalculationContext } from './context';
import { Heading4 } from '../../../../libs/uiKit/globalStyledComponents';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import HouseInProgress from './HouseInProgress';

const Risks: React.FC<{ riskType: 'life' | 'title' | 'property' }> = ({ riskType }) => {
	const { propertyForm, titleForm } = usePrecalculationContext();

	switch (riskType) {
		case 'life':
			return (
				<>
					<Heading4>Жизнь</Heading4>
					<HouseInProgress />
				</>
			);
		case 'property':
			return (
				<>
					<Heading4>Имущество</Heading4>
					<FieldsBuilder {...propertyForm} />
				</>
			);
		case 'title':
			return (
				<>
					<Heading4>Титул</Heading4>
					<FieldsBuilder {...titleForm} />
				</>
			);
		default:
			return null;
	}
};

export default React.memo(Risks);
