import styled from 'styled-components';
import { grey40 } from '../../../../libs/uiKit/globalStyledComponents';

export const StyledNotifierWrapper = styled.div<{ animationTimeout: number }>`
	position: fixed;
	right: 5rem;
	bottom: 5rem;
	max-width: 70%;

	& > * {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.notify-enter {
		opacity: 0.1;
		transform: translateX(25rem);
		transition: ${props => `all ${props.animationTimeout}s ease-in-out`};
	}

	.notify-enter-active {
		transform: translateX(0);
		opacity: 1;
	}

	.notify-exit-active {
		transform: translateX(25rem);
		opacity: 0.1;
		transition: ${props => `all ${props.animationTimeout}s ease-in-out`};
	}
`;

export const StyledStatusNotification = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background: ${grey40};
	box-shadow: 0 2px 20px rgba(0, 61, 129, 0.1);
	> p {
		max-width: 95%;
		margin-left: 1rem;
	}
	> svg {
		width: 3rem;
		height: 3rem;
	}

	> .iconButton {
		margin-left: 1rem;
	}
`;
