import React from 'react';
import DashboardUi from './ui';
import DashboardContextProvider from './ui/context';

const DashboardPage: React.FC = () => (
	<DashboardContextProvider>
		<DashboardUi />
	</DashboardContextProvider>
);

export default React.memo(DashboardPage);
