import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { LoadAnketaQuery, LoadAnketaQueryVariables } from '../../../../../../../libs/api/graphqlTypes';
import { LOAD_ANKETA } from '../../../../../../../libs/api/queries';
import { useGlobalContext } from '../../../../../../../apps/main/ui/GlobalContext';

export type AnketaViewContextProps = {
	id: number | null;
	children?: React.ReactNode;
};

type Question = {
	question: string;
	answer: string;
};

type AnketaPart = {
	title: string;
	questions: Question[];
};

type AnketaViewContext = {
	parts: AnketaPart[];
	isLoading: boolean;
};

const AnketaViewContext = createContext<AnketaViewContext>({} as AnketaViewContext);

export const useAnketaViewContext = (): AnketaViewContext => useContext(AnketaViewContext);

export const AnketaViewContextProvider: React.FC<AnketaViewContextProps> = props => {
	const { children, id } = props;
	const {
		routes: { error500 },
	} = useGlobalContext();

	const { data: dataAnketa, loading: loadingAnketa } = useQuery<LoadAnketaQuery, LoadAnketaQueryVariables>(LOAD_ANKETA, {
		variables: { id },
		onError: error => error500(error),
	});

	const { anketa } = dataAnketa?.loadAgreement || {};

	const parts: AnketaPart[] = (anketa || []).reduce<AnketaPart[]>((prev, part) => {
		if (!part) return prev;
		const questions = (part.questions || []).reduce<Question[]>((prevQuestions, question) => {
			if (!question) return prevQuestions;
			return [...prevQuestions, { question: question.question, answer: question.answer }];
		}, []);

		return [
			...prev,
			{
				title: part.name,
				questions,
			},
		];
	}, []);

	const value: AnketaViewContext = React.useMemo(
		() => ({
			parts,
			isLoading: loadingAnketa,
		}),
		[loadingAnketa, parts]
	);

	return <AnketaViewContext.Provider value={value}>{children}</AnketaViewContext.Provider>;
};
