import styled from 'styled-components';

export const StyledAnketaFillingWrapper = styled.div`
	padding-bottom: 4rem;
	> .filledButton {
		float: right;
		width: calc(50% - 1rem);
		margin-left: 2rem;
	}

	> .transparentButton {
		float: left;
		width: calc(50% - 1rem);
	}
`;
