import { createGlobalStyle } from "styled-components";
import { ITheme } from "./types";

export const GlobalStyles = createGlobalStyle<{ theme: ITheme }>`
  body {
    background: ${({ theme }) => theme.bg.primary};
    color: ${({ theme }) => theme.text.primary};
    transition: all 0.50s linear;
    font-family: 'Montserrat', 'Arial', sans-serif;
    // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    overflow: auto;
  }
`;
