import React from 'react';
import HeaderUi from './ui';
import { HeaderContextProvider } from './ui/context';

const HeaderIgniter: React.FC = () => (
	<HeaderContextProvider>
		<HeaderUi />
	</HeaderContextProvider>
);

export default React.memo(HeaderIgniter);
