import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';

type RiskTitleContext = {
	form: IForm;
};

export type RiskTitleContextProps = {
	children?: React.ReactNode;
	number: number;
	defaultValues?: RiskTitleField;
	arrayName: string;
	isDisabled: boolean;
};

export type RiskTitleField = {
	permanentSum: boolean;
	oneTimePay: boolean;
	address?: string;
	dateEnd?: Date | null;
	summ?: number;
	tariff?: number;
	premium?: number;
};

const RiskTitleContext = createContext<RiskTitleContext>({} as RiskTitleContext);

export const useRiskTitleContext = (): RiskTitleContext => useContext(RiskTitleContext);

const RiskTitleContextProvider: React.FC<RiskTitleContextProps> = props => {
	const { children, defaultValues, number, arrayName, isDisabled } = props;
	const formHook = useFormContext();
	const { permanentSum, oneTimePay, dateEnd, premium, tariff, address, summ } = defaultValues || {};

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'checkbox',
						fieldName: `${arrayName}[${number}].constantPay`,
						label: 'Постоянная страховая сумма',
						defaultChecked: permanentSum,
						isDisabled,
						grid: 4,
					},
				},
				{
					field: {
						fieldType: 'checkbox',
						fieldName: `${arrayName}[${number}].oneTimePay`,
						label: 'Единовременная оплата',
						defaultChecked: oneTimePay,
						isDisabled,
						grid: 8,
					},
				},

				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].address`,
						isDisabled: true,
						placeholder: 'Адрес предмета титула',
						defaultValue: address,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: `${arrayName}[${number}].dateEnd`,
						placeholder: 'Дата окончания титула',
						defaultValue: dateEnd,
						isDisabled,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].summ`,
						isDisabled: true,
						placeholder: 'Страховая сумма',
						digitsAfterDot: 2,
						defaultValue: summ,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].tariff`,
						digitsAfterDot: 4,
						isDisabled: true,
						placeholder: 'Тариф',
						defaultValue: tariff,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].premium`,
						digitsAfterDot: 2,
						isDisabled: true,
						placeholder: 'Премия',
						defaultValue: premium,
						grid: 6,
					},
				},
			],
		}),
		[address, arrayName, permanentSum, dateEnd, formHook, number, oneTimePay, premium, summ, tariff]
	);

	const value: RiskTitleContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <RiskTitleContext.Provider value={value}>{children}</RiskTitleContext.Provider>;
};

export default React.memo(RiskTitleContextProvider);
