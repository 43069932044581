import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';

type RiskPropertyContext = {
	form: IForm;
};

export type RiskPropertyContextProps = {
	children?: React.ReactNode;
	number: number;
	defaultValues?: RiskPropertyField;
	arrayName: string;
	isSigned: boolean;
	isDisabled: boolean;
	maxDiscount: number;
};

export type RiskPropertyField = {
	type?: string;
	address?: string;
	summ?: number;
	discount?: number;
	basicTariff?: number;
	premium?: number;
	allowance?: number;
	permanentSum: boolean;
	oneTimePay: boolean;
};

const RiskPropertyContext = createContext<RiskPropertyContext>({} as RiskPropertyContext);

export const useRiskPropertyContext = (): RiskPropertyContext => useContext(RiskPropertyContext);

const RiskPropertyContextProvider: React.FC<RiskPropertyContextProps> = props => {
	const { children, defaultValues, number, arrayName, isSigned, isDisabled, maxDiscount } = props;
	const formHook = useFormContext();
	const { type, summ, oneTimePay, premium, permanentSum, address, basicTariff, discount, allowance } = defaultValues || {};

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'checkbox',
						fieldName: `${arrayName}[${number}].permanentSum`,
						label: 'Постоянная страховая сумма',
						defaultChecked: permanentSum,
						isDisabled: isSigned,
						grid: 4,
					},
				},
				{
					field: {
						fieldType: 'checkbox',
						fieldName: `${arrayName}[${number}].oneTimePay`,
						label: 'Единовременная оплата',
						defaultChecked: oneTimePay,
						isDisabled: isSigned,
						grid: 8,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].type`,
						isDisabled: true,
						placeholder: 'Предмет залога',
						defaultValue: type,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].address`,
						isDisabled: true,
						placeholder: 'Адрес приобретаемого / закладываемого объекта залога',
						defaultValue: address,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].summ`,
						isDisabled: true,
						placeholder: 'Страховая сумма',
						digitsAfterDot: 2,
						defaultValue: summ,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].discount`,
						placeholder: 'Скидка',
						errorMessage: 'Введите скидку',
						digitsAfterDot: 2,
						validationType: 'minMax',
						maxValue: (isSigned || isDisabled || !maxDiscount) ? 100 : maxDiscount,
						defaultValue: Math.abs(discount || 0),
						isDisabled: isSigned || isDisabled || !maxDiscount,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].basicTariff`,
						isRequired: true,
						digitsAfterDot: 4,
						placeholder: 'Базовый тариф',
						errorMessage: 'Введите тариф',
						defaultValue: basicTariff,
						isDisabled: isSigned || isDisabled,
						grid: 6,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].premium`,
						isDisabled: true,
						placeholder: 'Премия',
						digitsAfterDot: 2,
						defaultValue: premium,
						grid: 6,
					},
				},

				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].allowance`,
						placeholder: 'Повышающий коэф %',
						errorMessage: 'Введите повышающий коэф',
						digitsAfterDot: 2,
						defaultValue: allowance,
						isDisabled: isSigned || isDisabled,
						grid: 6,
					},
				},
			],
		}),
		[address, allowance, arrayName, basicTariff, permanentSum, discount, formHook, number, oneTimePay, premium, summ, type]
	);

	const value: RiskPropertyContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <RiskPropertyContext.Provider value={value}>{children}</RiskPropertyContext.Provider>;
};

export default React.memo(RiskPropertyContextProvider);
