import React from 'react';
import {useMutation}        from "@apollo/react-hooks";
import {useForm} from "react-hook-form";
import {StyledButtonsGroup} from "./styles";
import {IModalProps} from "../../../../../libs/uiKit/modal/types";
import {IRadioItem} from "../../../../../libs/uiKit/radio/types";
import useNotification from "../../../../widgets/notifier/ui/hooks/useNitification";
import {IForm} from "../../../../../libs/uiKit/fieldsBuilder/types";
import {Modal} from "../../../../../libs/uiKit/modal";
import {FieldsBuilder} from "../../../../../libs/uiKit/fieldsBuilder";
import Button from "../../../../../libs/uiKit/button";
import {SEND_PAYMENT_LINK} from "../../../../../libs/api/commands";
import {
  SendPaymentLinkMutation,
  SendPaymentLinkMutationVariables
} from "../../../../../libs/api/graphqlTypes";

const typeRadioItems: IRadioItem[] = [
  {label: "E-mail", value: "email"},
  {label: "Телефон", value: "phone"},
];

const SendPayLinkModal = (props: IModalProps) => {
  const {data} = props || {};
  const {email, phone, agreementId, invoice} = data || {}
  const {setNotification} = useNotification();
  const formHook = useForm({mode: "onBlur"});
  const {handleSubmit, watch} = formHook;
  const [sendPayLink, {loading: loadingSendPayLink}] = useMutation<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>(SEND_PAYMENT_LINK);

  const type = watch("type", typeRadioItems[0].value) || typeRadioItems[0].value;
  const isHavePhone: boolean = !!phone;

  const form: IForm = {
    formHook,
    hotReload: true,
    fields: [
      {
        isHidden: !isHavePhone,
        field: {
          fieldType: "radio",
          fieldName: "type",
          items: typeRadioItems,
          defaultValue: typeRadioItems[0].value
        }
      },
      {
        isHidden: isHavePhone ? type !== "phone" : true,
        field: {
          fieldType: "input",
          fieldName: "phone",
          placeholder: "Телефон",
          isDisabled: true,
          defaultValue: phone,
          readOnly: true,
        }
      },
      {
        isHidden: type !== "email",
        field: {
          fieldType: "input",
          fieldName: "email",
          isRequired: true,
          defaultValue: email,
          readOnly: false,
          validationType: "email",
          errorMessage: "Введите Email",
          placeholder: "Email",
        }
      },
      {
        field: {
          fieldType: "checkbox",
          fieldName: "isSendFinish",
          label: "Отправить пакет документов после оплаты",
        }
      }
    ]
  };

  const onFormSubmit = handleSubmit((formData) => {
    sendPayLink({
      variables: {
        agreementIsn: parseInt(agreementId, 10),
        ...formData,
        agreementNo: invoice?.agreementNo,
        premiumSum: invoice?.invoiceSum
      }
    })
      .then(() => {
        setNotification({type: 'success', text: 'Ссылка успешно отправлена'});
        props.onClose()
      })
      .catch(error => setNotification({type: "error", text: error}))
  })

  return (
    <Modal {...props} header="Отправить ссылку на оплату">
      <form onSubmit={onFormSubmit}>
        <FieldsBuilder {...form}/>
        <StyledButtonsGroup>
          <Button isLoading={loadingSendPayLink} appearance="filled" type="submit">Отправить</Button>
          <Button isLoading={loadingSendPayLink} appearance="transparent" onClick={props.onClose}>Отмена</Button>
        </StyledButtonsGroup>
      </form>
    </Modal>
  );
};

export default SendPayLinkModal;