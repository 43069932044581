import React from 'react';
import { usePrecalculationContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { StyledPrecalculationForm, StyledPrecalculationFormCard, StyledPrecalculationFormCardWrapper, StyledPrecalculationFormWrapper } from './styles';
import { ArrowIcon } from '../../../../icons/ui/Arrow';
import Button from '../../../../libs/uiKit/button';
import { Heading2 } from '../../../../libs/uiKit/globalStyledComponents';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import Tabs from '../../../../libs/uiKit/tabs';
import HouseInProgress from './HouseInProgress';
import HouseReady from './HouseReady';
import Result from './Result';
import { PROPERTY_TYPE } from '../../../../libs/utils/staticData';

const PrecalculationUi: React.FC = () => {
	const { goBackHandler, onFormSubmit, currentTab, precalculationForm, changeTabHandler, isShowHouseInProgress, isShowHouseReady, isLoading, isDisabled } =
		usePrecalculationContext();

	return (
		<MainLayoutIgniter>
			<StyledPrecalculationFormWrapper>
				<Button onClick={goBackHandler} appearance='text' iconBefore={<ArrowIcon direction='left' />}>
					Назад
				</Button>
				<StyledPrecalculationFormCardWrapper>
					<StyledPrecalculationFormCard>
						<Heading2>Создание предварительной заявки на расчет</Heading2>
						<form onSubmit={onFormSubmit}>
							<StyledPrecalculationForm>
								<FieldsBuilder {...precalculationForm} />
								<Tabs onChange={changeTabHandler} items={PROPERTY_TYPE} value={currentTab} />
								{isShowHouseInProgress && <HouseInProgress />}
								{isShowHouseReady && <HouseReady />}
							</StyledPrecalculationForm>
							<Button isDisabled={isDisabled} isLoading={isLoading} type='submit' appearance='filled'>
								Рассчитать
							</Button>
						</form>
					</StyledPrecalculationFormCard>
					<Result />
				</StyledPrecalculationFormCardWrapper>
			</StyledPrecalculationFormWrapper>
		</MainLayoutIgniter>
	);
};

export default React.memo(PrecalculationUi);
