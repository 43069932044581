import { GQLApi } from '../../../../../libs/api/gqlApi';
import { LoadAgreementQuery, LoadAgreementQueryVariables } from '../../../../../libs/api/graphqlTypes';
import { AGREEMENT_DRAFT } from '../../../../../libs/api/queries';
import { AgreementId } from '../../ui/context';

export type QueriesCreateAgreement = ReturnType<typeof QueriesCreateAgreement>;

export const QueriesCreateAgreement = (api: GQLApi) => ({
	getAgreementById: async (id: AgreementId) =>
		api.query<LoadAgreementQuery, LoadAgreementQueryVariables>({
			query: AGREEMENT_DRAFT,
			variables: {
				id,
			},
		}),
});
