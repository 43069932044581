import React, { useCallback } from 'react';
import { IFloatInputProps } from './types';
import Input from '../input';
import { TypeInputRef } from '../input/types';
import { stringToNumber } from '../utils';

let timeout: ReturnType<typeof setTimeout>;

const NumberInput: React.FC<IFloatInputProps> = React.forwardRef((props, ref: TypeInputRef) => {
	const { onChange, debounceMs, ...rest } = props;

	const onChangeDebounce = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			if (!debounceMs) return;
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				if (onChange) onChange(ev);
			}, debounceMs);
		},
		[debounceMs, onChange]
	);

	const inputChangeHandler = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => {
			const {
				target: { value },
			} = ev;
			ev.target.value = stringToNumber(value);
			if (typeof debounceMs === 'number') return onChangeDebounce(ev);
			if (onChange) onChange(ev);
		},
		[onChange, debounceMs, onChangeDebounce]
	);

	return <Input {...rest} ref={ref} onChange={inputChangeHandler} />;
});

export default React.memo(NumberInput);
