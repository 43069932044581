import React from 'react';
import AgreementTableUi from './ui';
import { AgreementTableContextProps, AgreementTableContextProvider } from './ui/context';

const AgreementTableIgniter: React.FC<AgreementTableContextProps> = props => (
	<AgreementTableContextProvider {...props}>
		<AgreementTableUi />
	</AgreementTableContextProvider>
);

export default React.memo(AgreementTableIgniter);
