import React from 'react';
import MainLayoutIgniter from '../../common/mainLayout';
import { useAgreementsLogContext } from './context';
import Button from '../../../../libs/uiKit/button';
import FlyButtons from '../../../../libs/uiKit/flyButtons';
import { Heading2, Text4 } from '../../../../libs/uiKit/globalStyledComponents';
import { StyledAgreementsLogHeader, StyledApplicationSearch, StyledFilter, StyledFilterButtons } from './styles';
import DropdownMenu from '../../../../libs/uiKit/dropdownMenu';
import Input from '../../../../libs/uiKit/input';
import { SearchIcon } from '../../../../icons/ui/Search';
import Table from '../../../../libs/uiKit/table';
import Accordion from '../../../../libs/uiKit/accordion';
import { CloseIcon } from '../../../../icons/ui/Close';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import SkipPrecalculationIgniter from '../../../widgets/skipPrecalculation';
import SearchProlongationModal from "../../contract/widgets/SearchProlongationModal";
import UserWarning from "../../../widgets/userWarning";

const AgreementsLogUi: React.FC = () => {
	const {
		isShowAgreements,
		isShowContracts,
		isShowProlongation,
		isOpenContinueContractHandler,
		openContinueContractHandler,
		closeContinueContractHandler,
		isCanCreateAgreement,
		dropDownItems,
		isHaveFilters,
		changeSearchHandler,
		searchText,
		isOpenAccordion,
		switchAccordionHandler,
		changePaginationHandler,
		isLoading,
		head,
		rows,
		total,
		selectedPage,

		form,
		onFormSubmit,
		accodionHeader,

		resetFiltersHandler,

		isOpenSkipPrecalculation,
		closeSkipPrecalculationonHandler,
		skipPrecalculationCallback,
		banksOk
	} = useAgreementsLogContext();

	return (
		<MainLayoutIgniter>
			{isShowAgreements && (
				<StyledAgreementsLogHeader>
					<Heading2>Журнал заявок</Heading2>
					<FlyButtons>
						<DropdownMenu
							trigger={
								<Button isDisabled={!isCanCreateAgreement} appearance='filled'>
									Создать заявку
								</Button>
							}
							items={dropDownItems}
						/>
					</FlyButtons>
				</StyledAgreementsLogHeader>
			)}
			{isShowContracts && (
				<StyledAgreementsLogHeader>
					<Heading2>Журнал договоров</Heading2>
				</StyledAgreementsLogHeader>
			)}
			{isShowProlongation && (
				<StyledAgreementsLogHeader>
					<Heading2>Журнал пролонгаций</Heading2>
					<FlyButtons>
						<Button appearance='filled' onClick={openContinueContractHandler} isDisabled={!banksOk}>
							Продлить договор
						</Button>
					</FlyButtons>
				</StyledAgreementsLogHeader>
			)}

			{!banksOk && <UserWarning />}

			<StyledApplicationSearch>
				<Input placeholder='Поиск' debounceMs={600} defaultValue={searchText} onChange={changeSearchHandler} iconBefore={<SearchIcon />} />
				<Accordion header={<Text4>{accodionHeader}</Text4>} isOpen={isOpenAccordion} onChange={switchAccordionHandler}>
					<form onSubmit={onFormSubmit}>
						<StyledFilter>
							<FieldsBuilder {...form} />
							<StyledFilterButtons>
								<Button appearance='filled' type='submit'>
									Применить
								</Button>
								<Button appearance='text' iconBefore={<CloseIcon />} onClick={resetFiltersHandler}>
									Очистить
								</Button>
							</StyledFilterButtons>
						</StyledFilter>
					</form>
				</Accordion>
			</StyledApplicationSearch>

			<Table
				emptyView={isHaveFilters ? 'По данным параметрам ничего не найдено' : 'Здесь пока ничего нет'}
				isLoading={isLoading}
				onChange={changePaginationHandler}
				total={total}
				rowsPerPage={10}
				selectedPage={selectedPage}
				head={head}
				rows={rows}
			/>
			<SkipPrecalculationIgniter onClose={closeSkipPrecalculationonHandler} isOpen={isOpenSkipPrecalculation} skipCallback={skipPrecalculationCallback} />
			<SearchProlongationModal onClose={closeContinueContractHandler} isOpen={isOpenContinueContractHandler}/>
		</MainLayoutIgniter>
	);
};

export default React.memo(AgreementsLogUi);
