import React from 'react';
import ContragentsUi from './ui';
import ContragentsContextProvider from './ui/context';

const ContragentsPage: React.FC = () => (
	<ContragentsContextProvider>
		<ContragentsUi />
	</ContragentsContextProvider>
);

export default React.memo(ContragentsPage);
