import React from "react";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {IModalProps} from "../../../../libs/uiKit/modal/types";
import useNotification from "../../../widgets/notifier/ui/hooks/useNitification";
import {
  AddendumMutation,
  AddendumMutationVariables,
  AddendumReasonEnum,
  SendToSbMutation, SendToSbMutationVariables
} from "../../../../libs/api/graphqlTypes";
import {ADDENDUM, SEND_TO_SB} from "../../../../libs/api/commands";
import {IFormField} from "../../../../libs/uiKit/fieldsBuilder/types";
import {formatDateToStringRu} from "../../../../libs/utils/gormatDateToUsa";
import FormModal from "../../../widgets/FormModal";


const SendToSbModal = (props: IModalProps) => {
  const {data} = props;
  const {life_risks, property_risks, titul_risks} = data;
  const { id } = useParams<{ id?: string }>();

  const {setNotification} = useNotification();

  const [sendToSb, {loading: loadingSendToSb}] = useMutation<SendToSbMutation, SendToSbMutationVariables>(SEND_TO_SB, {
    refetchQueries: ['loadAgreementUnderwriting'], awaitRefetchQueries: true
  });

  const risks = [
    ...(life_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: `${risk?.type} ${risk?.fio}`
    })),
    ...(property_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: risk?.type
    })),
    ...(titul_risks || []).map((risk: any) => ({
      isn: risk?.isn,
      label: risk?.type
    })),
  ];

  const riskFields : IFormField[] = risks.map((risk) => ({
    field: {
      fieldType: "checkbox",
      fieldName: `risk-${risk.isn}`,
      label: risk?.label,
    }
  }));

  const onSubmit = (formData: any) => {
    if(!id) return;
    const riskIsns: string[] = [];
    Object.keys(formData).forEach((key) => {
      if(key.startsWith('risk') && formData[key]){
        riskIsns.push(key.substring(5))
      }
    })
    sendToSb({
      variables: {
        id: parseInt(id, 10),
        input: {
          remark: formData.remark,
          risks: riskIsns,
        }
      }
    }).then((data) => {
      console.log('done send to SB');
      props.onClose();
    })
      .catch(error => setNotification({type: "error", text: error}))
  }

  return (
    <FormModal {...props}
               header="Направить в СБ"
               fields={[
                 ...riskFields,
                 {
                   field: {
                     fieldName: "remark",
                     fieldType: "textarea",
                     placeholder: "Примечание"
                   }
                 },
               ]}
               onSubmit={onSubmit}
               submitButton="Отправить"
               isLoading={loadingSendToSb}
    />

  );
}

export default SendToSbModal;