import styled from 'styled-components';
import { ANIMATION_SEC } from '../globalStyles';

export const StyledTabsWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 3rem;
`;

export const StyledTab = styled.div`
	height: 3rem;
	display: flex;
	align-items: center;
	padding: 0 2rem;
	cursor: pointer;
	border-radius: 5px;
	transition: all ${ANIMATION_SEC}s ease;
`;

export const StyledTabWatcher = styled.div<{ width: number | null; height: number | null; left: number | null; isFirstRender: boolean }>`
	position: absolute;
	border-bottom: 2px solid ${({ theme }) => theme.tabs.selected.border};
	pointer-events: none;

	width: ${({ width }) => (width ? width + 'px' : 0)};
	height: ${({ height }) => (height ? height + 10 + 'px' : 0)};
	left: ${({ left }) => (left ? left + 'px' : 0)};

	transition: ${({ isFirstRender }) => (isFirstRender ? 'none' : 'all')} ${ANIMATION_SEC}s ease-in-out;
`;
